// import npm packages
import React from 'react';
import { connect } from 'react-redux';

// import local files
import InstallerDashboard from '../Installer/container/dashboard';
import HomeownerDashboard from '../homeowner/container/dashboard/dashboard';
import AdminDashboard     from "../Admin/components/dashboard/dashboard"
import withRouter   from '../../shared/withRouter';
import { getToken } from '../../shared/util';
import { jwtDecode } from '../../shared/jwt';

class DashboardCommon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      role: null,
      data: null
    };
  }

  componentWillMount() {
    this.slug = localStorage.getItem('slug');
    let token = getToken();
    this.installerData = jwtDecode(token);
    this.setState(
      {
        role: this.installerData.role,
        data: this.installerData
      }
    );
  }

  render() {
    let { role, data } = this.state;
    if(window.location.pathname.includes('admin'))
      role = 'admin';

    switch (role) {
      case 'admin':
        return <AdminDashboard {...this.props}/>;
      case 'homeowner':
        return <HomeownerDashboard {...this.props} />;
      case 'homeowner-secondary':
        return <HomeownerDashboard {...this.props} />;
      default:
        return <InstallerDashboard {...this.props} data={data} />;
    }
  }
}
const mapStatetoProps = state => {
  return {
    auth: state.auth,
    header: state.header
  };
};

export default withRouter(
  connect(
    mapStatetoProps,
    null
  )(DashboardCommon)
);
