import { combineReducers } from 'redux';
import { reducerPowerMeter,reducerStateList,reducerSlugStatus,reducerAddCompany,reducerHomeownerReport, reducerReferralReport } from './dashboard';
import {reducerGetCompanyById, reducerEditInstaller, reducerEditInstallerManagment, uploadCSVReducer} from './editInstaller'


const dataReducer = combineReducers({
	powerMeter: reducerPowerMeter,
	stateList: reducerStateList,
	slugStatus: reducerSlugStatus,
	addCompany: reducerAddCompany,
	getCompanyById: reducerGetCompanyById,
	editInstaller: reducerEditInstaller,
	EditInstallerManagment:reducerEditInstallerManagment,
	uploadCSV :uploadCSVReducer,
	homeownerReport :reducerHomeownerReport,
	referralReport :reducerReferralReport

})


export const reducer = combineReducers({
	data : dataReducer
});