// import npm modules
import React, { Component }         from 'react';
import { Form, Input,
          Select, Radio }  from 'antd';
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';
import premium_sample               from "./premium-sample.json"
import { jwtDecode }                from "../../../../shared/jwt";
import { getToken }                 from '../../../../shared/util';
import {setLoader}                  from '../../../Common/Loader/action';
import CompanyLogoUpload            from "../../../Common/CompanyLogoUpload"
import {deletePremiumAccount}       from "../../api/companyJourney";
import {envImageUrl,envAppIcon}     from "../../../User/components/setUserBackgroud";
import {
  requestCompanyJourney,
  addCompanyJourney,
  receiveCompanyJourney,
  receiveUpdateCompanyJourney
}                                   from "../../actions/companyJourney";
import { appFontOptions } from '../../../../constants'
import {ReactComponent as CaretIcon}  from "../../../../Assets/Images/path-2.svg";

const FormItem = Form.Item;
const Option = Select.Option;

const PlanCustomizationForm = props => {
  const [form] = Form.useForm()

  return (
    <>
    <div className="content-box plan detail">
      <Form form={ form } onFinish={ props.handleSubmit }>
        {
          props.installerData && props.installerData.slug === "admin" &&
          <Radio.Group
            className="mb-3"
            onChange={props.handleChange}
            value={props.isPremium}
          >
            <Radio className="d-inline-flex" value={false} >
              <label htmlFor="company name" className="lbl-cmn-field m-0">Standard Plan</label>
            </Radio>
            <Radio className="d-inline-flex" value={true}>
              <label htmlFor="company name" className="lbl-cmn-field m-0">Premium Plan</label>
            </Radio>
          </Radio.Group>
        }

        <div className="internal-cmn-form" style={{paddingTop:0}}>
          <div className="form-heading">Styling</div>
          <div className="row m-0">
            <div className="col-md-3">
              <div className="cmn-label-field-input">
                <div className="form-group cmn-form-grp-input">
                  <label htmlFor="company name" className="lbl-cmn-field">Font</label>
                  <FormItem hasFeedback>
                    <Select
                      suffixIcon = { <CaretIcon /> }
                      disabled={!props.isPremium}
                      className="form-control filter-select"
                      id="exampleFormControlSelect1"
                      placeholder="Select"
                      value={ props.fontType }
                      onChange={ props.fontChangeHandler }
                    >
                      { props.fontDropdown }
                    </Select>
                  </FormItem>
                </div>
              </div>
            </div>
            <div className="col-md-9">
              <label className={`lbl-cmn-field dynamicFont ${props.fontType}`}>{props.companyDetail && props.companyDetail.name}</label>
            </div>
          </div>

          <div className="row color">
            <div className="col-md-4">
              <div className="form-group cmn-form-grp-input">

              <label htmlFor="company name" className="lbl-cmn-field">Button fill</label>
                <Input
                  className="form-control color-input"
                  placeholder="Use hex code: #04bfff"
                  value={props.premiumButtonFill}
                  disabled={!props.isPremium}
                  onChange={props.premiumButtonFillHandler}
                />
                <div className="color-view" style={{ 'backgroundColor': props.premiumButtonFill }} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group cmn-form-grp-input">
                <label htmlFor="company name" className="lbl-cmn-field">Button Text</label>
                <Input
                  className="form-control color-input"
                  placeholder="Use hex code: #fc0"
                  value={props.premiumButtonText}
                  disabled={!props.isPremium}
                  onChange={props.premiumButtonTextHandler}
                />
                <div className="color-view" style={{ 'backgroundColor': props.premiumButtonText }} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group cmn-form-grp-input">
                <label htmlFor="company name" className="lbl-cmn-field">Background</label>
                <Input
                  className="form-control color-input"
                  placeholder="Use hex code: #04bfff"
                  value={props.premiumBackground}
                  disabled={!props.isPremium}
                  onChange={props.premiumBackgroundHandler}
                />
                <div className="color-view" style={{ 'backgroundColor': props.premiumBackground }} />
              </div>
            </div>
          </div>
          <div className="row color">
            <div className="col-md-4">
              <div className="form-group cmn-form-grp-input">
                <label htmlFor="company name" className="lbl-cmn-field">Icons</label>
                <Input
                  className="form-control color-input"
                  placeholder="Use hex code: #fc0"
                  value={props.premiumIcon}
                  disabled={!props.isPremium}
                  onChange={ props.premiumIconHandler }
                />
                <div className="color-view" style={{ 'backgroundColor': props.premiumIcon }} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group cmn-form-grp-input">
                <label htmlFor="company name" className="lbl-cmn-field">Secondary text</label>
                <Input
                  className="form-control color-input"
                  placeholder="Use hex code: #04bfff"
                  value={props.premiumSecondaryText}
                  disabled={!props.isPremium}
                  onChange={ props.premiumSecondaryTextHandler }
                />
                <div className="color-view" style={{ 'backgroundColor': props.premiumSecondaryText }} />
              </div>
            </div>
            <div className="col-md-4">
              <div className="form-group cmn-form-grp-input">
                <label htmlFor="company name" className="lbl-cmn-field">Links & miscellaneous</label>
                <Input
                  className="form-control color-input"
                  placeholder="Use hex code: #fc0"
                  value={props.premiumLinks}
                  disabled={!props.isPremium}
                  onChange={ props.premiumLinksHandler }
                />
                <div className="color-view" style={{ 'backgroundColor': props.premiumLinks }} />
              </div>
            </div>
          </div>
        </div>

        {
          props.uploadComponents
        }
        <div className="row">
          <div className="text-center margt40 margin-auto width-100 btn-mobiel comp">
            <button
              className="btn btn-secondary"
              onClick={ props.cancelHandler }
              type="button"
            >
              Cancel
            </button>
            <button className="btn btn-primary" type="submit">
              Save
            </button>
          </div>
        </div>
      </Form>
    </div>
    </>
  )
}

class PlanDetailForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
        premiumSecondaryText : '#FFD110',
        isPremium         : false,
        premiumBackground : '#00142D',
        premiumButtonFill : '#FFD110',
        premiumButtonText : '#00142D',
        premiumIcon       : '#FFD110',
        premiumLinks      : '#FF6F47',
        primaryColor      : '#04bfff',
        secondaryColor    : '#fc0',
        font              : 'harmonia',
        loginUrl          : '',
        homeUrl           : ''
    };
    this.baseState = { ...this.state };
    this.fontOption = appFontOptions;
  }
  
  componentDidMount() {
    let token = getToken()
    let {companyData:{companyDetail}} = this.props;

    this.installerData = jwtDecode(token);
    this.props.receiveCompanyJourney({});

    envImageUrl(companyDetail.slug || this.installerData.slug).then(r=>{
      this.setState({
        loginUrl : r,
        isPremium : companyDetail.isPremium
      });
    });

    envAppIcon(companyDetail.slug || this.installerData.slug).then(r=>{
      this.setState({
        homeUrl : r
      })
    })
      
    if (companyDetail && companyDetail.isPremium) {
      let data = {
        slug    : companyDetail.slug || this.installerData.slug,
        premium : true,
        type    : 'premium'
      };
      this.props.setLoader({loader: true});
      this.props.requestCompanyJourney(data);
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.companyJourney && nextProps.companyJourney[0]){
      this.setState(nextProps.companyJourney[0]);
    }    
  }


  wc_hex_is_light(color) {
    const hex = color.replace('#', '');
    const c_r = parseInt(hex.substr(0, 2), 16);
    const c_g = parseInt(hex.substr(2, 2), 16);
    const c_b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
    return brightness > 155 ? '#000000' : "#FFFFFF";
  }

  handleSubmit = values => {
    let { companyData: { companyDetail } } = this.props;

    let postData = this.state;
    postData.companyName = companyDetail.name;
    let premiumData = JSON.stringify(premium_sample);
    premiumData = JSON.parse(premiumData.replace(/#premiumIcon/g,this.state.premiumIcon).replace(/#premiumBackground/g,this.state.premiumBackground).replace(/#premiumLinks/g,this.state.premiumLinks).replace(/#premiumButtonFill/g,this.state.premiumButtonFill).replace(/#premiumButtonText/g,this.state.premiumButtonText).replace(/#premiumSecondaryText/g,this.state.premiumSecondaryText).replace(/#avatarColor/,this.wc_hex_is_light(this.state.premiumIcon)));

    if (!postData.isPremium && (companyDetail && companyDetail.isPremium)) {
      let data = {
        companyId   : companyDetail.id,
        slug        : companyDetail.slug,
        premium     : true,
        type        : 'premium'
      };
      this.props.receiveCompanyJourney({});
      this.props.setLoader({loader:true});
      deletePremiumAccount(data).then(r => {
        this.setState(this.baseState);
        this.props.requestCompanyJourney(data);
        return;
      }).catch(() => {
        this.props.setLoader({loader:false});
        return;
      })
     
    } else if (postData.isPremium) {
      let data = {
        companyId : companyDetail.id,
        slug      : companyDetail.slug,
        premium   : true,
        premiumData: premiumData,
        triggerData: postData,
        type       : 'premium'
      };
      this.props.addCompanyJourney(data);
    }
  };

  cancelHandler = () => {
    let {companyData:{companyDetail}} = this.props;
    if (companyDetail.isPremium){
      let data = {
        slug    :  companyDetail.slug || this.installerData.slug,
        premium : true,
        type    : 'premium'
      }
      this.props.setLoader({loader:true});
      this.props.requestCompanyJourney(data);
    } else {
      this.setState(this.baseState);
    }
  };

  renderDropDown(arr) {
      return arr.sort().map((ele, i) => {
          return <Option
            key={i}
            value={ele.key}
            >
              {ele.label}
            </Option>
      })
  }


  getSource = (type, url) => {
    this.setState({
      [type]: url
    });
  };

  isFieldDisabled() {
    if (!this.state.isPremium)
      return true;
    else
      return false
  }

  addFontFamily(fonts) {
    if (!fonts)
      return;

    switch (fonts) {
      case 'gothic':
        return <link id="dynamic-font" href="http://fonts.cdnfonts.com/css/century-gothic" rel="stylesheet" />;
      case 'futura':
        return <link id="dynamic-font" href="//db.onlinewebfonts.com/c/921351f146d78d55c8030239527bf2d6?family=Futura+Std" rel="stylesheet" type="text/css" />
      case 'kulim':
        return <link href="https://fonts.googleapis.com/css2?family=Kulim+Park:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap" rel="stylesheet" />;
      default:
        return <link id="dynamic-font" href="http://fonts.cdnfonts.com/css/century-gothic" rel="stylesheet" />;
    }
  }

  getUploadComponents(slug) {
    return (
      <div className="internal-cmn-form pdt-25">
        <div className="form-heading"> Branded images </div>
        <div className="company-and-their-logo">
          <div className="row">
            <div className="form-group col-5">
              <label htmlFor="exampleFormControlInput1">Login page background</label>

              <CompanyLogoUpload
                {...this.props}
                from="edit"
                type="loginUrl"
                fileType="jpg"
                fileName={`${slug}_loginImage`}
                slug={slug}
                disabled = {this.isFieldDisabled()}
                isClear={false}
                saveUploadUrl={this.getSource}
                style={{ maxWidth: "150px", maxHeight: "45px", marginTop: "5px", display: "block" }}
                url={this.state.loginUrl}
              />
            </div>
            <div className="form-group col-3">
              <label htmlFor="exampleFormControlInput1">Home screen icon</label>

              <CompanyLogoUpload
                isClear={this.clear}
                {...this.props}
                from="edit"
                fileType="jpg"
                disabled = {this.isFieldDisabled()}
                fileName={`${slug}_homeIcon`}
                type="homeUrl"
                slug={slug}
                saveUploadUrl={this.getSource}
                style={{ maxWidth: "300px", maxHeight: "45px", marginTop: "5px", display: "block" }}
                url={this.state.homeUrl}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      dashboard,
      companyData: { companyDetail }
    } = this.props;

    const slug = companyDetail && companyDetail.slug;

    return (
      <PlanCustomizationForm
        handleSubmit = { this.handleSubmit }
        installerData = { this.installerData }
        handleChange = { (e) => this.setState({ isPremium: e.target.value }) }
        cancelHandler = { () => this.cancelHandler() }
        isPremium = { this.state.isPremium }
        fontType = { this.state.font || "roboto" }
        fontChangeHandler = { (e) => {
            this.setState({ font: e });
        } }
        fontDropdown = { this.renderDropDown(this.fontOption) }
        companyDetail = { companyDetail }
        premiumButtonFill = { this.state.premiumButtonFill }
        premiumButtonFillHandler = { (e) => this.setState({ premiumButtonFill: e.target.value }) }
        premiumButtonText = { this.state.premiumButtonText }
        premiumButtonTextHandler = { (e) => this.setState({ premiumButtonText: e.target.value }) }
        premiumBackground = { this.state.premiumBackground }
        premiumBackgroundHandler = { (e) => this.setState({ premiumBackground: e.target.value }) }
        premiumIcon = { this.state.premiumIcon }
        premiumIconHandler = { (e) => this.setState({ premiumIcon: e.target.value }) }
        premiumSecondaryText = { this.state.premiumSecondaryText }
        premiumSecondaryTextHandler = { (e) => this.setState({ premiumSecondaryText: e.target.value }) }
        premiumLinks = { this.state.premiumLinks }
        premiumLinksHandler = { (e) => this.setState({ premiumLinks: e.target.value }) }
        uploadComponents = { this.getUploadComponents(slug) }
      />
    );
  }
}

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    setCompanyDetail: state.setting.setCompanyDetail,
    companyJourney: (state.companyBodhiJourney && state.companyBodhiJourney.bodhiJourneyReducer
      && state.companyBodhiJourney.bodhiJourneyReducer.data)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ 
    requestCompanyJourney,
    receiveCompanyJourney,
    receiveUpdateCompanyJourney,
    addCompanyJourney,
    setLoader }, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(PlanDetailForm);
