import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import Header from '../../../screens/Admin/components/header'

function addScript() {
  return (
    <Helmet>
      <script type="text/javascript">
        {`!function (e, t, n) {
      function a() {
        var e = t.getElementsByTagName("script")[0], n = t.createElement("script");
        n.type = "text/javascript", n.async = !0, n.src = "https://beacon-v2.helpscout.net", e.parentNode.insertBefore(n, e);
      }
      if (e.Beacon = n = function (t, n, a) { e.Beacon.readyQueue.push({ method: t, options: n, data: a }) }, n.readyQueue = [], "complete" === t.readyState) return a(); e.attachEvent ? e.attachEvent("onload", a) : e.addEventListener("load", a, !1)
    }
      (window, document, window.Beacon || function () { })`}
      </script>
      <script type="text/javascript">{`window.Beacon('init', 'be4037d0-1769-4c71-b3fc-f20936a0f566')`}</script>
    </Helmet>
  )
}

export default class AdminLayout extends Component {
  constructor(props) {
    super(props)
    document.body.classList.remove('homeowner-bg');
    document.body.classList.remove('bg-color');
    if(document.getElementById('beacon-container')){
      document.getElementById('beacon-container').style.display='block';
    }
  }

  render() {
    const Component = this.props.component

    return (
        <React.Fragment>
            <header>
            <Header {...this.props} />
            </header>
            {addScript()}
            <Component {...this.props} /*{...params}*/ />
        </React.Fragment>
    )
  }
}

