import React, { Component }     from 'react';
import { Progress }             from 'antd';
import {Link}                   from "react-router-dom"
import { jwtDecode }            from "../../../../shared/jwt";
import { getToken }             from '../../../../shared/util';
import {filterHomeownerTouches} from "../../../Common/FilterTouchHistory"
import EnergyFlow               from './energyFlow';

class BodhiJourney extends Component {

  displayName(phaseName,name,key){
    if(phaseName && phaseName[key] && phaseName[key].name){
      return phaseName[key].name;
    } else {
      return name;
    }
  }

  render() {
    const { systemGlance,loggedInUser,companyJourney } = this.props;

    let apiData = (loggedInUser && loggedInUser.solarUnit) ||  {};   
    let filterData = apiData.touchHistory && filterHomeownerTouches(apiData.touchHistory,[], 'default',companyJourney)[0];
    if(filterData && filterData.phase=='6'){
      filterData.phase = 5;
    }
    const element       = document.getElementsByClassName('premiumIcon');
    const style         = element[0] && window.getComputedStyle(element[0]);
    const premiumIcon   = style && style.getPropertyValue('color');
    let token = getToken()
    let slug = jwtDecode(token).slug;
    let userRole = jwtDecode(token)['role'] || '';
    if (systemGlance && systemGlance.solarGlance &&  systemGlance.solarGlance.dailySyncTime) 
    {
      return (
        <div className="white-box-secondary margb30 paddl25 paddr25  desktop-view-only">
          <EnergyFlow
            energyReport={this.props.energyReport}
            setLoader={this.props.setLoader}
            loggedInUser={this.props.loggedInUser}
            isInstaller={this.props.isInstaller}
            systemGlance={systemGlance}
          />
        </div>
      );
    }
    let phaseName = 0;
    if (filterData && filterData.phase)
      phaseName = Number(filterData.phase);

    let activeJourney = (loggedInUser && loggedInUser.solarUnit && loggedInUser.solarUnit.activeJourney &&
      Object.keys(loggedInUser.solarUnit.activeJourney.journeyPhase)) || ['design', 'permit', 'thebuild', 'inspection', 'poweringup'];

      if(activeJourney.indexOf('service')!=-1){
        activeJourney.splice(activeJourney.indexOf('service'),1);
      }

    let factor = activeJourney ? 100 / activeJourney.length : 20;
    let index = filterData ? filterData.phase : 0;
    let dynamicPhaseName = (loggedInUser && loggedInUser.solarUnit && loggedInUser.solarUnit.activeJourney &&
      loggedInUser.solarUnit.activeJourney.journeyPhase);
    let defaultPhases = [
      {name:'Design',     key:'design',     phase:1},
      {name:'Permitting', key:'permit',     phase:2},
      {name:'The Build',  key:'thebuild',   phase:3},
      {name:'Inspection', key:'inspection', phase:4},
      {name:'Powering Up',key:'poweringup', phase:5},
    ]
    
    return (
      <div className="white-box-secondary margb30 col pr-0">
        <div className="dashboard-heading text-center">
          YOUR SOLAR JOURNEY PROGRESS
        </div>
      <div className="your-progress-wrap">
      <div className="row">
          <div className="col-sm-7 col-6">
            <div className="text-center">
              <Link style={userRole.includes('homeowner') ? { cursor: 'pointer' }: { pointerEvents: 'none' }} to={{pathname :"/" + slug +  "/dashboard",state:{}}} >
              <Progress
                type="circle"
                percent={Math.ceil(factor * (index))}
                width={155}
                strokeColor={(premiumIcon) || '#FFD110'}
                format={percent => {
                  return (
                    <p className="bodhi-percent">
                      {percent}%{' '}
                      <span className="to-being-solar-power">
                        {' '}
                        To being solar powered{' '}
                      </span>
                    </p>
                  );
                }}
              />
              </Link>
            </div>
          </div>
          <div className="col-sm-5 journey-progress col-6">
            {
              defaultPhases.map((ele)=>{
                return (
                  
                  (activeJourney.includes(ele.key)) &&<p
                    className={
                      filterData && filterData.phase
                        ? filterData.phase == ele.phase
                          ? 'active-journey-progress premiumIcon'
                          : Number(filterData.phase) < ele.phase
                            ? 'journey-progress greytext'
                            : 'journey-progress blacktext'
                        : 'journey-progress blacktext'
                    }

                  > <Link style={userRole.includes('homeowner') ? { cursor: 'pointer' } : { pointerEvents: 'none' }} to={`/${slug}/resources/1/${ele.phase}`}>{this.displayName(dynamicPhaseName,ele.name,ele.key)}</Link>
                  </p>
                )
              })
            }
          </div>
        </div>
      </div>
      </div>
    );
  }
}

export default BodhiJourney;
