// import npm packages
import React, { Component } from "react";
import { Radio,DatePicker}  from "antd";
import moment               from "moment";
import withRouter     from '../../../../shared/withRouter'
import { getToken }   from "../../../../shared/util";

class ReviewCampaignForm extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }
  
    componentWillMount() {}
  
    componentWillReceiveProps(nextProps) {}  

    campaignReview(){
        return(
          <>
            <div className="col-sm-7" style={{paddingRight:'160px'}}>
            <h3 className="review-title">Campaign Name</h3>
          <p className="off-heading">{this.props.campaignHeadline}</p>
              <div className="form-group mt-0">

                {this.props.checkBoxWrapper}
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    <strong>Recipients</strong>
                  </label>
                  <p className="total-filter">{this.props.totalRecipients}{" "}
                  customers will receive this campaign</p>
                </div>

                <div className="form-group">
                  <label htmlFor="exampleInputEmail1">
                    <strong>Scheduled Send Date</strong>
                  </label>
                  <Radio.Group onChange={() => {
                    this.props.updateCampaignDataState('scheduledType',!this.props.scheduledType);
                  }} value={this.props.scheduledType}>
                    <div className="form-inline">
                      <div className="form-check">
                        <Radio value={true}/><span style={{"fontWeight":"300"}}>Date To Send</span>
                      </div>

                      <div className="form-group mt-0 ml-2">
                        <DatePicker 
                        disabledDate={this.disabledDate}
                        value={moment(parseInt(this.props.launchedDate))}
                        disabled={!this.props.scheduledType} 
                        className ='campaign-scheduled'
                        onChange={(date, dateString) => {
                          this.props.updateCampaignDataState('launchedDate',date.valueOf());
                        }} />
                      </div>
                    </div>

                    <div className="form-check pl-0 mt-3">
                      <Radio value={false}/><span style={{"fontWeight":"300"}}>Immediately</span>
                    </div>
                  </Radio.Group>
                </div>
                <div className="footer-btn-box mt-3">
                  <a className="btn btn-primary" onClick={() => {
                    this.props.updateCampaignDataState('testTrigger', true);
                    this.props.updateCampaignDataState('testPhone', '');
                    this.props.updateCampaignDataState('testEmail', '');
                  }
                  }>
                    Send test</a>
                </div>
              </div>
            </div>
            {this.props.previewPost()}
          </>
        )
    }

    disabledDate(current) {
      return current && current < moment().endOf('day');
    }

    render() {
      if (getToken()) {
        return this.campaignReview();
      } else {
        this.props.router.navigate("/");
        window.location.reload();
        return null;
      }
    }
  }

export default withRouter(ReviewCampaignForm)
