// import npm modules
import React, { Component }        from 'react';
import { Form, Input,
        Switch, Tooltip }           from 'antd';
import { connect }                 from 'react-redux';
import { bindActionCreators }      from 'redux';
import NumberFormat                from 'react-number-format';
// import local files
import {setLoader}                 from '../../../Common/Loader/action';
import { requestCompanyDetailSet } from '../../actions/profile';

const FormItem = Form.Item;
const { TextArea } = Input;

const ReferralForm = props => {

  const [form] = Form.useForm()

  const { companyData, cancelHandler, router } = props

  return (
    <Form form={form} onFinish={props.handleSubmit}>    
     <div className="section-from-box detail-info">
        <div className="row">
          <div className="col-sm-12 form-group">
            <div className="v-h-center detail-info-box">
              <div className="col-12">
                <FormItem
                  hasFeedback
                  name="referralMessage"
                  initialValue = { companyData && companyData.companyDetail
                        ? companyData.companyDetail.ReferralMessage
                        : '' }
                  rules = { [{ required: false }] }
                >
                  <TextArea
                    rows={4}
                    id="exampleFormControlTextarea1"
                    placeholder="Type message..."
                  />
                </FormItem>
              </div>
            </div>
          </div>
        </div>
        <div className="row margt40">
          <div className="col-sm-6">
            <div className="detail-info-box">
              <label
                htmlFor="referralAmount"
                className="detail-info-label comp-setting"
              >
                Referral Amount
              </label>
              <FormItem
                hasFeedback
                id="referralAmount"
                name="referralAmount"
                rules = { [{ required: false }] }
                initialValue = { companyData && companyData.companyDetail
                        ? companyData.companyDetail.referralAmount
                        : '' }
              >
                <NumberFormat
                  type="tel"
                  thousandSeparator={true} prefix={'$ '}
                  className="ant-input form-control ref-amount-company"
                  style={{ border: "0 none", height: "25px" }}
                />
              </FormItem>
              </div>
          </div>
          <div className="col-sm-6">
            <div className="detail-info-box">
              <Tooltip
                overlayClassName="black-tooltip"
                title="This will be used to calculate a prospective homeowner’s solar savings. If blank, Bodhi’s calculator will be used."
              >
                <label
                  htmlFor="calculatorUrl"
                  className="detail-info-label comp-setting"
                >
                  Calculator URL
              </label>
              </Tooltip>
              <FormItem
                hasFeedback
                id="calculatorUrl"
                name="calculatorUrl"
                initialValue = { companyData
                    && companyData.companyDetail
                    && companyData.companyDetail.calculatorUrl
                    && companyData.companyDetail.calculatorUrl }
                rules = { [{ required: false }, { pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ }] }
              >
                <Input className="text-lowercase" id="calculatorUrl" type="text" style={{ border: "0 none" }} />
              </FormItem>
            </div>
            <div className="detail-info-box">
              <Tooltip overlayClassName="black-tooltip" title="This will be used for the Book a Meeting link on the homeowner’s public referral pages.">
                <label
                  htmlFor="meetingUrl"
                  className="detail-info-label comp-setting"
                >
                  Meeting URL
              </label>
              </Tooltip>
              <FormItem
                id="meetingUrl"
                name="meetingUrl"
                hasFeedback
                initialValue = { companyData
                    && companyData.companyDetail
                    && companyData.companyDetail.meetingUrl
                    && companyData.companyDetail.meetingUrl }
                rules = { [{ required: false }, { pattern: /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/ }] }
              >
                <Input className="text-lowercase" id="meetingUrl" type="text" style={{ border: "0 none" }} />
              </FormItem>
            </div>
          </div>
        </div>

        <div className="col-sm-9">
          <FormItem
            name="referralType"
            rules = { [{ required: false }] }
            initialValue = { (companyData.companyDetail && companyData.companyDetail.referralType === 1)
                  ? true : false }
          >
              <Switch checked={props.referralType} checkedChildren="Double-sided"
                onChange={props.referralTypeChangeHandler}
                unCheckedChildren="Single-sided" style={{ 'margin': '30px 0 0 10px' }}
              />
          </FormItem>
        </div>

      </div>
      <div className="margin-b-20">
        <div className="text-center margt40 margin-auto width-100 btn-mobiel comp">
          <button
            className="btn btn-secondary"
            onClick={() => cancelHandler(form) }
            type="button"
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
          >
            Save
          </button>
        </div>
      </div>
    </Form>
  )
}

class ReferralMessageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  componentDidMount(){
    let {companyData} = this.props;
    if (companyData.companyDetail && companyData.companyDetail.referralType === 1) {
      this.setState({referralType:true});
    }
    else{
      this.setState({referralType:false});
    }
  }

  handleSubmit = values => {
    values.referralAmount = typeof (values.referralAmount) === "string" ? values.referralAmount.replace(/[^0-9]/g, "") * 1 : values.referralAmount;
    let bodyData = {
      data: {
        companyInfo: {
          ReferralMessage : values.referralMessage,
          referralAmount  : values.referralAmount,
          calculatorUrl   : values.calculatorUrl,
          meetingUrl      : values.meetingUrl,
          referralType    : this.state.referralType ? 1 : 2
        }
      },
      companyId: this.props.companyId
    };
    this.props.requestCompanyDetailSet(bodyData);
    this.props.setLoader({ loader: true })
  };

  cancelHandler = form => {
    form.resetFields()
    this.props.DelayedcancelHandler();
  };

  render() {
    const {
      companyData
    } = this.props;

    return (
      <div className="content-box box-Shadow">
        <div className="mob-box">Message detailing referral policy</div>
        <ReferralForm
          handleSubmit={this.handleSubmit}
          companyData={companyData}
          router={this.props.router}
          referralType={this.state.referralType}
          referralTypeChangeHandler={value => this.setState({ referralType: value })}
          cancelHandler={form => this.cancelHandler(form)}
        />
      </div>
    );
  }
}

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    setCompanyDetail: state.setting.setCompanyDetail
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ requestCompanyDetailSet,setLoader }, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(ReferralMessageForm);
