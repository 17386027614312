import { Services } from '../../../Api/services';
import * as config from '../../../shared/config';
import { openNotificationWithIcon } from '../../../shared/notification';

export const getFaqListApi = async id => {
  const url = `${config.COMPANY_BASE_URL}company/${id}/faq`;
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const getFaqPostApi = async data => {
  const url = `${config.COMPANY_BASE_URL}company/${data.companyId}/faq`;
let res = await Services.postCall(url, { faq: data.faqData },true);
  return { data: res.data, status: res.status };
};

export const setCompanyDetailApi = async data => {
  const url = `${config.COMPANY_BASE_URL}company/${
    data.companyId
  }/electricUtility`;
  const bodyData = data.data;
  let res = await Services.putCall(url, bodyData);

  if (res && res.status === 200) {
    openNotificationWithIcon(
      'success',
      'Updated settings saved successfully',
      'Success'
    );
  } else {
    openNotificationWithIcon('error', "I'm having trouble loading the page.Let's try refreshing.", 'Oops');
  }
  return { data: res.data, status: res.status };
};

export const getCompanyDetailApi = async data => {
  let url = `${config.COMPANY_BASE_URL}company/${data.companyId}/`;
  if(data.admin){
    url = url + `?admin=true`;
  }
  let res = await Services.getCall(url, true);
  return { data: res.data, status: res.status };
};

export const setInviteEmployesApi = async data => {
  const url = `${config.INSTALLER_BASE_URL}company/${
    data.companyId
  }/installers/`;
  const bodyData = {
    email: data.email ? data.email.toLowerCase() : data.email,
    slug: data.slug,
    name : data.name
  };

  let res = await Services.postCall(url, bodyData,true);

  openNotificationWithIcon('success', res.data.message, ' Success ');
  return { data: res.data, status: res.status };
};

export const updateInstallerByIdApi = async data => {
  const url = `${config.INSTALLER_BASE_URL}company/${
    data.companyId
  }/installers/${data.installerId}`;
  let res = {};
  if (data.isRevoked) {
    res = await Services.deleteCall(url, false, data.bodyData);
  } else {
    res = await Services.putCall(url, data.bodyData);
  }
  if (res && res.status === 200) {
    openNotificationWithIcon(
      'success',
      data.message || 'Profile saved successfully',
      'Success'
    );
  } else {
    openNotificationWithIcon('error', "I'm having trouble loading the page.Let's try refreshing.", 'Oops');
  }
  return { data: res.data, status: res.status };
};
export const updateCompanyDetailApi = async data => {
  const url = `${config.COMPANY_BASE_URL}company/${
    data.companyId
  }`;
  const bodyData =data.bodyData;
  let res= await Services.putCall(url, bodyData);
  if (res && res.status === 200) {
    openNotificationWithIcon(
      'success',
      'Default project manager has been updated successfully',
      'Success'
    );
  } else {
    openNotificationWithIcon('error', "I'm having trouble loading the page.Let's try refreshing.", 'Oops');
  }
  return { data: res.data, status: res.status };
};
