// import npm packages
import { call, put } from "redux-saga/effects";

// import local files
import { updateAccountDetails } from "../../screens/homeowner/api/settings";
import handleError from "../../shared/errorHandler";
import { setLoader } from "../../screens/Common/Loader/action";
import { receiveHomeownerData } from "../../screens/homeowner/actions/homeOwner";
import { openNotificationWithIcon } from '../../shared/notification';

function* editUpdateAccount(action) {
	try {
		const data = yield call(updateAccountDetails, action.data);
		yield put(setLoader({ loader: false }));
		yield put(receiveHomeownerData(data));
		openNotificationWithIcon('success', 'Profile saved successfully', 'Success');
	} catch (e) {
		yield put(setLoader({ loader: false }));
		handleError(e);
	}
}

export const homeownerSettings = {
	editUpdateAccount
};