import React, { Component }           from "react";
import { connect }                    from 'react-redux'
import {bindActionCreators}           from "redux";
import { Form, Input,Checkbox,Select }       from "antd";
import NumberFormat                   from 'react-number-format';
import { requestCompanyDetailSet }    from '../../actions/profile';
import { addCompanyJourney,
        requestCompanyCRMData,
        receiveCompanyCRMData,
        receiveUpdateCompanyJourney}  from "../../actions/companyJourney";
import {fetchs3File}                  from "../../../homeowner/api/fetchs3File";
import * as config                    from "../../../../shared/config";
import {openNotificationWithIcon}     from "../../../../shared/notification";
import {ReactComponent as CaretIcon}  from "../../../../Assets/Images/path-2.svg";

const { Option }   = Select;

class MultipleJourneyForm extends Component {
  constructor(props) {
    super(props);
    this.serviceOption = [
      {field:'crm', label:'CRM Field'}
    ];
    this.webhookOption =['hubspot','salesforce','monday']
    this.state = {
      journeyName : '',
      journeyKey : null,
      journeyPhase :{
        'design'    : {active:true},
        'permit'    : {active:true},
        'thebuild'  : {active:true},
        'inspection': {active:true},
        'poweringup': {active:true},
        'service'   : {active:true}
      },
      jourenyCondition : {}
    };
    this.baseState = this.state;
  }

  componentWillMount() {
    let {companyData : {companyDetail}} = this.props;
    let data = {
      companyId : companyDetail.id,
      slug :  companyDetail.slug
    } 
    this.props.setLoader({ loader: true });
    this.props.requestCompanyCRMData(data);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.companyCRMData && nextProps.companyCRMData.bodhiKeyFormat && this.webhookOption.indexOf(nextProps.companyCRMData.bodhiKeyFormat) != -1) {
      this.companyJourney = nextProps.companyCRMData['dealInfo'];
      this.bodhiKeyFormat = nextProps.companyCRMData['bodhiKeyFormat'];
      this.companyJourneyProps = nextProps.companyCRMData;
      if (nextProps.companyCRMData['serviceOption'] && this.serviceOption.length < 2) {
        this.companyJourney = nextProps.companyCRMData['dealInfo']['projObj'];
      }
    }

    if (nextProps.companyCRMData) {
      this.props.setLoader({ loader: false });
    }

    if (nextProps.updateJourney && nextProps.updateJourney.status) {
      this.props.setLoader({ loader: false });
      this.props.updateState(this.state.updateState);
      this.props.receiveUpdateCompanyJourney({});
    }
  }

  updateCompanyJourney = () => {
    let {companyData : {companyDetail},router:{location}} = this.props;
    let editJourneyData = (location.state && location.state.editJourneyData) || this.props.editJourneyData;
    let { journeyPhase, journeyName } = this.state;

    editJourneyData['journeyName'] = journeyName;
    editJourneyData['journeyPhase']= journeyPhase;
    companyDetail.journey[editJourneyData.journeyKey] = editJourneyData;
    let bodyData = {
      data: {
        companyInfo: {
          journey: companyDetail.journey
        }
      },
      companyId: this.props.companyId
    };
    this.props.requestCompanyDetailSet(bodyData);
  }


  handleSubmit = values => {

    let { companyData: { companyDetail }, editJourneyData } = this.props;
    let { journeyPhase, journeyName, jourenyCondition } = this.state;
    let journeyKey = [];

    if (!journeyName) {
      this.setState({ error: true, errorMessage: 'Journey name is mandatory.' });
      return;
    }

    if (editJourneyData && editJourneyData != "empty") {
      return this.updateCompanyJourney();
    }

    for (var i in journeyPhase) {
      if (journeyPhase[i].active) {
        journeyKey.push(i);
      } else {
        delete journeyPhase[i];
      }
    }

    if (journeyKey.length == 0) {
      this.setState({ error: true, errorMessage: 'Select atleast one installation phase' });
      return;
    }

    let obj = {
      journeyName: this.state.journeyName,
      isDeleted: false,
      jourenyCondition: jourenyCondition,
      journeyKey: editJourneyData && editJourneyData != "empty" ? editJourneyData.journeyKey : (!companyDetail.journey ? 0 : (companyDetail.journey.length)),
      journeyPhase: journeyPhase,
      s3Key: this.state.journeyName.replace(/ /g, '').toLowerCase()
    };

    if (companyDetail.journey) {
      let filter = companyDetail.journey.filter(ele => {
        let temp = typeof (ele) === "object" ? ele : JSON.parse(ele);
        return temp.journeyName.replace(/ /g, '').toLowerCase() == obj.s3Key
      })[0];
      if (filter) {
        this.setState({ error: true, errorMessage: 'Journey name is already taken. Please enter new value' });
        return;
      }
      companyDetail.journey.push(obj);
    } else {
      companyDetail.journey = [obj];
    }

    let url = `${config.S3_17TW_BUCKET}/companyJourney/${companyDetail.slug}/default.json`;

    this.setState({
      updateState: {
        journeyData: obj,
        companySettingState: this.props.journeyPhase(journeyKey[0]),
        journeyKey: journeyKey,
        journeyIndex: obj.journeyKey
      }
    });

    this.props.setLoader({ loader: true });
    fetchs3File(url).then(r => {
      let data = {
        companyId: companyDetail.id,
        triggerData: r.data,
        toolSearch: true,
        slug: `${companyDetail.slug}/${obj.s3Key}`,
        type: 'companyJourney'
      };
      let bodyData = {
        data: {
          companyInfo: {
            journey: companyDetail.journey
          }
        },
        companyId: this.props.companyId
      };

      this.props.addCompanyJourney(data);
      this.props.requestCompanyDetailSet(bodyData);
    }).catch(error=>{
      this.props.setLoader({ loader: false });
      companyDetail.journey.splice(companyDetail.journey.length-1,1);
      this.setState({refresh : !this.state.refresh});
      openNotificationWithIcon("error","Default file is not present.","File Missing");
    })

  };

  sortData(triggerData) {
    this.phaseArray = [];
    return this.orderKeys(triggerData, (a, b) => {
      if (!a.isDeleted && this.phaseArray.indexOf(a.phaseName) === -1) {
        this.phaseArray.push(a.phaseName);
      }
      return a.newTouchNo - b.newTouchNo;
    });
  }

  orderKeys(o, f) {
    var os = [], ks = [], i;
    for (i in o) {
      if (!o[i].isDeleted)
        os.push([i, o[i]]);
    }
    os.sort(function (a, b) { return f(a[1], b[1]); });
    for (i = 0; i < os.length; i++) {
      ks.push(os[i][0]);
    }
    return ks;
  };

  handleChange = (e) => {
    this.setState({
      error: "",
      email: e.target.value,
    })
  }

  onChange = (checkedValues) => {
    this.setState({phase:checkedValues})
  }

  render() {
    const { router:{location} ,companyData : {companyDetail}} = this.props;
    let editJourneyData = (location.state && location.state.editJourneyData) || this.props.editJourneyData;
    if (editJourneyData && (this.state.journeyKey != editJourneyData.journeyKey) && editJourneyData != "empty") {
      editJourneyData['edit'] = false;
      this.setState(editJourneyData);
    } else if (editJourneyData === "empty" && !this.state.edit) {
      this.baseState['edit'] =true;
      this.setState(this.baseState, () => {
        this.baseState['edit'] =false;
      });
    }

    const options = [
        { value: 'design',     label: 'Design' },
        { value: 'permit',     label: 'Permitting' },
        { value: 'thebuild',   label: 'The Build' },
        { value: 'inspection', label: 'Inspection' },
        { value: 'poweringup', label: 'Powering Up' },
        { value: 'service',    label: 'Service' },
    ];

    return (
      <div className="content-box box-Shadow">
        <div className='mob-box'>Create a notification sequence tailored to a subset of {companyDetail && companyDetail.name}'s installations.</div>
        <div className="section-from-box  border0 company-setting-wrap">
          <div className="form-wrap">
            <Form onFinish={this.handleSubmit}>
              <div className="form-group row mb-5">
                <label className="col-sm-12 bold-text">Name this journey:</label>
                <div className=" col-sm-8">
                  <Input
                    type="text"
                    className="form-control"
                    value={this.state.journeyName}
                    onChange={(e) => {
                      this.setState({ journeyName: e.target.value })
                      this.setState({ error: false });
                    }}
                  />
                </div>
                {
                  this.state.error && <label style={{
                    margin: '13px 13px 0',
                    color: 'rgb(200, 0, 0)',
                    height: 'auto'
                  }}>{this.state.errorMessage}</label>
                }
                
              </div>
              <label className="bold-text" style={{marginBottom:'20px'}}>Check all the installation phases that apply:</label>
              <div className="form-group row">
                <div className="col-sm-3"></div>
                <div className="col-sm-5 bold-text centered"><div>Alias</div></div>
                <div className="col-sm-3 bold-text"><div>Duration</div></div>
              </div>
              {
                options.map((ele, index) => {
                  return(
                    <div className="form-group row" key={index}>
                      <div className="col-sm-3">
                        <Checkbox
                          disabled={(editJourneyData && editJourneyData != "empty") ? true : false}
                          checked={this.state.journeyPhase[ele.value] && this.state.journeyPhase[ele.value].active} onChange={(e) => {
                            let obj = this.state.journeyPhase;
                            obj[ele.value]['active'] = e.target.checked;
                            this.setState({ journeyPhase: Object.assign(this.state.journeyPhase, obj) })
                          }}>
                          {ele.label}
                        </Checkbox>
                      </div>

                      <div className="col-sm-5">
                        <Input 
                        value = {(this.state.journeyPhase[ele.value] && this.state.journeyPhase[ele.value]['name'])||''}
                        disabled={!this.state.journeyPhase[ele.value] || !this.state.journeyPhase[ele.value]['active']}
                        onChange={(e) => {
                          let obj = this.state.journeyPhase;
                          obj[ele.value]['name'] = e.target.value;
                          this.setState({ journeyPhase: Object.assign(this.state.journeyPhase,obj) })
                        }} />
                      </div>
                      {
                        index != 5 &&
                        <div className="col-sm-3 journey-input">
                          <Form.Item
                            hasFeedback
                            name = { `days${index}` }
                            initialValue = { (this.state.journeyPhase[ele.value] && this.state.journeyPhase[ele.value]['days'] *1) || '' }
                          >
                            <NumberFormat
                              className="ant-input"
                              style={{ marginRight: '10px' }}
                              disabled={!this.state.journeyPhase[ele.value] || !this.state.journeyPhase[ele.value]['active']}
                              onChange={(e) => {
                                let obj = this.state.journeyPhase;
                                obj[ele.value]['days'] = e.target.value;
                                this.setState({ journeyPhase: Object.assign(this.state.journeyPhase, obj) })
                              }}
                            />
                            <span>days</span>
                          </Form.Item>
                        </div>
                      }
                    </div>
                  )
                })
              }

                {this.companyJourney && this.renderTriggerBlock(this.state.jourenyCondition || {})}

              <div className="form-submitted-btn">
                <button type="submit" className="btn btn-primary"> Save </button>
              </div>

            </Form>
          </div>
        </div>
      </div>
    );
  }

  // Render trigger block for webhook mapping

  capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }


  renderCRMKeys(journeyOption, pipelineOption) {
    let condition = pipelineOption || this.bodhiKeyFormat;
    switch (condition) {
      case 'sharepoint':
        return this.renderPipelineDropDown(journeyOption ? journeyOption : this.companyJourney, 'EntityPropertyName', 'Title');

      case 'teamwork':
        return this.renderPipelineDropDown(this.companyJourney, 'content', 'content');

      case 'scoop':
        return this.renderPipelineDropDown(this.companyJourney, 'id', 'name');

      case 'trello':
        let arr = [];
        this.companyJourney.map(ele => {
          arr = [...arr, ...ele['checkItems']]
        });
        return this.renderPipelineDropDown(arr, 'id', 'name');

      case 'monday':
        return this.renderPipelineDropDown(this.companyJourney, 'id', 'title');
      case 'solarnexus':
        return this.renderPipelineDropDown(this.companyJourney, 'id', 'name_before_completion');
      case 'dynamics365':
        return this.renderPipelineDropDown(this.companyJourney, 'Task No', 'Task Name');
      case 'hubspotPipeline':
        return this.renderPipelineDropDown(journeyOption, 'label', 'label')
      case 'insightlyCustom':
        return this.renderPipelineDropDown(journeyOption, 'CUSTOM_FIELD_ID', 'CUSTOM_FIELD_ID');
      default:
        return this.renderDropDown(journeyOption ? journeyOption : this.companyJourney)
    }
  }

  renderPipelineDropDown(data, key, label, isDisableSort) {
    var sortedData = [];
    if (data && label && data[0] && data[0]['label'] && !isDisableSort)
      sortedData = data.sort((a, b) => a[label].localeCompare(b[label], undefined, { caseFirst: "upper" }));
    else if (data && !isDisableSort)
      sortedData = data.sort();

    if (data && data.length > 0) {
      return data.map(ele => {
        if (this.bodhiKeyFormat == "solarnexus" && ele['milestone'])
          ele = ele['milestone'];
        return <Option value={key ? ele[key] : ele}>{label ? ele[label] : ele}</Option>
      })
    }
  }

  renderTriggerBlock =(element) => {
    let journeyOption;
    let placeholder = `${this.capitalize(this.bodhiKeyFormat)} key`;
    return (<>
      <label className="mt-5 bold-text" style={{ marginBottom: '20px', width: '100%' }}>Automatically set this journey on new installs when:</label>
      <div className="form-group row">
        <div className="col-sm-5">
          {this.companyJourney &&
            <Select  suffixIcon = {<CaretIcon/>}
              showSearch
              value={element && element['key']}
              className="form-control filter-select"
              id="exampleFormControlSelect1"
              style={{lineHeight:'15px'}}
              optionFilterProp="children"
              placeholder={placeholder}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onChange={
                (e) => {
                  if (e === "dealstage")
                    var hubspotPipeline = true;
                  else
                    var hubspotPipeline = false;

                  if (hubspotPipeline)
                    var v = 'Select Pipeline Stage'

                  let data = {
                    key: e,
                    value: v ? v : this.renderValue(journeyOption ? journeyOption[e] : this.companyJourney[e]),
                    [e]: v ? v : this.renderValue(journeyOption ? journeyOption[e] : this.companyJourney[e]),
                    hubspotPipelineActive: hubspotPipeline,
                    triggerCondition: 'equal',
                    triggerType: element.triggerType,
                  }
                  this.setState({ jourenyCondition: data });
                }
              }>
                 {this.renderCRMKeys(journeyOption)}
              {/* {this.renderDropDown(journeyOption ? journeyOption : this.companyJourney)} */}
            </Select>
          }
        </div>

        <div className="col-sm-2 bold-text" style={{lineHeight:'40px',textAlign:"center",maxWidth: '12%',
        'padding': 0}}>equals</div>

        {this.bodhiKeyFormat === "hubspot" && element && element.hubspotPipelineActive &&
          <div className="col-sm-4">
            <Select  suffixIcon = {<CaretIcon/>}
              value={(element && element['pipelineType']) || ''}
              className="form-control filter-select"
              id="exampleFormControlSelect1"
              onChange={
                (e) => {
                  let hubspotState = this.state.jourenyCondition
                  if (!hubspotState)
                    hubspotState = {};
                  hubspotState['pipelineType'] = e;
                  this.setState({ jourenyCondition: hubspotState })
                }
              }>
              <Option value=''>Select Pipeline</Option>
              {this.renderPipelineDropDown(this.companyJourneyProps['pipeline'], 'label', 'label')}
            </Select>
          </div>
        }

        {this.bodhiKeyFormat === "hubspot" && element['pipelineType'] &&
          <div className="col-sm-5">
            <Select  suffixIcon = {<CaretIcon/>}
              value={element['pipelineType'] && element && element['value']}
              className="form-control filter-select"
              id="exampleFormControlSelect1"
              onChange={
                (e) => {
                  Object.assign(element, { value: e, [element.key]: e });
                  this.setState({ jourenyCondition: element });
                }
              }>
              {this.companyJourneyProps['pipeline'] &&
                this.companyJourneyProps['pipeline'].map(ele => {
                  if (ele.label === element['pipelineType'])
                    return this.renderPipelineDropDown(ele.stages, 'stageId', 'label')
                })
              }
            </Select>
          </div>
        }
        {this.renderValueType(element)}
      </div>
    </>)
  }

  renderValueType(element,number) {
    let valueNumber = number ? 'value'+number : 'value';
    return (
      !element.hubspotPipelineActive && <div className="col-sm-3">
        <Input type="text"
          className="form-control filter-select"
          value={element && element[valueNumber]}
          style ={{height:'20px',marginTop:'10px'}}
          onChange={(e) => {
            if(!element || !element.key) {
              openNotificationWithIcon('error', 'Please select trigger field firstly', 'Error');
          }
          else {
              Object.assign(element, { [valueNumber]: e.target.value, [element.key]: e.target.value });
              this.setState({jourenyCondition:element})
          }
          }}
        />
      </div>
    )
  }

  renderValue(data) {
    if (this.bodhiKeyFormat === "hubspot") {
      return data.value;
    }
    else {
      return data;
    }
  }


  renderPipelineDropDown(data, key, label, isDisableSort) {
    var sortedData = [];
    if (data && label && !isDisableSort)
      sortedData = data.sort((a, b) => a[label].localeCompare(b[label], undefined, { caseFirst: "upper" }));
    else if (data && !isDisableSort)
      sortedData = data.sort();

    if (data && data.length > 0) {
      return data.map((ele, index) => {
        return <Option key={index} value={key ? ele[key] : ele}>{label ? ele[label] : ele}</Option>
      })
    }
  }

  //  render different type of dropdown options
  renderDropDown(data, isPersonlize) {
    if (!data || JSON.stringify(data) === '{}')
      return;

    //  render dropdown for podio
    if (data && Array.isArray(data)) {
      return this.renderPodioArrayDropDown(data, isPersonlize);
    }
    let arr = Object.keys(data);       

    return arr.sort().map((ele, index) => {
      if (!ele.includes('hs_'))
        return <Option key={index} value={isPersonlize ? (data[ele] || ele) : ele}>{`${this.appendValue(ele)}`}</Option>
    })
  }

  renderPodioArrayDropDown(data) {
    let sortedData = data.sort((a, b) => {
      if (a['label'])
        return a['label'].localeCompare(b['label'], undefined, { caseFirst: "upper" })
    });
    return sortedData.map((ele, index) => {
      return <Option key={index} value={ele['field_id']}>{`${this.appendValue(ele['label'])}`}</Option>
    })
  }

  appendValue = (ele) => {
    return `${ele}`
  }

}

const mapStatetoProps = state => {
  return {
    companyCRMData :  state.companyBodhiJourney.companyCRMDataReducer
    && state.companyBodhiJourney.companyCRMDataReducer.data,
    updateJourney :state.companyBodhiJourney && state.companyBodhiJourney.updateBodhiJourneyReducer
    && state.companyBodhiJourney.updateBodhiJourneyReducer,
    auth: state.auth,
    setCompanyDetail: state.setting.setCompanyDetail
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    requestCompanyDetailSet,
    requestCompanyCRMData,
    receiveCompanyCRMData,
    receiveUpdateCompanyJourney,
    addCompanyJourney
  }, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(MultipleJourneyForm)