// import npm packages
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { combineReducers } from 'redux'
import { reducer as authReducer } from 'aws-cognito-redux-saga'
import { reducer as formReducer } from 'redux-form'

// import local files
import sagas from './sagas/index'
import { reducer as addhomeOwnerReducer } from './screens/Installer/reducers/addHomeOwner/reducer'
import { reducer as headerReducer } from './screens/Installer/reducers/header/reducer'
import { reducer as userReducer } from './screens/User/reducer'
import { reducer as settingListReducer } from './screens/Installer/reducers/profile/reducer'
import { reducer as homeOwnerReducer } from './screens/Installer/reducers/homeOwner/reducer'
import { reducer as dashboardStatsReducer } from './screens/Installer/reducers/dashboard/reducer';
import { reducer as loaderStatsReducer } from './screens/Common/Loader/reducer';
import { reducer as cloudImagesReducer } from './screens/Common/ShowImageFromDrive/reducer';
import { reducer as homeownerDashboard } from "./screens/homeowner/reducers/dashboard/reducer";
import { reducer as homeownerFaq } from "./screens/homeowner/reducers/faq/reducer";
import { reducer as homeownerLoggedInUser } from "./screens/homeowner/reducers/homeowner/reducer";
import { reducer as homeownerResources } from "./screens/homeowner/reducers/resources/reducer";
import { reducer as homeownerContact } from "./screens/homeowner/reducers/contact/reducer";
import { reducer as homeownerReferralDetails } from "./screens/homeowner/reducers/referralDetails/reducer";
import { reducer as homeownersList } from "./screens/homeowner/reducers/solar/reducer";
import { reducer as statusFeedPhaseSurvey } from './screens/homeowner/reducers/statusFeed/reducer';
import { reducer as installerMeterConnection } from "./screens/Installer/reducers/testConnection/reducer";
import { reducer as homeownerGetEversign } from "./screens/homeowner/reducers/eversign/reducer";
import { reducer as homeownerResendInvitation } from "./screens/homeowner/reducers/forgotpassword/forgotpassword";
import { reducer as companyBodhiJourney} from "./screens/Installer/reducers/bodhiJourney/reducer";
import { reducer as companyCampaign} from "./screens/campaign/reducers/existingCampaign";
import { reducer as adminSetting } from "./screens/Admin/Reducers/Setting/setting";
import { reducer as adminHeader }  from "./screens/Admin/Reducers/Header/header";
import {reducer as adminOption } from "./screens/Admin/Reducers/Dashboard/reducer";
import {reducer as fleet}        from "./screens/Installer/reducers/fleet/reducer"

const sagaMiddleware = createSagaMiddleware()
let composeEnhancers = compose

if (process.env.NODE_ENV === 'development') {
  const composeWithDevToolsExtension =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  if (typeof composeWithDevToolsExtension === 'function') {
    composeEnhancers = composeWithDevToolsExtension
  }
}

const reducers = combineReducers({
  auth: authReducer.reducer,
  form: formReducer,
  addhomeOwner: addhomeOwnerReducer,
  header: headerReducer,
  user: userReducer,
  setting: settingListReducer,
  homeOwner: homeOwnerReducer,
  dashboardStats : dashboardStatsReducer,
  loader: loaderStatsReducer,
  cloudImages: cloudImagesReducer,
  homeownerDashboard,
  homeownerFaq,
  homeownerLoggedInUser,
  homeownerResources,
  homeownerContact,
  homeownerReferralDetails,
  homeownersList,
  statusFeedPhaseSurvey,
  installerMeterConnection,
  homeownerGetEversign,
  homeownerResendInvitation,
  companyBodhiJourney,
  companyCampaign,
  adminSetting,
  adminHeader,
  adminOption,
  fleet
})

export default createStore(
  reducers,
  composeEnhancers(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(sagas)