import React, { Component } from 'react';
import Modal from './Modal'

class GridImages extends Component {
  static defaultProps = {
    images: [],
    hideOverlay: false,
    renderOverlay: () => 'Preview Image',
    overlayBackgroundColor: '#222222',
    onClickEach: null,
  }

  constructor(props) {
    super(props)

    this.state = {
      modal: false,
      countFrom: props.images && props.images.length && props.images.length < 5 ? props.images.length : 5,
      conditionalRender: false
    };

    this.openModal = this.openModal.bind(this);
    this.onClose = this.onClose.bind(this);

    if(props.countFrom <= 0 || props.countFrom > 5) {
      console.warn('countFrom is limited to 5!')
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.images && nextProps.images.length && nextProps.images.length !== this.state.countFrom) {
      this.setState({
        countFrom:  nextProps.images.length < 5 ? nextProps.images.length : 5
      })
    }
  }
  openModal(index) {
    const {onClickEach, images, isOpen} = this.props;
    if(isOpen) {
      if(onClickEach) {
        return onClickEach({src: images[index], index})
      }

      this.setState({modal: true, url: images[index], index})
    }
  }

  onClose() {
    this.setState({modal: false})
  }

  renderOne() {
    const {images} = this.props;
    const {countFrom} = this.state;
    const overlay = images.length > countFrom && countFrom === 1 ? this.renderCountOverlay(true) : this.renderOverlay();

    return  <div className='container'>
      <div className='row'>
        <div
          className={`col-12 col-md-12 border height-one background`}
          onClick={this.openModal.bind(this, 0)}
          style={{background: `url(${images[0]})`}}
        >
          {overlay}
        </div>
      </div>
    </div>;
  }

  renderTwo() {
    const {images} = this.props;
    const {countFrom} = this.state;
    const overlay = images.length > countFrom && [2, 3].includes(+countFrom) ? this.renderCountOverlay(true) : this.renderOverlay();
    const conditionalRender = ([3, 4].includes(images.length) || images.length > +countFrom) && [3, 4].includes(+countFrom);

    return <div className='container'>
      <div className='row'>
        <div
         className="col-xs-6 col-md-6 border height-two background"
          onClick={this.openModal.bind(this, conditionalRender ? 1 : 0)}
          style={{background: `url(${conditionalRender ? images[1] : images[0]})`}}
        >
          {this.renderOverlay()}
        </div>
        <div
         className="col-xs-6 col-md-6 border height-two background"
          onClick={this.openModal.bind(this, conditionalRender ? 2 : 1)}
          style={{background: `url(${conditionalRender ? images[2] : images[1]})`}}
        >
          {overlay}
        </div>
      </div>
    </div>;
  }

  renderThree(more) {
    const {images} = this.props;
    const {countFrom} = this.state;
    const overlay = (!countFrom || countFrom > 5 || images.length) > countFrom && [4, 5].includes(+countFrom) ? this.renderCountOverlay(true) : this.renderOverlay();
    const conditionalRender = (images.length === 4 || images.length > +countFrom) && +countFrom === 4;
    return <div className='container images-grid'>
      <div className='row'>
        <div
          className="col-xs-6 col-md-4 border height-three background"
          onClick={this.openModal.bind(this, conditionalRender ? 1 : 2)}
          style={{background: `url(${conditionalRender ? images[1] : images[2]})`}}
        >
          {this.renderOverlay(conditionalRender ? 1 : 2)}
        </div>
        <div
          className="col-xs-6 col-md-4 border height-three background"
          onClick={this.openModal.bind(this, conditionalRender ? 2 : 3)}
          style={{background: `url(${conditionalRender ? images[2] : images[3]})`}}
        >
          {this.renderOverlay(conditionalRender ? 2 : 3)}
        </div>
        <div
          className="col-xs-6 col-md-4 border height-three background"
          onClick={this.openModal.bind(this, conditionalRender ? 3 : 4)}
          style={{background: `url(${conditionalRender ? images[3] : images[4]})`}}
        >
          {overlay}
        </div>
      </div>
    </div>;
  }

  renderOverlay(id) {
    const {hideOverlay, renderOverlay, overlayBackgroundColor} = this.props;

    if(hideOverlay) {
      return false
    }

    return [
      <div key={`cover-${id}`} className="cover slide" style={{backgroundColor: overlayBackgroundColor}}></div>,
      <div key={`cover-text-${id}`} className="cover-text slide animate-text"  style={{fontSize: '100%'}}>
        {renderOverlay()}
      </div>
    ]
  }

  renderCountOverlay(more) {
    const {images} = this.props;
    const {countFrom} = this.state;
    const extra = images.length - (countFrom && countFrom > 5 ? 5 : countFrom);

    return [more && <div key="count" className="cover"></div>, more && <div key="count-sub" className="cover-text" style={{fontSize: '200%'}}><p>+{extra}</p></div>]
  }

  render(){
    const {modal, index, countFrom} = this.state;
    const {images} = this.props;
    const imagesToShow = [...images];

    if(countFrom && images.length > countFrom) {
      imagesToShow.length = countFrom;
    }

    return(
        <div className="grid-container">
          {[1, 3, 4].includes(imagesToShow.length)  && this.renderOne()}
          {imagesToShow.length >= 2 && imagesToShow.length !== 4 && this.renderTwo()}
          {imagesToShow.length >= 4 && this.renderThree()}

          {modal && <Modal onClose={this.onClose} index={index} images={images}/>}
        </div>
    )
  }

}



export default GridImages;