// import npm packages
import React, { Component }     from "react";
import { Input, Switch }        from 'antd';
import { Form }                 from "antd";
import NumberFormat             from "react-number-format";
import { bindActionCreators }   from "redux";
import { connect }              from "react-redux";

// import local files
import { requestEditUpdateAccount }     from "../../actions/settings";
import { requestHomeownerAddUser ,
    receiveHomeownerAddUser}            from "../../../Installer/actions/homeOwner"
import ChangePassword                   from "../../../Installer/components/profile/changePassword";
import withRouter                       from '../../../../shared/withRouter';
import { setHeight }                    from '../../../../shared/setHeight';
import { DeleteModal }                  from "../../../Common/DeleteModal";
import { NotificationModal }            from "../../../Common/NotificationModal";
import {openNotificationWithIcon}       from '../../../../shared/notification';
import { deleteAdditionalUsers,
    addHomeownerAdditionalUser }        from "../../../Installer/api/homeOwner";
import {ReactComponent as AddIcon }     from "../../../../Assets/Images/addIcon.svg";
import deleteIcon from '../../../../Assets/Images/delete-icon.png'

const FormItem = Form.Item;

const AddUserForm = props => {

    const [form] = Form.useForm()
    const {
        handleSubmit,
        isInstaller,
        homeownerData,
        phoneNumberChangeHandler,
        cancelHandler
    } = props

    return (
        <Form form={form} onFinish={handleSubmit}>
            <hr className="update-acc-divider" />
            <label className="notification-lable">ADD ADDITIONAL USER</label>
            <div className="form-group paddt30 paddl50 paddr50">
                <label>FIRST NAME</label>
                <FormItem
                    hasFeedback
                    name="firstName"
                    rules = { [{
                        required: true
                    }] }
                >
                    <Input
                        type="text"
                        className="ant-input"
                    />
                </FormItem>
            </div>

            <div className="form-group paddt30 paddl50 paddr50">
                <label>LAST NAME</label>
                <FormItem
                    hasFeedback
                    name="lastName"
                    rules = { [{
                        required: true
                    }] }
                >
                    <Input
                        type="text"
                        className="ant-input"
                    />
                </FormItem>
            </div>

            <div className="form-group paddt30 paddl50 paddr50">
                <label>EMAIL</label>
                <FormItem
                    hasFeedback
                    name="email"
                    rules = { [{
                        required: true
                    }] }
                >
                    <Input
                        type="email"
                        className="ant-input"
                    />
                </FormItem>
            </div>

            <div className="form-group paddt30 paddl50 paddr50">
                <label>PHONE NUMBER</label>
                <FormItem
                    hasFeedback
                    name="userPhone"
                    rules = { [{
                        required: true
                    }] }
                >
                    <NumberFormat
                        disabled={isInstaller ? 'disabled' : ''}
                        format={homeownerData.formSetting && homeownerData.formSetting.phone}
                        mask=" "
                        className="ant-input"
                        placeholder="(   )  -  "
                        onChange={e => {
                            phoneNumberChangeHandler(e.target.value.replace(/[^0-9]/g, ""), homeownerData.formSetting && homeownerData.formSetting.phoneLength, 'phoneNumber')
                        }}
                    />
                </FormItem>
            </div>

            <div className="form-submitted-btn">
                <button
                    type='button'
                    className="btn btn-secondary yellow-btn reversePremiumButtonFill mt-0"
                    style={{width: '145px', border:'1px solid'}}
                    disabled={isInstaller ? 'disabled' : ''}
                    onClick={
                        () => cancelHandler(form)
                    }
                >
                    Cancel
                </button>
                <button
                    className="btn premiumButtonFill premiumButtonText mt-0"
                    style={{width: '145px'}}
                    disabled={isInstaller ? 'disabled' : ''}
                >
                    Add user
                </button>
            </div>
        </Form>
    )
}

const UpdateAccountForm = props => {

    const [form] = Form.useForm()
    const {
        handleSubmit,
        accountDetails,
        phoneNumberChangeHandler,
        isInstaller,
        cancelHandler,
        errorObj,
        formSetting,
        loggedInUser,
        smsNotificationInitialValue,
        emailNotificationInitialValue
    } = props

    return (
        <Form form={form} onFinish={handleSubmit} initialValues={{
            smsNotification: smsNotificationInitialValue,
            emailNotification: emailNotificationInitialValue
        }}>
            <label className="update-account">UPDATE ACCOUNT</label>
            <div className="form-group paddt30 paddl50 paddr50 margb0">
                <label>EMAIL</label>
                {
                    accountDetails &&
                        <input type="text" defaultValue={accountDetails.email} className="form-control" disabled />
                }
            </div>

            <div className="form-group paddt30 paddl50 paddr50">
                <label>PHONE NUMBER</label>
                {
                    accountDetails &&
                    <FormItem
                        hasFeedback
                        validateStatus={errorObj.phoneNumber || ''}
                        name="phone"
                        rules = { [{
                            required: true
                        }] }
                        initialValue = {
                            accountDetails && accountDetails.phone ? accountDetails.phone : "" 
                        }
                    >
                        <NumberFormat
                            type="tel"
                            disabled={isInstaller ? 'disabled' : ''}
                            format= {formSetting.phone}
                            mask=" "
                            className="ant-input"
                            placeholder="(   )  -  "
                            onChange={e => {
                                phoneNumberChangeHandler(e.target.value.replace(/[^0-9]/g, ""), formSetting.phoneLength, 'phoneNumber')
                            }}
                        />
                    </FormItem>
                }
            </div>
           
            <hr className="update-acc-divider" />
            <label className="notification-lable setting">NOTIFICATION PREFERENCES</label>
            <div className="form-group notification-btn homeowner margb10">
                {
                    accountDetails &&
                    <FormItem
                        name="smsNotification"
                        valuePropName="checked"
                    >
                        <Switch
                            className="premiumIcon bgColor"
                            disabled={ isInstaller }
                        />
                    </FormItem>
                }
                <label className="paddl20 text-left notification-lable-text">Text</label>
            </div>
            <div className="form-group notification-btn homeowner paddl10">
                {
                    accountDetails &&
                    <FormItem
                        name="emailNotification"
                        valuePropName="checked"
                    >
                        <Switch
                            className="premiumIcon bgColor"
                            disabled={ isInstaller }
                        />
                    </FormItem>
                }
                <label className="paddl20 text-left notification-lable-text">Email</label>
            </div>
            <div className="form-submitted-btn">
                <button
                    type='button'
                    className="btn btn-secondary yellow-btn reversePremiumButtonFill mt-0"
                    style={{width: '145px', border: '1px solid'}}
                    disabled={isInstaller ? 'disabled': ''}
                    onClick={
                        () => cancelHandler(form)
                    }
                >
                    Cancel
                </button>
                <button
                    className="btn premiumButtonFill premiumButtonText"
                    style={{width: '145px'}}
                    disabled={isInstaller ? 'disabled' : ''}
                >
                    Save settings
                </button>
            </div>
        </Form>
    )
}

class SettingsForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            addUserForm: false,
            deleteTrigger: false,
            userData : {},
            notificationTrigger : false,
            errorObj: {}
        }
        this.checkNumberRange = this.checkNumberRange.bind(this)
    }

    componentDidMount() {
        let {additionalUser,loggedInUser:{homeownerData}} = this.props;
        this.homeownerData = {
            homeownerId : homeownerData.id,
            companyId   : homeownerData.companyId
        }
        if (homeownerData && homeownerData.id && !additionalUser){
            this.props.requestHomeownerAddUser(this.homeownerData);
        }
    }

    componentWillReceiveProps() {
        setHeight('homeOwnerWrapper', 'menuMobile');
    }

    checkNumberRange(value, range, type) {
        const { errorObj } = this.state;
        switch (type) {
            case 'phoneNumber':
                if (value.length !== (range|| 10)) {
                    errorObj.phoneNumber = 'error'
                } else {
                    errorObj.phoneNumber = ''
                }
                this.setState({
                    errorObj
                })
                break;
            default:
                if (value > range) {
                    errorObj[type] = 'error'
                } else {
                    errorObj[type] = ''
                }
                this.setState({
                    errorObj
                })
                break;
        }
    }

    renderDeletePopUp = () =>{
        let headerText = 'Do you really want to delete this user?';
        let subText = "Deleting this user cannot be undone";
        return(
             <DeleteModal deletePopUpProp={this.deletePopUpProp} headerText={headerText} subText={subText}/>
        )
    }

    deletePopUpProp = value => {
        if (value === 'cancel') {
            this.setState({
                deleteTrigger: false,
                addUserForm: false,
                notificationTrigger: false
            });
        }
        else {
            this.setState({ deleteTrigger: false });
            this.props.setLoader({loader:true});

            let obj = {
              companyId : this.homeownerData.companyId,
              userEmail : this.state.userEmail,
              userId    : this.state.userId
            };
            deleteAdditionalUsers(obj).then(r => {
              if (r && r.status === 200) {
                this.props.setLoader({loader:false});
                openNotificationWithIcon('success', 'User is Deleted', 'Success');
                this.props.requestHomeownerAddUser(this.homeownerData);
              }
            }).catch((e => {
                this.props.setLoader({loader:false});
                openNotificationWithIcon('error', 'User is not Deleted', 'Error');
            }))
        }
    }

    addUserSubmit = values => {
        let {homeownerData} = this.props.loggedInUser

        let body = {
            homeownerId : homeownerData.id,
            companyId   : homeownerData.companyId,
            companyName : homeownerData.companyName,
            firstName   : values.firstName,
            middleName  : values.middleName,
            lastName    : values.lastName,
            email       : values.email,
            state       : homeownerData.address && homeownerData.address.state,
            phone       : values.userPhone.replace(/[^A-Z0-9]/ig, "")
        }

        this.props.setLoader({loader: true});

        addHomeownerAdditionalUser(body).then(r => {
            this.props.setLoader({loader:false});
            if (r && r.status === 200) {
                this.setState({
                    notificationTrigger: true,
                    userData: r.data
                })
                this.props.requestHomeownerAddUser(this.homeownerData);
            }
        }).catch(r => {
            this.props.setLoader({loader: false});
            openNotificationWithIcon('error',`The user was not added because there is already a Bodhi account for ${values.email}`,'Error');
        });
    }

    handleAccountFormSubmit = values => {
        let {homeownerData} = this.props.loggedInUser;
        let companyId   = homeownerData.companyId;
        let homeownerId = this.props.homeOwnerId || homeownerData.homeownerId;

        if (JSON.stringify(this.state.errorObj) !== '{}' && this.state.errorObj.phoneNumber) {
            return null;
        }
       
        let phone             = values.phone.replace(/[^0-9]/g, "");
        let emailNotification = values.emailNotification;
        let smsNotification   = values.smsNotification;
    
        this.props.setLoader({ loader: true })
        this.props.requestEditUpdateAccount({
            companyId,
            homeownerId,
            phone,
            emailNotification,
            smsNotification,
            /**
             * @todo: verify - don't see role on the loggedInUser.homeownerData prop...
            */
            // role : this.homeownerData.role
        });
    }

    OnCancelPress = form => {
        form.resetFields();
        this.setState({addUserForm: false})
    }

    switchValue(value) {
        if( value || value === false) {
            return value
        }
        return true;
    }

    goToDashboard() {
        this.props.router.navigate("/" + this.homeownerData.slug + "/settings");
    }

    renderPopUp() {
        let { userData } = this.state;        
        return(
            <NotificationModal deletePopUpProp={this.deletePopUpProp} name={`${userData.firstName} ${userData.lastName}`}
            email ={userData.email}  imageName={'check-success.svg'}
            />
        )
    }

    render() {
        let accountDetails = this.props && this.props.loggedInUser && this.props.loggedInUser.homeownerData;
        let titleValue = accountDetails &&  accountDetails.companyName;
        let formSetting = (accountDetails && accountDetails.formSetting) || {};
        return (
            <>
            <title>Settings - {titleValue}</title>
            {
                this.state.deleteTrigger &&  this.renderDeletePopUp()
            }
            {
                this.state.notificationTrigger && this.renderPopUp()
            }
            <div className="next-level-wrapp">
            <p className="page-title">Settings</p>
                <div className="white-box margb35 border0">
                    <div className="more-detail-wrapper">
                        <div>
                            <UpdateAccountForm
                                handleSubmit={this.handleAccountFormSubmit}
                                accountDetails={accountDetails}
                                isInstaller={this.props.isInstaller}
                                phoneNumberChangeHandler={this.checkNumberRange}
                                cancelHandler={form => this.OnCancelPress(form)} 
                                formSetting={formSetting}
                                errorObj={this.state.errorObj}
                                smsNotificationInitialValue={
                                    this.props.loggedInUser
                                        && this.props.loggedInUser.homeownerData
                                            && this.switchValue(this.props.loggedInUser.homeownerData.smsNotification)
                                }
                                emailNotificationInitialValue={
                                    this.props.loggedInUser
                                        && this.props.loggedInUser.homeownerData
                                            && this.switchValue(this.props.loggedInUser.homeownerData.emailNotification)
                                }
                            />
                            {
                                this.state.addUserForm ? this.addAdditionalUser()
                                : this.additionalUser()
                            }

                            <ChangePassword
                                setLoader={loader => { this.props.setLoader(loader) }}
                                email={accountDetails && accountDetails.email}
                                isInstaller={this.props.isInstaller}
                            />
                        </div>
                    </div>
                </div>
            </div>
            </>
        );
    }

    additionalUser() {
        return(
            <>
                <hr className="update-acc-divider" />
                <label className="notification-lable">ADDITIONAL USERS</label>
                <div className="additional-user block">
                    {
                        this.props.additionalUser && this.props.additionalUser.map((ele, index) => {
                            return (
                                <div className="user" key={index}>
                                    <img
                                        onClick={() => {
                                            this.setState({
                                                deleteTrigger: true,
                                                userId: ele.id,
                                                userEmail: ele.email
                                            })
                                        }}
                                        src={deleteIcon}
                                    />
                                    {
                                        ele.userName
                                    }
                                </div>
                            )
                        })
                    }
                    <div className="add-user" onClick={() => this.setState({ addUserForm: true })}>
                        <AddIcon className="add-trigger m-0" />
                        <div className="premiumLinks d-inline-flex ml-2">Add Additional User</div>
                    </div>
                </div>
            </>
        )
    }

    addAdditionalUser() {
        const { loggedInUser: { homeownerData } } = this.props;
        return (
            <AddUserForm
                handleSubmit={this.addUserSubmit}
                isInstaller={this.props.isInstaller}
                homeownerData={homeownerData}
                phoneNumberChangeHandler={this.checkNumberRange}
                cancelHandler={form => this.OnCancelPress(form)}
            />
        )
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {   
            requestHomeownerAddUser,
            receiveHomeownerAddUser,
            requestEditUpdateAccount
        },
        dispatch
    );

export default withRouter(
    connect(
        null,
        mapDispatchToProps
    )(SettingsForm)
);