export class JSONToCSVConvertor {
  public convertData(JSONData, ReportTitle, ShowLabel) {
    JSONData = JSONData.map(data => {
      delete data['touchHistory'];
      let newAdd = "";
      if (data["address"]) {
        for (let address in JSON.parse(data["address"])) {
          if (JSON.parse(data["address"])[address]) {
            newAdd += JSON.parse(data["address"])[address] + " ; ";
          }
        }
        data["address"] = newAdd;
      }
      return data;
    });
    return this.downloadCSV(JSONData,ReportTitle,ShowLabel);
  }

  private downloadCSV(JSONData, ReportTitle, ShowLabel) {
    let arrData = typeof JSONData != "object" ? JSON.parse(JSONData) : JSONData;
    let CSV = "";
    //This condition will generate the Label/Header
    if (ShowLabel) {
      let row = "";
      for (let index in arrData[0]) {
        row += index + ",";
      }
      row = row.slice(0, -1);
      CSV += row + "\r\n\n";
    }
    for (let i = 0; i < arrData.length; i++) {
      let row = "";
      for (let index in arrData[i]) {
        if (index === "created") {
          let val = arrData[i][index];
          let formattedDate = this.formatDate(val);
          row += '"' + formattedDate + '",';
        } else {
          row += '"' + arrData[i][index] + '",';
        }
      }
      row.slice(0, row.length - 1);
      CSV += row + "\r\n";
    }

    if (CSV == "") {
      alert("Invalid data");
      return;
    }

    let fileName = "";
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    let uri = "data:text/csv;charset=utf-8," + escape(CSV);
    let link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    // link['style'] = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    return;
  }

  public convertDriveData(JSONData, ReportTitle, ShowLabel) {
    let newData = [];
    for (var i in JSONData) {
      for (var j = 1; j <= JSONData[i].length - 1; j++) {
        let ele = JSONData[i][j];
        var name = JSONData[i][0]
        ele.folderName = name
        newData.push(ele)
      }
    }
    return this.downloadCSV(newData,ReportTitle,ShowLabel);
  }

  private formatDate(date) {
    return `${new Date(+date).getMonth() + 1}/${new Date(
      +date
    ).getDate()}/${new Date(+date).getFullYear()}`;
  }
}
