import React, { Component }     from "react";
import { Select,DatePicker,
    Input,Popover}              from "antd";
import  moment                  from "moment";
import { bindActionCreators }   from "redux";
import NumberFormat             from "react-number-format";
import { connect }              from 'react-redux';
import { requestInstallerData,
        requestCompanyIdData }  from './../../actions/addHomeOwner';
import withRouter               from '../../../../shared/withRouter';
import { jwtDecode }            from '../../../../shared/jwt';
import { monitoringSystemData } from '../../../../shared/fleet'
import leftArrowActiveIcon from './../../../../Assets/Images/leftArrow_active.svg'
import rightArrowActiveIcon from './../../../../Assets/Images/rightArrow_active.svg'
import { ReactComponent as Select_Icon} from "./../../../../Assets/Images/rightArrow_active.svg";
import {ReactComponent as CaretIcon}    from "./../../../../Assets/Images/path-2.svg";


const { Option } = Select;

let metricDataKeys = {
    "fleet":{
        "Name" : {
            key : `Name`,
            filterKey : 'Name' 
        },
        "Street address":{
            // TODO
            key:`Street address`,
            filterKey : "Street address",
            filterType : 'Input'
        },
        "Alert severity":{
            key : "Alert severity",
            filterKey : "Alert severity",
            filterType:'List',
            filterList : [
                {id:'Yellow',name:'Yellow'},
                {id:'Red',name:'Red'}
            ]
        },
        "Status":{
            key : "Status",
            filterKey : "Status",
            filterType:'List',
            filterList : [
                {id:'No data',name:'No data'},
                {id:'No production',   name:'No production'},
                {id:'Low production',   name:'Low production'},
            ]
        },
        "Monitoring system":{
            key :`Monitoring system`,
            filterKey :`Monitoring system`,
            filterType : 'List',
            filterList : [
                {id:5,name:'APsystems'},
                {id:1,name:'eGauge'},
                {id:3,name:'Enphase'},
                {id:6,name:'Fronius'},
                {id:2,name:'Solar Edge'},
                {id:4,name:'Sense'},                
            ]
        },
        "Todays production":{
            hideFilterBlock: true
        },
        "Yesterdays production":{
            hideFilterBlock: true
        },
        "Last data sync":{
            hideFilterBlock: true
        }
    },
    "companies":{
        "Company":{},
        "Active Accounts":{},
        "Employees":{},
        "Referrals":{},
        "Average Survey Score":{},
        "Latest CRM Sync":{}
    },
    "campaign":{
        "campaignId": {
            key   :`"headline"`,
            filterKey : 'headline'      
        },
        "filters":{
            key   :`"headline"`,
            filterKey : 'headline'      
        },
        "campaign Name"  : {
            key   :`"headline"`,
            filterKey : 'headline'      
        },
        "Creator"  : {
            key   :`"installerId"`,
            filterKey : '"installerId"', 
            filterType:'List',
            filterList : []      
        },
        "Date Created"  : {
            key : `"createdAt"`,
            filterKey :  `"createdAt"`,
            filterType : 'DatePicker',
            displayFormat : "moment"
        },
        "Date Launched"  : {
            key : `"launchedDate"`,
            filterKey :  `"launchedDate"`,
            filterType : 'DatePicker',
            displayFormat : "moment" 
        },
        "status"  : {
            key   :`"status"`,
            filterKey : '"status"',
            filterType:'List',
            filterList : [
                {id:'1',  name:'Sent'},
                {id:'2',  name:'Scheduled'},
                {id:'3',  name:'Draft'},
            ]  
        },
        "Total Recipients"  : {
            key   :`"totalRecipients"`,
            filterKey : '"totalRecipients"',
            filterType: 'InputNumber'      
        },
    },
    "social shares":{
        "Homeowner"  : {
            key   :`"homeowners"."firstName"`,
            filterKey : 'function."name"'      
        },
        "Email"      : {
            key :`"homeowners"."email"`,
            filterKey : `"homeowners"."email"` 
        },
        "Date Shared": {
            key : `social_share."created"`,
            filterKey :  `social_share."created"`,
            filterType : 'DatePicker'
        },
        "Media"      : {
            key : `ssl.name`,
            filterKey : 'social_share.method',
            filterType:'List',
            filterList : [
                {id:1,name:'Facebook'},
                {id:2,name:'Twitter'},
                {id:3,name:'SMS'},
                {id:4,name:'whatsApp'},
            ]
        },
        "Page Shared": {
            key : `share`,
            filterKey : 'share' 
        }
    },
    "referrals" : {
        "Referral name" :{
            key : "name",
            filterKey : 'name' 
        },
        "Referral email" :{
            key : `referrals.email`,
            filterKey : 'referrals.email' 
        },
        "Referral number" :{
            key : "referrals.phone",
            filterKey : 'referrals.phone',
            filterType : 'InputNumber'
        },
        "Referred by" :{
            key : `"firstName"`,
            filterKey : 'function."name"' 
        },
        "Date referred" :{
            key : `referrals."created"`,
            filterKey : 'referrals."created"',
            filterType : 'DatePicker'
        }
    },
    "surveys" : {
        "Homeowner" : {
            key  : `"firstName`,
            filterKey : 'function."name"' 
        },
        "Email" : {
            key : `homeowners.email`,
            filterKey: 'homeowners.email'
        },
        "NPS survey" : {
            filterKey : `data."NPS survey"`,
            key : "survey.isNPS",
            filterType:'List',
            filterList : [
                {id:'Yes',name:'Yes'},
                {id:'No',name:'No'}
            ]
        },
        
        "Phase" : {
            key : "survey.phase",
            filterKey: 'hs."phaseId"',
            filterType:'List',
            filterList : [
                {id:1,name:'Design'},
                {id:2,name:'Permit'},
                {id:3,name:'The Build'},
                {id:4,name:'Inspection'},
                {id:5,name:'Final'},
                {id:6,name:'Solar System'},
                {id:7,name:'company_name'},
                {id:8,name:'Bodhi App'},
            ]
        },
        "Survey score" : {
            key : "rating",
            filterKey: 'rating',
            filterType: "InputNumber"
        },
        "Date" : {
            key : `hs."created"`,
            filterKey: 'hs."created"',
            filterType: "DatePicker"
        },
        "Project manager" : {
            key : "installers.name",
            filterKey: 'installers.id',
            filterType : 'List'
        }
    },
    "active accounts" : {
        "Name" : {
            key : `"firstName"`,
            filterKey : 'function."name"' 
        },
        "Email" : {
            key : `homeowners.email`,
            filterKey :`homeowners.email`,
            filterType : 'Input'
        },
        "Phone number" : {
            key : `homeowners.phone`,
            filterKey :`homeowners.phone`,
            filterType : 'InputNumber'
        },
        "Address":{
            // TODO
            key:`homeowners.address`,
            filterKey : `data."Address"`,
            filterType : 'Input'
        },
        "Project manager" : {
            key : `"installers".name`,
            filterKey :`"installers".id`,
            filterType : 'List'
            
        },
        "Date created" : {
            key : `homeowners."created"`,
            filterKey :`homeowners."created"`,
            filterType : 'DatePicker'
        },
        "Meter Id" : {
            key : `su."meterconId"`,
            filterKey :`su."meterconId"`,
            filterType : 'Input'
        },
        "Meter" : {
            // TODO
            key : `su."meterId"`,
            filterKey :`su."meterId"`,
            filterType : 'List',
            filterList : [
                {id:1,name:'eGauge'},
                {id:3,name:'Enphase'},
                {id:2,name:'Solar Edge'},
                {id:4,name:'Sense'},
                {id:5,name:'APsystems'},
                {id:6,name:'Fronius'}
            ]
        },
        "Utility" : {
            key : `eu.name`,
            filterType : 'Input',
            filterKey :`eu.name`
        },
        "Utility rate plan" : {
            key : `rp.display`,
            filterType : 'Input',
            filterKey :`rp.display`,
        },
        "Phase" : {
            key : `survey.phase`,
            filterKey :`data."Phase"`,
            filterType:'List',
            filterList : [
                {id:'1',  name:'Design'},
                {id:'2',  name:'Permit'},
                {id:'3',  name:'The Build'},
                {id:'4',  name:'Inspection'},
                {id:'5',  name:'Powering Up'},
                {id:'6',  name:'Service'},
                {id:'8',  name:'Powered Up'}
            ]
        },
        "Last notification" : {
            key : `su."updated"`,
            filterKey :`su."updated"`,
            filterType : 'DatePicker'
        },
        "Salesperson" : {
            key : `"installers".name`,
            filterKey :`"homeowners"."salePersonId"`,
            filterType : 'List'
            
        },
        "Solar sync" : {
            key : `su."dailySyncTime"`,
            filterKey :`su."dailySyncTime"`,
            timeFormat : 1000,
            filterType : 'DatePicker',
        },
        "History sync" : {
            key : `su."historySyncTime"`,
            filterKey :`su."historySyncTime"`,
            timeFormat : 1000,
            filterType : 'DatePicker',
        },
        "Default":{
            key : '',
            filterKey:''
        },
        
    }
}

class CompanyMetrics extends Component {
    constructor(props) {
        super(props);
        this.state= {
          isCoulmnDisplay   : false,
          currentShow       : null,
          filterValue       : null,
          stateMetricFilterParam : {},
          metricColumnParam      : ["homeownerId","Name","Phase","Last notification","Email","Phone number","isDisable","Address"] 
        }
      }

      componentWillMount(){
        let token = localStorage.getItem("token");
        this.companyId = this.props.router.params.companyId;
        this.decoded = jwtDecode(token);
        if(this.decoded.role && (!this.props.getInstaller || JSON.stringify(this.props.getInstaller)==='{}')){
            // this.props.requestInstallerData(this.companyId);
            // this.props.requestCompanyIdData(this.companyId);
        };
        this.slug = this.props.router.params.slug;   
      }

      componentDidMount(){
        this.props.getmetricRef && this.props.getmetricRef(this)
      }

      componentWillReceiveProps(nextProps){
        metricDataKeys['surveys']['Phase']['filterList'][6]['name'] = nextProps.companyName;
        metricDataKeys['campaign']['Creator']['filterList'] = metricDataKeys['surveys']['Project manager']['filterList']= metricDataKeys['active accounts']['Project manager']['filterList'] = metricDataKeys['active accounts']['Salesperson']['filterList'] = nextProps.getInstaller.data && nextProps.getInstaller.data;
      }

      displayMetricsName(metricsType){
          switch(metricsType){
            case 'referrals':
                return 'Referrals';
      
            case 'surveys':
                return 'Surveys';
            
            case 'social shares':
                return 'Social shares';
        
            case 'active accounts':
                return 'Active accounts';

            case 'companies':
                return 'Companies';

            case 'fleet':
                return 'Fleet monitoring';
          }
      }

    render() {
        let {email,metricsData,metricsType,metricsParam,changeMetricQueryParam,metricFilterParam,metricsHeader} = this.props;         
        let {metricColumnParam,stateMetricFilterParam} = this.state
        if(!metricsData || !metricsData[0]){
            if (metricsHeader) {
                let homeownerId_Index = metricsHeader.indexOf("homeownerId");
                let companyId_Index = metricsHeader.indexOf("companyId");
                let isDisable_Index = metricsHeader.indexOf("isDisable");
                if(companyId_Index !=-1)
                metricsHeader.splice(companyId_Index, 1);
                if (isDisable_Index != -1)
                metricsHeader.splice(isDisable_Index, 1);
                if ((homeownerId_Index != -1))
                metricsHeader.splice(homeownerId_Index, 1);
            }
            return this.emptyResponse(metricsData); 
        }

        let tableHeader         = Object.keys(metricsData[0]);
        let homeownerId_Index   = tableHeader.indexOf("homeownerId");   
        let companyId_Index     = tableHeader.indexOf("companyId");   
        let isDisable_Index     = tableHeader.indexOf("isDisable");
        let campaignId_Index    = tableHeader.indexOf("campaignId");
        let campaignFilter_Index= tableHeader.indexOf("filters");
        let dataSize            = (metricsParam['skip']+1)* metricsParam['limit'];
        let previousButtonClass = metricsParam['skip'] != 0 ? true : false;
        let nextButtonClass     = (metricsParam['count'] > dataSize && (metricsData.length>=metricsParam['limit']) ) ? true : false;
        if(companyId_Index !=-1)
            tableHeader.splice(companyId_Index,1);
        if(isDisable_Index != -1)
            tableHeader.splice(isDisable_Index,1);
        if((homeownerId_Index != -1))
            tableHeader.splice(homeownerId_Index,1);
        if(campaignFilter_Index !== -1){
            tableHeader.splice(campaignFilter_Index,1);
            tableHeader.splice(campaignId_Index,1);
        } 
        
        let activeJourney_Index = tableHeader.indexOf("activeJourney");
        if(activeJourney_Index != -1)
            tableHeader.splice(activeJourney_Index,1);

        return (
            
            <div className='metrics-table-box' >
                <div className="table-box-header box-Shadow" onClick={this.displaySelectionColumn}>
                    <h2 className="table-type">{metricsType == 'campaign' ? 'Marketing Campaigns': this.displayMetricsName(metricsType)}</h2>
                    {
                        metricsType == 'campaign' && <div className="btn btn-primary mt-2 ml-3" onClick={()=>{
                            this.props.router.navigate(`/${this.slug}/${this.companyId}/campaign/create`)
                        }}>Add new campaign</div>
                    }
                    {metricsType == "active accounts" &&
                        this.selectAccountOption(metricsType,email,metricColumnParam)
                    }
                    { metricsType == "fleet" && this.renderSwitch() }
                </div>

                <div className="table-responsive box-Shadow">
                <table className="metrics-table">
                    <tbody>
                        <tr>
                            {this.renderTableHeader(tableHeader,metricsParam['sort'],changeMetricQueryParam,metricsType,stateMetricFilterParam,metricFilterParam)}
                        </tr>
                        {metricsData.map((ele, i) => {
                            return <tr key={i} className="metrics-row">
                            { metricsType==='campaign' ?
                                this.renderCampaignData({...ele}) : this.renderTableData({...ele})
                            }
                            </tr>
                        })}
                    </tbody>
                </table>
                </div>
                {(!this.props.filterHide && !this.props.hidePagination) &&  
                this.renderPaginationBox(previousButtonClass,nextButtonClass,metricsParam,changeMetricQueryParam)}
            </div>
        )
    }


    renderSwitch(){
        return (
            <div className="fleet-viewType" id="radios">
                <input id="rad1" type="radio" name="radioBtn" onChange={() => this.props.changeState({ mapView: false })} />
                <label className={`labels ${!this.state.mapView ? 'checked' : ''}`} for="rad1">List view</label>

                <input id="rad2" type="radio" name="radioBtn" onChange={() => this.props.changeState({ mapView: true })} />
                <label className={`labels ${this.state.mapView ? 'checked' : ''}`} for="rad2">Map view</label>
                <div id="bckgrnd"></div>
            </div>
        )
    }

    selectAccountOption = (metricsType,email,metricColumnParam) =>{
        return(
            <div className="column-header btn" onClick={this.displaySelectionColumn}>
            Choose columns
            {this.state.isCoulmnDisplay &&
                <div className="column-box">
                    <div className="drop-up-arrow"></div>
                    {Object.keys(metricDataKeys[metricsType]).map((ele, index) => {
                        let temp = metricDataKeys[metricsType][ele];
                        let isSelected = (metricColumnParam.indexOf(ele) != -1) ? 'btn-primary' : 'btn-secondary'
                        return <div key={index} className={"column-name btn " + isSelected}
                            onClick={this.markColumnValue}
                            data-id={ele}
                            data-index={index}
                        >{ele}</div>
                    })}
                    <div className="column-name btn link-Color hover-link"
                    onClick={()=>{
                        this.setState({isCoulmnDisplay : false});
                        this.props.changeMetricQueryParam("param",metricColumnParam);
                    }}
                    >Apply columns</div>
                </div>
            }
        </div>
        )
    }

    emptyResponse = (metricsData) =>{
        let {installerData,email,metricsType,metricsParam,changeMetricQueryParam,metricFilterParam,metricsHeader} = this.props;         
        let {metricColumnParam,stateMetricFilterParam} = this.state;
        let count = metricsParam['count'];
        let hideAddButton = installerData && installerData.formSetting && installerData.formSetting.hideAddButton;
        let pageNumber = metricsParam['skip'];

        return (
            <div className='metrics-table-box'>
                <div className="table-box-header box-Shadow" onClick={this.displaySelectionColumn}>
                <h2 className="table-type">{metricsType == 'campaign' ? 'Marketing Campaigns': this.displayMetricsName(metricsType)}</h2>
                    
                    {
                        metricsType == 'campaign' && <div className="btn btn-primary mt-2 ml-3" onClick={()=>{
                            this.props.router.navigate(`/${this.slug}/${this.companyId}/campaign/create`)
                        }}>Add new campaign</div>
                    }
                </div>
                {count==0 &&(
                    <div className="data-empty">
                         {
                             metricsType=='active accounts' && 
                            <>
                                <div>Add your first account now.</div>
                                {
                                    (!hideAddButton) &&

                                    <button style={{ 'padding': '0 19px' }} className="btn btn-primary mt-3 auto-width" onClick={() => {
                                        this.props.router.navigate(
                                            "/" + this.slug + "/" + this.companyId + "/addhomeowner"
                                        )
                                    }}> +{" "}Add homeowner</button>
                                }
                            </>                                                     
                         }
                         {metricsType=='referrals' && 'No referrals to share yet.'}
                         {metricsType=='surveys' && 'No survey results to share yet.'}
                         {metricsType=='social shares' && 'No social shares to share yet.'}
                         {metricsType=='campaign' && 'Add your first campaign now.'}
                    </div>
                )}
                <div className="table-responsive box-Shadow">
                <table className="metrics-table">
                    <tbody>
                        <tr>
                            {metricsHeader && this.renderTableHeader(metricsHeader,metricsParam['sort'],changeMetricQueryParam,metricsType,stateMetricFilterParam,metricFilterParam)}
                        </tr>
                    </tbody>
                </table>
                {count>0 && metricsData && (
                    <div className="data-empty">No results for this filter</div>
                )}
                {count>0 && !metricsData &&(
                    <div className="data-empty">Loading...</div>
                )}
                </div>
            </div>
        )
    }

    hideTableHeader(metricDataKeys,metricsType,ele){
        if(!metricDataKeys[metricsType] || !metricDataKeys[metricsType][ele])
        return true;
    }
    
    hideFilterBlock(metricDataKeys,metricsType,ele){
        let hideFilterBlock = (metricDataKeys[metricsType] && metricDataKeys[metricsType][ele] && metricDataKeys[metricsType][ele]['hideFilterBlock']) || this.props.filterHide;
        return hideFilterBlock;
    }

    renderTableHeader=(data,sortParam,changeMetricQueryParam,metricsType,stateMetricFilterParam,metricFilterParam)=>{
        sortParam = sortParam ? sortParam : {};
        return data.map((ele) => (
           
            !this.hideTableHeader(metricDataKeys,metricsType,ele) && <th  className="table-header seprator-right" key={ele}>
                <div className="header-title">{ele}</div>
                {
                    (!this.hideFilterBlock(metricDataKeys,metricsType,ele)) &&
                    <div className="filter-sort-icon">
                    <img  src={require(`./../../../../Assets/Images/DownArrow_${
                        (metricDataKeys[metricsType][ele]&&metricDataKeys[metricsType][ele]['key']==Object.keys(sortParam)[0]&& Object.values(sortParam)[0]=='asc') ? 'active' : 'inactive'}.svg`)
                    } 
                    className="sort-icon" alt='search' onClick={() => changeMetricQueryParam("sort", {[metricDataKeys[metricsType][ele]['key']]:'asc'})} />
                    <img  src={require(`./../../../../Assets/Images/UpArrow_${(metricDataKeys[metricsType][ele]&&metricDataKeys[metricsType][ele]['key']==Object.keys(sortParam)[0]&& Object.values(sortParam)[0]=='desc') ? 'active' : 'inactive'}.svg`)} 
                    className="sort-icon" alt='search' onClick={() => changeMetricQueryParam("sort", {[metricDataKeys[metricsType][ele]['key']]:'desc'})} />
                    <div className="filter-block">
                        <img alt='search' src={require(`./../../../../Assets/Images/filter_${metricFilterParam.hasOwnProperty(metricDataKeys[metricsType][ele] && metricDataKeys[metricsType][ele]['filterKey']) ? 'active' : 'inactive'}.svg`)}
                         className="filter-icon" onClick={(e)=>{this.displayFilterBox(metricDataKeys[metricsType][ele],e)}}/>
                    {this.state.currentShow === metricDataKeys[metricsType][ele] &&
                        <div className="filter-box">
                            <div className="drop-up-arrow"></div>
                            {this.renderInputType(metricDataKeys[metricsType][ele],stateMetricFilterParam[metricDataKeys[metricsType][ele]['filterKey']] , metricFilterParam[metricDataKeys[metricsType][ele]['filterKey']],metricDataKeys[metricsType][ele]['filterList'])}
                            {
                                metricFilterParam.hasOwnProperty(metricDataKeys[metricsType][ele]['filterKey'])&&
                            <button className="filter-button cancel" onClick={(e)=>{this.updatePropFilterValue(metricDataKeys[metricsType][ele]['filterKey'],'cancel')}}>Clear</button>
                            }
                            <button className="filter-button" onClick={(e)=>{this.updatePropFilterValue(metricDataKeys[metricsType][ele]['filterKey'],'apply')}}>Apply</button>
                        </div>
                    }    
                   </div>
                </div>
                }
            </th>          
          ))
    }

    renderInputType(filterOption,stateValue,propValue,filterList){
        let name = filterOption['filterKey'];
        let inputType = filterOption['filterType'];
        let timeFormat = filterOption['timeFormat'] ?  1000 : 1;
        let value = (stateValue || stateValue=="")  ? stateValue : propValue;

        switch(inputType){

            case 'InputNumber':
                return <React.Fragment>
                <div className="header">Number Filter</div>
                <Input type="number" className="form-control input"  pattern="[0-9]*" value={value} name={name} onChange={(e)=>{this.changeQueryParam(inputType,name,e.target.value*1)}}/>
                </React.Fragment>

            case 'DatePicker':
                return <React.Fragment>
                    <div className="header">To</div>
                    <DatePicker className="form-control input" name={name} value={value&& value['startDate']&& moment(value['startDate']*timeFormat)} 
                    format = 'YYYY/MM/DD' onChange={(date) => {this.changeQueryParam(inputType,name, { startDate: date.startOf('day').utc().valueOf()/timeFormat })
                    }} />
                    <div className="header">From</div>
                    <DatePicker className="form-control input" name={name} value={value&& value['endDate'] && moment(value['endDate']*timeFormat)}
                    onChange={(date) => {
                        this.changeQueryParam(inputType,name, { endDate: date.endOf('day').utc().valueOf()/timeFormat })
                    }} />
                </React.Fragment>

            case 'List':
                return (
                    <>
                        <div className="header">Select Any</div>
                        <Select  suffixIcon = {<CaretIcon/>}
                            mode="multiple"
                            style={{ width: '100%', padding: ' 0 10px', display: 'flow-root' }}
                            placeholder="Click....."
                            value={value}
                            className="list-box"
                            onChange={(v) => {
                                this.changeQueryParam(inputType, name, v);
                            }}
                        >
                            {filterList.map((ele, i) => {
                                return <Option key={i} value={ele.id}> {ele.name ? ele.name : ele.email}</Option>
                            })}
                        </Select>
                    </>
                )
                   
            default:
                return <React.Fragment>
                    <div className="header">Text Filter</div>
                    <Input className="form-control input" value={value} name={name} onChange={(e)=>{
                        let v = e.target.value
                        this.changeQueryParam(inputType,name,v.toLowerCase())
                    }} />
                </React.Fragment>
        }       
    }

    renderTableData =  (data) =>{
        let {metricsHeader} = this.props;
        let isDisable   = data['isDisable'];
        let homeownerId = data['homeownerId'];
        let companyId   = data['companyId'];
        let activeJourney = data['activeJourney'];
        delete  data['isDisable'];
        delete  data['homeownerId'];
        delete  data['companyId'];
        delete  data['activeJourney'];
        let values = Object.values(data);
        let keys   = Object.keys(data);
        let homeowner_link  = keys.indexOf('Homeowner')    != -1 ? keys.indexOf('Homeowner')   : (keys.indexOf('Name') != -1  ? keys.indexOf('Name') : keys.indexOf('Referred by'));
        let number_index    = keys.indexOf('Phone number') != -1 ? keys.indexOf('Phone number') : keys.indexOf('Referral number');
        let companyId_index = keys.indexOf("Company")      != -1 ? keys.indexOf("Company") : null;
        let phase_index     = keys.indexOf("Phase")        != -1 ? keys.indexOf("Phase") : null;
        let alert_index     = keys.indexOf("Alert severity")!= -1 ? keys.indexOf("Alert severity") : null;
        let monitoring_system = keys.indexOf("Monitoring system")!= -1 ? keys.indexOf("Monitoring system") : null;

        return values.map((ele, index) =>{
            ele = (number_index !=1 && index == number_index && ele )?  this.maskContactNumber(ele) : ele;
            if(homeowner_link==index && !isDisable){
                return <td  className="metrics-data"><a href={`${window.location.origin}/${this.slug}/${this.companyId}/dashboard/${homeownerId}/homeowner`} target='_blank'>{ele}</a></td>
            }
            else if (companyId_index == index) {
                return <td key={index} className="metrics-data">
                    <a href={window.location.origin + '/admin/' + companyId + '/companysetting'} target='_blank'>
                        {ele}</a>
                </td>
            }
            else if (phase_index == index && ele) {
                return <td key={index} className="metrics-data">{this.displayPhaseName(ele,activeJourney || 'default')}
                </td>
            } else if(index === alert_index){
                let css = (ele==="Yellow") ? {color:"#E3AC00","font-weight": "bold"} : {color:"#FF6F47","font-weight": "bold"};
                return <td  className="metrics-data" style={css}>{ele}</td>
            } else if(index === monitoring_system){
                return <td>{monitoringSystemData(ele,data)}</td>
            }
            else if(!metricsHeader || (Array.isArray(metricsHeader) && metricsHeader && metricsHeader.indexOf(keys[index])!=-1))
                return <td  className="metrics-data">{ele}</td>
        })
    }


    displayPhaseName = (phaseValue,activeJourney) => {
        let phaseName = '';
        switch(phaseValue){
            case 1:
                phaseName = 'Design';
            break;
            case 2:
                phaseName = 'Permit';
            break;
            case 3:
                phaseName = 'The Build';
            break;
            case 4:
                phaseName = 'Inspection';
            break;
            case 5:
                phaseName = 'Powering Up';
            break;
            case 8:
                phaseName = 'Powered Up';
            break;
            default:
                phaseName = 'Service';
        }

        let key     = phaseName.toLowerCase().replace(/ /g,'');
        let journey = this.props.companyData && this.props.companyData.journey;
        let journeyDetail = journey && journey.filter((ele)=>{
            return activeJourney == ele.s3Key;
        })[0];

        if(journeyDetail && journeyDetail.journeyPhase && journeyDetail.journeyPhase[key]){
            return journeyDetail.journeyPhase[key].name || phaseName;
        } else {
            return phaseName;
        }
    }

    renderCampaignData =  (data) =>{
        let campaignId = data['campaignId'];
        let campaignStatus = data.status;

        let content = (
            <div className="action-option">
                <div className="action-name" onClick={()=>{
                    if(this.companyId){
                        this.props.campaignDashboardAction('delete',campaignId);
                    }
                }
                }
                    >Delete</div>
                <div className="action-name" onClick={()=>{
                    if(this.companyId){
                        this.props.campaignDashboardAction('clone',campaignId);
                    }
                }
                }>Clone</div>
            </div>
        );     
          
        // delete campaignObject Data
        delete data['filters']
        delete data['campaignId'];

        let values = Object.values(data);
        let keys = Object.keys(data);
        let campaign_index = keys.indexOf("campaign Name") != -1 ? keys.indexOf("campaign Name") : null;
        let status_index = keys.indexOf("status") != -1 ? keys.indexOf("status") : null;
        return values.map((ele, index) => {
          
            if (metricDataKeys[this.props.metricsType][keys[index]]['displayFormat'] === "moment") {
                return <td key={index} className="metrics-data">{ ele && moment(parseInt(ele)).format('MMM DD, YYYY')}</td>
            }
            else if (campaign_index == index && ele && campaignId) {
                return <td key={index} className="metrics-data">
                    <a target='_blank'
                    className= 'link-Color'
                    onClick={() => {this.props.campaignLink(campaignStatus,campaignId)}}>{ele}</a></td>
            }
            else if (status_index === index && ele) {
                return <React.Fragment key={index}>
                    <td className="metrics-data">
                        <a target='_blank'
                            onClick={() => { this.props.campaignLink(campaignStatus, campaignId) }}>
                            {this.props.campaignStatus(ele)}
                        </a>

                        <span className="text-right">
                            <Popover overlayClassName={"campaign-status-action"} placement="rightBottom" trigger="click" content={content}>
                                <span className="action-btn">...</span>
                            </Popover>
                        </span>
                    </td>
                </React.Fragment>
            }
            else 
                return <td key={index} className="metrics-data">{ele}</td>
        })
    }

    renderPaginationBox = (previousButtonClass,nextButtonClass,metricsParam,changeMetricQueryParam) =>{
        let {metricsData} = this.props;
        let onlyDisplayCount = window.location.pathname.includes('fleet');

        return(
            <div className="pagination-box">
                {
                    onlyDisplayCount ? <div className="pagination-link-button">
                        {/* <div className="page-number">{(metricsData && metricsData.length) || 0} alerts</div> */}

                        </div> :
                    (
                        <> 
                    <div className="pagination-link-button">
                    <div className={"link-button " + previousButtonClass} onClick={(e) =>{
                        if (!e.target.className.includes('inactive'))
                         changeMetricQueryParam("skip", -1)
                    }}>
                        {previousButtonClass && <img src={leftArrowActiveIcon} alt='search' />}
                        </div>

                        <div className="page-number">{this.displayCount(metricsParam)}</div>

                    <div className={"link-button " + nextButtonClass} onClick={(e) => {
                        if (!e.target.className.includes('inactive'))
                            changeMetricQueryParam("skip", 1)
                    }}>
                        {nextButtonClass &&  <img src={rightArrowActiveIcon} alt='search' className={"rightIcon rightArrow_active"} />}
                    </div>
                </div>
                <div className="pagination-limit-button">
                    <Select  suffixIcon = {<CaretIcon/>} value={metricsParam.limit ? metricsParam.limit.toString() : "25"} className="pagination-option" onChange={this.handlePaginationChange} >
                        <Option value="25">25 per page</Option>
                        <Option value="50">50 per page</Option>
                        <Option value="100">100 per page</Option>
                    </Select>
                </div>
                </>)
                }
               
            </div>
        )
    }

    renderSelectIcon(){
        return(
            <img src={rightArrowActiveIcon} alt='search' className={"rightIcon rightArrow_active"} />
        )
    }

    displayCount(metricsParam){
        let temp = metricsParam.count <2 ? 'result':'results'
        let skip = metricsParam.skip ? metricsParam.skip + 1 : 1;
        let endText = `of ${metricsParam.count} ${temp}`;
        let startCount = metricsParam.skip ? (metricsParam.limit * metricsParam.skip) : 1;
        let endCount = (skip) * metricsParam.limit;
        return `${startCount}-${endCount > metricsParam.count ? metricsParam.count : endCount} ${endText}`;
    }


    displaySelectionColumn = (e) =>{
        e.stopPropagation();
        if(e.target.className=="column-header btn"){
            this.setState({isCoulmnDisplay : !this.state.isCoulmnDisplay});
        }
        else if(!e.target.className.includes("column-name")) {
            this.setState({isCoulmnDisplay : false})
        }
    }

    markColumnValue = (e) =>{
        let {metricColumnParam} = this.state;
        let value   = e.target.getAttribute('data-id');
        let operation = e.target.className.includes('btn-primary');
        if (value == 'Default')
            metricColumnParam = ["homeownerId", "Name", "Phase", "Last notification", "Email", "Phone number", "Address", "isDisable"];
        else if (operation==true) {
            let i = metricColumnParam.indexOf(value);
            metricColumnParam.splice(i, 1);
        } else {
            metricColumnParam.push(value);
        }
         this.setState({metricColumnParam});
    }

    handlePaginationChange = (value) =>{
        this.props.changeMetricQueryParam("limit",value)
    }


    displayFilterBox = (value,event) =>{
        if(event)
            event.stopPropagation();
        this.setState({currentShow:this.state.currentShow ? null : value})
    }

    changeQueryParam = (inputType,key,value) =>{
        let stateObj = this.state.stateMetricFilterParam;       
        let obj;
        
        switch(inputType){
           
            case 'DatePicker':
                obj = { [key]: value };
                if (stateObj[key]) {
                    let temp = Object.assign(stateObj[key], obj[key]);
                    stateObj[key] = temp;
                }
                else
                    Object.assign(stateObj, obj);
                break;
            case 'InputNumber':
                value = value * 1;
            default:
                obj = {
                    [key]: value

                };
                Object.assign(stateObj, obj);
                break
        }
        
        if(key){
            this.setState({stateMetricFilterParam :stateObj});
        }   
    }

    updatePropFilterValue = (id,operation) =>{
        
        let { stateMetricFilterParam } = this.state;
        let { metricFilterParam, metricsType } = this.props;

        switch(operation){
            
            case 'apply':
                Object.assign(metricFilterParam, stateMetricFilterParam);
                this.props.changeMetricQueryParam('filter',metricFilterParam);
                this.displayFilterBox();
            break;

            case 'cancel':
                    delete stateMetricFilterParam[id];
                    if (!metricFilterParam) {
                        metricFilterParam = {}
                    }
                    else{
                        delete metricFilterParam[id];
                    }
                    Object.assign(stateMetricFilterParam,metricFilterParam);
                    this.props.changeMetricQueryParam('filter', metricFilterParam);
                    this.displayFilterBox();
        }
    }

    maskContactNumber(number){
        let {searchData:{data}} = this.props;
        return (
            <NumberFormat
                type="tel"
                value={number}
                format={data && data.formSetting && data.formSetting.phone}
                disabled={true}
                className="border-0"
            />
        )
    }
}

const mapStatetoProps = state => {
    return {
        companyData    : state.addhomeOwner.companyId && state.addhomeOwner.companyId.data,
        getInstaller   : state.addhomeOwner.getInstaller,
        installerData  : state.header.getInstaller.data||{},
    }
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        requestInstallerData,
        requestCompanyIdData
    }, dispatch);

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(CompanyMetrics));
