import React, { Component } from "react";
import { connect }          from 'react-redux'
import {bindActionCreators} from "redux";
import { Form, Input }      from "antd";
import {requestInviteEmployes} from '../../actions/profile'

const EmployeeInviteForm = props => {

  const [form] = Form.useForm()

  const { handleSubmit } = props

  const formSubmit = values => {
    handleSubmit(values, form)
  }

  return (
    <Form form={form} onFinish={formSubmit}>
      <div className="form-group row">
        <div className="col-sm-3">
          <div>Name <span className="requiredSign"> * </span></div>
          <Form.Item
            hasFeedback
            name="name"
            rules = { [{ required: true }] }
          >
            <Input
              className="form-control"
              id="exampleInputEmail"
            />
          </Form.Item>
        </div>

        <div className="col-sm-4 offset-md-1">
          <div>Email address<span className="requiredSign"> * </span></div>
          <Form.Item
            hasFeedback
            name="email"
            rules = { [{ required: true }, { type: "email" }] }
          >
            <Input
              type="email"
              className="form-control"
              id="exampleInputEmail"
            />
          </Form.Item>
        </div>

        <div className="col-sm-4">
          <button type="submit" className="btn btn-primary">Invite</button>
        </div>
      </div>
    </Form>
  )
}

class InviteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.inviteResponse.status === 200) {
      this.props.setLoader({loader:false});
    }
  }

  handleSubmit = (values, form) => {
    values["companyId"] = this.props.companyId;
    values["slug"] = this.props.slug;
    this.props.requestInviteEmployes(values);
    this.props.setLoader({loader:true});
    form.resetFields()
  };

  render() {
    return (
      <div className="content-box box-Shadow">
        <div className='mob-box'>Invite employees </div>
        <div className="section-from-box  border0 company-setting-wrap mt-4">
          <div className="form-wrap">
            <EmployeeInviteForm
              handleSubmit={this.handleSubmit}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    inviteResponse: state.setting.inviteEmployees
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({ requestInviteEmployes }, dispatch)
)

export default connect(mapStatetoProps, mapDispatchToProps)(InviteForm)
