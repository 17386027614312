import jwt_decode from 'jwt-decode'

export const jwtDecode = token => {
  if (token !== '') {
    let decoded = jwt_decode(token)
    if (decoded) {
      let data = {
        companyId : decoded['custom:companyId'],
        slug      : decoded['custom:companySlug'],
        role      : decoded['custom:role'],
        exp       : decoded['exp'],
        email     : decoded['email']
      }
      if(data.role === "installer-admin" || data.role === 'installer') {
        data.installerId= decoded['custom:teraWattId'];
      } else {
        data.homeownerId= decoded['custom:teraWattId'];
      }
      return data
    }
  } else {
    return {}
  }
}
