// import npm packages
import React from "react";
import { Form, Slider, Input } from 'antd';
import GoogleDriveSync  from "../../../../shared/GoogleDriveSync";
import withRouter                   from '../../../../shared/withRouter'
import {openNotificationWithIcon}   from '../../../../shared/notification';

const FormItem = Form.Item;
const { TextArea } = Input;

class SurveyForm extends React.Component {
    constructor(props) {
        super(props);
        this.GoogleDriveSync = new GoogleDriveSync();
        this.state = {
            rating: 3,
            finalRating: 3,
        };
        this.slug = this.props.slug;
    }
   
    componentWillReceiveProps(nextProps) {       
        if (
            nextProps.statusFeedPhaseSurvey
            && nextProps.statusFeedPhaseSurvey.status
            && nextProps.statusFeedPhaseSurvey.status === 200
        ) {
            this.setState({rating: 3});
            openNotificationWithIcon('success', 'Survey submitted successfully', 'Success');
            // this.state.finalRating < 3 ?
                nextProps.router.navigate(`/${this.slug}/dashboard`) 
                //: nextProps.router.navigate(`/${this.slug}/solarCommunity`)
            nextProps.receiveStatusFeedPhaseSurvey({});
        }
    }

    // submit slider form
    handleSubmit = (values, phase) => {
        let {loggedInUser}  = this.props;
        let homeownerData = loggedInUser && loggedInUser.homeownerData;
        const installerId = homeownerData && homeownerData.installerId;

        let tempData = [];
        if (phase && phase.survey) {
            let totalRating = 0;
            phase.survey.forEach(data => {
                totalRating += values[`rating${data.phaseId}`]
                tempData.push({
                    phaseId: data.phaseId,
                    rating: values[`rating${data.phaseId}`],
                    installerId: installerId,
                });
            })
            this.setState({
                finalRating: totalRating / phase.survey.length
            })
        }
        this.props.setLoader({ loader: true })
        this.props.receiveStatusFeedPhaseSurvey({});
        this.props.requestStatusFeedPhaseSurvey({
            companyId    : homeownerData.companyId,
            homeownerId  : homeownerData.id,
            phase        : tempData,
        });
    }

    // render phase surve html
    renderPhaseServe(phase) {
        const { loggedInUser, customTouchData } = this.props;
        let { rating } = this.state;
        return (
            <div className="paddall25">
                <div className='phase-survey-title'>{`How has your experience through ${customTouchData && customTouchData.phaseName} been so far?`}</div>
                <Form
                    onFinish={(values) => this.handleSubmit(values, phase)}
                >
                    {
                        phase
                        && phase.survey
                        && phase.survey.length !== 0
                        && phase.survey.map(survey => (
                            <React.Fragment key={survey.phaseId}>
                                <div className="text-left">
                                    <FormItem
                                        name={ `rating${survey.phaseId}` }
                                        initialValue = { 3 }
                                    >
                                        <Slider className="premiumLinks"  disabled={this.props.isInstaller} min={1} max={5} onChange={val => {
                                            this.setState({rating:val,phaseId:survey.phaseId });
                                        }} />
                                    </FormItem>
                                </div>
                                <ul className="ratingSlider" style={{ fontSize: 'small' }}>
                                    <li>{rating === 1 ? <span className="premiumLinks">Poor</span> : <span>Poor</span>}</li>
                                    <li className="text-center">{rating === 2 ? <span className="dots premiumLinks"></span> : <span className="dots"></span>}</li>
                                    <li className="text-center">{rating === 3 ? <span className="premiumLinks">Neutral</span> : <span>Neutral</span>}</li>
                                    <li className="text-center">{rating === 4 ? <span className="dots premiumLinks"></span> : <span className="dots"></span>}</li>
                                    <li className="text-right">{rating === 5 ? <span className="premiumLinks">Love It</span> : <span>Love It</span>}</li>
                                </ul>
                            </React.Fragment>
                        ))
                    }
                    <div className="text-center">
                        <button className="btn yellow-btn premiumButtonFill premiumButtonText rating-slider-btn" disabled={this.props.isInstaller ? 'disabled' : ''}>Submit</button>
                    </div>
                </Form>
            </div>
        )
    }

    renderPhaseServe2(phase) {
        const { loggedInUser } = this.props;
        return (
            <div className="paddall25">
                <div className='phase-survey-title'>Thanks! Please share any additional feedback</div>
                <Form onFinish={values => this.handleSubmit(values)}>
                    {
                        phase
                        && phase.survey
                        && phase.survey.length !== 0
                        && phase.survey.map(survey => (
                            <React.Fragment key={survey.phaseId}>
                                <div className="text-left">
                                    <FormItem
                                        name="feedback"
                                        validateTrigger = { ['onChange', 'onBlur'] }
                                        rules = { [{required: true}] }
                                    >
                                        <TextArea
                                            className="status-feed-message textarea"
                                            placeholder="Type feedback here..."
                                            rows={6}
                                        />
                                    </FormItem>
                                </div>
                            </React.Fragment>
                        ))
                    }
                    <div className="text-center">
                        <button className="btn yellow-btn premiumButtonFill premiumButtonText rating-slider-btn" disabled={this.props.isInstaller ? 'disabled' : ''}>Submit</button>
                    </div>
                </Form>
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                 {this.renderScreen()}
            </React.Fragment>
        )
    }

    renderScreen(){
        if(this.state.isFeedback){
            return this.renderPhaseServe2(this.props.homeownerPhase);
        }
        else{
            return this.renderPhaseServe(this.props.homeownerPhase);
        }
    }
}

export default withRouter(SurveyForm)
