// import npm packages
import React, { Component } from 'react'
import { Progress } from 'antd'

// import local files
import { MONTH_ARRAY } from './../../../../shared/config'
import { jwtDecode } from "../../../../shared/jwt";
import { getToken } from "../../../../shared/util";
import { JSONToCSVConvertor } from './../../../../shared/downloadCSV';
import { openNotificationWithIcon } from '../../../../shared/notification';
import { capitalize } from '../../../../shared/capitalize';

export default class SurveyResults extends Component {

  static getDerivedStateFromProps(props, state) {
    const {downloadSurveyData} = props
    if (
      downloadSurveyData
      && downloadSurveyData.status
      && downloadSurveyData.status === 200
    ) {
      const data = downloadSurveyData.data;
      let token = getToken();
      let decoded = jwtDecode(token);
      if(data && data.length && data.length !== 0)
        JSONToCSVConvertor(data, `${decoded.companyId}_survey`, true)
      else
        openNotificationWithIcon('error', 'No data found', 'Error')
      props.receiveDownloadSurveyData({})
    }
  }

  handleChangeMonth = (e) => {
    let monthId = e.target.value;
    let token = getToken();
    let decoded = jwtDecode(token);
    const data = {
      companyId: decoded.companyId,
      installerId: decoded.installerId,
      month: monthId
    };
    if(monthId)
      this.props.requestDownloadSurveyData(data)
  }

  renderPhaseResult = data => {
    let name = data.name;
    if(this.props.companyName&& name === 'company_name') {
      name = this.props.companyName;
    }

    let phaseObj ={
      'phase 1' : 'Design',
      'phase 2' : 'Permit',
      'phase 3' : 'The Build',
      'phase 4' : 'Inspection',
    }
    return (
      <div className="progress-wrapper" key={data.id}>
        <div className="row">
          <div className="col-sm progress-name">
            {
              name && capitalize(phaseObj[name] || name)
            }
          </div>
          <div className="col-sm progress-wrap">
            <Progress percent={data.avgRating * 20} showInfo={false} />
          </div>
          <div className="col-sm progress-raing">{data.avgRating && this.fixedRating(data.avgRating)}</div>
        </div>
      </div>
    )
  }

  fixedRating = (rating) =>{
    rating = typeof(rating)=="string" ? rating*1 :rating;
    return rating.toFixed(1)
  }
  
  render() {
    const { getSurveyData } = this.props;
    let surveyData = getSurveyData && getSurveyData.status === 200 ? getSurveyData.data : []
    return (
      <div className="utility-tab-main-pnl box-Shadow white-box installer-block-top-marg-mobile margb30-mobile">
        <div className="heading d-flex surveyResult-outer" style={{marginTop :'25px'}}>
          <h2 className="w-100 font-weight-400 margin-bottom-20">Survey results</h2>
          <div className="justify-content-end">
            <section className="white-box-select">
              <select onChange={(e) => this.handleChangeMonth(e)}>
                <option value=''>
                Download Month                </option>
                {
                  MONTH_ARRAY && MONTH_ARRAY.map((item, index) => (
                    <option value={index} >
                      {item}
                    </option>
                  ))
                }
              </select>
            </section>
          </div>
        </div>
        <div className="pddl30 pddr30 ">
          {surveyData.map(item => (
            this.renderPhaseResult(item)
          ))}
        </div>
      </div>
    )
  }
}
