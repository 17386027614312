
import * as d3 from "d3";
import * as moment from "moment"

export class RenderTrackChart{
    constructor(){
        this.chartData = [];
        this.tipBox = null;
        this.tooltipLine =null;
        this.tooltip =null;
        this.xScale = null;
    }

    renderMultiChart(data) {
        this.chartData  = data;
        let width  = 500;
        let margin = 50;
        let height = 300;   
        /* Format Data */
        let parseDate = d3.timeParse("%b %Y");
        data.forEach(function (d) {
            d.values.forEach(function (d) {
                d.date = parseDate(d.x);
                d.monthMoment = parseInt(moment(d.date, 'MMM YYYY').format('YYYYMM'))
                d.price = +d.y;
            });
        });
        
        /* Scale */
        let maxValue0 = d3.max(data[0].values, d => d.price);
        let maxValue1 = d3.max(data[1].values, d => d.price);
        this.xScale = d3.scaleTime().domain(d3.extent(data[0].values, d => d.date)).range([0, width - margin]);
        let yScale = d3.scaleLinear().domain([0,maxValue1>maxValue0 ? maxValue1 : maxValue0]).range([height - margin, 0]);
    
        /* Add SVG */
        let svg = d3
            .select("#chartWrapper")
            .append("svg")
            .attr("width", width + margin + "px")
            .attr("height", height + margin + "px")
            .append("g")
            .attr("transform", `translate(${margin}, ${margin})`);
    
        d3.select("body")
            .append("div")
            .attr("id", 'tooltip')
            .attr("class", 'chartWrapper-tooltip')
    
        /* Add line into SVG */
        let line = d3.line().x(d => this.xScale(d.date)).y(d => yScale(d.price));
        let lines = svg.append("g").attr("class", "lines");
    
        lines.selectAll(".line-group")
            .data(data)
            .enter()
            .append("g")
            .attr("class", "line-group")
            .append("path")
            .attr("class", "line")
            .attr("d", d => line(d.values))
            .style("stroke", (d, i) => this.color(i))
            .style("opacity", 1)
    
        /* Add Axis into SVG */
        let xAxis = d3.axisBottom(this.xScale).ticks(12).tickFormat(d3.timeFormat("%b"));
        let yAxis = d3.axisLeft(yScale).ticks(5);
    
        svg.append("g")
            .attr("class", "x axis")
            .attr("transform", `translate(0, ${height - margin})`)
            .call(xAxis);
    
        svg.append("g")
            .attr("class", "y axis")
            .call(yAxis)
            .append("text")
            .attr("y", 15)
            .attr("transform", "rotate(-90)")
            .attr("fill", "#000")
    
        this.tooltipLine = svg.append('line')
        this.tipBox = svg.append('rect')
            .attr('width', width)
            .attr('height', height)
            .attr('opacity', 0)
            .on('mousemove', this.drawTooltip)
            .on('mouseout', this.removeTooltip);
    }

    removeTooltip = () => {
        this.tooltip = d3.select('#tooltip');
        if (this.tooltip) this.tooltip.style('display', 'none');
        if (this.tooltipLine) this.tooltipLine.attr('stroke', 'none');
    }

    drawTooltip = (event) => {
        let hoverMonth = this.xScale.invert(d3.pointer(this.tipBox.node())[0]);
        let year = parseInt(moment(hoverMonth).format('YYYYMM'));
        this.tooltip = d3.select('.chartWrapper-tooltip');
        let displayToolTip = this.chartData && this.chartData[0].values.filter(ele => {
            return ele.monthMoment === year
        })[0];
        if (displayToolTip) {
            this.tooltipLine.attr('stroke', 'lightgray')
                .attr('x1', this.xScale(hoverMonth))
                .attr('x2', this.xScale(hoverMonth))
                .attr('y1', 0)
                .attr('y2', 250);
    
            this.tooltip.html(moment(year, 'YYYYMM').format("MMMM, YYYY"))
                .style('display', 'block')
                .selectAll()
                .data(this.chartData).enter()
                .append('div')
                .style('color', (d, i) => this.color(i))
                .html(d => {
                    return d.lable + ': ' + d.values.find(h => h.monthMoment == year).price
                });
    
            document.getElementsByClassName('chartWrapper-tooltip')[0].style.left = `${event.pageX + 18}px`;
            document.getElementsByClassName('chartWrapper-tooltip')[0].style.top = `${event.pageY - 20}px`;
        }
    }

    color(i) {
        return (i === 1) ? "#fc0" : "#00142D";
    };
}