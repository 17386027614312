import { Services } from './../../../Api/services'
import * as config from './../../../shared/config'
export const slugApi = async data => {

  const url = `${config.COMPANY_BASE_URL}company/slug/${data}`;

  let res = await Services.getCall(url, false)
  return { data: res.data, status: res.status }
};


export const slugVerificationApi = async data => {
  const url = `${config.COMPANY_BASE_URL}company/email/${data}`;

  let res = await Services.getCall(url, false)
  return { data: res.data, status: res.status }
};
