// import npm packages
import React from "react";
import { connect } 	  from "react-redux";
import { Spin } from "antd";
import { LoadingOutlined }  from '@ant-design/icons';
import {bindActionCreators} from "redux";

// import local files
import withRouter           from '../../../../shared/withRouter';
import {requestReferralNeighbour,
	requestReferralDetails} from "../../actions/referralDetails";
import ReferralLanding 		from "./sharedPage/referralLanding";
import SystemGlanceLanding 	from "./sharedPage/systemGlanceLanding";
import MonitoringLanding 	from "./sharedPage/monitoringLanding";
import { setLoader } 		from '../../../Common/Loader/action';
import { requestPostReferralDetails, 
	receivePostReferralDetails,
	requestPostAddressDetails,
	receivePostAddressDetails,
	receivePostAddressFormData } 	  from "../../actions/referralDetails";
import {requestHomeownerSystemGlance} from '../../actions/dashboard';
import { requestHomeownerEnergyData } from '../../actions/homeOwner';
import { convertJSON2CSS } 			  from "../../../User/components/setUserBackgroud"

class ShareCommon extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			share: '',
			isApiResponse: false
		}
	}

	componentWillMount() {
		let shareType = this.props.router.params.share;
		this.setState({ share: shareType });
		let referralId = this.props.router.params.referralId;
		convertJSON2CSS(this.props.router.params.slug);
		this.props.requestReferralDetails({referralId,shareType});
		if(shareType=="referral"){
			this.props.requestReferralNeighbour({referralId,shareType});
		}
	}

	componentWillReceiveProps(nextProps) {
		let details = ( nextProps.referralDetails && nextProps.referralDetails.homeownerReferrals && nextProps.referralDetails.homeownerReferrals.data ) || {};

		if (JSON.stringify(details) !== '{}'
			&& !this.state.isApiResponse) {
			let companyId = details && details.companyId;
			let homeownerId = details && details.id;
			let date = this.props.router.params.date;
			let offset = this.props.router.params.offset;
			nextProps.setLoader({ loader: true });

			if(this.state.share==='glance')
				this.props.requestHomeownerSystemGlance({companyId,homeownerId});

			if(this.state.share==='sunburst')
				this.props.requestHomeownerEnergyData({homeownerId,date,offset})

			this.setState({
				isApiResponse: true
			});
			nextProps.setLoader({ loader: false });
		}
	}

	renderPage() {
		let { share } = this.state;
		switch (share) {
			case "referral":
				return <ReferralLanding {...this.props} />;
			case "glance":
				return <SystemGlanceLanding {...this.props} />;
			case "sunburst":
				return <MonitoringLanding {...this.props} />;
			default:
				return null;
		}
	}
	render() {
		const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
		return (
			<Spin
				spinning={this.props.loader}
				indicator={antIcon}
				style={{ color: "#04bfff" }}
				wrapperClassName ="referral-loader" 
			>
				{this.renderPage()}
			</Spin>
		)
	}
}

const mapStatetoProps = state => {
	return {
		referralDetails: state.homeownerReferralDetails,
		loader: state.loader.loader.loader,
		systemGlance: state.homeownerDashboard.homeownerSystemGlance.data || {},
		energyReport: state.homeownerLoggedInUser.homeownerEnergyReportReducer.data || {}
	};
};

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			requestReferralDetails,
			requestReferralNeighbour,
			setLoader,
			requestPostReferralDetails,
			receivePostReferralDetails,
			requestPostAddressDetails,
			receivePostAddressDetails,
			receivePostAddressFormData,
			requestHomeownerSystemGlance,
			requestHomeownerEnergyData
		},
		dispatch
	);

export default withRouter(
	connect(
		mapStatetoProps,
		mapDispatchToProps
	)(ShareCommon)
);