// import npm packages
import React from "react";

// import local files

class LandingPage extends React.Component {
	render() {
        var loadingIcon
        if(this.props.loadingIcon&& this.props.loadingIcon!==''){
            loadingIcon = this.props.loadingIcon;
        }
        else if(this.props.role!=="homeowner"){
            loadingIcon = require('../../Assets/Images/landing-page.png');
        }

		return(
            <div className='landing-page-container'>
                <div className='image-container'>
                    {loadingIcon && <img
                        src={loadingIcon}
                        alt='landing-page.png'
                    />}
                </div>
            </div>
        )
	}
}

export default LandingPage;