export var campaignFilterOption = {

    "Inverter Brand": {
      key       : `su."inverterBrandId"`,
      filterKey : `su."inverterBrandId"`,
      masterData: 'inverterBrands',
      filterType: 'List',
    },

    "System Size": {
      key: `su."systemSize"`,
      filterKey: `(su."systemSize")::numeric`,
      filterType: 'Input',
      isNumeric : true,
      operators : ['greater than', 'less than', 'equals']
    },

    "Module Brand": {
      key: `su."moduleBrandId"`,
      filterKey: `su."moduleBrandId"`,
      filterType: 'List',
      masterData: 'moduleBrands',
      filterList: []
    },

    "Meter": {
      key: `su."meterId"`,
      filterKey: `su."meterId"`,
      filterType: 'List',
      masterId:'meterId',
      masterData:'powerMeter',
      filterList: []
    },

    "State": {
      key: `homeowners."state"`,
      filterKey: `homeowners.address::json->>'state'`,
      masterId:'code',
      filterType: 'List',
      masterData : 'states',
      filterList: []
    },

    "City": {
      key: `homeowners."city"`,
      filterType: 'Input',
      filterKey: `homeowners.address::json->>'city'`,
      operators: ['equals', 'contains']
    },

    "Zip Code": {
      key: `homeowners."zipCode"`,
      filterType: 'Input',
      filterKey: `homeowners.address::json->>'zipCode'`,
      operators: ['equals', 'contains']
    },

    "Utility": {
      key: `eu.name`,
      filterType: 'List',
      masterData: 'electricutility',
      masterId:'electricUtilityId',
      filterKey: `su."electricUtilityId"`,
      filterList: []
    },

    "Phase": {
      key: `survey.phase`,
      filterKey: `su.phase`,
      filterType: 'List',
      phatomMapping:{
        '1':'Design',
        '2':'Permit',
        '3':'The Build',
        '4':'Inspection',
        '5':'Powering Up',
        '6':'Powered Up'
      },
      filterList: [
        { id: '1', name: 'Design' },
        { id: '2', name: 'Permit' },
        { id: '3', name: 'The Build' },
        { id: '4', name: 'Inspection' },
        { id: '5', name: 'Powering Up' },
        { id: '6', name: 'Powered Up' }
      ]
    },

};

export function createCampaignFilterQuery (filter, companyId){
  let query = '';
  var previousFilterIndex = 0;
  filter && filter.forEach((element, index) => {
    element.value = element.value.filter(Boolean);
    if (element.parameter && element.value && element.value.length > 0) {
      let operatorValue = 'AND';
      if (element.value && element.value.length > 0)
        operatorValue = 'OR';
      element.value.forEach((filterValue, filterIndex) => {
        if (filterIndex === element.value.length - 1)
          operatorValue = 'AND';
        let matchOperation = checkCondition(element.condition);
        if (element.condition === 'contains') {
          query += `${filterIndex === 0 ? '(' : ''} LOWER(${campaignFilterOption[element.parameter].filterKey}) ${matchOperation} '%${filterValue.toLowerCase()}%' ${operatorValue === 'AND' ? ')' : ''} ${operatorValue} `;
        }
        else  if(campaignFilterOption[element.parameter].isNumeric)
          query += `${filterIndex === 0 ? '(' : ''} (${campaignFilterOption[element.parameter].filterKey}) ${matchOperation} '${filterValue.toLowerCase()}' ${operatorValue === 'AND' ? ')' : ''} ${operatorValue} `;
        else if (campaignFilterOption[element.parameter].filterType == 'Input')
          query += `${filterIndex === 0 ? '(' : ''} LOWER(${campaignFilterOption[element.parameter].filterKey}) ${matchOperation} '${filterValue.toLowerCase()}' ${operatorValue === 'AND' ? ')' : ''} ${operatorValue} `;
        else
          query += `${filterIndex === 0 ? '(' : ''} ${campaignFilterOption[element.parameter].filterKey} ${matchOperation} '${filterValue}' ${operatorValue === 'AND' ? ')' : ''} ${operatorValue} `;
      })
    }
    previousFilterIndex = index;
  });
  query += `homeowners."companyId" = ${companyId} AND homeowners."isDisable" = false`;
  return query;
}

function checkCondition(condition){
  if(!condition)
    return '=';
  switch(condition.toLowerCase()){
    case 'contains':
      return 'LIKE';
    case 'greater than':
      return '>';
    case 'less than':
      return '<';
    default:
      return '=';
  }
}