// import npm packages
import React from 'react';
import PropTypes from 'prop-types';
import { Input, Modal } from 'antd';
import NumberFormat from 'react-number-format';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap/dist/css/bootstrap.min.css';

// import local files
import Footer from '../../../Installer/components/footer';
import * as config from '../../../../shared/config';
import withRouter from '../../../../shared/withRouter';
import { jwtDecode } from '../../../../shared/jwt';
import { getToken } from '../../../../shared/util';
import { convertJSON2CSS } from '../../../User/components/setUserBackgroud';
import GoogleDriveSync from '../../../../shared/GoogleDriveSync';
import { PostShareDetails } from '../../api/promiseApi';
import { handleSubmitgeneric } from '../../components/shared/solarShare';
import { deviceType, checkIsMobile } from '../../../../shared/checkIsMobile';
import { ReactComponent as ShareSun_icon } from '../../../../Assets/Images/shareSun_icon.svg';
import { ReactComponent as Document_icon } from '../../../../Assets/Images/documents.svg';
import { ReactComponent as Question_mark } from '../../../../Assets/Images/question_mark.svg';
import { ReactComponent as Ed_resources_icon } from '../../../../Assets/Images/Ed_resources_blue.svg';
import { ReactComponent as Setting_icon } from '../../../../Assets/Images/setting-icon-blue.svg';
import { ReactComponent as Phone_chat_icon } from '../../../../Assets/Images/phone_chat.svg';
import { ReactComponent as Logout_icon } from '../../../../Assets/Images/logout_blue.svg';
import { ReactComponent as Group_of_people_Icon } from '../../../../Assets/Images/group_of_people_yellow.svg';

import {
  contactIconSvg,
  settingsIconSvg,
  logoutIconSvg,
  gmailIconSvg,
  microsoftIconSvg,
  yahooIconSvg,
  icloudIconSvg
} from '../../../../constants/icons';

class HeaderComponent extends React.Component {
  static propTypes = {
    isSignedIn: PropTypes.string,
    signUpError: PropTypes.bool,
    signOut: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    auth: PropTypes.object
  };

  constructor(props) {
    super(props);
    const token = getToken();
    this.homeownerData = jwtDecode(token);
    this.dataSource = [];
    this.GoogleDriveSync = new GoogleDriveSync();
    this.data = {};
    this.slug = localStorage.getItem('slug');
    this.state = {
      active: false,
      isShow: false,
      visible: false,
      errorObj: {},
      isReferralThankYou: false,
      addOption: true
    };
  }

  get token() {
    return getToken();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.router.location.pathname !== prevProps.router.location.pathname &&
      (this.state.isShow || this.state.active)
    ) {
      this.setState({
        isShow: false,
        active: false
      });
      document.body.classList.remove('overflowHidden');
    }
  }

  componentWillReceiveProps(nextProps) {
    const data = nextProps.referralDetails && nextProps.referralDetails.postHomeownerReferrals;
    if (data && data.data && this.state.isHeaderReferral) {
      this.props.receivePostReferralDetails('');
      this.props.setLoader({ loader: false });
      this.setState(
        { isHeaderReferral: false, name: '', email: '', phone: null, visible: true },
        () => {
          this.setState({ isReferralThankYou: true });
        }
      );
    }
  }

  signOut = () => {
    const slug = localStorage.getItem('slug');
    this.props.router.navigate(`/${slug}/`);
    const timeInterval = localStorage.getItem('timeInterval');
    if (timeInterval) {
      clearInterval(timeInterval);
    }
    localStorage.clear();
    localStorage.setItem('slug', slug);
    this.GoogleDriveSync.handleSignoutClick();
    this.props.signOut();
    document.body.classList.add('bg-color');
  };

  coloapsable = () => {
    const currentState = this.state.active;
    this.setState({ active: !currentState });
    this.setState({
      isMount: !this.state.isMount,
      isShow: !this.state.isShow
    });
    document.body.classList.toggle('overflowHidden');
  };

  showModal = (value) => {
    this.setState({
      visible: value
    });
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
      isReferralThankYou: false,
      addPopUp: false
    });
  };

  checkNumberRange(value, range, type) {
    const { errorObj } = this.state;
    this.setState({ phone: value });
    switch (type) {
      case 'phone':
        if (value.length !== range) {
          errorObj.phone = 'error';
        } else {
          errorObj.phone = '';
        }
        this.setState({
          errorObj
        });
        break;
      default:
        if (value > range) {
          errorObj[type] = 'error';
        } else {
          errorObj[type] = '';
        }
        this.setState({
          errorObj
        });
        break;
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.showModal(false);
    this.saveReferral(handleSubmitgeneric(this.state, [], this.props));
  };

  saveReferral(body) {
    if (!body) return;
    this.setState({ isHeaderReferral: true });
    this.props.setLoader({ loader: true });
    this.props.requestPostReferralDetails(body);
  }

  setMessageHref(url, method) {
    this.beforeClick(method)
      .then((res) => {})
      .catch((e) => {
        console.log('error', e.toString());
      });
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  beforeClick(method) {
    const bodyData = {
      homeownerId:
        this.props.loggedInUser &&
        this.props.loggedInUser.solarUnit &&
        this.props.loggedInUser.solarUnit.homeownerId,
      companyId:
        this.props.loggedInUser &&
        this.props.loggedInUser.homeownerData &&
        this.props.loggedInUser.homeownerData.companyId,
      method,
      share: 'referral'
    };
    return PostShareDetails(bodyData);
  }

  convertJSON2CSS() {
    const slug = this.props.router.params.slug || localStorage.getItem('slug');
    this.setState(
      {
        uploadCssDynamic: true
      },
      () => {
        convertJSON2CSS(slug);
      }
    );
  }

  render() {
    const { loggedInUser, systemGlance } = this.props;
    {
      !this.state.uploadCssDynamic && this.convertJSON2CSS();
    }
    if (this.token && loggedInUser) {
      const mobileImage = systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.icon;
      const image = systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.logo;
      const sectionStyle = checkIsMobile()
        ? {
            backgroundImage: `url(${mobileImage})`,
            height: '33px',
            width: '33px',
            backgroundSize: '33px',
            display: 'block'
          }
        : {
            backgroundImage: `url(${image})`,
            height: '44px',
            width: '350px',
            backgroundSize: 'auto 44px',
            backgroundRepeat: 'no-repeat'
          };

      return (
        <>
          <div>{this.renderAddToScreen()}</div>
          <div
            className={`nav-header-wrapper dashboard-nav-wrapper home-owner-mobile-menu fixed-top ${this.props.isNewUser}`}>
            <nav className="navbar navbar-expand-lg navbar-light bg-light bg-light-homeowner border-bottom-0">
              <p
                className="navbar-brand homeowner"
                style={image && sectionStyle}
                onClick={() => {
                  this.props.router.navigate(`/${this.slug}/dashboard`);
                }}
              />
              <button
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={this.coloapsable.bind(this)}
                className={`navbar-toggler ${this.state.active ? 'cross-icon' : ''}`}>
                <span className="navbar-toggler-icon" />
              </button>
              <div
                className={
                  this.state.isShow ? 'collapse navbar-collapse show' : 'collapse navbar-collapse'
                }
                id="navbarSupportedContent">
                <ul className="mr-auto display-none"> </ul>
                <div className="my-2 my-lg-0">
                  {this.renderHomeownerDesktopMenu()}

                  <div className="row">
                    <div className="menu-mobile homeOwner-menu-mobile col-12">
                      <div className="media">
                        <div className="media-body">
                          <h5 className="font-weight-400">MENU OPTIONS</h5>
                        </div>
                      </div>
                      {this.renderHomeownerMobileMenu()}
                    </div>
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <Footer isHomeownerProtal {...this.props} />
        </>
      );
    }
    return null;
  }

  renderHomeownerDesktopMenu() {
    const { loggedInUser, systemGlance, homeownersList } = this.props;
    const referralId = loggedInUser.homeownerData && loggedInUser.homeownerData.referralId;
    const mobileImage =
      loggedInUser.homeownerData && loggedInUser.homeownerData.icon
        ? loggedInUser.homeownerData.icon
        : 'https://prod-17terawatts.s3.amazonaws.com/public/logo/bothi-mobile-logo.png';
    const companyName = loggedInUser.homeownerData && loggedInUser.homeownerData.companyName;
    const installerName =
      loggedInUser.homeownerData &&
      (loggedInUser.homeownerData.installerName || loggedInUser.homeownerData.installerEmail);
    const installerTitle = loggedInUser.homeownerData && loggedInUser.homeownerData.installerTitle;
    return (
      <ul className="navbar-nav">
        <li
          className={
            this.props.router.location.pathname.includes(`/${this.slug}/dashboard`) ||
            this.props.router.location.pathname.includes(`/${this.slug}/statusFeed`)
              ? 'home-owner-active nav-item nav-add-link home-owner-nav'
              : 'nav-item nav-add-link home-owner-nav'
          }>
          <span onClick={() => this.props.router.navigate(`/${this.slug}/dashboard`)}>Home</span>
        </li>

        {this.slug !== 'creative' && (
          <li
            className={
              this.props.router.location.pathname.includes(`/${this.slug}/documents`)
                ? 'home-owner-active nav-item nav-add-link home-owner-nav'
                : 'nav-item nav-add-link home-owner-nav'
            }>
            {' '}
            <span onClick={() => this.props.router.navigate(`/${this.slug}/documents`)}>
              Documents
            </span>
          </li>
        )}

        <li
          className={
            this.props.router.location.pathname.includes(`/${this.slug}/resources`)
              ? 'home-owner-active nav-item nav-add-link home-owner-nav'
              : 'nav-item nav-add-link home-owner-nav'
          }>
          <span onClick={() => this.props.router.navigate(`/${this.slug}/resources`)}>
            Resources
          </span>
        </li>
        <li
          className={
            this.props.router.location.pathname.includes(`/${this.slug}/faq`)
              ? 'home-owner-active nav-item nav-add-link home-owner-nav'
              : 'nav-item nav-add-link home-owner-nav'
          }>
          <span onClick={() => this.props.router.navigate(`/${this.slug}/faq`)}>FAQs</span>
        </li>

        <li className="nav-item nav-add-link home-owner-nav premiumLinks">
          <span className="shareSun" onClick={() => this.showModal(true)}>
            Share the sun
          </span>
          <Modal
            visible={this.state.visible}
            onCancel={this.handleCancel}
            forceRender
            bodyStyle={{ height: 'auto' }}
            footer={null}
            wrapClassName="shareSun-modal">
            <div>
              {this.state.isReferralThankYou
                ? this.renderThankYou()
                : this.renderReferralForm(systemGlance, homeownersList, referralId)}
            </div>
          </Modal>
        </li>

        <li className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle pad-left"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={(e) => this.setState({ isShow: !this.state.isShow })}>
            <img
              className="dashboard-profile-image comp-icon"
              src={mobileImage}
              alt="Mobile profile-pic"
            />
            <span className="dashboard-profile-name">{companyName}</span>
          </a>
          <div
            className={`dropdown-menu box-Shadow homeowner ${this.state.isShow ? 'show' : ''}`}
            aria-labelledby="navbarDropdown">
            <div className="drop-up-arrow" />

            <a
              className="dropdown-item homeowner"
              onClick={() => {
                this.props.router.navigate(`/${this.slug}/contact`);
              }}>
              <span className="drop-icon homeowner">
                <img src={contactIconSvg} alt="contact-icon" />
              </span>
              <span className="dropHeader premiumLinkHover">Contact {companyName}</span>
              <div className="text">{installerTitle}</div>
              <div className="text">{installerName}</div>
            </a>

            <a
              className="dropdown-item homeowner"
              onClick={() => {
                this.props.router.navigate(`/${this.slug}/settings`);
              }}>
              <span className="drop-icon">
                <img src={settingsIconSvg} alt="setting-icon" />
              </span>
              <span className="dropHeader premiumLinkHover">Change settings</span>
            </a>

            <a className="dropdown-item homeowner" onClick={this.signOut}>
              <span className="drop-icon">
                <img src={logoutIconSvg} alt="logout-icon" />
              </span>

              <span className="dropHeader premiumLinkHover">Logout</span>
            </a>
          </div>
        </li>
      </ul>
    );
  }

  renderHomeownerMobileMenu() {
    /**
     * @todo: verify this change
     */
    const isSolarCommunityPage = !!this.props.router.location.pathname.includes('solarCommunity');
    return (
      <div className="row">
        {!isSolarCommunityPage && (
          <div className="col-6 menu-box">
            <span
              onClick={() => {
                this.props.router.navigate(`/${this.slug}/solarCommunity`, {
                  state: { isHamburger: true }
                });
              }}>
              <ShareSun_icon />
              <span className="menu-text">Share the sun</span>
            </span>
          </div>
        )}
        <div className="col-6 menu-box">
          <span
            onClick={() => {
              this.props.router.navigate(`/${this.slug}/documents`);
              this.setState({ active: !this.state.active, isShow: false });
              document.body.classList.remove('overflowHidden');
            }}>
            <Document_icon />
            <span className="menu-text">Documents</span>
          </span>
        </div>
        <div className="col-6 menu-box">
          <span
            onClick={() => {
              this.props.router.navigate(`/${this.slug}/faq`);
              const currentState = this.state.active;
              this.setState({ active: !currentState, isShow: false });
              document.body.classList.remove('overflowHidden');
            }}>
            <Question_mark />
            <span className="menu-text">FAQs</span>
          </span>
        </div>
        <div className={`col-6 menu-box ${!isSolarCommunityPage ? 'border-t-0' : ''}`}>
          <span
            onClick={() => {
              this.props.router.navigate(`/${this.slug}/resources`);
              const currentState = this.state.active;
              this.setState({ active: !currentState, isShow: false });
              document.body.classList.remove('overflowHidden');
            }}>
            <Ed_resources_icon />
            <span className="menu-text">Educational resources</span>
          </span>
        </div>
        <div className="col-6 menu-box">
          <span
            onClick={() => {
              this.props.router.navigate(`/${this.slug}/settings`);
              const currentState = this.state.active;
              this.setState({ active: !currentState, isShow: false });
              document.body.classList.remove('overflowHidden');
            }}>
            <Setting_icon />
            <span className="menu-text">Settings</span>
          </span>
        </div>
        <div className="col-6 menu-box">
          <span
            onClick={() => {
              this.props.router.navigate(`/${this.slug}/contact`);
              const currentState = this.state.active;
              this.setState({ active: !currentState, isShow: false });
              document.body.classList.remove('overflowHidden');
            }}>
            <Phone_chat_icon />
            <span className="menu-text">Contact your project manager</span>
          </span>
        </div>
        <div className={`col-6 menu-box ${!isSolarCommunityPage ? 'logout' : ''}`}>
          <span onClick={this.signOut}>
            <Logout_icon />
            <span className="menu-text">Logout</span>
          </span>
        </div>
      </div>
    );
  }

  renderAddToScreen() {
    const deviceInfo = deviceType();
    deviceInfo.text = 'Add Bodhi to your home screen to easily access it at anytime.';
    const t2 = 'Bookmark this page for easily access to Bodhi at anytime.';
    deviceInfo.isLearnMore = true;

    deviceInfo.position = 'bottom';
    deviceInfo.tap = 'chrome_dots.png';
    deviceInfo.className = 'chrome-icon';
    deviceInfo.pressText = 'Add to Home Screen.';

    if (deviceInfo.os === 'ios' && deviceInfo.browser === 'Chrome') {
      deviceInfo.text = t2;
      deviceInfo.tap = 'upload-blue-icon.png';
      deviceInfo.className = '';
      deviceInfo.position = 'top';
      deviceInfo.pressText = 'Add Bookmark.';
      deviceInfo.isLearnMore = false;
    } else if (deviceInfo.os === 'ios' && deviceInfo.browser === 'Opera') {
      deviceInfo.text = t2;
      deviceInfo.position = 'bottom';
      deviceInfo.className = 'opera';
      deviceInfo.isStar = true;
      deviceInfo.isLearnMore = false;
      deviceInfo.tap = 'opera.png';
    } else if (deviceInfo.os === 'ios' && deviceInfo.browser === 'Firefox') {
      deviceInfo.text = t2;
      deviceInfo.position = 'top';
      deviceInfo.className = 'revert';
      deviceInfo.pressText = 'Pin to Top Sites.';
      deviceInfo.isLearnMore = false;
    } else if (deviceInfo.os === 'ios' && deviceInfo.browser === 'Safari') {
      deviceInfo.tap = 'upload-blue-icon.png';
      deviceInfo.className = '';
    } else if (deviceInfo.os === 'android' && deviceInfo.browser === 'Opera') {
      deviceInfo.pressText = 'Add Bookmark.';
      deviceInfo.isLearnMore = false;
    } else if (deviceInfo.os === 'android' && deviceInfo.browser === 'Firefox') {
      deviceInfo.isStar = true;
    }

    if (deviceInfo.os === 'android' && deviceInfo.browser === 'Chrome') {
      return '';
    }
    return (
      <>
        {this.state.addOption &&
          this.checkIsStorageValid() &&
          (this.state.addPopUp
            ? this.renderAddToScreenPopUP(deviceInfo)
            : this.renderAddToScreenHeader(deviceInfo))}
      </>
    );
  }

  checkIsStorageValid() {
    const time = localStorage.getItem('addToHomeHide');
    if (!time) return true;
    return false;
  }

  renderAddToScreenHeader(deviceInfo) {
    return (
      <div className="notice-box">
        <div className="text-right">
          {' '}
          <span
            onClick={() => {
              this.setState({ addOption: false });
              localStorage.setItem('addToHomeHide', new Date().getTime());
            }}
            className="close-icon">
            &times;
          </span>
        </div>
        <p>
          {deviceInfo.text} Tap{' '}
          <img
            className={deviceInfo.className}
            src={require(`../../../../Assets/Images/${deviceInfo.tap}`)}
            width="10px"
          />{' '}
          at the {deviceInfo.position} of your browser and then{' '}
          {!deviceInfo.isStar ? (
            <strong>{deviceInfo.pressText}</strong>
          ) : (
            <span>
              press<span className="star-icon">&#9733;</span>
            </span>
          )}{' '}
          {deviceInfo.isLearnMore && (
            <strong>
              <span
                className="button-popUp"
                onClick={() => {
                  this.setState({ addPopUp: true });
                }}>
                Learn More
              </span>
            </strong>
          )}
        </p>
      </div>
    );
  }

  renderAddToScreenPopUP(deviceInfo) {
    let homeScreenMenu = 'chrome_menu.png';
    let homeScreenOption = 'chrome_addhomescreen.png';
    if (deviceInfo.os === 'ios' && deviceInfo.browser === 'Safari') {
      homeScreenOption = 'add-home-graphic-3.png';
      homeScreenMenu = 'add-home-graphic-2.png';
    }

    return (
      <Modal
        visible
        onCancel={this.handleCancel}
        bodyStyle={{ height: 'auto' }}
        footer={null}
        wrapClassName="add-screen-popup">
        <div className="add-home-screen-view">
          <h2>ADD TO HOME SCREEN</h2>
          <p>Add to Home Screen creates a shortcut icon of the Bodhi app on your phone.</p>
          <p className="text-center">
            <img src={require('../../../../Assets/Images/add-home-graphic-1.png')} />
          </p>
          <p>
            When you open the Bodhi icon{' '}
            <img className="bodhi-icon" src={require('../../../../Assets/Images/bodhi_icon.png')} />
            from your home screen, Bodhi will launch so you can quickly access all your important
            solar information.
          </p>
          <p>
            1) Using {deviceInfo.browser} tap{' '}
            <img
              className={deviceInfo.className}
              src={require(`../../../../Assets/Images/${deviceInfo.tap}`)}
              width="15px"
            />{' '}
            found at the bottom of your screen.
          </p>
          <p className="text-center mt-4 mb-4 homeScreenMenu">
            <img src={require(`../../../../Assets/Images/${homeScreenMenu}`)} />
          </p>
          <p>
            2) Then press <strong>Add to Home Screen</strong>.
          </p>
          <p className="text-center homeScreenOption">
            <img src={require(`../../../../Assets/Images/${homeScreenOption}`)} />
          </p>
          <p className="got-it-btn">
            <div onClick={() => this.handleCancel()}>GOT IT</div>
          </p>
        </div>
      </Modal>
    );
  }

  renderThankYou() {
    return (
      <div className="solar-community-box">
        <center>
          <div>
            <i>THANK YOU FOR SHARING!</i>
            <p>We will notify you when your neighbor goes solar.</p>
          </div>
          <button
            type="button"
            className="btn yellow-btn premiumButtonFill premiumButtonText"
            onClick={() => this.setState({ visible: false, isReferralThankYou: false, lat: null })}>
            Continue
          </button>
        </center>
      </div>
    );
  }

  referralMessageType = (systemGlance) => {
    if (systemGlance.solarGlance && systemGlance.solarGlance.referralType === 1) {
      return (
        <h2>
          SHARE THE SUN <br />
          {systemGlance.phaseGlance && systemGlance.phaseGlance.referralAmount
            ? `GIVE YOUR FRIEND $${systemGlance.phaseGlance.referralAmount}`
            : ''}
        </h2>
      );
    }
    if (systemGlance.solarGlance && systemGlance.solarGlance.referralType === 2) {
      return (
        <h2>
          REFER A FRIEND. <br />
          {systemGlance.phaseGlance && systemGlance.phaseGlance.referralAmount
            ? `RECEIVE $${systemGlance.phaseGlance.referralAmount}`
            : ''}
        </h2>
      );
    }
  };

  renderReferralForm(systemGlance, homeownersList, referralId) {
    const {
      loggedInUser: { homeownerData }
    } = this.props;

    return (
      <div className="solar-community-box nav-header">
        <div className="community-icon logo">
          <Group_of_people_Icon style={{ height: '100%', width: '100%' }} />
        </div>
        {this.referralMessageType(systemGlance)}
        <div className="sharethesun-content">
          <p>
            17 trillion watts is needed to power our global energy consumption. Make sure it's 17
            terawatts of clean energy. {homeownersList && homeownersList.ReferralMessage}
          </p>
        </div>
        <div className="invite-box col">
          <p>Easily share specific contacts</p>
          <a className="cloudsponge-launch" data-cloudsponge-source="gmail">
            {' '}
            <img src={gmailIconSvg} alt="Gmail_Icon.svg" width="25" />
          </a>
          <a className="cloudsponge-launch" data-cloudsponge-source="outlook">
            {' '}
            <img src={microsoftIconSvg} alt="microsoft_icon.svg" width="25" />
          </a>
          <a className="cloudsponge-launch" data-cloudsponge-source="yahoo">
            {' '}
            <img src={yahooIconSvg} alt="yahoo_icon.svg" width="25" />
          </a>
          <a className="cloudsponge-launch" data-cloudsponge-source="icloud">
            {' '}
            <img src={icloudIconSvg} alt="icloud_icon.svg" width="25" />
          </a>
        </div>
        <form onSubmit={this.handleSubmit}>
          <div className="form-group sharethesun-field-pad">
            <Input
              type="text"
              value={this.state.name}
              className="form-control"
              placeholder="Name"
              onChange={(e) => {
                this.setState({ name: e.target.value });
              }}
            />
          </div>
          <div className="form-group sharethesun-field-pad">
            <Input
              type="email"
              value={this.state.email}
              placeholder="Email"
              className="form-control"
              onChange={(e) => {
                this.setState({ email: e.target.value });
              }}
            />
          </div>
          <div className="form-group sharethesun-field-pad">
            <NumberFormat
              type="tel"
              disabled={this.props.isInstaller ? 'disabled' : ''}
              format={homeownerData && homeownerData.formSetting && homeownerData.formSetting.phone}
              mask=" "
              className="form-control"
              value={this.state.phone}
              placeholder="Phone Number"
              onChange={(e) => {
                this.checkNumberRange(
                  e.target.value.replace(/[^0-9]/g, ''),
                  homeownerData &&
                    homeownerData.formSetting &&
                    homeownerData.formSetting.phoneLength,
                  'phone'
                );
              }}
            />
          </div>
          <div className="button-wrap text-center">
            <button
              type="submit"
              className="btn yellow-btn premiumButtonFill premiumButtonText"
              disabled={this.props.isInstaller ? 'disabled' : ''}>
              {systemGlance.solarGlance && systemGlance.solarGlance.referralType === 1
                ? 'Give'
                : 'Invite'}
            </button>
          </div>
        </form>

        <div className="share-via-wrapper border-zero share-sun">
          <div
            className="heading-share premiumLinks"
            data-toggle="collapse"
            data-target="#share-media-box">
            {' '}
            Share on social media{' '}
          </div>

          <ul id="share-media-box" className="white-box share-via-wrapper collapse">
            <li>
              <FacebookShareButton
                beforeOnClick={() => this.beforeClick(1)}
                quote="Hey, I've gone solar and thought you might be interested. This is a link to my solar community. Check it out!"
                url={`${window.location.origin}/${this.slug}/referral/${referralId}/referral/1`}>
                <a
                  // href="#"
                  className="facebook">
                  <img
                    src={require('../../../../Assets/Images/facebook.svg')}
                    alt="facebook.svg"
                    width="25"
                  />
                </a>
              </FacebookShareButton>
            </li>

            <li>
              <TwitterShareButton
                beforeOnClick={() => this.beforeClick(2)}
                title="Hey, I've gone solar and thought you might be interested. This is a link to my solar community. Check it out!"
                url={`${window.location.origin}/${this.slug}/referral/${referralId}/referral/2`}>
                <a href="#" className="facebook">
                  <img
                    src={require('../../../../Assets/Images/twitter.svg')}
                    alt="twitter.svg"
                    width="25"
                  />
                </a>
              </TwitterShareButton>
            </li>
            <li className="disabled">
              <a
                onClick={() =>
                  this.setMessageHref(
                    `https://api.whatsapp.com/send?text=Hey, I've gone solar and thought you might be interested. This is a link to my solar community. Check it out!${window.location.origin}/${this.slug}/referral/${referralId}/referral/4`,
                    4
                  )
                }
                href="#"
                className="message">
                <img
                  src={require('../../../../Assets/Images/whatsapp.png')}
                  alt="sms.svg"
                  width="25"
                />
              </a>
            </li>
            <li className="disabled">
              <a
                onClick={() =>
                  this.setMessageHref(
                    `sms:?&body=Hey, I've gone solar and thought you might be interested. This is a link to my solar community. Check it out!${window.location.origin}/${this.slug}/referral/${referralId}/referral/3`,
                    3
                  )
                }
                href="#"
                className="message">
                <img src={require('../../../../Assets/Images/sms.svg')} alt="sms.svg" width="25" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default withRouter(HeaderComponent);
