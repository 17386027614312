export const statusButtonOption = [
    {key:'1', label:'Home'},
    {key:'2', label:'System at a glance'},
    {key:'3', label:'Solar Community'},
    {key:'4', label:'Documents'},
    {key:'5', label:'FAQs'},
    {key:'6', label:'Educational Resources'},
    {key:'7', label:'Settings'},
    {key:'8', label:'Contact'}
];

export const statusButtonLink ={
    1:'/dashboard',
    2:'/systemGlance',
    3:'/solarCommunity',
    4:'/documents',
    5:'/faq',
    6:'/resources',
    7:'/settings',
    8:'/contact'
}