import React from "react";
import { AutoComplete, Button } from "antd";

import withRouter  from '../../../../shared/withRouter';
import * as config from "../../../../shared/config";
import { getToken } from "../../../../shared/util";
import { jwtDecode } from "../../../../shared/jwt";
import searchIcon from "./../../../../Assets/Images/search.svg"

const Option = AutoComplete.Option;
const OptGroup = AutoComplete.OptGroup;

let token

class searchMobile extends React.Component {
    constructor(props) {
        super(props);
        this.options = [];
        this.dataSource = [];
        this.state = {
            isProfileCreated: '',
            installerId: '',
            newInstall: '',
            settingLink: '',
            searchID: '',
            firstName: '',
        }
    }
    componentWillMount() {
        this.slug = localStorage.getItem("slug");
        let token = getToken();
        this.installerData = jwtDecode(token);
        this.companyId = this.props.router.params.companyId || this.installerData.companyId;
    }
    renderNewInstall() {
        const email = encodeURIComponent(this.state.emailId);
        const data = {
            id: this.installerData.companyId,
            email: email
        };
        this.setState({
            newInstall: true
        });
        if (email) {
            this.props.requestAddHomeOwner(data);
        }
    }

    componentWillReceiveProps(nextProps) {
        
        if (
            nextProps.installerData
            && nextProps.installerData.data
        ) {
            if (
                !nextProps.installerData.data.isProfileCreated
                && nextProps.location.pathname !== `/${this.slug}/${nextProps.installerData.data.companyId}/setting`
            ) {
                nextProps.router.navigate(`/${this.slug}/${nextProps.installerData.data.companyId}/setting`);
            }
            this.setState({
                isProfileCreated: nextProps.installerData.data.isProfileCreated,
                settingLink: `/${this.slug}/${nextProps.installerData.data.companyId}/setting`,
                installerId: nextProps.installerData.data.id
            })
        }
        if (nextProps.searchData && nextProps.searchData.data) {
            this.dataSource = nextProps.searchData.data;
            if(
                this.dataSource
                && this.dataSource.length
                && this.dataSource.length !== 0
            ) {
                this.options = this.dataSource.map(item => {
                    return (
                        <OptGroup>
                            <Option key={item.id} value={`${item.firstName}-${item.email}`}>
                                <span className="searchId">{item.id}</span>
                                <p className="certain-search-item-count">
                                    {item.firstName} {" "+item.lastName} - {item.email}
                                </p>
                            </Option>
                        </OptGroup>
                    );
                });
            } else {
                this.options = [(
                    <OptGroup>
                        <Option value=''>
                            <span className="searchId"></span>
                            <p className="certain-search-item-count">
                                No results
                            </p>
                        </Option>
                    </OptGroup>
                )]
            }
        }
    }

    handleSearch = value => {
        this.setState({
            searchID: '',
        });
        if (value.length > 2) {
            let data = {
                value: value,
                companyId   : this.installerData.companyId,
                installerId : this.state.installerId
            };
            this.props.requestSearchData(data);
        } else if (value.length === 0) {
            this.dataSource = [];
            this.forceUpdate();
        }
    };
    onSelect = (option) => {
        this.setState({
          searchID: option.props.children[0].props.children,
          firstName: option.props.value,
        }, () => {
          this.state.isProfileCreated?
            option.props.value && this.props.router.navigate(
              "/" +
              this.slug + `/${this.companyId}`+
              "/dashboard/" + option.props.children[0].props.children + "/homeowner",
              { firstName: option.props.value }
            )
          :
            this.state.settingLink && this.props.router.navigate(this.state.settingLink)
        })
    }
    render() {
        let {companyName} = this.props;
        return (
            <React.Fragment >
                <div>
                <title>Search Homeowner - {companyName&&companyName.data&&companyName.data.name+'-'} Bodhi</title>

                    <div className="container margin-t-120 text-center dashboard-nav-wrapper search-wrapper">
                        <h5 className="margin-b-20  font-weight-400">HOMEOWNER SEARCH</h5>
                        <div className="dashboar-search">
                            <form className="form-inline">
                                <div className="search-wrap search-wrapMobile">
                                    <AutoComplete
                                        className="certain-category-search"
                                        dropdownClassName="certain-category-search-dropdown"
                                        dropdownMatchSelectWidth={false}
                                        size="large"
                                        style={{ width: "100%" }}
                                        placeholder={config.SEARCH_HOMEOWNER}
                                        onSelect={(value, option) => { this.onSelect(option) }}
                                        onSearch={this.handleSearch}
                                    >
                                    {
                                        this.options
                                    }
                                    </AutoComplete>
                                    <Button
                                        className="btn btn-outline-success my-2 my-sm-0 search-btn"
                                        onClick={() => {
                                            this.state.isProfileCreated ?
                                                this.state.firstName && this.props.router.navigate(
                                                    "/" +
                                                    this.slug + `/${this.companyId}`+
                                                    "/dashboard/" + this.state.searchID + "/homeowner",
                                                    { firstName: this.state.firstName }
                                                )
                                            :
                                                this.state.settingLink && this.props.router.navigate(this.state.settingLink)
                                        }}
                                    >
                                        <img
                                            src={searchIcon}
                                            alt='search'
                                        />
                                    </Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </React.Fragment>

        )

    }
}

export default withRouter(searchMobile);
