const dev = {
  s3: {
    BUCKET: 'dev-17terawatts',
    URL: 'cognito-idp.us-east-1.amazonaws.com/us-east-1_NJnHkZSqe',
    IMAGE_URL: 'https://s3.amazonaws.com/uat-17terawatts'
  },
  podium:{
    clientId:'60fb796a-1b11-4011-8da4-f75dd2a81d3a'
  },
  apiGateway: {
    REGION: '',
    BASE_URL: {
      HOME_OWNER: 'https://qshgrgnkwc.execute-api.us-east-1.amazonaws.com/dev/',
      ADMIN: 'https://rlathxjsgd.execute-api.us-east-1.amazonaws.com/dev/',
      INSTALLER: 'https://6gseoegsgj.execute-api.us-east-1.amazonaws.com/dev/',
      COMPANY: 'https://67ds33k681.execute-api.us-east-1.amazonaws.com/dev/',
      REFERRAL: 'https://ag145vaxd7.execute-api.us-east-1.amazonaws.com/dev/',
      SOCIAL_SHARE:
        'https://v5s9trugc8.execute-api.us-east-1.amazonaws.com/dev/',
      SURVEY: 'https://mjf39s32x8.execute-api.us-east-1.amazonaws.com/dev/',
      ENERGY_REPORT:
        'https://ouueubsp1m.execute-api.us-east-1.amazonaws.com/dev/',
      EVERSIGN_POST:
        'https://05dfy7ugt8.execute-api.us-east-1.amazonaws.com/dev/',
      MARKETING_URL:
        'https://hmrm7jcqxd.execute-api.us-east-1.amazonaws.com/dev/'
    }
  },
  cognito: {
    Region: 'us-east-1',
    UserPoolId: 'us-east-1_NJnHkZSqe',
    ClientId: '5bqiaf053iiig30f7vth0sn8s9',
    IdentityPoolId: "us-east-1:d24da3d5-1ca2-4151-8da3-8413e83d1448",
  },
  googleDrive: {
    CLIENT_ID:
      '984756927397-h6d0547s6f0tk789rctdqgtv10c8uv08.apps.googleusercontent.com',
    API_KEY: 'AIzaSyCT5KwAZr20p5IGcKLT9_9i63FE-MmDYRQ'
  },
  dropbox: {
    clientId: 'iih4chsjrjei9f5',
    client_secret : '4mgda3af8urax6z'
  },
  googleMap: {
    API_KEY: 'AIzaSyCB6-bafvWwJRIi48ycKHKbWLfiJbhPt4w'
  },
  SALES_FORCE_BASE_URL: 'https://test.salesforce.com',
  SERVICE_FUSION_BASE_URL : 'https://api.servicefusion.com',
  HUBSPOT_BASE_URL:'https://app.hubspot.com',
  BUCKET_URL:'https://dev-17terawatts.s3.amazonaws.com/public',
  REPORT_SYNC: false,
  GA_KEY: 'UA-144118249-1'
};

export default dev;
