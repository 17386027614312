import { installerSettingKey } from './routes'

const appFontOptions = [
      {
        "key":"arial",
        "label" : "Arial"
      },
      {
        "key": "cabin",
        "label": "Cabin"
      },
      // {
      //   "key": "futura",
      //   "label": "Futura STD"
      // },
      {
        "key": "gothic",
        "label": "Century Gothic"
      },
      {
        "key": "harmonia",
        "label": "Harmonia"
      },
      {
        "key": "kulim",
        "label": "Kulim"
      },
      {
        "key": "lato",
        "label": "Lato"
      },
      {
        "key": "montserrat",
        "label": "Montserrat"
      },
      {
        "key": "muli",
        "label": "Muli"
      },
      {
        "key": "poppins",
        "label": "Poppins"
      },
      {
        "key": "proxima",
        "label": "Proxima Nova"
      },
      {
        "key": "questrial",
        "label": "Questrial"
      },
      {
        "key": "raleway",
        "label": "Raleway"
      },
      {
        "key": "roboto",
        "label": "Roboto"
      },
      {
        "key": "rubik",
        "label": "Rubik"
      },
      {
        "key": "source",
        "label": "Source Sans Pro"
      }
    ]

export {
    installerSettingKey,
    appFontOptions
}