
import React, { Component }         from 'react';
import { Modal, Input, Button }     from 'antd';

import withRouter                   from '../../shared/withRouter'
import SharePointSync               from '../../shared/SharePointSync';
import GoogleDriveSync              from '../../shared/GoogleDriveSync';
import DropBoxSync                  from '../../shared/DropBoxSync';
import {openNotificationWithIcon}   from '../../shared/notification';

class UploadDocument extends Component {
  constructor(props) {
    super(props);
    this.clickCount       = 0;
    this.singleClickTimer = '';
    this.GoogleDriveSync  = new GoogleDriveSync();
    this.DropBoxSync      = new DropBoxSync();
    this.SharePointSync   = new SharePointSync();
    this.state = {
      classFolderList: [],
      selectedFolder: {},
      files: null,
      documentId: {},
      isOpen: false,
      errorMessage: {},
      filter: '',
      companyDocumentId: null,
      dropBoxToken: null,
      isSyncCalled: false,
      previewUrls: {},
      searchFolder: [],
      searchFilter : '',
      syncFolderId : {},
      searchHeader : []
    };
  }

  componentWillUnmount() {
    this.setState({
      filter: null
    });
  }

  componentWillReceiveProps(nextProps) {
    let token = localStorage.getItem('dropboxAccessToken');
    if (token && !this.state.isDPInititalize) {
      this.setState({ dropBoxToken: token , isDPInititalize: true},
        () => {
          this.DropBoxSync.initializeDropbox(token).then(res => {
            if (res) {
              localStorage.removeItem('dropboxAccessToken');
              localStorage.removeItem('pathname');
              if (!localStorage.getItem('isFileSync'))
                this.syncDropBoxFolderList(this.state.dropBoxToken);
              else localStorage.removeItem('isFileSync');
            }
          });
        }
      );
    }

    let filter = '';
    let companyDocumentId = {};
    // fetching filter and company data
    if (
      nextProps &&
      nextProps.companyId &&
      nextProps.companyId.data &&
      nextProps.companyId.data.document &&
      nextProps.companyId.data.document.length &&
      nextProps.companyId.data.document.length !== 0
    ) {
      companyDocumentId = nextProps.companyId.data.document[0].documentId;
      filter = nextProps.companyId.data.document[0].filter;
      this.setState({
        filter,
        token : nextProps.companyId.data.document[0].token,
        companyDocumentId
      });
    }

    // fetching homeowner document data
    if (
      nextProps &&
      nextProps.homeOwnerData &&
      nextProps.homeOwnerData.getHomeOwner &&
      nextProps.homeOwnerData.getHomeOwner.data &&
      nextProps.homeOwnerData.getHomeOwner.data.documentData &&
      nextProps.homeOwnerData.getHomeOwner.data.documentData.key &&
      !this.state.isSyncCalled
    ) {
      if (!this.state.documentId || !this.state.documentId.key) {
        const { documentData } = nextProps.homeOwnerData.getHomeOwner.data;
        if (documentData && documentData.source && documentData.key) {
          if (documentData.source === 'google' && companyDocumentId === 1) {
            this.displayDriveFiles(documentData); 
          }
        }
      }
    }
    this.initializeDropbox(nextProps, filter);
  }

  displayDriveFiles = (documentData) => {
    let driveData = documentData.driveData
    if (driveData && (JSON.stringify(driveData) != '{}')) {
      this.props.setLoader({ loader: false });
      this.props.setImages(driveData)
      this.setState({
        isSyncCalled: true,
        files: driveData,
        documentId: {
          key: documentData.key,
          source: documentData.source,
        },
      })
    }
    else if (driveData && (JSON.stringify(driveData) == '{}')) {
      if (!this.state.files) {
        // fetching google drive files
        this.setState(
          {
            isSyncCalled: true
          },
          () => {
                  this.setState({
                    files: this.props.cloudImages,
                    documentId: {
                      key: documentData.key,
                      source: documentData.source
                    },
                  });
                  this.props.setLoader({ loader: false });
          }
        );
      }
    }
  }

  // initialize dropbox and fetch files if add
  initializeDropbox(nextProps, filter) {
    if (
      nextProps.installerData &&
      nextProps.installerData.data &&
      nextProps.installerData.data.dropboxToken &&
      !this.state.isSyncCalled
    ) {
      this.setState(
        {
          isSyncCalled : true,
          dropBoxToken: nextProps.installerData.data.dropboxToken
        },
        () => {
          // fetching dropbox files
          this.DropBoxSync.initializeDropbox(
            nextProps.installerData.data.dropboxToken
          ).then(res => {
            if (res) {
              if (
                nextProps &&
                nextProps.homeOwnerData &&
                nextProps.homeOwnerData.getHomeOwner &&
                nextProps.homeOwnerData.getHomeOwner.data &&
                nextProps.homeOwnerData.getHomeOwner.data.documentData &&
                nextProps.homeOwnerData.getHomeOwner.data.documentData.key
              ) {
                const {
                  documentData
                } = nextProps.homeOwnerData.getHomeOwner.data;
                if (!this.state.files && !localStorage.getItem('isFileSync')) {
                  this.setState(
                    {
                      isSyncCalled: true
                    },
                    () => {
                      this.getDropBoxFilesList(documentData.key, filter);
                    }
                  );
                }
              }
            }
          });
        }
      );
    }
  }

  singleClick = (folder) => {
    let {searchHeader} = this.state;
    searchHeader.push(folder);
    this.setState({searchHeader,searchFilter:''})
    this.foldersHandler(folder);
  }


  handleClicks(folder) {
    this.clickCount++;
    if (this.clickCount === 1) {
      this.singleClickTimer = setTimeout(function () {
        this.clickCount = 0;
        this.singleClick(folder);
      }.bind(this), 200);

    }
  }

  // sync folders
  foldersHandler = data => {
    this.setState({syncFolderId: data,searchFilter:''});
    this.props.setLoader({ loader: true });
    let { classFolderList } = this.state;

    // check company document is dropbox
    if (this.state.companyDocumentId && this.state.companyDocumentId === 2) {
      // call function for sync folder of drop box
      this.syncDropBoxFolder(data);
      return null;
    } else if(this.state.companyDocumentId == 7){
      this.SharePointSync.sharePointFolderList(this.state.token,data.id,null).then(res=>{
        this.props.setLoader({ loader: false });
        if (res.length) {
          res.forEach(folder => {
            const index = classFolderList.findIndex(
              classFolder => classFolder.id === folder.id
            );
            if (index === -1) {
              classFolderList.push(folder);
            }
          });
          this.setState({
            classFolderList : res
          });
        } else {
          let {searchHeader} = this.state;
          searchHeader.pop(); 
          const errorMessage = this.state.errorMessage;
          errorMessage[data.id] = 'No more sub folder';
          this.setState({
            searchHeader,
            errorMessage
          });
        }
      })
    } else {
      // sync folder of google drive
      this.GoogleDriveSync.folderList(data.id, this.state.filter).then(res => {
        this.props.setLoader({ loader: false });
        if (res.folder.length) {
          res.folder.forEach(folder => {
            const index = classFolderList.findIndex(
              classFolder => classFolder.id === folder.id
            );
            if (index === -1) {
              classFolderList.push(folder);
            }
          });
          this.setState({
            classFolderList : res.folder
          });
        } else {
          let {searchHeader} = this.state;
          searchHeader.pop(); 
          const errorMessage = this.state.errorMessage;
          errorMessage[data.id] = 'No more sub folder';
          this.setState({
            searchHeader,
            errorMessage
          });
        }
      });
    }
  };

  // sync drop box folder
  syncDropBoxFolder(data) {
    let {loggedInUser} = this.props;
    this.props.setLoader({ loader: true });
    let token = (loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.dropBoxToken) || this.state.dropBoxToken;
    this.DropBoxSync.dropBoxFolderList(data.path,token).then(res => {
      this.props.setLoader({ loader: false });
      if (res && res.length && res.length !== 0) {
        this.setState({
          classFolderList:res
        });
      } else {
        const errorMessage = this.state.errorMessage;
        errorMessage[data.id] = 'No more sub folder';
        this.setState({
          errorMessage
        });
      }
    });
  }

  // sync drive
  uploadDocuments = () => {
    // check company document is dropbox
    this.props.setLoader({ loader: true });
    if (this.state.companyDocumentId && this.state.companyDocumentId === 2) {
      // called function of sync drop box
      let {loggedInUser} = this.props;
      let dropBoxToken = (loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.dropboxToken) || this.state.dropBoxToken || localStorage.getItem('dropboxAccessToken');
      this.syncDropBox(dropBoxToken);
      return null;
    } else if (this.state.classFolderList && this.state.classFolderList.length > 0) {
      this.setState({ isOpen: true });
    } else if (this.state.companyDocumentId && this.state.companyDocumentId === 7) {
      this.SharePointSync.sharePointFolderList(this.state.token).then(classFolderList => {
        this.setState({
          searchFolder: classFolderList,
          classFolderList,
          isOpen: true
        });
      })
    }

    // sync google drive
    else this.GoogleDriveSync.handleClientLoad().then(res => {
      if (!res) {
        openNotificationWithIcon(
          'error',
          'Not Authorized To Access Google Account.',
          'Error'
        );
        return;
      }
      const classFolderList = res && res.folder ? res.folder : [];
      if (
        classFolderList &&
        classFolderList.length &&
        classFolderList.length !== 0
      ) {
      //  folder getting set 
        this.setState({
          searchFolder :classFolderList,
          classFolderList,
          isOpen: true
        });
        this.forceUpdate();
      } else {
        openNotificationWithIcon(
          'error',
          "Your account doesn't have folder",
          'Error'
        );
      }
    });
  };

  // sync drop box
  syncDropBox(token) {
    if (!token) {
      this.DropBoxSync.handleClientLoad(window.location.origin,this.props.router.location.pathname);
    } else {
      this.syncDropBoxFolderList(token);
    }
  }

  // sync drop box folders list
  syncDropBoxFolderList(token) {
    let { loggedInUser } = this.props;
    let dropBoxToken = (loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.dropboxToken) || token;
    this.DropBoxSync.dropBoxFolderList('',dropBoxToken).then(res => {
      if (typeof res === 'string' && res.includes(window.origin)) {
        window.location.replace(res);
      } else {
        const classFolderList = res || [];
        if (
          classFolderList &&
          classFolderList.length &&
          classFolderList.length !== 0
        ) {
          this.setState({
            classFolderList,
            isOpen: true
          });
        } else {
          openNotificationWithIcon(
            'error',
            "Your account doesn't have folder",
            'Error'
          );
        }
      }
    });
  }

  // select folder to sync inner files list
  selectFolder = folder => {
    if (
      folder && folder.id &&
      this.state.selectedFolder &&
      this.state.selectedFolder.id &&
      this.state.selectedFolder.id === folder.id
    ) {
      this.setState({
        selectedFolder: null,
        files: {}
      });
      this.props.setDocuement({});
    } else {
      this.setState({
        selectedFolder: folder,
        isOpen: false,
        errorMessage: {}
      },()=>{
        this.syncFile();
      });
    }

  };

  // sync inner files list
  syncFile = () => {
    if (this.state.selectedFolder && this.state.selectedFolder.id) {
      this.props.setLoader({ loader: true });
      if (this.state.companyDocumentId && this.state.companyDocumentId === 2) {
        // called function of sync drop box Files
        this.syncDropBoxFiles();
        return null;
      } else if (this.state.companyDocumentId && this.state.companyDocumentId === 7) {
        this.SharePointSync.listFiles(this.state.selectedFolder.id,
          this.state.filter,null,this.state.token,true).then(res => {
            this.syncDocumentFilesResult(res, 'sharepoint')
          })
      } else {
        // sync google files
        this.GoogleDriveSync.listFiles(
          this.state.selectedFolder.id,
          this.state.filter
        ).then(res => {
          this.syncDocumentFilesResult(res, 'google')
        });
      }
    }
  };


  syncDocumentFilesResult(res,source){
    if (
      source !== "sharepoint" &&
      res &&
      res.missingFile &&
      res.missingFile.length &&
      res.missingFile.length !== 0
    ) {
      this.props.checkFileMissing(true, res.missingFile);
      openNotificationWithIcon(
        'error',
        `${res.missingFile.join()} files is missing.Please add them.`,
        'Error'
      );
    } else {
      this.props.checkFileMissing(false, null);
    }
    this.props.setDocuement({
      source: source,
      key: this.state.companyDocumentId==7 ? this.state.selectedFolder.id.replace(/\//g,'#')  :this.state.selectedFolder.id,
      files: res.fileList,
      isSharedDrive : res.isSharedDrive ? true : false
    });
    this.props.setLoader({ loader: false });
    this.setState({
      files: res.fileList
    });
  }

  // sync dropbox files
  syncDropBoxFiles() {
    let {loggedInUser} = this.props;
    this.props.setDocuement({
      dropboxToken: this.state.dropBoxToken || (loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.dropBoxToken),
      source: 'dropbox',
      key: this.state.selectedFolder.path
    });
    this.props.setLoader({ loader: true });
    this.getDropBoxFilesList(this.state.selectedFolder.path, this.state.filter);
  }

  getDropBoxFilesList(path, filter) {
    this.DropBoxSync.dropBoxListFiles(path, filter).then(res => {
      if (res && res.status) {
        openNotificationWithIcon('error', res.message, 'Error');
      } else {
        if (
          res &&
          res.missingFile &&
          res.missingFile.length &&
          res.missingFile.length !== 0
        ) {
          this.props.checkFileMissing(true, res.missingFile);
          openNotificationWithIcon(
            'error',
            `${res.missingFile.join()} files is missing.Please add them.`,
            'Error'
          );
        } else {
          this.props.checkFileMissing(false, null);
        }
        this.props.setLoader({ loader: false });
        if (typeof res === 'string' && res.includes(window.origin)) {
          window.location.replace(res);
        } else {
          Object.keys(res.fileList).forEach(key => {
            res.fileList[key].forEach(file => {
              if (file.path_display) {
                this.DropBoxSync.getPreview(file.path_display).then(res => {
                  let { previewUrls } = this.state;
                  previewUrls[file.id] = res
                    ? res.previewUrl.replace('dl=0', 'raw=1')
                    : '';
                  this.setState({
                    previewUrls
                  });
                });
              }
            });
          });
          this.setState({
            files: res && res.fileList ? res.fileList : {}
          });
        }
      }
    });
  }

  // sort folder by name
  sortFolder(files) {
    const temp = {};
    const tempArray = [];
    Object.keys(files).forEach(file => {
      if (files[file][0] && !files[file][0].id) {
        tempArray.push(files[file][0]);
      } else {
        temp[file] = files[file];
      }
    });
    tempArray
      .sort((val1, val2) => {
        return val1 && val2 && val1.toLowerCase() < val2.toLowerCase()
          ? -1
          : val1 && val2 && val1.toLowerCase() > val2.toLowerCase()
          ? 1
          : 0;
      })
      .forEach(val => {
        Object.keys(files).forEach(file => {
          if (files[file][0] && !files[file][0].id && files[file][0] === val) {
            temp[file] = files[file];
          }
        });
      });
    return temp;
  }

  renderHeader(){
    let {searchHeader} = this.state;    
    return searchHeader.map((ele,index)=>{
      return <div 
      className={`filter-option ${(index===(searchHeader.length-1) ? 'selectedFolder' : '')}`} 
      onClick={()=>{
        searchHeader.splice(index+1);
        this.foldersHandler(ele);
      }}>{ele.name}
      {
        index !== searchHeader.length &&
        <i className="fa fa-chevron-right" aria-hidden="true"></i>
      }
      </div>;
    })
  }

  // render upload section
  renderUploadDocSection = () => {
    if (this.state.isOpen) {
      return '';
    }
    return (
      <div
        className="upload-box"
        onClick={() => this.uploadDocuments()}
        key="no-folder-select"
      >
        <div className="upload-btn-wrapper">
          <button type="button" className="btn" />
          <span>Select homeowner document folder</span>
        </div>
      </div>
    );
  };

  // render Google drive files
  renderFiles = files => {
    const { previewUrls } = this.state;
    return Object.keys(this.sortFolder(files)).map(file => {
      if (
        files &&
        files[file] &&
        files[file].length < 2 &&
        files[file][0] &&
        !files[file][0].id
      ) {
        return (
          <li key={file} className="file-error">
            {files[file][0]}
          </li>
        );
      }
      return (
        <li key={file}>
          {files[file][0] && !files[file][0].id ? files[file][0] : ''}
          <ul>
            {files[file]
              .sort((file1, file2) => {
                const name1 =
                  file1 && file1.name && (file1.name.toLowerCase() || null);
                const name2 =
                  file2 && file2.name && (file2.name.toLowerCase() || null);
                return name1 && name2 && name1 < name2
                  ? -1
                  : name1 && name2 && name1 > name2
                  ? 1
                  : 0;
              })
              .map((val, index) => {
                if (index === 0 && !files[file][0].id) return '';

                return (
                  <li key={val.id}>
                    <a
                      href={
                        val.previewUrl
                          ? val.previewUrl
                          : previewUrls[val.id] || ''
                      }
                      target="_blank"
                      className ="drive-file"
                    >
                      {val && val.name}
                    </a>
                  </li>
                );
              })}
          </ul>
        </li>
      );
    });
  };

  render() {
    let parentFolder = [];
    let {classFolderList,searchFilter,selectedFolder,searchHeader} = this.state;
    if (classFolderList && classFolderList.length !== 0) {
      parentFolder = classFolderList.filter(
        folder =>
          !folder.parents ||
          !folder.parents.length ||
          classFolderList.findIndex(
            classFolder => classFolder.id === folder.parents[0]
          ) === -1
      );
    }
    let selectFolderId = '';
    var regex = new RegExp( searchFilter, "i");
    if (selectedFolder && selectedFolder.id) {
      selectFolderId = this.state.selectedFolder.id;
    }
    if(this.props.removeFIleState){
      this.removeFIleState();
    }
    return (
      <React.Fragment>
        {parentFolder && parentFolder.length !== 0 && (
          <>
            <Modal
              visible={this.state.isOpen}
              closable={false}
              cancelText="Cancel Sync"
              okText={"Sync " + `${(this.state.syncFolderId && this.state.syncFolderId.name) ? this.state.syncFolderId.name : ''} `}
              footer={[
                <Button className="btn btn-secondary" onClick={() => {
                  this.props.setLoader({ loader: false });
                  this.setState({
                    isOpen: false,
                    errorMessage: {}
                  });
                }}>
                  Cancel Sync
                </Button>,
                <Button  className="btn btn-primary auto-width"  onClick={() => {
                  this.setState({
                    isOpen: false,
                    selectedFolder: {}
                  }, () => {
                    this.selectFolder(this.state.syncFolderId);
                  });
  
                }}>
                  {"Sync " + `${(this.state.syncFolderId && this.state.syncFolderId.name) ? this.state.syncFolderId.name : ''} `}
                </Button>,
              ]}

            >
              <div className="document-popUp">
                <h1 className="header-popUp">Document sync</h1>
                <div className="search-header">
                  <div
                    className={`filter-option ${this.state.syncFolderId &&
                      (!this.state.syncFolderId.id ? 'selectedFolder' : '')}`}
                    onClick={() => {
                      searchHeader.splice(0);
                      this.foldersHandler({});
                    }}>{'My Folder'}
                    <i className="fa fa-chevron-right" aria-hidden="true"></i>
                  </div>
                  {this.renderHeader()}
                </div>

                <form className="search-form">
                  <div className="align-items-center">
                    <div className="syn-search-box">
                      <Input type="text" className="form-control"
                        value={this.state.searchFilter}
                        placeholder="Search for folder"
                        onChange={(e) => {
                          this.setState({ searchFilter: e.target.value });
                        }} />
                    </div>
                  </div>
                </form>

                <div className="select-folder-wrapper">
                  <h3>Select folder</h3>
                  <div className="select-folder-box">
                    <ul>
                      {parentFolder.sort((file1, file2) => {
                        const name1 = file1 && file1.name && (file1.name.toLowerCase() || null);
                        const name2 = file2 && file2.name && (file2.name.toLowerCase() || null);
                        return name1 && name2 && name1 < name2
                          ? -1 : name1 && name2 && name1 > name2 ? 1 : 0;
                      })
                        .filter(ele => regex.test(ele.name))
                        .map((folder, index) => {
                          return (
                            <li key={folder.id}>
                              <span className="icon"></span>
                              <span
                                className={
                                  this.state.syncFolderId.id === folder.id ? 'selectedFolder' : 'folderName'
                                }
                                onClick={() => this.handleClicks(folder)}
                              >
                                {folder.name}
                                {this.state.errorMessage &&
                                  this.state.errorMessage[folder.id] && (
                                    <span className="file-error">
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      {this.state.errorMessage[folder.id]}
                                    </span>
                                  )}
                              </span>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </Modal>
          </>)

        }
        <div className="upload-wrap">
          {this.renderUploadDocSection()}
          {this.props.updateDocumentId && this.state.selectedFolder && this.state.selectedFolder.name && (
            <div className="upload-box">
              <div className="sync-btn-wrapper">
                <button
                  type="button"
                  className="btn btn-primary auto-width"
                  onClick={() => {
                    this.props.updateDocumentId();
                  }}
                >
                  Save "{this.state.selectedFolder.name}"
                    </button>
              </div>
            </div>
          )}
        </div>
        {this.state.files && JSON.stringify(this.state.files) !== '{}' && (
          <div className="folderList">
            <span
              className="close-folder-section"
              onClick={() => {
                this.props.setDocuement({});
                this.removeFIleState();
              }}
            >
              X
                </span>
            <ul>{this.renderFiles(this.state.files)}</ul>
          </div>
        )}
      </React.Fragment>
    );
  }

  removeFIleState(){
    this.setState({
      selectedFolder: {},
      files: {},
      isOpen: false,
      errorMessage: {}
    });
  }

}

export default withRouter(UploadDocument);
