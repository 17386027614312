import React from "react";
// import "./../style.css";
import PropTypes                    from "prop-types";
import { bindActionCreators }       from "redux";
import { connect }                  from 'react-redux';
import { DatePicker, 
    AutoComplete, Spin }            from "antd";
import { LoadingOutlined }          from '@ant-design/icons';
import { openNotificationWithIcon } from '../../../../shared/notification'
import { requestSearchData }        from "../../Actions/header";
import { requestReferralReportApiData,
    recieveReferralReportApiData,
    recieveHomeownerReportApiData,
    requestHomeownerReportApiData}  from "../../Actions/dashboard";
import withRouter                   from '../../../../shared/withRouter';
import { JSONToCSVConvertor }       from '../../../../shared/jsontoCSV.ts'
import { setLoader }                from '../../../Common/Loader/action';

const antIcon = (
    <LoadingOutlined
        type="loading"
        className="dashboard-loading"
        style={{ fontSize: 60 }}
        spin
    />
);

const Option = AutoComplete.Option;
const OptGroup = AutoComplete.OptGroup;
class Report extends React.Component {
    static propTypes = {
        auth: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.state = {
            searchID: null
        };
    }
    handleSearch = (value) => {
        if (value.length > 2) {
            this.props.requestSearchData(value);
        }
        else if (value.length < 3) {
            this.options = [];
            this.forceUpdate();
        }

    }
    onSelect = (option) => {
        this.setState({
            searchID: option.key,
            firstName: option.props.value,
        }, () => {
        })
    }

    componentWillReceiveProps(nextProps, nextstate) {
        
        if (
            nextProps.reportData
            && nextProps.reportData[this.state.reportType]
            && nextProps.reportData[this.state.reportType].status === 200
        ) {
            nextProps.recieveReferralReportApiData({});
            nextProps.recieveHomeownerReportApiData({});
            this.props.setLoader({loader:false});
            const jsonToCSVConvertor = new JSONToCSVConvertor();
            let data = nextProps.reportData[this.state.reportType].data;
            if (data && data.length > 0) {
                jsonToCSVConvertor.convertData(data, this.state.reportType, true);
            }
            else {
                openNotificationWithIcon("error", 'No  data is available for selected date range.', "Error");
            }


        }

        if (nextProps.searchData && nextProps.searchData.data) {
            let dataSource = nextProps.searchData.data;
            this.options = dataSource.map((item, index) => {
                return (
                    <Option key={item.companyId} value={item.Company}>
                        {item.Company}
                    </Option>
                );
            });
            this.forceUpdate();
        }
    }

    reporthandler = (reportType,startDate,endDate) => {
        if (!this.state.searchID) {
            openNotificationWithIcon("error", 'Please select Installer', "Error");
            return null;
        }

        if (!startDate) {
            openNotificationWithIcon("error", 'Please select start date', "Error");
            return null;
        }

        if (!endDate) {
            openNotificationWithIcon("error", 'Please select end date', "Error");
            return null;
        }

        if (new Date(startDate).getTime() > new Date(endDate).getTime()) {
            openNotificationWithIcon("error", 'Please select correct date', "Error");
            return null;
        }


        this.setState({reportType},()=>{
            this.props.setLoader({loader:true});
            if(reportType=='referralReport'){
                this.props.requestReferralReportApiData({
                    companyId: this.state.searchID,
                    startDate: startDate,
                    endDate: endDate
                });
            }
            else{
                this.props.requestHomeownerReportApiData({
                    companyId: this.state.searchID,
                    startDate: startDate,
                    endDate: endDate
                });
            }
            
        })

      

    }

    renderReferralReport = () => {
        return (
            <Spin
                spinning={this.props.loader}
                indicator={antIcon}
                style={{ color: "#04bfff" }}
            >

                <div className="reports-owner">
                    <div className="container">
                        <div className="inner-reports-owner">
                            <div className="row justify-center-content space-bottom">
                                <div className="col-sm-4">
                                    <div className="box-Shadow reports-inner-pnl">
                                        <h2 className="form-heading2"> Homeowner reports</h2>
                                        <form className="form-inline">
                                            <label className="lbl-cmn-field mb-2">Installer
                                            </label>
                                            <div className="report">
                                                <AutoComplete
                                                    className="certain-category-search"
                                                    dropdownClassName="certain-category-search-dropdown"
                                                    dropdownMatchSelectWidth={false}
                                                    style={{ width: '100%' }}

                                                    onSelect={(value, option) => {
                                                        this.onSelect(option);
                                                    }}
                                                    onSearch={this.handleSearch}
                                                >
                                                    {this.options}
                                                </AutoComplete>
                                            </div>
                                        </form>
                                        
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="cmn-label-field-input">
                                                        <div className="form-group cmn-form-grp-input">
                                                            <label  className="lbl-cmn-field">start date</label>
                                                            <DatePicker
                                                                placeholder=''
                                                                onChange={(date, dateString) => {
                                                                    this.setState({
                                                                        accountStartDate: dateString
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="cmn-label-field-input">
                                                        <div className="form-group cmn-form-grp-input">
                                                            <label  className="lbl-cmn-field">end date </label>
                                                            <DatePicker
                                                                placeholder=''
                                                                onChange={(date, dateString) => {
                                                                    this.setState({
                                                                        accountEndDate: dateString
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                       
                                        <div className="form-submitted-btn">
                                            <button type="submit" className="btn btn-primary"  onClick={() => this.reporthandler("homeownerReport",this.state.accountStartDate,this.state.accountEndDate)}>Homeowner report</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="box-Shadow reports-inner-pnl">
                                        <h2 className="form-heading2"> referral reports</h2>
                                        <form className="form-inline">
                                        <label  className="lbl-cmn-field mb-2">Installer 
                                        </label>
                                            <div className="report">
                                            <AutoComplete
                                                    className="certain-category-search"
                                                    dropdownClassName="certain-category-search-dropdown"
                                                    dropdownMatchSelectWidth={false}
                                                    style={{ width: '100%' }}

                                                    onSelect={(value, option) => {
                                                        this.onSelect(option);
                                                    }}
                                                    onSearch={this.handleSearch}
                                                >
                                                    {this.options}
                                                </AutoComplete>
                                            </div>
                                        </form>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="cmn-label-field-input">
                                                        <div className="form-group cmn-form-grp-input">
                                                            <label  className="lbl-cmn-field">start date</label>
                                                            <DatePicker
                                                                placeholder=''
                                                                onChange={(date, dateString) => {
                                                                    this.setState({
                                                                        referralStartDate: dateString
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="cmn-label-field-input">
                                                        <div className="form-group cmn-form-grp-input">
                                                            <label  className="lbl-cmn-field">end date </label>
                                                            <DatePicker
                                                                placeholder=''
                                                                onChange={(date, dateString) => {
                                                                    this.setState({
                                                                        referralEndDate: dateString
                                                                    })
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        <div className="form-submitted-btn">
                                            <button type="submit" className="btn btn-primary"  onClick={() => this.reporthandler("referralReport",this.state.referralStartDate,this.state.referralEndDate)}>Referral report</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Spin>
        )
    }

    render() {
        if (localStorage.getItem("token")) {
            return this.renderReferralReport();
        } else {
            this.props.router.navigate("/");
            return null;
        }
    }

}

const mapStatetoProps = state => {
    return {
    loader: state.loader.loader.loader,
    searchData: state.adminHeader.searchData,
    reportData: state.adminOption.data
    }
  }

  const mapDispatchToProps = dispatch =>
	bindActionCreators({ 
        setLoader,
        requestSearchData,
        recieveReferralReportApiData,
        recieveHomeownerReportApiData,
        requestHomeownerReportApiData,
        requestReferralReportApiData
    },
		dispatch
)

export default withRouter(
    connect(
      mapStatetoProps,
      mapDispatchToProps
    )(Report)
);
