import {currencyFormater} from "../../../../shared/currencyFormater"
import * as config        from "../../../../shared/config";
import {openNotificationWithIcon} from '../../../../shared/notification';

export function  handleSubmitgeneric(formValues, cloudspongeContacts = [],props) {
    let { loggedInUser,systemGlance,homeownersList } = props;
    let homeownerId = loggedInUser && loggedInUser.solarUnit && loggedInUser.solarUnit.homeownerId;
    let companyId = loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.companyId;
    let installerId = loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.installerId;
    let referralContact = []
    if (cloudspongeContacts.length === 0) {

      if(!formValues.name && (!formValues.email && !formValues.phone)){
        openNotificationWithIcon('error', 'Name and email or phone are needed to share the sun', 'Please try again');
        return false;
      }
      else if(!formValues.email && !formValues.phone){
        openNotificationWithIcon('error', 'Email or phone is needed to share the sun', 'Please try again');
        return false;
      }
      else if(!formValues.name){
        openNotificationWithIcon('error', 'Name is needed to share the sun', 'Please try again');
        return false;
      }


      referralContact.push({
        name: formValues.name,
        email: formValues.email,
        phone: formValues.phone && formValues.phone.replace(/[^0-9]/g, '')
      })
    } else {
      cloudspongeContacts.forEach((item) => {
        referralContact.push({
          name: item.first_name,
          email: item.email[0].address
        })
      })
    }


    let companyPhone = loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.companyPhone;
    let isLead = false;
    let projectedSaving = systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.saving;
    let homeownerFirstname = loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.firstName;
    let solarCompany = loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.companyName;
    let solarFamiliesCount = homeownersList && (homeownersList.homesByzip || 0);
    let powered = systemGlance
      && systemGlance.solarGlance
      && systemGlance.solarGlance.production
      && systemGlance.solarGlance.consumption
      && currencyFormater((systemGlance.solarGlance.production / systemGlance.solarGlance.consumption) * 100);

    let markers = "";

    homeownersList
      && homeownersList.homesByAdd
      && homeownersList.homesByAdd.length !== 0
      && homeownersList.homesByAdd.forEach((element, index) => {
        if (
          element.lat
          && element.lng
          && element.nearaddress && index < 90
        ) {
          markers +=
            `&markers=icon:${config.S3_URL}/public/location.png%7Clabel:S%7C` + Number(element.lat) + "," + Number(element.lng);
        }
      });
    let mapUrl = loggedInUser.homeownerData && loggedInUser.homeownerData.lat && loggedInUser.homeownerData.lng && loggedInUser.homeownerData.address &&
      "https://maps.googleapis.com/maps/api/staticmap?center=" + loggedInUser.homeownerData.lat + "," + loggedInUser.homeownerData.lng + `&zoom=15&scale=1&size=600x300&maptype=roadmap&markers=icon:${config.S3_URL}/public/current-location.png%7Clabel:S%7C` + loggedInUser.homeownerData.lat + "," + loggedInUser.homeownerData.lng + markers + "&key=AIzaSyCB6-bafvWwJRIi48ycKHKbWLfiJbhPt4w&format=png&visual_refresh=true";


    let body = {
      homeownerId,
      companyId,
      installerId,
      referralContact,
      isLead,
      projectedSaving,
      homeownerFirstname,
      solarCompany,
      solarFamiliesCount,
      powered,
      mapUrl,
      companyPhone
    };

    return body;

}