export const monitoringSystemData = (meterType,data) =>{
    switch(meterType){
        case 5:
            return <span  className="metrics-data"><a href="https://apsystemsema.com/" target="_blank">APsystems<i className="fa fa-external-link ml-2" style={{"color":"#CCCCCC",cursor : "pointer"}} aria-hidden="true"/></a> </span>
        case 4:
            return <span  className="metrics-data">Sense</span>
        case 3:
            return <span  className="metrics-data">
                <a href={`https://enlighten.enphaseenergy.com/systems/${data.meterconId}`} target="_blank">Enphase<i className="fa fa-external-link ml-2" style={{"color":"#CCCCCC",cursor : "pointer"}} aria-hidden="true"/></a></span>
        case 2:
            return <span  className="metrics-data"><a href={`https://monitoring.solaredge.com/solaredge-web/p/site/${data.meterconId}`} target="_blank">SolarEdge<i className="fa fa-external-link ml-2" style={{cursor : "pointer","color":"#CCCCCC"}} aria-hidden="true"/></a></span>
        case 1:
            return <span  className="metrics-data"><a href={data.meterUrl && data.meterUrl.includes('//') ? data.meterUrl : '//'+data.meterUrl} target="_blank">eGauge<i className="fa fa-external-link ml-2" style={{cursor : "pointer","color":"#CCCCCC"}} aria-hidden="true"/></a></span>
        
    }
}

export const monitoringSystemUrl = (meterType,data) =>{
    switch(meterType){
        case 5:
            return <span  className="metrics-data"><a href="https://apsystemsema.com/" target="_blank"><i className="fa fa-external-link ml-2" style={{"color":"#ff6f47",cursor : "pointer"}} aria-hidden="true"/></a> </span>
        case 4:
            return <span  className="metrics-data"></span>
        case 3:
            return <span  className="metrics-data">
                <a href={`https://enlighten.enphaseenergy.com/systems/${data.meterconId}`} target="_blank"><i className="fa fa-external-link ml-2" style={{"color":"#ff6f47",cursor : "pointer"}} aria-hidden="true"/></a></span>
        case 2:
            return <span  className="metrics-data"><a href={`https://monitoring.solaredge.com/solaredge-web/p/site/${data.meterconId}`} target="_blank"><i className="fa fa-external-link ml-2" style={{cursor : "pointer","color":"#ff6f47"}} aria-hidden="true"/></a></span>
        case 1:
            return <span  className="metrics-data"><a href={data.meterUrl && data.meterUrl.includes('//') ? data.meterUrl : '//'+data.meterUrl} target="_blank"><i className="fa fa-external-link ml-2" style={{cursor : "pointer","color":"#ff6f47"}} aria-hidden="true"/></a></span>
        
    }
}
