import React, { Component }      from 'react';
import { Form, InputNumber, Checkbox } from 'antd';
import { connect }               from 'react-redux';
import {bindActionCreators}      from 'redux';
import {requestEditInstallerApiData,
  recieveEditInstallerApiData
}   from "../../../Admin/Actions/editInstaller";
import {requestCompanyFleetData} from "../../actions/fleet";
import {setLoader}               from '../../../Common/Loader/action';
import DashboardStats            from "../dashboard/dashboardStats";
import LowProdIcon               from '../../../../Assets/Images/lowprod_icon.svg';
import NoDataIcon                from '../../../../Assets/Images/nodata_icon.svg';
import NoProdIcon                from '../../../../Assets/Images/noprod_icon.svg'


const SystemNotificationsForm1 = props => {
  const [form] = Form.useForm();

  const cancelHandler = () => {
    form.resetFields();
  };

  return (
    <Form
      form={ form }
      onFinish={props.handleSubmit}
      initialValues={props.initialValue}
      validateMessages={{
        required: "This field is required."
      }}
    >
      <div class="content-box box-Shadow">
        <div class="row pt-4 m-0">
          {props.noDataAlert()}
          {props.noProdAlert()}
          <div className="border-line col-md-12" style={{ borderTop: '1px solid #DFE6EE', marginTop: '-25px' }}></div>
          {props.lowProdAlert()}
        </div>
      </div>

      <div className="row">
        <div className="text-center margt40 margin-auto width-100 btn-mobiel comp">
          <button
            className="btn btn-secondary width200 margr15"
            onClick={() => cancelHandler()}
            type="button"
          >
            Cancel
          </button>
          <button className="btn btn-primary width200">
            Save
          </button>
        </div>
      </div>
    </Form>
  )
}

class SystemNotificationsForm extends Component {
  constructor(props) {
    super(props);
    this.companyId = this.props.router.params.companyId;
    this.state = {};
  }

  componentWillMount() {
    this.props.setLoader({ loader: true });
    this.props.requestCompanyFleetData({companyId:this.companyId});
  }

  componentDidMount() {
    this.setState({
      generatingCutoff: (this.props.companyData && this.props.companyData.companyDetail && 
      this.props.companyData.companyDetail.generatingCutoff)||{},
      monitoringCutoff: (this.props.companyData && this.props.companyData.companyDetail && 
        this.props.companyData.companyDetail.monitoringCutoff)||{}
    })
  }

  componentWillReceiveProps(nextProps){
    let {companyFleet} = nextProps;
    if (!this.state.companyFleetCount && companyFleet && companyFleet[1]) {

      let noDataRed = 0;
      let noDataYellow = 0;
      let noProdRed = 0;
      let noProdYellow = 0;
      let lowProdRed = 0;
      let lowProdYellow = 0;
      let totalUser = companyFleet[companyFleet.length - 1][0]['totalUser'];
      companyFleet[0].map((ele) => {

        switch (ele.type) {
          case 1:
            noDataYellow++;
            break;
          case 2:
            noDataRed++;
            break;
          case 3:
            noProdYellow++;
            break;
          case 4:
            noProdRed++;
            break;
          case 5:
            lowProdYellow++;
            break;
          case 6:
            lowProdRed++;
            break;

        }
      });
      this.setState({
        companyFleetCount: {
          noProdRed,
          noProdYellow,
          noDataYellow,
          noDataRed,
          totalUser,
          lowProdRed,
          lowProdYellow,
          totalAlert: noDataRed + noDataYellow + noProdRed + noProdYellow + lowProdRed + lowProdYellow
        }
      });
      this.props.setLoader({ loader: false });
    }

    if (nextProps.dashboard.editInstaller && nextProps.dashboard.editInstaller.status === 200) {
      nextProps.requestCompanyDetail({companyId:this.props.companyId,admin:this.props.isAdmin});
      nextProps.recieveEditInstallerApiData({});
  }
}

  handleSubmit = values => {
    let obj = {
      bodyData: {
        noProduction: {
          noProdHomeownerDay: values.noProdHomeownerDay || null,
          noProdRedDay: values.noProdRedDay || null,
          noProdRedEmail: values.noProdRedEmail || null,
          noProdYellowDay: values.noProdYellowDay || null,
          noProdYellowEmail: values.noProdYellowEmail || null
        },
        lowProduction: {
          lowProdYellowPower: values.lowProdYellowPower || null,
          lowProdYellowDay: values.lowProdYellowDay || null,
          lowProdYellowEmail: values.lowProdYellowEmail || null,
          lowProdRedPower: values.lowProdRedPower || null,
          lowProdRedDay: values.lowProdRedDay || null,
          lowProdRedEmail: values.lowProdRedEmail || null,
          lowProdHomeownerDay: values.lowProdHomeownerDay || null,
        },
        noPowerData: {
          noDataHomeownerDay: values.noDataHomeownerDay || null,
          noDataRedDay: values.noDataRedDay || null,
          noDataRedEmail: values.noDataRedEmail || null,
          noDataYellowDay: values.noDataYellowDay || null,
          noDataYellowEmail: values.noDataYellowEmail || null
        }
      },
      companyId: this.props.companyId
    };
    this.props.requestEditInstallerApiData(obj);
    this.props.setLoader({ loader: true })
  };


  noDataAlert() {
    return (
      <div className="col-md-6 seprator-right pl-5 mb-5">

        <div className="row mb-3">
          <img className="fleet-icon" src={NoDataIcon} />
          <strong>No data: </strong>
          <p className="fleet">Trigger alert when system is offline for X days: </p>
        </div>
        <div className="form-group d-inline-flex ml-3">
          <p className="fleet">Yellow alert</p>

          <div className="col-md-4 d-inline-flex">
            <Form.Item 
            name = "noDataYellowDay"
            >
              <InputNumber min={1} max={100} className="alert-value mr-2"/>
            </Form.Item>
            <span className="fleet ml-2">days</span>
          </div>

          {/* <div className="col-md-7">
            <Form.Item
            name= "noDataYellowEmail"
              valuePropName="checked"
            >
              <Checkbox className="font-weight-light">Email service manager</Checkbox>
            </Form.Item>
          </div> */}

        </div>
        <div className="form-group d-inline-flex ml-3">
          <p className="fleet">Red alert</p>

          <div className="col-md-4 d-inline-flex">
            <Form.Item
            name={"noDataRedDay"}
            >
              <InputNumber min={1} max={100} className="alert-value mr-2"/>
            </Form.Item>
            <span className="fleet ml-2">days</span>
          </div>

          {/* <div className="col-md-7">
            <Form.Item
              valuePropName="checked"
              name={"noDataRedEmail"}
            >
              <Checkbox className="font-weight-light">Email service manager</Checkbox>
            </Form.Item>
          </div> */}

        </div>
        {/* <div className="form-group d-inline-flex  ml-3">
          <p className="fleet col-md-7 pr-0">Email alert to homeowner after</p>
          <div className="col-md-5 d-inline-flex">

            <Form.Item
            name={"noDataHomeownerDay"}
            >

              <InputNumber min={1} max={100} className="alert-value mr-2"/>
            </Form.Item>
            <span className="fleet ml-2">days</span>

          </div>
        </div> */}

      </div>
    )
  }

  noProdAlert() {
    return (
      <div className="col-md-6 pl-5 mb-5">

        <div className="row mb-3">
          <img className="fleet-icon" src={NoProdIcon} />
          <strong>No production: </strong>
          <p className="fleet">Trigger alert when system not producing for X days: </p>
        </div>
        <div className="form-group d-inline-flex ml-3">
          <p className="fleet">Yellow alert</p>

          <div className="col-md-4 d-inline-flex">
            <Form.Item
              name={'noProdYellowDay'}
            >
              <InputNumber min={1} max={100} className="alert-value mr-2"/>
            </Form.Item>
            <span className="fleet ml-2">days</span>
          </div>

          {/* <div className="col-md-7">
            <Form.Item
              name={'noProdYellowEmail'}
              valuePropName="checked"
            >
              <Checkbox className="font-weight-light">Email service manager</Checkbox>
            </Form.Item>

          </div> */}

        </div>
        <div className="form-group d-inline-flex ml-3">
          <p className="fleet">Red alert</p>

          <div className="col-md-4 d-inline-flex">
              <Form.Item
                name='noProdRedDay'
              >
                <InputNumber min={1} max={100} className="alert-value mr-2"/>
              </Form.Item>
              <span className="fleet ml-2">days</span>
          </div>

          {/* <div className="col-md-7">
            <Form.Item
              name='noProdRedEmail'
              valuePropName="checked"
            >
              <Checkbox className="font-weight-light">Email service manager</Checkbox>
            </Form.Item>

          </div> */}

        </div>
        {/* <div className="form-group d-inline-flex  ml-3">
          <p className="fleet col-md-7 pr-0">Email alert to homeowner after</p>
          <div className="col-md-5 d-inline-flex">

            <Form.Item
              name={'noProdHomeownerDay'}
            >
              <InputNumber min={1} max={100} className="alert-value mr-2"/>
            </Form.Item>

            <span className="fleet ml-2">days</span>

          </div>
        </div> */}

      </div>
    )
  }

  lowProdAlert() {
    return (
      <div className="col-md-7 pl-5 mb-5">

        <div className="row mb-3">
          <img className="fleet-icon" src={LowProdIcon} />
          <strong>Low production: </strong>
          <p className="fleet">Trigger alert when system is at or below X% power for Y days: </p>
        </div>

        <div className="form-group ml-3 d-inline-flex">
          <p className="fleet mr-3">Yellow alert</p>

          <div className='mr-3 d-inline-flex'>
            <Form.Item
              name='lowProdYellowPower'
            >
              <InputNumber min={10} max={100} className="alert-value mr-2"/>
            </Form.Item>
            <span className="fleet ml-2">% power for</span>
          </div>

          <div className='d-inline-flex'>
            <Form.Item
              name={'lowProdYellowDay'}
            >
              <InputNumber min={10} max={100} className="alert-value mr-2"/>
            </Form.Item>
            <span className="fleet ml-2">days</span>
          </div>

          {/* <div className="col-md-7">
            <Form.Item
              valuePropName="checked"
              name={'lowProdYellowEmail'}
            >
              <Checkbox className="font-weight-light">Email service manager</Checkbox>
            </Form.Item>
          </div> */}

        </div>

        <div className="form-group ml-3 d-inline-flex">
          <p className="fleet mr-3">Red alert</p>

          <div className='mr-3 d-inline-flex'>
            <Form.Item
              name='lowProdRedPower'
            >
              <InputNumber min={10} max={100} className="alert-value mr-2"/>
            </Form.Item>
            <span className="fleet ml-2">% power for</span>

          </div>

          <div className='d-inline-flex'>
            <Form.Item
              name={'lowProdRedDay'}
            >
              <InputNumber min={10} max={100} className="alert-value mr-2"/>
            </Form.Item>
            <span className="fleet ml-2">days</span>
          </div>

          {/* <div className="col-md-7">
            <Form.Item
              name={'lowProdRedEmail'}
              valuePropName="checked"
            >
              <Checkbox className="font-weight-light">Email service manager</Checkbox>
            </Form.Item>

          </div> */}

        </div>

        {/* <div className="form-group d-inline-flex  ml-3">
          <p className="fleet col-md-7 pr-0">Email alert to homeowner after</p>
          <div className="col-md-5 d-inline-flex">

            <Form.Item
              name={'lowProdHomeownerDay'}
            >
              <InputNumber min={10} max={100} className="alert-value mr-2"/>
            </Form.Item>
            <span className="fleet ml-2">days</span>

          </div>
        </div> */}

      </div>
    )
  }

  render() {  
    let {companyData:{companyDetail}} = this.props;
    if (!companyDetail || !Object.keys(companyDetail).length)
      return <></>

    let {lowProduction,noPowerData,noProduction} = companyDetail;
    let initialValue = {...lowProduction,...noPowerData,...noProduction};

    return (<>
      <div className="sys-DesktopView pt-5">
        <section className="profile-detail-box p-0">
          <div className="container col-md-12 p-0">
            <div className="row m-0">
             
              <div className="col-md-4">
                <h2 className="border-0 pt-0">Fleet alerts</h2>
                <p style={{letterSpacing:0,fontWeight:300}}>Define severity thresholds  and manage when alerts get triggered for system and production issues in your fleet.</p>
              </div>
              <DashboardStats {...this.props} companyFleetCount={this.state.companyFleetCount} statsType={"systemNotification"}/>
            </div>
          </div>
        </section>
        
        <SystemNotificationsForm1
          handleSubmit  =   {this.handleSubmit}
          noDataAlert   =   {this.noDataAlert}
          noProdAlert   =   {this.noProdAlert}
          lowProdAlert  =   {this.lowProdAlert}
          initialValue  =   {initialValue}
        />
        
      </div>
    </>)
    
  }
}

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    setCompanyDetail: state.setting.setCompanyDetail,
    dashboard: state.adminOption.data,
    companyFleet : state.fleet.companyFleet
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ 
    requestEditInstallerApiData,
    recieveEditInstallerApiData,
    setLoader,
    requestCompanyFleetData 
  }, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(SystemNotificationsForm);
