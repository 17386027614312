// import npm packages
import React, { Component } from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';

// import local files
import ShowImageFromDrive from '../../../Common/ShowImageFromDrive';
import { MONTH_ARRAY } from '../../../../shared/config';
import { currencyFormater } from '../../../../shared/currencyFormater';
import { PostShareDetails } from '../../api/promiseApi';
import { capitalize } from '../../../../shared/capitalize';
import { setHeight } from '../../../../shared/setHeight';

import { ReactComponent as ElectricIcon } from '../../../../Assets/Images/electric-icon.svg';
import { ReactComponent as SolarIcon } from '../../../../Assets/Images/solar-icon.svg';
import { ReactComponent as SunIcon } from '../../../../Assets/Images/sun-icon.svg';

import {
  facebookIconSvg,
  twitterIconSvg,
  whatsAppIconPng,
  smsIconSvg
} from '../../../../constants/icons';

class SystemAtGlance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hrefValue: '#'
    };
  }

  componentWillReceiveProps() {
    setHeight('homeOwnerWrapper', 'menuMobile');
  }

  beforeClick(method) {
    const { loggedInUser } = this.props;

    const bodyData = {
      homeownerId: loggedInUser && loggedInUser.solarUnit && loggedInUser.solarUnit.homeownerId,
      companyId: loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.companyId,
      method,
      share: 'glance'
    };
    return PostShareDetails(bodyData);
  }

  setMessageHref(url, method) {
    this.beforeClick(method)
      .then((res) => {})
      .catch((e) => {
        console.log('error', e.toString());
      });
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  }

  getMeterUrl(meterID, meterUrl) {
    this.meterUrl = {
      2: 'monitoring.solaredge.com',
      3: 'api.enphaseenergy.com',
      4: 'data.sense.com',
      5: 'apsystemsema.com',
      6: 'solarweb.com'
    };
    switch (meterID) {
      case 1:
      case 7:
        return meterUrl || '';
      case 2:
        return 'https://monitoring.solaredge.com/';
      case 3:
        return 'https://api.enphaseenergy.com/';
      case 4:
        return 'https://home.sense.com/';
      case 5:
        return 'https://apsystemsema.com/';
      case 6:
        return 'https://www.solarweb.com/';
    }
  }

  render() {
    const { systemGlance, loggedInUser } = this.props;
    let activationDate = null;
    if (systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.phaseName === '6') {
      if (systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.activationDate) {
        if (typeof systemGlance.solarGlance.activationDate === 'string')
          activationDate = Number(systemGlance.solarGlance.activationDate);
        else activationDate = systemGlance.solarGlance.activationDate;
      } else if (loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.created) {
        if (typeof loggedInUser.homeownerData.created === 'string')
          activationDate = Number(loggedInUser.homeownerData.created);
        else activationDate = loggedInUser.homeownerData.created;
      }
    }
    const referralId =
      loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.referralId;
    this.slug = localStorage.getItem('slug');
    let meterUrl = '';

    const avgOffset =
      systemGlance &&
      systemGlance.solarGlance &&
      systemGlance.solarGlance.production &&
      systemGlance.solarGlance.consumption;

    const isProjectTrack =
      loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.isProjectTrack;
    if (loggedInUser && loggedInUser.solarUnit && loggedInUser.solarUnit.meterId) {
      meterUrl = this.getMeterUrl(
        parseInt(loggedInUser.solarUnit.meterId),
        loggedInUser.solarUnit.meterUrl
      );

      if (meterUrl && !meterUrl.includes('http')) {
        meterUrl = `http://${meterUrl}`;
      }
    }
    return (
      <>
        <p className="page-title">Your system at a glance</p>
        <div className="referral-wrap slider-wrapper">
          <ShowImageFromDrive
            {...this.props}
            isOpen
            filterByName=""
            style={{ marginBottom: '80px' }}
          />
          <div className="row text-center system-glance-box">
            <div className={`col-sm-${avgOffset ? '4' : '6'} col-${avgOffset ? '4' : '6'}`}>
              <div className="icon">
                <ElectricIcon />
              </div>
              <div className="kw">
                {systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.systemSize} kW
              </div>
              <div className="desc">Size</div>
            </div>
            <div className="col-sm-4 col-4">
              <div className="icon">
                <SolarIcon />
              </div>
              <div className="kw">
                {systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.moduleCount}
              </div>
              <div className="desc">Panels</div>
            </div>
            {avgOffset != 0 && avgOffset && (
              <div className="col-sm-4 col-4">
                <div className="icon">
                  <SunIcon />
                </div>
                <div className="kw">
                  {`${currencyFormater(
                    (systemGlance.solarGlance.production / systemGlance.solarGlance.consumption) *
                      100
                  )}%`}
                </div>
                <div className="desc">Avg offset</div>
              </div>
            )}
          </div>
          {systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.saving ? (
            <div className="white-box padd15 text-center saving-box paddt10">
              <div className="annual-saving-text">Your annual savings</div>
              <div className="total-saving premiumLinks">
                ${currencyFormater(systemGlance.solarGlance.saving)}
              </div>
            </div>
          ) : (
            ''
          )}

          <div className="white-box padd15 text-center comapny-detail-box">
            <div className="detail-box  w-100">
              <span className="pull-left">Your solar company:</span>
              <span className="pull-right">
                <a
                  className="premiumLinks"
                  target="_blank"
                  href={
                    systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.companyUrl
                  }
                  rel="noreferrer">
                  {systemGlance &&
                    systemGlance.solarGlance &&
                    systemGlance.solarGlance.companyName &&
                    capitalize(systemGlance.solarGlance.companyName)}
                </a>
              </span>
            </div>

            <div className="detail-box  w-100">
              <span className="pull-left">Panels: </span>
              <span className="pull-right">
                {systemGlance &&
                  systemGlance.solarGlance &&
                  systemGlance.solarGlance.moduleName &&
                  `${capitalize(systemGlance.solarGlance.moduleName)} 
                                    ${
                                      (systemGlance &&
                                        systemGlance.solarGlance &&
                                        systemGlance.solarGlance.moduleModel &&
                                        capitalize(systemGlance.solarGlance.moduleModel)) ||
                                      ''
                                    }
                                    `}
              </span>
            </div>

            <div className="detail-box  w-100">
              <span className="pull-left">Inverter: </span>
              <span className="pull-right">
                {systemGlance &&
                  systemGlance.solarGlance &&
                  systemGlance.solarGlance.inverterName &&
                  capitalize(systemGlance.solarGlance.inverterName)}
              </span>
            </div>
            {!isProjectTrack && (
              <div className="detail-box  w-100">
                <span className="pull-left">Monitoring: </span>
                <a href={meterUrl} target="_blank" className="premiumLinks" rel="noreferrer">
                  <span className="pull-right">
                    {systemGlance &&
                      systemGlance.solarGlance &&
                      systemGlance.solarGlance.meterName &&
                      capitalize(systemGlance.solarGlance.meterName)}
                  </span>
                </a>
              </div>
            )}

            {activationDate ? (
              <div className="detail-box  w-100">
                <span className="pull-left">Activation Date: </span>
                <span className="pull-right">
                  {`${MONTH_ARRAY[new Date(activationDate).getMonth()]}
                                        ${new Date(activationDate).getDate()},
                                        ${new Date(activationDate).getFullYear()}`}
                </span>
              </div>
            ) : (
              ''
            )}
          </div>

          <div className="white-box share-via-wrapper">
            <div className="heading"> Share via:</div>
            {!this.props.isInstaller ? (
              <ul>
                <li>
                  <FacebookShareButton
                    beforeOnClick={() => this.beforeClick(1)}
                    quote="Hey, I've gone solar and thought you might be interested. This is a link to the equipment powering my home. Check it out!"
                    url={`${window.location.origin}/${this.slug}/referral/${referralId}/glance/1`}>
                    <a href="#" className="facebook">
                      <img src={facebookIconSvg} alt="facebook.svg" width="25" />
                    </a>
                  </FacebookShareButton>
                </li>
                <li>
                  <TwitterShareButton
                    beforeOnClick={() => this.beforeClick(2)}
                    title="Hey, I've gone solar and thought you might be interested. This is a link to the equipment powering my home. Check it out!"
                    url={`${window.location.origin}/${this.slug}/referral/${referralId}/glance/2`}>
                    <a href="#" className="facebook">
                      <img src={twitterIconSvg} alt="twitter.svg" width="25" />
                    </a>
                  </TwitterShareButton>
                </li>
                <li className="disabled">
                  <a
                    onClick={() =>
                      this.setMessageHref(
                        `https://api.whatsapp.com/send?text=Hey, I've gone solar and thought you might be interested. This is a link to the equipment powering my home. Check it out!${window.location.origin}/${this.slug}/referral/${referralId}/glance/4`,
                        4
                      )
                    }
                    href="#"
                    className="message">
                    <img src={whatsAppIconPng} alt="sms.svg" width="25" />
                  </a>
                </li>
                <li className="disabled">
                  <a
                    onClick={() =>
                      this.setMessageHref(
                        `sms:?&body=Hey, I've gone solar and thought you might be interested. This is a link to the equipment powering my home. Check it out!${window.location.origin}/${this.slug}/referral/${referralId}/glance/3`,
                        3
                      )
                    }
                    href="#"
                    className="message">
                    <img src={smsIconSvg} alt="sms.svg" width="25" />
                  </a>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <img
                    src={facebookIconSvg}
                    alt="facebook.svg"
                    width="25"
                    style={{ cursor: 'not-allowed' }}
                  />
                </li>
                <li>
                  <img
                    src={twitterIconSvg}
                    alt="twitter.svg"
                    width="25"
                    style={{ cursor: 'not-allowed' }}
                  />
                </li>
                <li>
                  <img
                    src={whatsAppIconPng}
                    alt="twitter.svg"
                    width="25"
                    style={{ cursor: 'not-allowed' }}
                  />
                </li>
                <li>
                  <img
                    src={smsIconSvg}
                    alt="sms.svg"
                    width="25"
                    style={{ cursor: 'not-allowed' }}
                  />
                </li>
              </ul>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default SystemAtGlance;
