import React, { Component } from 'react'

export default class DelayedProjects extends Component {
 
 renderShowPhaseName(Phase) {
    switch (parseInt(Phase)) {
      case 1:
        return 'Design';

      case 2:
        return 'Permitting';

      case 3:
        return 'The Build';

      case 4:
        return 'Inspection';

      case 5:
        return 'Powering Up';

        default:
        return 'Powered Up';
    }
  }

  render() {
    const { dashboardData } = this.props
    let { params } = this.props.router;
    return (
      <div className='utility-tab-main-pnl white-box box-Shadow margb0 height-delayand-track'>
        <div className='heading d-flex'>
          <h3 className='w-100 margin-bottom-33'>Delayed projects</h3>
          <div className='justify-content-end' />
        </div>
        <div className='table-box'>
          <table className='table metrics-table'>
            <thead>
              <tr>
                <th scope='col'>Phase</th>
                <th className="pl-4" scope='col'>Homeowner</th>
                <th scope='col'>Days</th>
              </tr>
            </thead>
            <tbody>
              {
                dashboardData
                && dashboardData.delayedProjectReducer
                && dashboardData.delayedProjectReducer.data
                && dashboardData.delayedProjectReducer.data.length
                && dashboardData.delayedProjectReducer.data.length !== 0 ?
                  dashboardData.delayedProjectReducer.data.sort((a, b) => parseFloat(b.day) - parseFloat(a.day)).map((delayedProject, index) => (
                    <tr key={index} className="metrics-row">
                      <td scope='row' className="text-capitalize">{this.renderShowPhaseName(delayedProject.phase)}</td>
                      <td className="metrics-data">
                      <a href={window.location.origin+'/'+params.slug+ '/'+ params.companyId + '/dashboard/'+delayedProject.homeOwnerId+'/homeowner'}>
                      {delayedProject.homeOwner}
                      </a>
                      </td>
                      <td>{delayedProject.day}</td>
                    </tr>
                  ))
                :
                  <tr>
                    <td scope='row' colSpan="3" >No projects are delayed</td>
                  </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}
