// import npm packages
import React, { Component }       from "react";
import { Select, Input, Tooltip } from "antd";
import { LoadingOutlined }      from '@ant-design/icons';
import { connect }              from "react-redux";
import {bindActionCreators}     from "redux";
import { setLoader }    from '../../../Common/Loader/action';
import withRouter       from '../../../../shared/withRouter'
import { getToken }     from "../../../../shared/util";
import {requestCompanyIdData}    from '../../../Installer/actions/addHomeOwner';
import {getCampaignRecipents}    from "../../api/campaign";
import {campaignFilterOption,
  createCampaignFilterQuery} from "./campaignFilter"
import addIcon from "../../../../Assets/Images/addIcon.svg"
import {ReactComponent as CaretIcon} from "../../../../Assets/Images/path-2.svg";

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option }   = Select;

class RecipentsCampaignForm extends Component {
    constructor(props) {
      super(props);
      this.state = {
        campaignFilter  : this.props.campaignFilter || [],
        totalRecipients : this.props.totalRecipients || 0
      };
    }
  
    componentDidMount() {
      this.slug = localStorage.getItem("slug");
      this.companyId = this.props.router.params.companyId;
      if (!this.props.companyData){
        this.props.requestCompanyIdData(this.companyId);
        this.props.setLoader({loader:true});
      }
    }
  
    componentWillReceiveProps(nextProps) {
      if (nextProps.companyData){
        this.props.setLoader({loader:false});
      }
    }  

    fetchRecipents(){
      var filterData = this.state.campaignFilter;
      let companyId  = this.companyId;
      let obj = {
        filterData : createCampaignFilterQuery(filterData,companyId),
        parameter  : '?count=true',
        companyId
      };
      getCampaignRecipents(obj).then(r=>{
        this.setState({totalRecipients:r && r.count || 0});
        this.props.updateCampaignDataState('totalRecipients',r && r.count || 0);
        this.props.updateCampaignDataState('campaignFilter',this.cleanFilterData(filterData));
        this.setState({spinClass:''});
        return;
      });
    }

    cleanFilterData(filter){
      for( var i = 0; i < filter.length; i++){ 
        let element = filter[i];
        if(!element.parameter || !element.value || element.value.length===0){
          filter.splice(i,1);
          i--;
        }
      }
      return filter;
    }

    campaignRecipents(){
      return(
          <>
          <h3>Campaign Name</h3>
          <p className="off-heading">{this.props.campaignHeadline}</p>
          <div className="fillter-wrapper">
            <h3>Filters: </h3>
            
              {this.state.campaignFilter.map((ele, index) => {
                return (
                <React.Fragment key={index}>
                  <div className="row pl-1 close-btn-wrapp">
                    <div className="unstyled-buffer-node">
                      <div className="form-group">
                        {
                          this.renderOptionSelect('parameter', ele, index, campaignFilterOption, 'Parameter')
                        }
                      </div>
                      <div className="form-group">
                        {
                          this.renderOptionSelect('condition', ele, index, (campaignFilterOption[ele.parameter] && campaignFilterOption[ele.parameter]['operators'])||['equals'], 'Condition')
                        }
                      </div>
                      <div className="form-group">
                        <div className=" value-plus">
                          {
                            this.props.companyData && this.renderFilterValue(ele,index,'value')
                          }
                          <a className="plus-icon" onClick={()=>{
                            ele.value.push([''])
                            this.setFilter(ele.value, index, 'value');
                          }}>+</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={
                    `${index} and-row ${this.state.campaignFilter
                    && this.state.campaignFilter.length-1===index && 'd-none'} `
                  }>And</div>
                </React.Fragment>
                )
              })}
            <div className="add-new-campaign" onClick={()=>{
              let filterArray = this.state.campaignFilter;
              filterArray.push({value:['']});
              this.setState({campaignFilter:filterArray})
            }}>
              <img src={addIcon} className="plus"/><span className="link-Color">Add Filters</span>
            </div>
            <div className="recipients-box">
              <h3>Recipients</h3>

              <Tooltip overlayClassName="black-tooltip" placement="right"
                visible={this.props.clickNext}
                title={'Add filters and click here to check count'}>
                <span>
                  <i className={`fa fa-refresh ${this.state.spinClass}`} style={{
                    "color": "rgb(106, 106, 106)",
                    "cursor": "pointer"
                  }} onClick={() => {
                    this.setState({ spinClass: 'fa-spin' });
                    this.fetchRecipents()
                  }}>

                  </i>
                </span>
              </Tooltip>
              <p>{this.state.totalRecipients} {this.state.totalRecipients>1 ? 'customers' : 'customer'} will receive this campaign</p>
            </div>
          </div>

          </>
      )
    }


    renderFilterValue(ele,index,filterKey){
      let filter = campaignFilterOption[ele.parameter];
      let companyMasterData = this.props.companyData;

      if(ele.value && ele.value.length==0){
        if (filter && filter.filterType == 'List') {
          let data = companyMasterData[filter.masterData] || filter['filterList'];
          return this.renderOptionSelect('value', ele, index, data, 'Value', filter.masterId || 'id', 'name',0);
        }
        else {
          return (<div className="value-block">
          <Input className="form-control input"
          disabled = {ele.parameter ? false : true}
            value={ele.value[0]}
            onChange={(e) => {
              let v = [e.target.value];
              this.setFilter(v, index, filterKey);
            }} />
            <span className="close-btn"
              onClick={() => {
                let filter = this.state.campaignFilter;
                filter.splice(index,1);
                this.setState({campaignFilter:filter});
              }}
            >X</span></div>)
        }
      }
      else if(ele.value){
        return ele.value.map((valueElement, valueIndex) => {
          if (filter && filter.filterType == 'List') {
            let data = companyMasterData[filter.masterData] || filter['filterList'];
            return(<div className="value-block" key={valueIndex}>
              {this.renderOptionSelect('value', ele, index, data, 'Value', filter.masterId || 'id', 'name',valueIndex)}
              {this.renderOrText(ele.value.length,valueIndex,index,ele.value,filterKey)}
            </div>)

            ;
          }
          else {
            return (<div className="value-block"><Input className="form-control input"
              value={valueElement}
              disabled = {ele.parameter ? false : true}
              onChange={(e) => {
                ele.value[valueIndex] = e.target.value;
                this.setFilter(ele.value, index, filterKey);
              }}/>
              {this.renderOrText(ele.value.length,valueIndex,index,ele.value,filterKey)}
              </div>
              )
          }
        });
      }
    }

    renderOrText(arraySize,valueIndex,index,elementValue,filterKey){
      return (
        <>
              <span className="close-btn"
                onClick={() => {
                  if(arraySize==1){
                    let filter = this.state.campaignFilter;
                    filter.splice(index,1);
                    this.setState({campaignFilter:filter});
                  }
                  else{
                    elementValue.splice(valueIndex, 1);
                    this.setFilter(elementValue, index, filterKey);
                  }
                }}
              >X</span>
        <div className={
          ` and-row ${this.state.campaignFilter
          && arraySize - 1 === valueIndex && 'd-none'} `
        }>or</div>
        </>
      )
    }

    renderOptionSelect(filterKey,ele,index,data,text,key,label,valueIndex){
      let {companyData} = this.props;      
      return(
        <Select  suffixIcon = {<CaretIcon/>} 
        className="form-control filter-select personlize" 
        id="exampleFormControlSelect1"
        value = {ele[filterKey] && (Array.isArray(ele[filterKey]) ? ele[filterKey][valueIndex] : ele[filterKey]) || (filterKey==='condition'? 'equals':'')}
        onChange = {(e)=>{
          if(ele.value && filterKey==='parameter'){
            this.setFilter('',index,'condition');
            this.setFilter([''], index, 'value');
            this.setFilter([''], index, 'phantomValue');
          }         

          if(filterKey==='value'){
            ele.value[valueIndex] = e;
            this.setFilter(ele.value, index, filterKey);

            if(companyData && ele.parameter && companyData[campaignFilterOption[ele.parameter]['masterData']]){
              let parameterDataOption = companyData[campaignFilterOption[ele.parameter]['masterData']];
              let phantomData = parameterDataOption.filter(mastervalue=>{
                return mastervalue[key] == e
              })[0];
              
              if(phantomData){
                ele.phantomValue[valueIndex] = phantomData.name;
                this.setFilter(ele.phantomValue,index,'phantomValue')
              }
            }
            
          } else
            this.setFilter(e,index,filterKey);
        }}>
            <Option value = {''}>{text}</Option>
           {this.renderDropDown(data,key,label)}
        </Select>
      )
    }

    setFilter(e,index,filterKey){
      let x ={};
      let filterArray = this.state.campaignFilter;
      let campaignFilter = filterArray[index];
      x[filterKey] = e;

      Object.assign(campaignFilter,x);
      filterArray[index] = campaignFilter;
      this.setState({campaignFilter:filterArray})
    }

    renderDropDown(data,key,label){
      let arr = (data && (Array.isArray(data) ? data : Object.keys(data))) || [];
      return(
         arr.sort().map((ele, i) => {
        return <Option key={i} value={key? ele[key]:ele}>{label? ele[label]: ele}</Option>
        })
      )
    }
  
    render() {
      if (getToken()) {
        return this.campaignRecipents();
      } else {
        this.props.router.navigate("/");
        window.location.reload();
        return null;
      }
    }
  }

const mapStatetoProps = state => {
    return {
      loader: state.loader.loader.loader,
      companyData: state.addhomeOwner.companyId.data,
     };
  };
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        setLoader,
        requestCompanyIdData
      },
      dispatch
    );
  
  export default connect(
    mapStatetoProps,
    mapDispatchToProps
  )(withRouter(RecipentsCampaignForm));
  