import React, { Component }   from 'react';
import { Form }               from 'antd';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { AddHardware }    from "./AddCompanyHardware";
import { setLoader }          from '../../../Common/Loader/action';
import {DeleteModal}          from "../../../Common/DeleteModal";
import {
   requestGetUtilityRatePlanByIdData,
   requestGetUtilityRatePlanData,
   requestSetUtilityByIdData,
   receiveSetUtilityByIdData,
} from "../../../Admin/Actions/setting";
import deleteIcon from "../../../../Assets/Images/delete-icon.png"

class companyUtilityForm extends Component {
   constructor(props) {
      super(props);
      this.state = {
         addPopUp: false,
         viewName: 'utility'
      };
   }

   componentDidMount() {
      let { companyId } = this.props;
      this.props.requestGetUtilityRatePlanByIdData(companyId);
      this.props.setLoader({ loader: true });
   }

   componentWillReceiveProps(nextProps) {
      if (nextProps.companyUtility && this.props.loader) {
         this.props.setLoader({ loader: false })
      }

      if(nextProps.setUtilityById && nextProps.setUtilityById.status==200){
         nextProps.receiveSetUtilityByIdData({});
         nextProps.requestGetUtilityRatePlanByIdData(nextProps.companyId);
      }
   }

   updateState = (key, values) => {
      if (key === "formData") {
         this.props.setLoader({ loader: true });
         let data = [];
         let keyName = `${this.state.viewName==='utility' ? 'electricUtilityId' : 'name'}`;
         values.labels.forEach((ele, index) => {
            let temp = {
               label: ele,
               [keyName] : values.names[index]
            };
            data.push(temp);
         });
         if(this.state.viewName === 'utility'){
            this.props.requestSetUtilityByIdData({
               companyId: this.props.companyId,
               data
           })
         }

      }
      else {
         this.setState({
            [key]: values
         })
      }
   }

   deletePopUpProp = (value) =>{
      this.setState({deleteTrigger: false});
      let { actionId } = this.state;
      if(value=='cancel'){
        return;
      }
      else{
            this.props.setLoader({loader:true});
            this.props.requestSetUtilityByIdData({
               companyId: this.props.companyId,
               id: actionId,
               isDelete: true
           })
      }
  }

   renderDeletePopUp = () =>{
      let headerText = 'Do you really want to delete this item?';
      let subText = "Deleting this item cannot be undone";
      return(
           <DeleteModal deletePopUpProp={this.deletePopUpProp} headerText={headerText} subText={subText}/>
      )
  }

   render() {
      let { companyUtility } = this.props;

      let renderData = companyUtility;


      return (
         <>
         {this.state.deleteTrigger && this.renderDeletePopUp()}
            {this.state.addPopUp && <AddHardware updateState={this.updateState} {...this.state} {...this.props} />}
            <div className="internal-pnl-cmn box-Shadow content-box">
               <div className="utility-tab-main-pnl">
                  <div className="utility-inner-pnl">
                     <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item" style={{width:"100%"}} onClick={() => this.setState({ viewName: 'utility' })}>
                           <a className={`nav-link clearfix ${this.state.viewName == 'utility' && 'active'}`}>
                              Utilities
                              <span className="addutility-btn" onClick={() => {
                                 this.setState({ addPopUp: true, viewName: 'utility' })
                              }}> + </span>
                           </a>
                        </li>
                     </ul>

                     <div className="tab-content tab-child-secction">
                        <div id="home" className="tab-pane active p-0">
                           <div className="inner-tab-pannel">
                              <div className="filter-div-pnl seprator-right">
                                 <span>Utility name</span>
                              </div>

                              <div className="filter-div-pnl">
                                 <span>Customer facing name</span>
                              </div>
                              
                              <div className="cmn-table-format">
                                 <table className="table table-bordered m-0 metrics-table">
                                    <tbody>
                                       {
                                          renderData && renderData.map((ele, i) => {
                                             return (
                                                <tr className="metrics-row" key={i}>
                                                   <td className="metrics-data seprator-right">{ele.name}</td>
                                                   <td className="metrics-data border-none">{ele.label}
                                                      <img className="icon"
                                                         onClick={() => {
                                                            this.setState({
                                                               actionId: ele.id,
                                                               deleteTrigger: true
                                                            })
                                                         }}
                                                         src={deleteIcon}
                                                      />
                                                   </td>
                                                </tr>
                                             )
                                          })
                                       }
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </>
      )
   }
}

const mapStatetoProps = state => {
   return {
      utilityAndRatePlan: state.adminSetting.getUtilityAndRatePlan,
      companyUtility: (state.adminSetting.getUtilityAndRatePlanById.data && state.adminSetting.getUtilityAndRatePlanById.data.companyElecricUtilityList),
      setUtilityById : state.adminSetting.setUtilityById[state.adminSetting.setUtilityById.length-1],
   };
};

const mapDispatchToProps = dispatch =>
   bindActionCreators({
      requestGetUtilityRatePlanByIdData,
      receiveSetUtilityByIdData,
      requestGetUtilityRatePlanData,
      requestSetUtilityByIdData,
      setLoader
   },
      dispatch);

export default connect(
   mapStatetoProps,
   mapDispatchToProps
)(companyUtilityForm);
