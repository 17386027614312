
import { RECEIVE_SLUG_DATA, RECEIVE_SLUG_VERIFICATION } from "./action";

export  const slugReducer =  (state = {}, { type, data }) => {
  switch (type) {
    case RECEIVE_SLUG_DATA:
      return data;
    default:
      return state;
  }
};

export  const slugVerificationReducer =  (state = [], { type, data }) => {
  switch (type) {
    case RECEIVE_SLUG_VERIFICATION:
      return [...state, data];
    default:
      return state;
  }
};


// return Object.assign({}, data, {error:data.error})
