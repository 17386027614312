
export const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
    let arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    let CSV = '';
    //This condition will generate the Label/Header
    if (ShowLabel) {
        let row = "";
        for (let index in arrData[0]) {
            row += index + ',';
        }
        row = row.slice(0, -1);
        CSV += row + '\r\n\n';
    }
    for (let i = 0; i < arrData.length; i++) {
        let row = "";
        for (let index in arrData[i]) {
            if (index === "created" || index === "updated" || index.includes('Date')) {
                let val = arrData[i][index]
                let formattedDate = formatDate(val)
                row += '"' + formattedDate + '",';
            } else {
                row += '"' + arrData[i][index] + '",';
            }
        }
        row = row.slice(0, row.length - 1);
        CSV += row + '\r\n';
    }

    if (CSV === '') {
        alert("Invalid data");
        return;
    }

    let fileName = "";
    //this will remove the blank-spaces from the title and replace it with an underscore
    fileName += ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    let uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension    

    //this trick will generate a temp <a /> tag
    let link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

const formatDate = date => `${new Date(+date).getMonth() + 1}/${new Date(+date).getDate()}/${new Date(+date).getFullYear()}`      