import dev from './env.development'
import stage from './env.stage'
import prod from './env.production'
var envConfig
const customEnv = process.env.CUSTOM_ENV || 'development'

if (customEnv === 'development') {
    envConfig = dev
}
if (customEnv === 'staging') {
    envConfig = stage
}
if (customEnv === 'production') {
    envConfig = prod
}
export default {
    envConfig
}
