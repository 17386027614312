import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { reducer } from 'aws-cognito-redux-saga'

import HeaderComponent from './Header'
import { requestSearchData, requestInstallerDataById, receiveSearchData } from "../../actions/header";
import { requestSlugData } from '../../../User/data/action'
import {setMetricsType} from '../../actions/dashboard';

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    searchData: state.header.searchData,
    installerData: state.header.getInstaller,
    companyName: state.user.slugImage
  }
}
const mapDispatchToProps = dispatch => (
  bindActionCreators({ requestSearchData, receiveSearchData, signOut: () => dispatch(reducer.signOut()), requestInstallerDataById, requestSlugData, setMetricsType}, dispatch)

)
export default connect(mapStatetoProps, mapDispatchToProps)(HeaderComponent)
