// import npm packages
import { call, put } from 'redux-saga/effects';

// import local files
import {
  // referral
  getReferralDataForChartApi,
  getReferralCountApi,
  downloadReferralByMonthApi,

  // survey
  getSurveyDataApi,
  getSurveyCountApi,
  downloadSurveyDataApi,

  // social share
  getSocialShareCountApi,
  getSocialShareDataApi,
  downloadSocialSharDataApi,

  // active account
  getActiveAccountsDataApi,

  // delayed proeject
  getDelayedProjectApi,
} from '../../screens/Installer/api/dashboard';
import {
  // survey
  receiveSurveyData,
  receiveSurveyCount,
  receiveDownloadSurveyData,

  // referral
  receiveReferralDataForChart,
  receiveDownloadReferralData,
  receiveReferralCount,

  // social share
  receiveDownloadSocialShareData,
  receiveSocialShareCount,
  receiveSocialShareData,

  // active account
  receiveActiveAccountCount,

  // delayed project
  receiveDelayedProjectData,
} from '../../screens/Installer/actions/dashboard';
import handleError from '../../shared/errorHandler';
import { setLoader } from '../../screens/Common/Loader/action';


// ***************** Start Referral sagas *****************
  // get referral count
  function* getReferralCount(action) {
    try {
      const data = yield call(getReferralCountApi, action.data)

      // yield put(setLoader({loader: false}));
      yield put(receiveReferralCount(data));
    } catch (e) {
      yield put(setLoader({loader: false}));
      handleError(e);
    }
  }

  // get referral data
  function* getReferralChartData(action) {
    try {
      let data = {};
      if (JSON.stringify(action.data) !== '{}') {
        data = yield call(getReferralDataForChartApi, action.data);
      }

      // yield put(setLoader({loader: false}));
      yield put(receiveReferralDataForChart(data));
    } catch (e) {
      yield put(setLoader({loader: false}));
      handleError(e);
    }
  }

  // get referral data to download
  function* getDownloadReferral(action) {
    try {
      let data = {};
      if (JSON.stringify(action.data) !== '{}') {
        data = yield call(downloadReferralByMonthApi, action.data);
      }

      // yield put(setLoader({loader: false}));
      yield put(receiveDownloadReferralData(data));
    } catch (e) {
      yield put(setLoader({loader: false}));
      handleError(e);
    }
  }

// ***************** End Referral sagas *****************


// ***************** Start Social Share sagas *****************
  // get social share count
  function* getSocialShareCount(action) {
    try {
      const data = yield call(getSocialShareCountApi, action.data);

      // yield put(setLoader({loader: false}));
      yield put(receiveSocialShareCount(data));
    } catch (e) {
      yield put(setLoader({loader: false}));
      handleError(e);
    }
  }

  // get social share data
  function* getSocialShareData(action) {
    try {
      const data = yield call(getSocialShareDataApi, action.data);

      // yield put(setLoader({loader: false}));
      yield put(receiveSocialShareData(data));
    } catch (e) {
      yield put(setLoader({loader: false}));
      handleError(e);
    }
  }

  // get social share data to download
  function* getDownloadSocialShare(action) {
    try {
      let data = {};
      if (JSON.stringify(action.data) !== '{}') {
        data = yield call(downloadSocialSharDataApi, action.data);
      }

      // yield put(setLoader({loader: false}));
      yield put(receiveDownloadSocialShareData(data));
    } catch (e) {
      yield put(setLoader({loader: false}));
      handleError(e);
    }
  }
// ***************** End Socail Share sagas *****************


// ***************** Start Survey sagas *****************
  // get survey count
  function* getSurveyCount(action) {
    try {
      const data = yield call(getSurveyCountApi, action.data);

      // yield put(setLoader({loader: false}));
      yield put(receiveSurveyCount(data));
    } catch (e) {
      yield put(setLoader({loader: false}));
      handleError(e);
    }
  }

  // get survey data
  function* getSurveyDataResult(action) {
    try {
      let data = {};
      if (JSON.stringify(action.data) !== '{}') {
        data = yield call(getSurveyDataApi, action.data);
      }

      // yield put(setLoader({loader: false}));
      yield put(receiveSurveyData(data));
    } catch (e) {
      yield put(setLoader({loader: false}));
      handleError(e);
    }
  }

  // get survey data to download
  function* getDownloadSurvey(action) {
    try {
      let data = {};
      if (JSON.stringify(action.data) !== '{}') {
        data = yield call(downloadSurveyDataApi, action.data);
      }

      // yield put(setLoader({loader: false}));
      yield put(receiveDownloadSurveyData(data));
    } catch (e) {
      yield put(setLoader({loader: false}));
      handleError(e)
    };
  }
// ***************** End Survey sagas *****************


// ***************** Start Active Account sagas *****************
  // get active account count
  function* getActiveAccountCount(action) {
    try {
      const data = yield call(getActiveAccountsDataApi, action.data);

      // yield put(setLoader({loader: false}));
      yield put(receiveActiveAccountCount(data));
    } catch (e) {
      yield put(setLoader({loader: false}));
      handleError(e);
    }
  }
// ***************** End Active Account sagas *****************


// ***************** Start Delaye Project sagas *****************
  // get delayed project data
  function* getDelayedProjectResult(action) {
    try {
      let data = {};
      if (JSON.stringify(action.data) !== '{}') {
        data = yield call(getDelayedProjectApi, action.data);
      }

      // yield put(setLoader({loader: false}));
      yield put(receiveDelayedProjectData(data));
    } catch (e) {
      yield put(setLoader({loader: false}));
      handleError(e);
    }
  }
// ***************** End Delaye Project sagas *****************


export const dashboard = {
  // acitve account
  getActiveAccountCount,

  // referral
  getReferralChartData,
  getReferralCount,
  getDownloadReferral,

  // delayed project
  getDelayedProjectResult,

  // survey
  getSurveyCount,
  getSurveyDataResult,
  getDownloadSurvey,

  // socail share
  getSocialShareCount,
  getSocialShareData,
  getDownloadSocialShare
}
