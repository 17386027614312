// import npm packages
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// import local files
import { MONTH_ARRAY } from './../../../../shared/config'
import { requestDownloadReferralData, requestReferralDataForChart } from './../../actions/dashboard'
import { jwtDecode } from "../../../../shared/jwt";
import { getToken } from "../../../../shared/util";
import { JSONToCSVConvertor } from './../../../../shared/downloadCSV'
import { openNotificationWithIcon } from '../../../../shared/notification';
import { RenderTrackChart } from "./chart"
let data = [], keys = [];

class TrackReferrals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderChart: false
    }
    this.renderTrackChart = new RenderTrackChart();
    let token = getToken();
    this.installerData = jwtDecode(token);
    this.companyId = this.props.router.params.companyId;
    if(this.companyId){
      this.props.requestReferralDataForChart({ companyId: this.companyId, installerId: this.installerData.installerId })
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { csvData, chartData } = props;
    if (csvData && csvData.status === 200) {
      props.requestDownloadReferralData({})
      if (csvData.data.length > 0) {
        JSONToCSVConvertor(csvData.data, `${props.router.params.slug}_referral`, true)
      }
      else
        openNotificationWithIcon('error', 'No data found', 'Error')
    }
    if (chartData && chartData.status === 200) {
      data = []
      keys = Object.keys(chartData.data[0])
      keys.forEach(item => {
        data.push(chartData.data[0][item])
      })
    }
  }

  handleChangeMonth = (e) => {
    let monthId = e.target.value;
    const data = {
      companyId: this.companyId,
      // installerId: this.installerData.installerId,
      month: monthId
    };
    if(monthId)
      this.props.requestDownloadReferralData(data)
  }
  
  renderMultiChart(data) {
    this.setState({renderChart:true},()=>this.renderTrackChart.renderMultiChart(data))
  }

  render() {
    let elem = document.getElementById("chartWrapper"), width = 0
    if (elem) {
      width = elem.offsetWidth
    }
    return (
      <div className='utility-tab-main-pnl white-box box-Shadow installer-block-top-marg-mobile height-delayand-track'>
        <div className='heading d-flex trakyourrefferals-outer'>
          <h3 className='w-100 margin-bottom-33'>Track your referrals</h3>
          <div className='justify-content-end'>
            <section className='white-box-select'>
              <select onChange={(e) => this.handleChangeMonth(e)}>
                <option value=''>
                Download Month
                </option>
                {
                  MONTH_ARRAY && MONTH_ARRAY.length && MONTH_ARRAY.map((item, index) => {
                    return (
                      <option key={index} value={index}>
                        {item}
                      </option>
                    )
                  })
                }
              </select>
            </section>
          </div>
        </div>

        <div className='pddl30 pddr30 pddb30' >
          <div id="chartWrapper">
            {data.length !== 0 && !this.state.renderChart && this.renderMultiChart(data)}
          </div>

          <div className='row chart-label'>
            <div className='justify-content-start col referral'>
              <span>Referrals</span>
            </div>
            <div className='justify-content-end col  active-account text-right'>
              <span className='text-right desktop-view-only'>{this.props.chartData && this.props.chartData.data && this.props.chartData.data[0] ? 'New accounts' : "" }</span> 
              <span className ='mobile-view-only text-right'>{this.props.chartData && this.props.chartData.data && this.props.chartData.data[0] ? 'Active account' : "" }</span>  
            </div>
          </div>
        </div>
      </div>
    )
  }
}


const mapStatetoProps = state => {
  return {
    csvData: state.dashboardStats.downloadReferralDataReducer,
    chartData: state.dashboardStats.referralsDataForChartReducer,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestDownloadReferralData, requestReferralDataForChart
    },
    dispatch
  );

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(TrackReferrals);
