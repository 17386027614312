// impor npm packages
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { Input } from 'antd';

// import local files
import withRouter from '../../../../../shared/withRouter';
import { getToken, ROLE } from '../../../../../shared/util';
import * as validate from '../../../../../shared/validators';
import config from '../../../../../environments/index';
import { handleCognitoError } from '../../../../../shared/handleCognitoError';
import { openNotificationWithIcon } from '../../../../../shared/notification';

const style = {
  error: {
    width: '80%',
    color: 'rgb(200,0,0)',
    margin: '8px'
  }
};

class ForceChangePassword extends Component {
  static propTypes = {
    history: PropTypes.object,
    handleSubmit: PropTypes.func.isRequired,
    forgotPassword: PropTypes.func.isRequired,
    completeNewPassword: PropTypes.func,
    auth: PropTypes.object,
    init: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.newPassword = '';
    this.confirmPassword = '';
    this.existingPassword = '';
    this.oldPasswordError = '';
    this.slug = '';
    this.image = '';
    this.showError = false;
    this.state = {
      email: '',
      code: '',
      password: '',
      newPassword: '',
      authError: undefined,
      loader: false,
      hasSentCode: '',
      hasChangedPassword: ''
    };
  }

  componentWillUnmount() {
    this.props.init();
  }

  componentWillMount() {
    if (this.state.loader) {
      // this.setState({
      //   loader: false
      // });
    }
    this.slug = localStorage.getItem('slug');
    // this.props.init();
  }

  componentWillReceiveProps(nextProps) {
    // for auth error
    if (
      nextProps &&
      nextProps.auth &&
      nextProps.auth.error &&
      JSON.stringify(nextProps.auth.error) !== '{}' &&
      !this.state.authError
    ) {
      nextProps.setLoader({ loader: false });
      this.setState({
        authError: nextProps.auth.error
      });
      openNotificationWithIcon('error', nextProps.auth.error.message, 'Error');
      nextProps.init();
    }

    // for success of code sent
    if (
      nextProps &&
      nextProps.auth &&
      nextProps.auth.hasSentCode === 'AUTH_SUCCESS' &&
      this.state.hasSentCode !== 'AUTH_SUCCESS'
    ) {
      nextProps.setLoader({ loader: false });
      this.setState({
        hasChangedPassword: '',
        hasSentCode: 'AUTH_SUCCESS',
        authError: null
      });
      nextProps.init();
    }

    // for success of change password
    if (
      nextProps &&
      nextProps.auth &&
      nextProps.auth.hasChangedPassword === 'AUTH_SUCCESS' &&
      this.state.hasChangedPassword === ''
    ) {
      nextProps.setLoader({ loader: false });
      this.setState({
        hasChangedPassword: 'AUTH_SUCCESS',
        hasSentCode: '',
        authError: null
      });
      openNotificationWithIcon('success', 'Password change successful', 'Success');
      nextProps.init();
    }
  }

  changePassword = (values) => {
    const { email } = this.props;
    // this.oldPasswordError = validate.passwordValidation( this.existingPassword );
    this.newPasswordError = validate.passwordValidation(this.newPassword);
    this.showError = true;
    this.confirmPasswordError = validate.passwordValidation(this.confirmPassword);
    const codeError = this.state.code === '' ? config.CODE_REQUIRED : undefined;
    if (this.newPassword !== '' && this.confirmPassword !== '') {
      if (this.newPassword === this.confirmPassword) {
        if (!(this.newPasswordError || this.confirmPasswordError || this.error || codeError)) {
          this.props.setLoader({ loader: true });
          this.props.changePassword(email, this.state.code, this.state.newPassword);
        } else {
          this.newPasswordError = undefined;
          this.confirmPasswordError = undefined;
          this.showError = false;
          this.error = this.error ? this.error : 'Something went wrong';
        }
      } else {
        this.showError = false;
        this.error = 'Password do not match';
      }
    } else {
      this.newPasswordError = undefined;
      this.confirmPasswordError = undefined;
      this.showError = false;

      this.confirmPasswordError = 'Please enter your password.';
    }
    if (this.newPassword === '' || this.confirmPassword === '') {
      this.showError = false;
      this.error =
        this.newPassword === '' ? 'New Password field empty' : 'Confirm Password field empty';
    } else {
    }
    this.forceUpdate();
  };

  forgotPassword = (values) => {
    const { email } = this.props;
    if (email) {
      this.props.setLoader({ loader: true });
      this.props.forgotPassword(email);
    } else this.emailError = config.EMPTY_EMAIL;
  };

  // componentWillUpdate() {
  //   this.error = validate.passwordMatch(this.newPassword, this.confirmPassword)
  // }

  onChangeCode = (e) => {
    this.codeError = undefined;
    this.showError = false;
    this.setState({ code: e.target.value });
  };

  onChangePassword = (e) => {
    this.newPasswordError = undefined;
    this.showError = false;
    this.newPassword = e.target.value;
    this.setState({ password: e.target.value });
  };

  onChangeNewPassword = (e) => {
    this.confirmPassword = e.target.value;
    this.showError = false;
    this.confirmPasswordError = undefined;
    this.setState({ newPassword: e.target.value });
  };

  renderChangePassword() {
    const { handleSubmit } = this.props;
    return (
      <>
        {this.state && this.state.hasSentCode && this.state.hasSentCode === 'AUTH_SUCCESS' ? (
          <div>
            <hr className="update-acc-divider" />
            <div>
              <label className="notification-lable">RESET PASSWORD</label>
              <p> Please enter to reset code found in your email. </p>
            </div>
            <div className="password-reset-form-wrap">
              <div className="form-group codeheading">
                <label htmlFor="exampleInputEmail1 notification-lable">RESET CODE</label>
                <Input
                  className="form-control"
                  id="exampleInputPassword1"
                  onChange={this.onChangeCode}
                  disabled={this.props.isInstaller ? 'disabled' : ''}
                />
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword2" style={{ textTransform: 'none' }}>
                  NEW PASSWORD
                </label>

                <Input
                  className="form-control"
                  id="exampleInputPassword1"
                  type="password"
                  onChange={this.onChangePassword}
                  disabled={this.props.isInstaller}
                />
              </div>

              <div className="form-group">
                <label htmlFor="exampleInputPassword3" style={{ textTransform: 'none' }}>
                  CONFIRM PASSWORD
                </label>
                <Input
                  disabled={this.props.isInstaller}
                  className="form-control"
                  id="exampleInputPassword3"
                  type="password"
                  onChange={this.onChangeNewPassword}
                />
              </div>
              {this.error ? <div style={style.error}>{this.error}</div> : ''}
              {this.state.authError && this.showError ? (
                <div style={style.error}>
                  {JSON.stringify(this.state.authError) !== '{}'
                    ? handleCognitoError(this.state.authError.code)
                    : null}
                </div>
              ) : (
                ''
              )}
              <div className="text-center margt40 margb90">
                <button
                  onClick={handleSubmit(this.changePassword)}
                  className="btn btn-primary updatePassword"
                  disabled={this.props.isInstaller ? 'disabled' : ''}>
                  UPDATE PASSWORD
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="form-group">
            <hr className="update-acc-divider" />
            <div>
              <label className="notification-lable">RESET PASSWORD</label>
              <p> We will send a code to your email address to reset your password </p>
            </div>
            <div className="text-center">
              <button
                className={
                  this.props.role === ROLE['INSTALLER']
                    ? 'btn btn-primary'
                    : 'btn yellow-btn premiumButtonFill premiumButtonText getCodeStyle premiumButtonFill premiumButtonText'
                }
                onClick={this.forgotPassword}>
                Send reset code
              </button>
            </div>
          </div>
        )}
        {this.codeError ? <div style={style.error}>{this.codeError}</div> : ''}
      </>
    );
  }

  render() {
    if (getToken()) {
      return this.renderChangePassword();
    }
    this.props.router.navigate('/');
    return null;
  }
}

export default reduxForm({
  form: 'forceChangePassword'
})(withRouter(ForceChangePassword));
