//import local files
import envConfig from '../environments/index';

const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
const SCOPES = 'https://www.googleapis.com/auth/drive';
const gapi = window.gapi;

class GoogleDriveSync {
  constructor() {
    this.result = {};
    this.syncFolder= {};
    this.parentFolders = {}
  }

  handleClientLoad(folders,cb) {

    return new Promise((resolve, reject) => {
      this.result = {};
      this.syncFolder= {};
      this.parentFolders = {}
      gapi.load('client:auth2', () => {
        gapi.client.init({
          apiKey: envConfig.envConfig.googleDrive.API_KEY,
          clientId: envConfig.envConfig.googleDrive.CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES
        }).then((p) => {
          resolve(p)
        })
          .catch(err => {
            reject(err)
          })
      });
    }).then(() => {
      return this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get(),folders,cb)
    })
  }

  async updateSigninStatus(isSignedIn,folders,cb) {
    if (isSignedIn) {
      this.folderList(folders,(x)=>{
        cb(x)
      });
      
    } else {
      return this.handleAuthClick().then((r) => {
        if (r){
          this.folderList(folders,(x)=>{
            cb(x)
          });
        }
        else {
          return false
        }
      })
    }
  }

  handleAuthClick(event) {
    return new Promise((res, rej) => {
      res(gapi.auth2.getAuthInstance().signIn());
    }).catch(error => {
      return false
    })

  }

  handleSignoutClick() {
    gapi.auth2 && gapi.auth2.getAuthInstance().signOut();
  }

  
  folderList = (folders,callback) => {
    this.syncFolder = folders;
    let tempFolder = JSON.parse(JSON.stringify(this.syncFolder));
    let filter = 'bodhi';
      folders.forEach(ele=>{
       this.listFiles(ele.key,filter,null,ele.key,tempFolder,(r)=>{
          callback(this.responseFile(JSON.parse(JSON.stringify(r)),folders));
        })
      })
  }

 

  listFiles = (folderId, filter, parentFolder, parentId,tempFolder,cb) => {
    
    if(!folderId && (parentFolder && parentFolder.length == 0)){
      tempFolder.forEach((ele,index)=>{
        if(ele.key==parentId){
          tempFolder.splice(index,1)
        }
         
      })
    }
   

    if (folderId || (parentFolder && parentFolder.length > 0)) {
    
      if (!parentFolder) {
        parentFolder = [];
      } else {
        folderId = parentFolder[0]['id'];
        parentFolder.splice(0, 1);
      }
      return gapi.client.drive.files.list({
        'corpora':'user',
        'supportsAllDrives': true,
        'includeItemsFromAllDrives':true,
        'pageSize': 100,
        'fields': "nextPageToken, files(id, webContentLink,webViewLink,name,mimeType,parents,trashed)",
        'q': `("${folderId}" in parents)`
      }).then((response) => {
        let folder = response.result.files.filter(x => !x.trashed);
        if(!this.result[parentId]){
          this.result[parentId] = {};
        }

        if (folder && folder.length) {
          folder.forEach((file, index) => {
            if (file.webContentLink) {
              if (!this.result[parentId][file.parents[0]]) {
                this.result[parentId][file.parents[0]] = []
              }
              if(filter){
                if (file.name.toLowerCase().includes(filter.toLowerCase())) {
                  this.makeFolderPublic(file.id);
                  const index = this.result[parentId][file.parents[0]].findIndex(val => val.id === file.id);
                  if (index === -1 || this.resul[parentId][file.parents[0]].length === 0) {
                    {
                      this.result[parentId][file.parents[0]].push({
                        name: file.name,
                        id: file.id,
                        url: file.webViewLink,
                        previewUrl: file.webContentLink,
                        mimeType: file.mimeType,
                      })
                    }
                  }
                  }
              }
              
              else{
              this.makeFolderPublic(file.id);
              const index = this.result[parentId][file.parents[0]].findIndex(val => val.id === file.id);
              if (index === -1 || this.resul[parentId][file.parents[0]].length === 0) {
                {
                  this.result[parentId][file.parents[0]].push({
                    name: file.name,
                    id: file.id,
                    url: file.webViewLink,
                    previewUrl: file.webContentLink,
                    mimeType: file.mimeType,
                  })
                }
              }
            }
          }
            else if (file.mimeType === "application/vnd.google-apps.folder") {
              parentFolder.push(file)
              if (!this.result[parentId][file.id])
                this.result[parentId][file.id] = [file.name];
            }
          });
          this.listFiles(null, filter, parentFolder,parentId,tempFolder,cb);
        }
        else {
          this.parentFolders[parentId] = parentFolder;
          this.listFiles(null, filter, parentFolder,parentId,tempFolder,cb);
        }
      });
    } 
    else if(!tempFolder[0]){
        cb(this.result);
    }
    else {
      this.listFiles(null, filter,this.parentFolders[tempFolder[0]['key']],tempFolder[0]['key'],tempFolder,cb);
    }
  }

  makeFolderPublic(fileId) {
    gapi.client.drive.permissions.create({
      fileId,
      "supportsAllDrives": true,
      "resource": {
        "type": "anyone",
        "role": "reader"
      }
    }).then((response) => {
      // Handle the results here (response.result has the parsed body).
    }).catch(err => {
      // console.error("Execute error", err);
    });
  }

  responseFile = (result) => {
    this.syncFolder.forEach((ele)=>{
      for (var j in result[ele.key]) {
        if (result[ele.key][j].length < 2 && (typeof (result[ele.key][j][0]) != "object")) {
          delete result[ele.key][j];
        }
      }
    })

    return result
  }
}

export default GoogleDriveSync;