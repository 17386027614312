export const REQUEST_COMPANY_JOURNEY = "REQUEST_COMPANY_JOURNEY";
export const REQUEST_COMPANY_CRMDATA = "REQUEST_COMPANY_CRMDATA";
export const RECEIVE_COMPANY_CRMDATA = "RECEIVE_COMPANY_CRMDATA";
export const RECEIVE_COMPANY_JOURNEY = "RECEIVE_COMPANY_JOURNEY";
export const ADD_COMPANY_JOURNEY	 = "ADD_COMPANY_JOURNEY";
export const UPDATE_COMPANY_JOURNEY  = "UPDATE_COMPANY_JOURNEY"

export const requestCompanyJourney = data => ({
	type: REQUEST_COMPANY_JOURNEY, data
});

export const requestCompanyCRMData = data => ({
	type: REQUEST_COMPANY_CRMDATA, data
});

export const receiveCompanyCRMData = data => ({
	type: RECEIVE_COMPANY_CRMDATA, data
});

export const receiveCompanyJourney = data => ({
	type: RECEIVE_COMPANY_JOURNEY, data
});

export const addCompanyJourney = data =>({
	type : ADD_COMPANY_JOURNEY, data
})

export const receiveUpdateCompanyJourney = data =>({
	type : UPDATE_COMPANY_JOURNEY , data
})