// import npm packages
import React, { Component }       from "react";
import {pdfConverterApi}          from "../../Admin/Api/dashboard"
import withRouter                 from '../../../shared/withRouter'
import GoogleDriveSync            from '../../../shared/GoogleDriveSync';
import DropBoxSync                from '../../../shared/DropBoxSync';
import {documentFilter}           from '../../../shared/DocumentFilter';
import GridImages                 from '../GridImages/GridImages';

class ShowImageFromDrive extends Component {
  constructor(props) {
    super(props);
    this.GoogleDriveSync = new GoogleDriveSync();
    this.DropBoxSync = new DropBoxSync();
    this.state = {
      classFolderList: [],
      files: null,
      documentId: {},
      isOpen: false,
      errorMessage: {},
      companyDocumentId: null,
      dropBoxToken: null,
      isSyncCalled: false,
      convertingFile :false
    }
  }

  componentWillMount() {
      this.initializeDropbox(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.setState({
        classFolderList: [],
        files: null,
        documentId: {},
        isOpen: false,
        errorMessage: {},
        companyDocumentId: null,
        dropBoxToken: null,
        isSyncCalled: false,
      })
    }
  }

  // initialize dropbox and fetch files if add
  initializeDropbox() {
    let {installerDetails,loggedInUser:{homeownerData,documentData}} = this.props;
    let dropboxToken = (installerDetails && installerDetails.dropboxToken) || (homeownerData && homeownerData.dropboxToken);
    if (dropboxToken && documentData.key && !this.state.isSyncCalled) {
        this.setState({isSyncCalled: true}, () => {
          this.DropBoxSync.initializeDropbox(dropboxToken)
            .then(res => {
              if (res) {
                   this.DropBoxSync.getDropBoxFilesList(documentData.key,documentData.filter).then((res)=>{
                    this.props.setImages({dropboxImage: res.previewUrls});
                   });
              }
          });
        });
      }
  }


  filterImage(images, filterByName) {
    let { documentData,convertFile } = this.props.loggedInUser;
    let f = documentData && documentData.filter;
    let filterImages    = [];
    let filterImages1   = [];
    let toConvertPdfArr = [];

    images && Object.keys(images).forEach(id => {
      images[id].forEach(val => {
        if(val && val.fileName)
          val.name = val.fileName ;

        if (val && val.name) {
          const extns = val.name.toLowerCase().split('.').pop();

          if (filterByName) {
            if(val.mimeType && val.mimeType.includes('pdf') && (/layout|site plan/i.test(val.name))){
              toConvertPdfArr.push(val.previewUrl);
            }
            if (val.mimeType && val.mimeType.includes('image/')) {
              if (val.previewUrl && val.name.toLowerCase().includes(filterByName)){
                filterImages.push({name:val.name,url:val.previewUrl})
              }
            } else if (['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(extns)) {
              if (val.previewUrl && val.name.toLowerCase().includes(filterByName)){
                filterImages.push({name:val.name,url:val.previewUrl})    
              }
              else if (documentData&& documentData.source === 'serviceFusion' && val.name.toLowerCase().includes(filterByName)) {
                if (f && !documentFilter(f,val.name))
                  return;
                filterImages.push({name:val.name,url:`https://servicefusion.s3.amazonaws.com/userdocs/6794/${val.file_location}`})
              }
              else if (documentData&& documentData.source === "monday") {
                if (f && !documentFilter(f,val.name)) {
                  return;
                }
                filterImages.push({name:val.name,url:val.public_url});
              } else if (val['$link_url'] && documentData&& documentData.source === "zoho") {
                if (f && !documentFilter(f,val.File_Name)) {
                  return;
                }
                filterImages.push({name:val.name,url:val.$link_url});
              } else if (documentData&& documentData.source === "teamwork") {
                if (f && !documentFilter(f,val.name)) {
                  return;
                }
                filterImages.push({name:val.name,url:val.previewUrl});
              }
              else if (documentData&& documentData.source === "solarNexus") {
                if (f && !documentFilter(f,val.name)) {
                  return;
                }
                filterImages.push({name:val.name,url:val.public_url});
              }
              else if (documentData&& documentData.source === 'salesforce' && val.name.toLowerCase().includes(filterByName)) {
                if (f && !documentFilter(f,val.fileName))
                  return;
                filterImages.push({name:val.fileName,url:val.downloadURL})
              }
              else if (documentData&& documentData.source === 'sharepoint') {
                filterImages.push({name:val.fileName,url:val.downloadURL})
              }
            }
          } else {
            if(val.mimeType && val.mimeType.includes('pdf') && (/layout|site plan/i.test(val.name))){
              toConvertPdfArr.push(val.previewUrl);
            }
            if (val.mimeType && val.mimeType.includes('image/')) {
              if (val.previewUrl){
                filterImages.push({name:val.name,url:val.previewUrl})
              }
            } else if (['png', 'jpg', 'jpeg', 'gif', 'bmp'].includes(extns)) {
              if (val.previewUrl){
                filterImages.push({name:val.name,url:val.previewUrl})
              }                
              else if (documentData&& documentData.source === 'serviceFusion') {
                if (f && !documentFilter(f,val.name))
                  return;
                filterImages.push({name:val.name,url:`https://servicefusion.s3.amazonaws.com/userdocs/6794/${val.file_location}`})
              }
              else if (documentData&& documentData.source === "monday") {
                if (f && !documentFilter(f,val.name)) {
                  return;
                }
                filterImages.push({name:val.name,url:val.public_url});
              } else if (documentData&& documentData.source === "zoho") {
                if (f && !documentFilter(f,val.File_Name)) {
                  return;
                }
                filterImages.push({name:val.name,url:val.$link_url});
              } else if (documentData&& documentData.source === "teamwork") {
                if (f && !documentFilter(f,val.name)) {
                  return;
                }
                filterImages.push({name:val.name,url:val.previewUrl});
              }
              else if (documentData&& documentData.source === "solarNexus") {
                if (f && !documentFilter(f,val.name)) {
                  return;
                }
                filterImages.push({name:val.name,url:val.public_url});
              }
              else if (documentData&& documentData.source === 'salesforce') {
                if (f && !documentFilter(f,val.fileName))
                  return;
                filterImages.push({name:val.fileName,url:val.downloadURL})
              } 
              else if (documentData&& documentData.source === 'sharepoint') {
                if (f && !documentFilter(f,val.fileName))
                  return;
                filterImages.push({name:val.fileName,url:val.downloadURL})
              } else{
                filterImages.push({name:val.name,url:val.url})
              }
            }
          }
        }
      })
    });

    if(filterByName){
      convertFile = [];
    }

    filterImages.sort((a, b) => (a.name > b.name) ? 1 : -1)
    filterImages.map(val=>{
      if(this.props.hideLayoutImage && filterImages.length>0 && val.name.toLowerCase().includes('layout')){}
       else filterImages1.push(val.url);
    });

    let convertedFile = [];
    let convertedOriginUrl  = [];
    Array.isArray(convertFile) &&  convertFile.map(ele=>{
      convertedFile.push(ele.url);
      convertedOriginUrl.push(ele.originUrl);
    });

    if(!this.state.convertingFile && toConvertPdfArr.length>0 && !this.props.filterByName && this.props.requestLoggedInHomeownerAction){
      this.setState({ convertingFile: true }, () => {
        // this.convertPdfFile(toConvertPdfArr,convertedOriginUrl);
      });
    }
    return filterImages1.concat(convertedFile);
  }

  convertPdfFile(toConvertPdfArr, convertedOriginUrl) {
    toConvertPdfArr  = [...new Set(toConvertPdfArr)]
    let { homeownerData } = this.props.loggedInUser;
    let counter = 0;
    toConvertPdfArr.forEach((ele, index) => {
      if (!convertedOriginUrl.includes(ele)) {
        counter++;
        setTimeout(()=> {
          pdfConverterApi({
            "time": new Date().getTime(),
            "homeownerId": homeownerData.id,
            "url": ele
          });

          setTimeout(() => {
            if ((index + 1) === toConvertPdfArr.length) {
              this.props.requestLoggedInHomeownerAction({
                homeownerId: homeownerData.id,
                companyId: homeownerData.companyId
              });
            }
          }, 3000)
        }, 3000 * (counter));
      }
    })
  }

  filterDropboxImage(images, filterByName) {
    let filterImages = []
    images && images.forEach(val => {
      const extns = val.toLowerCase().split('.').pop();

      if (extns.includes('png') || extns.includes('jpg') || extns.includes('jpeg') || extns.includes('gif') || extns.includes('bmp')) {
        if(filterImages.indexOf(val) === -1)
        {
        if (filterByName) {
          if (val.toLowerCase().includes(filterByName))
            filterImages.push(val);
        }
        else {
          filterImages.push(val);
        }
      }
      }
    })
    return filterImages;
  }

  render() {
    return (
      <React.Fragment>
        <div className={this.props.isModal ? "survey-modal-images" : ""}>
          <GridImages
            isOpen={this.props.isOpen}
            images={
              this.props.campaignPhoto ? this.props.campaignPhoto : this.filterImage(this.props.cloudImages, this.props.filterByName)}
            hideOverlay={true}
            overlayBackgroundColor=''
          />
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(ShowImageFromDrive);
