import * as AWS from "aws-sdk";
import envConfig from './../environments/index';

let s3;
const initializeS3 = () => {
    AWS.config.region = envConfig.envConfig.cognito.Region;
    let x = { key: 'cognito-idp.us-east-1.amazonaws.com/' + envConfig.envConfig.cognito.UserPoolId }
    let y = {};
    y[x.key] = localStorage.getItem("token");
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: envConfig.envConfig.cognito.IdentityPoolId,
        Logins: y
    })
    s3 = new AWS.S3();
    return s3
}

export default initializeS3;