// import npm packages
import React, { Component }       from "react";
import { connect }                from "react-redux";
import { Spin, Modal }            from "antd";
import { LoadingOutlined }        from '@ant-design/icons';
import { bindActionCreators }     from "redux";

import CompanyMetrics             from "../components/metrics/companyMetrics"
import DashboardStats             from "../components/dashboard/dashboardStats";
import CompanyDashboardMetrics    from "../components/dashboard/companyDashboardMetrics";
import { installerSettingKey }      from '../../../constants/routes'
import {getLoadingIcon}           from "../../User/components/setUserBackgroud"
import {metricsReportData,
  meterStatusNotification}        from "../api/dashboard"
import {
  requestReferralCount,

  // survey
  requestSurveyCount,
  requestDownloadSurveyData,
  receiveDownloadSurveyData,
  requestSurveyData,

  // social
  requestSocialShareCount,
  requestSocialShareData,
  receiveDownloadSocialShareData,
  requestDownloadSocialShareData,

  // active account
  requestActiveAccountCount,

  // delayed project
  requestDelayedProjectData,
  setMetricsType
}                                 from '../actions/dashboard';
import withRouter                 from './../../../shared/withRouter';
import { getSession }             from './../../../shared/updateRefreshToken';
import { getToken }               from "../../../shared/util";
import { jwtDecode }              from "../../../shared/jwt";
import { setLoader }              from '../../Common/Loader/action';
import LandingPage                from '../../Common/LandingPage';

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.slug = "";
    this.email="";
    this.state= {
      metricsType   : null,
      metricsData   : {},
      metricsQuery  : {},
      isPopUpVisible: false,
      metricColumnParam   : [],
      metricFilterParam   : {},
      metricRef     : null,
      metricsHeader : {}
    }
  }

  componentWillMount() {
    this.slug = localStorage.getItem("slug") || this.props.router.params.slug;
    let token = getToken()
    this.installerData = jwtDecode(token);
    this.companyId = this.installerData.companyId || this.props.router.params.companyId;
    this.email= this.installerData.email;
    let id = this.installerData.installerId;

    const data = {
      id: id,
      companyId: this.companyId
    }
    if (data.companyId) {
      this.props.setLoader({ loader: true });
      this.props.requestReferralCount(data);
      this.props.requestSurveyCount(data);
      this.props.requestSocialShareCount(data);
      this.props.requestActiveAccountCount(data);
    }
  }
  
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.dashboardData
      && nextProps.dashboardData.activeAccountCountReducer.status
    )  {
      this.setState({isPopUpVisible: true});
      nextProps.setLoader({ loader: false });
    }
  }

  updateRefreshToken = () => {
    getSession(this.props.auth)
  }

  renderDashboard = activeCount => {
    let [searchParams, setSearchParams] = this.props.router.searchParams;
    const view = searchParams.get(installerSettingKey);
    if (view === 'companyMetrics') {
      this.props.setMetricsType(null);
      this.setState({metricsType: 'active accounts'})
      this.props.router.navigate(`/${this.slug}/${this.companyId}/dashboard`)
    }
    else if (!this.props.metricsType && !this.state.metricsType) {
      this.setState({ metricsType: 'active accounts' }, () => {
        this.changeMetricState('active accounts', activeCount);
      });
    }

    return (
      <Spin
        spinning={this.props.loader}
        indicator={antIcon}
        style={{ color: "#00142D" }}
      >
        <React.Fragment>
        <title>Dashboard - {this.props.companyName+'-'} Bodhi</title>
          <div>
          {this.state.isPopUpVisible && this.renderPopUp()}
            {<DashboardStats {...this.props} companyId={this.companyId} changeMetricState={this.changeMetricState} />}
            {this.props.metricsType && this.state.metricsType && this.renderCompanyMetrics()}
            {!this.props.metricsType && <CompanyDashboardMetrics {...this.props}/>}
          </div>
        </React.Fragment>
      </Spin>
    );
  };

  renderPopUp = () => {
    let workingJson = {
      "resolved" :{
        "heading" : "Thank you for taking care of this issue. We'll let #HOMEOWNER_NAME know about the quick resolution here. They'll appreciate you were so proactive",
        "text":"",
        "api":true
      },
      "working" :{
        "heading": "Thanks for attempting to debug and isolate the performance issue with your system.",
        "text":"We're letting #HOMEOWNER_NAME know you've have taken these steps and to follow up with you",
        "api":false
      }
    }
    let location = window.location;
    let url = new URL(location);
    let action = url.searchParams.get("action");
   
    if (!action)
      return '';

      workingJson[action].api && meterStatusNotification({
        homeownerId : url.searchParams.get("homeowner"),
        action      : action,
        companyId   : this.companyId
      })

    return (
        <div>
            <Modal
                visible={true}
                className="thank-you popUp"
                footer={null}
            >
                <h2>{workingJson[action].heading.replace(/#HOMEOWNER_NAME/,url.searchParams.get("homeownerName"))}</h2>
                <p>{workingJson[action].text.replace(/#HOMEOWNER_NAME/,url.searchParams.get("homeownerName"))}</p>
                <button className="btn thanks-btn" onClick={()=>{
                  // remove query param without refreshing the page
                  let base_url =location.href;
                  window.history.pushState({}, "", base_url.split("?")[0]);
                  // hide pop-up
                    this.setState({
                        isPopUpVisible : false
                    });
                }}>DASHBOARD</button>
            </Modal>
        </div>
      );
  }

  render() {
    let activeCount = this.props.dashboardData && this.props.dashboardData.activeAccountCountReducer && this.props.dashboardData.activeAccountCountReducer.data && this.props.dashboardData.activeAccountCountReducer.data.activeCount;

    if (getToken()) {
      if (activeCount == undefined) {
        return <LandingPage loadingIcon={getLoadingIcon(this.slug)} role={'installer'} />
      } else {
        return this.renderDashboard(activeCount);
      }
    } else {
      this.props.router.navigate("/");
      window.location.reload();
      return null;
    }
  }

  changeMetricQueryParam = (operation,value) =>{  
    let {metricsQuery, metricsType,metricColumnParam,metricFilterParam} = this.state
    let temp  = JSON.parse(JSON.stringify( metricsQuery[metricsType]));

    switch(operation){
      case 'skip':
          if(value< 0){
            temp['skip'] = (temp['skip'] > 0) ? temp['skip'] + value : 0;
          }
          else 
            temp['skip'] = temp['skip'] + value;
      break;

      case 'limit':
          temp['limit'] = value  ? value : "25";
      break;

      case 'sort':
          temp['sort'] = value;
      break;

      case 'param':
        metricColumnParam = value;
      break;

      case 'filter':
          metricFilterParam[metricsType] = value;
          temp['skip'] =0
      break;
    }


    let totalSkip = temp['skip'] * temp['limit'];
    if (totalSkip >= metricsQuery[metricsType]['count']) {
      return;
    }else
    {
      metricsQuery[metricsType] = temp;
      this.setState({ metricsQuery,metricColumnParam,metricFilterParam }, () => {
        this.receiveMetricData();
      })
    }
  }

  changeMetricState = (metricsType, toltalCount) => {
    metricsType = metricsType.toLowerCase();
    let { metricsQuery, metricFilterParam } = this.state;
    this.props.setMetricsType(metricsType)

    metricsQuery[metricsType] = {
      skip: 0,
      limit: "25",
      count: toltalCount
    }
    metricFilterParam[metricsType] = {}


    this.setState({ metricsType, metricsQuery, metricFilterParam }, () => {
      this.receiveMetricData();
    })
  }

  receiveMetricData = () => {
    let { metricsType, metricsQuery, metricColumnParam, metricFilterParam, metricsData, metricsHeader } = this.state;
    let response;
    this.props.setLoader({ loader: true });
    metricsReportData({ companyId: this.companyId, metricsType, metricsQuery: metricsQuery[metricsType], metricColumnParam, metricFilterParam: metricFilterParam[metricsType] }).then(apiResponse => {
      switch (metricsType) {

        case 'active accounts':
          response = apiResponse.data;
          break;

        case 'referrals':
          response = apiResponse.data;
          break;

        case 'surveys':
          response = apiResponse.data;
          break;

        case 'social shares':
          response = apiResponse.data && apiResponse.data.socialSharesCount;
          break;
      };
      this.props.setLoader({ loader: false });
      if (!response || response.length == 0) {
        metricsQuery[metricsType]['skip'] = 0;
        let prevData = metricsData[metricsType] ? metricsData[metricsType][0] : null
        metricsHeader[metricsType] = prevData ? Object.keys(prevData) : metricsHeader[metricsType];
      }
      metricsData[metricsType] = response
      this.setState({ metricsData, metricsHeader });
    })
  }

  getmetricRef = (metricRef) => {
    this.setState({
      metricRef
    })
  }


  renderCompanyMetrics = () => {

    if (this.state.metricRef) {
      this.state.metricRef.setState({
        isCoulmnDisplay: false,
        currentShow: null,
        filterValue: null,
        stateMetricFilterParam: {},
        metricColumnParam: (this.state.metricColumnParam && this.state.metricColumnParam.length > 0) ? this.state.metricColumnParam : ["homeownerId", "Name", "Phase", "Last notification", "Email", "Phone number", "isDisable", "Address"]
      })
    }
    let { metricsType, metricsData, metricsQuery, metricFilterParam, metricsHeader } = this.state;
    return <CompanyMetrics {...this.props} email={this.email} getmetricRef={this.getmetricRef} {...this.props} metricsType={metricsType} metricsData={metricsData[metricsType]}  metricsHeader ={ metricsHeader[metricsType]}
    changeMetricQueryParam={this.changeMetricQueryParam} metricsParam = {metricsQuery[metricsType]} metricFilterParam={metricFilterParam[metricsType] ? metricFilterParam[metricsType] : {}}/>
  }

}

const mapStatetoProps = (state) => {
  return {
    auth: state.auth,
    dashboardData: state.dashboardStats,
    searchData: state.header.getInstaller,
    loader: state.loader.loader.loader,
    companyName: state.header.getInstaller && state.header.getInstaller.data && state.header.getInstaller.data.companyName,
    metricsType : state.dashboardStats.metricsType
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    // referral
    requestReferralCount,

    // servey
    requestSurveyCount,
    requestSurveyData,
    requestDownloadSurveyData,
    receiveDownloadSurveyData,

    // social share
    requestSocialShareCount,
    requestSocialShareData,
    requestDownloadSocialShareData,
    receiveDownloadSocialShareData,

    // active account
    requestActiveAccountCount,

    requestDelayedProjectData,

    // loader
    setLoader,
    setMetricsType
  }, dispatch)
)
export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(Dashboard));
