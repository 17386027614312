// import npm packages
import React, { Component }         from "react";
import { Spin, Form, 
  Collapse, Tooltip }                from "antd";
import { LoadingOutlined }              from '@ant-design/icons';
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';
import * as moment                  from 'moment';
// import local file
import { installerSettingKey }      from '../../../../constants/routes'
import { jwtDecode }       from "../../../../shared/jwt";
import { getToken }        from '../../../../shared/util';
import withRouter        from '../../../../shared/withRouter';
import SystemNotifications from "../../components/companySetting/SystemNotifications";
import FAQCompanySetting  from '../../components/companySetting/FAQCompanySetting';
import InviteEmployee     from '../../components/companySetting/InviteEmployee';
import EmployeeAccounts   from '../../components/companySetting/EmployeeAccont';
import ReferralMessage    from "../../components/companySetting/ReferralMessage";
import DetailedPhaseInfo  from "../../components/companySetting/DetailedPhaseInfo";
import CompanyDetail      from "../../components/companySetting/CompanyInformation";
import IntegrationDetail  from "../../components/companySetting/IntegrationInformation";
import UtilityInformation from "../../components/companySetting/UtilityInformation";
import Hardware           from "../../components/companySetting/HardwareInformation";
import PlanDetail         from "../../components/companySetting/PlanDetail";
import EdRerource         from "../../components/companySetting/EdRerource";
import MultipleJourney    from "../../components/companySetting/MultipleJourney";
import { setLoader }      from '../../../Common/Loader/action';
import LandingPage        from '../../../Common/LandingPage';
import { requestCompanyDetailSet,
        requestCompanyDetail, 
        receiveCompanyDetail,
        requestUpdateLoggedInInstallerById 
      }                        from '../../actions/profile';
import BodhiJourney            from "../../components/companySetting/BodhiJourney";
import {receiveCompanyCRMData} from "../../actions/companyJourney";
import engageScoreIcon         from '../../../../Assets/Images/engagement-score-icon.png'
import shareReferIcon          from '../../../../Assets/Images/share_referrals.svg'
import surveysIcon             from '../../../../Assets/Images/surveys.png'
import fingerTouchIcon         from '../../../../Assets/Images/finger_touch.png'
import chevronUpIcon           from "../../../../Assets/Images/chevronUP.svg"
import chevronDownIcon         from "../../../../Assets/Images/chevronDown.svg"
import settingsIcon            from "./../../../../Assets/Images/settings.svg"

const loadingIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

class CompanySettingsForm extends Component {

  constructor(props) {
    super(props);
    this.slug = this.props.router.params.slug;
    this.companyId = this.props.router.params.companyId;

    let [searchParams, setSearchParams] = this.props.router.searchParams;
    const view = searchParams.get(installerSettingKey);

    this.state = {
      onNextInfo: true,
      isExpand : true,
      journeyIndex : null,
      generatingCutoff: {},
      monitoringCutoff: {},
      companySettingState: view || 'isCompany'
    }
  }

  componentDidMount() {
    let token = getToken()
    this.installerData = jwtDecode(token);
    this.admin = window.location.pathname.includes('admin') ? true : false;  
    this.admin && this.props.requestCompanyDetail({
      companyId: this.companyId, admin:this.admin
    });
  }

  componentDidUpdate(prevProps) {
    let [searchParams, setSearchParams] = this.props.router.searchParams;
    const view = searchParams.get(installerSettingKey);

    /**
     * @todo: the `admin` user uses inline state setting (left rail, below) whereas the installer uses Sider implementation.
     * We need to handle the cases differently.
     */
    if (view && this.state.companySettingState !== view) {
      this.setState({
        companySettingState: view
      });
    }

    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.props.receiveCompanyCRMData({});
      this.props.setLoader({ loader: true });
      this.props.receiveCompanyDetail({});
      this.companyId = this.props.router.params.companyId;
      this.props.requestCompanyDetail({
        companyId: this.companyId,
        admin: this.admin
      });
      this.setState({
        companySettingState: 'isCompany'
      });
    }
  }

  updateState = (obj) => {
    this.setState(obj);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps && nextProps.companyData && nextProps.companyData.companyDetail && nextProps.companyData.companyDetail.slug){
      this.slug = nextProps.companyData.companyDetail.slug;
    }
    if (
      nextProps.companyData &&
      nextProps.companyData.companyDetail &&
      nextProps.companyData.companyDetail.monitoringCutoff &&
      Object.entries(this.state.monitoringCutoff).length === 0
    ) {
      this.setState({
        monitoringCutoff: nextProps.companyData.companyDetail.monitoringCutoff
      });
    }
    if (
      nextProps.companyData &&
      nextProps.companyData.companyDetail &&
      nextProps.companyData.companyDetail.generatingCutoff &&
      Object.entries(this.state.generatingCutoff).length === 0
    ) {
      this.setState({
        generatingCutoff: nextProps.companyData.companyDetail.generatingCutoff
      });
    }
  }

  DelayedcancelHandler = () => {
    let token = getToken();
    if (token) {
      this.props.requestCompanyDetail({companyId:this.companyId,admin:this.admin});
    }
    this.forceUpdate();
  }

  renderCompanySettingState(link) {
    switch (link) {
      case 'isEmployee':
        return this.renderEmployeeAccount();

      case 'isPlan':
        return this.renderCompanyPlan();

      case 'isSystemNotification':
        return this.renderSystemNotification();

      case 'isReferralMessage':
        return this.renderReferralMessage();

      case 'isPhaseInfo':
        return this.renderDetailedInfo();

      case 'isFAQ':
        return this.renderFAQ();

      case 'isCompany':
        return this.renderCompanyDetail();

      case 'isIntegrations':
        return this.renderCompanyIntegration()

      case 'isUtilities':
        return this.renderUtility()

      case 'isHardware':
        return this.renderHardwareDetail()

      case 'isEdResource':
        return this.renderEdResource();

      case 'addJourney':
        return this.renderMultipleJourney();

      default:
        return this.renderBodhiJourney();
    }
  }

  renderHardwareDetail() {
    return(
      <>
      <h2>Hardware</h2>
        <Hardware companyId={this.companyId} {...this.props} />
      </>
    )
  }

  renderEdResource() {
    return(
      <><h2>Educational resources</h2>
        <EdRerource
          companyId={this.companyId}
          DelayedcancelHandler={() => this.DelayedcancelHandler()}
          {...this.props}
        />
      </>
    )
  }

  renderUtility() {
    return(
      <>
      <h2>Electric utilities</h2>
        <UtilityInformation  companyId={this.companyId}
          {...this.props} DelayedcancelHandler={() => this.DelayedcancelHandler()} />
      </>
    )
  }

  renderCompanyIntegration() {
    return (
      <>
        <h2>Integrations</h2>
        <IntegrationDetail companyId={this.companyId} isAdmin ={this.admin}
           {...this.props} DelayedcancelHandler={() => this.DelayedcancelHandler()} />
      </>
    )
  }

  renderCompanyDetail() {
    return (
      <>
        <h2>Company information</h2>
        <CompanyDetail companyId={this.companyId} isAdmin ={this.admin}
           {...this.props} DelayedcancelHandler={() => this.DelayedcancelHandler()} />
      </>
    )
  }

  renderEmployeeAccount() {
    return (
      <>
        <h2>Employee accounts</h2>
        <div>
          <InviteEmployee setLoader={this.props.setLoader} companyId={this.companyId} slug={this.slug} />
        </div>
        <div className="box-Shadow content-box metrics-table-box ml-0 w-100">
          <EmployeeAccounts {...this.props} companyId={this.companyId} setLoader={this.props.setLoader} />
        </div>
      </>)
  }  

  renderCompanyPlan() {
    return (
      <>
        <h2>Plan details</h2>
        <div className="section-from-box">
          <PlanDetail companyId={this.companyId}
           {...this.props} setLoader={this.props.setLoader} />
        </div>
      </>)
  }  

  renderSystemNotification() {
    const { companyData } = this.props;
    return (
      <>
        {/* <h2>System notifications</h2> */}
        <SystemNotifications companyData={companyData} isAdmin ={this.admin}
          companyId={this.companyId} {...this.props} DelayedcancelHandler={() => this.DelayedcancelHandler()} />
      </>
    )
  }

  renderReferralMessage() {
    const { companyData } = this.props;
    return (
      <>
        <h2>Referrals</h2>
        <ReferralMessage
          companyData={companyData}
          companyId={this.companyId} {...this.props}
          setLoader={loader => { this.props.setLoader(loader) }}
          DelayedcancelHandler={() => this.DelayedcancelHandler()} />
      </>
    )
  }

  renderDetailedInfo() {
    const { companyData } = this.props;
    return (
      <>
        <h2>More Info</h2>
        <DetailedPhaseInfo companyData={companyData} companyId={this.companyId} {...this.props}
          DelayedcancelHandler={() => this.DelayedcancelHandler()} />
      </>
    )
  }
  
  renderFAQ() {
    return (
      <>
        <h2>FAQs</h2>
        <div className="content-box box-Shadow">
          <div className="mob-box">Use this section to answer important questions your homeowners may have</div>
          <FAQCompanySetting companyId={this.companyId} setLoader={this.props.setLoader} {...this.props}/>
        </div>
      </>
    )
  }

  renderBodhiJourney() {
    return(
      <BodhiJourney journeyState={this.state.companySettingState} journeyData={this.state.journeyData}
      setLoader={this.props.setLoader} tokenData={{
        companyId : this.companyId,
        slug : this.slug
      }} {...this.props} />
    )
  }

  renderProfileScreen = () => {
    const { companyData:{companyDetail} } = this.props;
    return (
      <React.Fragment>
        <Spin
          spinning={this.props.loader}
          indicator={loadingIcon}
          size="large"
          style={{ color: "#00142D" }}
        >
          {
            !companyDetail ? <LandingPage/> :

              <div>
                <title>Company Settings - {companyDetail && companyDetail.name + '-'} Bodhi</title>
                {this.admin && this.renderCompanyInfo()}
                <section className="white-box-wrapper mobileView" style={this.admin ? {} :
                  { 'marginTop': '72px' }}>
                  {this.renderDesktopView()}
                </section>
              </div>
          }
        </Spin>
      </React.Fragment>
    );
  }

  renderCompanyInfo() {
    const { companyData,companyData:{companyDetail,companyStats} } = this.props;
    let contactNumber = companyDetail && companyDetail.contactPhone &&('('+companyDetail.contactPhone.slice(0, 3) + ") "+companyDetail.contactPhone.slice(3, 6)+"-"+companyDetail.contactPhone.slice(6,companyDetail.contactPhone.length));
    return (
      <section className="profile-detail-box">
        <div className="container margin-t-150">
          <div className="row justify-content-md-center view content-center">
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-5 col-xs name-adddres-box">
                  <h1>{companyDetail && companyDetail.name} </h1>
                  <div className="address-box">
                    {companyDetail && companyDetail.address.address1} <br />
                    {companyDetail && companyDetail.address.city}, {" "}
                    {companyDetail && companyDetail.address.state} {" "}
                    {companyDetail && companyDetail.address.postalCode} <br />
                    {contactNumber}
                  </div>
                  <div className="company-link" onClick={()=>{
                    localStorage.setItem('slug',companyDetail.slug);
                    this.props.router.navigate(`/${companyDetail.slug}/${this.companyId}/dashboard`);
                  }}>View Company Portal</div>
                </div>
                <div className="col-md-7 col-xs personal-detail-box">
                <div className="detail-box">
                    <p>Customer Since</p>
                    <p> {companyDetail && companyDetail.created &&
                      moment(companyDetail.created*1).format('MMM DD, YYYY')}</p>
                  </div>
                  {
                    companyDetail && companyDetail.crmLastSync &&
                    <div className="detail-box">
                      <p>Last Sync Time</p>
                      <p>
                        {moment(companyDetail.crmLastSync * 1000).format('DD-MM-YYYY h:mm:ss a')}</p>
                    </div>
                  }
                  <div className="detail-box">
                    <p> Contact</p>
                    <div className="address-box">
                      {companyDetail && companyDetail.contactName}<br />
                      {companyDetail && companyDetail.contactEmail}<br />
                      {contactNumber}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 no-padding-box">
              <div className="white-box">
                <div className="engagement-scroe-box">
                  <div className="icon">
                    <img src={engageScoreIcon} /></div>
                  <div className="content">
                    <p className="content">Employees</p>
                    <p className="number">{companyStats && companyStats.installers || 0}</p>
                  </div>
                </div>
                <div className="referrals-box">
                  <div className="icon"><img src={shareReferIcon} /></div>
                  <div className="content">
                    <p className="content">Homeowners</p>
                    <p className="number">{companyStats && companyStats.homeowners || 0}</p>
                  </div>
                </div>
                <div className="surveys-box">
                  <div className="icon"><img src={surveysIcon} /></div>
                  <div className="content">
                    <p className="content">Surveys</p>
                    <p className="number">{companyStats && companyStats.survey || 0}</p>
                  </div>
                </div>
                <div className="social-shares-box">
                  <div className="icon"><img src={fingerTouchIcon} /></div>
                  <div className="content">
                    <p className="content">Referrals</p>
                    <p className="number">{companyStats && companyStats.referrals || 0}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

  expand = text => {
    return (<React.Fragment>
      <span className="text info"><img src={chevronUpIcon} className="collpaseble rotate"/>{text}</span>
    </React.Fragment>)
  }

  collapse = text => {
    return (<React.Fragment>
      <span className="text info"><img src={chevronDownIcon} className="collpaseble"/>{text}</span>
    </React.Fragment>)
  }

  journeyCollapse = (text, isCollpse, journeyData, index) => {    
    let isActiveJourney = false;
    if (this.state.editJourneyData && journeyData && this.state.editJourneyData.s3Key===journeyData.s3Key){
      isActiveJourney = true;
    }
    
    let className = (isActiveJourney) ? 'collpaseble' :  'collpaseble rotate';
    return <React.Fragment>
      <span className="text info">
        {
          (isActiveJourney) ?  <img src={chevronDownIcon} className={className} />
          : <img src={chevronUpIcon} className={className} />
        }
        <span className={isActiveJourney ? 'seeDetailsWrapper' : ''} onClick={(e) => {
          e.stopPropagation();
          this.setState({ companySettingState: 'addJourney', editJourneyData: journeyData,journeyIndex:index })
        }}>{text}</span>               
      </span>
    </React.Fragment>
  }

  addJourney = () => {
    return(
      <>
        <Tooltip overlayClassName="black-tooltip" title={'Create new Bodhi journey'}>
          <span
            onClick={() => { this.setState({ companySettingState: 'addJourney', editJourneyData: "empty" }) }}
            style={{ marginLeft: '5px', cursor: 'pointer' }}>+</span>
        </Tooltip>
      </>
    )
  }

  renderMultipleJourney = () => {
    return(
      <><h2> Bodhi journeys</h2>
        <MultipleJourney companyId={this.companyId} {...this.props} updateState={this.updateState} journeyPhase={this.journeyPhase} editJourneyData={this.state.editJourneyData}/>
      </>
    )
  }

  renderDesktopView() {
    return( 
    <div className="sys-notification-wrapper">
      <div className="row m-0">
          {
            this.admin && <div className="col-sm-3 left-menu-wrapper">
              <div className="left-menu-box">
                <h2> <img src={settingsIcon} /> Company Settings </h2>

                <ul className="left-menu">
                  <li><a onClick={() => { this.setState({ companySettingState: 'isCompany' }) }} className={this.state.companySettingState == 'isCompany' ? 'active' : ''}>Company Information</a></li>
                  <li><a onClick={() => { this.setState({ companySettingState: 'isPlan' }) }} className={this.state.companySettingState == 'isPlan' ? 'active' : ''}>Plan Details</a></li>
                  <li><a onClick={() => { this.setState({ companySettingState: 'isIntegrations' }) }} className={this.state.companySettingState == 'isIntegrations' ? 'active' : ''}>Integrations</a></li>
                  <li><a onClick={() => { this.setState({ companySettingState: 'isEmployee' }) }} className={this.state.companySettingState == 'isEmployee' ? 'active' : ''}>Employee Accounts</a></li>
                  <li><a onClick={() => { this.setState({ companySettingState: 'isHardware' }) }} className={this.state.companySettingState == 'isHardware' ? 'active' : ''}>Hardware</a></li>
                  <li><a onClick={() => { this.setState({ companySettingState: 'isUtilities' }) }} className={this.state.companySettingState == 'isUtilities' ? 'active' : ''}>Utilities</a></li>
                  <li>
                    <a href="#"
                      data-toggle="collapse"
                      data-target="#collapseDetails"
                      aria-expanded="true"
                      aria-controls="collapseDetails"
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ onNextInfo: !this.state.onNextInfo })
                      }}
                    >
                      {this.state.onNextInfo ? this.collapse('Content') : this.expand('Content')}
                    </a>
                  </li>

                  <div id="collapseDetails" className={`collapse ${this.state.onNextInfo ? 'show' : ''}`}>
                    <li className="shiftRight"><a onClick={() => { this.setState({ companySettingState: 'isEdResource' }); }} className={this.state.companySettingState == 'isEdResource' ? 'active' : ''}>Educational Resources</a></li>
                    <li className="shiftRight"><a onClick={() => { this.setState({ companySettingState: 'isSystemNotification' }); }} className={this.state.companySettingState == 'isSystemNotification' ? 'active' : ''}>System Notifications</a></li>
                    <li className="shiftRight"><a onClick={() => { this.setState({ companySettingState: 'isReferralMessage' }); }} className={this.state.companySettingState == 'isReferralMessage' ? 'active' : ''}>Referrals</a></li>
                    <li className="shiftRight"><a onClick={() => { this.setState({ companySettingState: 'isPhaseInfo' }); }} className={this.state.companySettingState == 'isPhaseInfo' ? 'active' : ''}>More Info</a></li>
                    <li className="shiftRight"><a onClick={() => { this.setState({ companySettingState: 'isFAQ' }); }} className={this.state.companySettingState == 'isFAQ' ? 'active' : ''}>Frequently Asked Questions</a></li>
                  </div>
                </ul>

                <h2 className="mt2"> <img src={settingsIcon} /> Bodhi Journeys {this.addJourney()}</h2>

                <ul className="left-menu">
                  {this.renderJourneyPhase()}
                </ul>
              </div>
            </div>
          }

        <div className={`${this.admin ? 'col-sm-9 ml-0' :'col-sm-11'} right-content-wrapper`}>
        <div className="right-content-box">
          {
            this.renderCompanySettingState(this.state.companySettingState)
          }
        </div>
      </div>
      </div>
    </div>)
  }

  formatJourney = (journey) => {
    let temp = journey.map(ele => {
      if (typeof(ele)==="string") {
        return ele = JSON.parse(ele);
      } else
        return ele;
    });
    return temp;
  }

  renderJourneyPhase() {
    let {companyData : {companyDetail}} = this.props;
    let journey  = [];

    if (companyDetail && companyDetail.journey) {
      journey = JSON.parse(JSON.stringify(companyDetail.journey));
    }

    journey =  this.formatJourney(journey);

    return (
      <>
      {
        journey.map((ele, index) => {
          return <React.Fragment key={index}>
            <li>
              <a 
                // data-toggle={"collapse"}
                data-target={"#collapseDetails" + index}
                aria-expanded="true"
                aria-controls={"collapseDetails"}
                // onClick={() => this.setState({isExpand : (index==this.state.journeyIndex) ? !this.state.isExpand : true,journeyIndex:index })}
              >
                {(index == this.state.journeyIndex) ? this.journeyCollapse(ele.journeyName,true,ele,index) : this.journeyCollapse(ele.journeyName,false,ele,index)}
              </a>
            </li>

            <div id={"collapseDetails"+ index } className={`${`collapse ${index == this.state.journeyIndex ? 'show' : ''}`}`}>
              {Object.keys(ele.journeyPhase).map((element, i) => {
                return <React.Fragment key={i}>
                  <li className="shiftRight">
                    <a onClick={() => {
                      this.setState({
                        journeyData: ele,
                        companySettingState: this.journeyPhase(element.toLowerCase()),
                        journeyKey: ele.journeyKey
                      })
                    }}
                      className={(this.state.companySettingState == this.journeyPhase(element) && index == this.state.journeyIndex) ? 'active' : ''}>
                      {(ele.journeyPhase && ele.journeyPhase[element] && ele.journeyPhase[element].name) || this.journeyPhase(element)}
                    </a>
                  </li>
                </React.Fragment>
              })}
            </div>
          </React.Fragment>
        })
      }                  
      </>
    )
  }

  journeyPhase(phase){
    switch(phase){
      case 'design':
        return 'Design';
      case 'permit':
        return 'Permit';
      case 'thebuild':
        return 'The Build';
      case 'inspection':
        return 'Inspection';
      case 'poweringup':
        return 'Powering Up';
      case 'service':
        return 'Service';
    }
  }

  render() {
    if (getToken()) {
      return this.renderProfileScreen();
    } else {
      this.props.router.navigate("/");
      return null;
    }
  }
}

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    setCompanyDetail: state.setting.setCompanyDetail,
    installerData: state.header.getInstaller.data,
    companyData: state.setting.companyDetail.data || {},
    getInstaller: state.addhomeOwner.getInstaller,
    loader: state.loader.loader.loader,
    companyList: state.adminHeader.searchData.data,
    updateInstallerById: state.setting.updateInstallerById[state.setting.updateInstallerById.length - 1]
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    requestCompanyDetailSet,
    requestUpdateLoggedInInstallerById,
    requestCompanyDetail,
    receiveCompanyDetail,
    receiveCompanyCRMData,
    setLoader
   }, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(withRouter(CompanySettingsForm));

