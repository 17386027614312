
import React, { Component }      from 'react';
import { Input, Form,
  Spin, Progress }               from "antd";
import { LoadingOutlined }       from '@ant-design/icons';
import { connect }               from 'react-redux';
import Slider                    from 'react-slick';
import { bindActionCreators }    from 'redux';
import * as AWS                  from "aws-sdk";
import envConfig                 from "../../../../environments/index"
import {addCompanyJourney,
        receiveCompanyJourney,
        receiveUpdateCompanyJourney,
        requestCompanyJourney}   from '../../actions/companyJourney';
import {setLoader}               from '../../../Common/Loader/action';
import { DeleteModal }           from "../../../Common/DeleteModal";
import ResourcesContent          from "../../../homeowner/container/resources/resources.json"
import addCloudIcon from '../../../../Assets/Images/add_cloud.svg'
import deleteIcon from "../../../../Assets/Images/delete-icon.png"

const { TextArea } = Input;

class EdResourceForm extends Component {
  constructor(props) {
    super(props);
    this.edResouceOption =['Solar journey','Solar 101','Getting your home ready','Your solar equipment'];
    this.state = {
      ResourcesObj:ResourcesContent,
      deleteTrigger    : false,
      currentSlideIndex: 0,
      activePhaseIndex : 0,
      s3UploadProgress   : null,
      edResourceImage    : {}
    };
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.updateJourney && nextProps.updateJourney.status) {
      this.fetchEdresource();
      this.props.receiveUpdateCompanyJourney({});
    }
    if(nextProps.resourceData  && Object.keys(nextProps.resourceData).length>0){
      this.props.setLoader({loader:false});
      this.setState({
        ResourcesObj: nextProps.resourceData
      },()=>{
        this.props.receiveCompanyJourney({});
      })
    }
  }

  componentWillMount(){
   this.fetchEdresource();
  }

  fetchEdresource(){
    let {companyData:{companyDetail}} = this.props;

    let data = {
      slug : companyDetail.slug,
      type : 'resource'
    };
    this.slug = data.slug;
  
    this.props.setLoader({loader:true});
    this.props.receiveCompanyJourney({});
    this.props.requestCompanyJourney(data);
  }

  renderDeletePopUp = () => {
    let headerText = 'Do you really want to delete this slide?';
    let subText = "Deleting this slide cannot be undone";
    return (
      <DeleteModal deletePopUpProp={this.deletePopUpProp} headerText={headerText} subText={subText} />
    )
  }

  uploadImageonS3 = (temp,mediaBody) => {
    return new Promise((res, rej) => {    
      this.setState({ s3UploadProgress: 0 })
      let s3Obj = {
        params: {
          Bucket: envConfig.envConfig.s3.BUCKET,
          Key: `public/resourceMedia/${temp.s3Path}`,
          Body: mediaBody
        }
      }

      AWS.config.region = envConfig.envConfig.cognito.Region;
      let x = { key: 'cognito-idp.us-east-1.amazonaws.com/' + envConfig.envConfig.cognito.UserPoolId }
      let y = {};
      y[x.key] = localStorage.getItem("token");
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: envConfig.envConfig.cognito.IdentityPoolId,
        Logins: y
      })
      let s3ManagedUpload2 = new AWS.S3.ManagedUpload(s3Obj).on('httpUploadProgress', (data) => {
        this.setState({ s3UploadProgress: parseInt((data.loaded * 100) / data.total) })
      }).promise();

      s3ManagedUpload2.then(result => {
        res([result.Location])
        this.setState({ s3UploadProgress: null });
        return;
      }, err => {
        res(false);
        this.setState({ s3UploadProgress: null });
        return;
      }).catch(() => {
        res(false);
        this.setState({ s3UploadProgress: null });
        return;
      });
    })
  }

  handleSubmit = values => {
    var promiseTask = [];
    let { ResourcesObj } = this.state;
    let Resources = JSON.parse(JSON.stringify(ResourcesObj));    

    Resources.resourcesMain.map((element) => {
      element.resourcesChild && element.resourcesChild.map(async (ele) => {
        if (this.state.edResourceImage[ele.s3Path] && ele.childImg && ele.childImg.includes('blob')) {
          delete ele.childImg;
          promiseTask.push(this.uploadImageonS3(ele, this.state.edResourceImage[ele.s3Path]));
        }
      })
    });

    return Promise.all(promiseTask).then(r => {
      let data = {
        type: 'resource',
        slug: this.slug,
        triggerData: Resources
      };
      this.props.setLoader({loader:true});
      this.props.addCompanyJourney(data);
    })
  };

  cancelHandler = () => {
    this.props.DelayedcancelHandler();
  };

  handleChangeTextArea = (slides, index) => {
    let {ResourcesObj} = this.state;
    ResourcesObj.resourcesMain[index] = slides;
    this.setState({ResourcesObj});
  };

  slideReset = () => {
    this.setState({
      slides: null
    });
  }

  deletePopUpProp = (value) => {
    if (value == 'cancel') {
      this.setState({deleteTrigger: false});
    }
    else {
      let {ResourcesObj,currentSlideIndex} = this.state;
      this.setState({ deleteTrigger: false });   
      let slides =  ResourcesObj.resourcesMain[this.state.activePhaseIndex].resourcesChild;

      slides.splice(currentSlideIndex,1);

      ResourcesObj.resourcesMain[this.state.activePhaseIndex].resourcesChild = slides;

      this.setState({
        ResourcesObj,
        currentSlideIndex:0
      });
    }
  }

  renderEdResource(slides){
    const {  companyDetail } = this.props;
    let { activePhaseIndex,currentSlideIndex } = this.state;
    return (
      <div className="next-level-wrapp edu-slider-wrap resource-box">
        <div className={"blue-bg-box resource-bg margb35 premiumBackground premiumSecondaryText "}>
          {slides && slides.resourcesChild && (
            <Slider ref={c => (this.slider = c)}>
              {slides.resourcesChild.map((slide, index) => {
                if (index !== currentSlideIndex)
                  return;

                return (
                  <React.Fragment key={index}>
                    <p className="text-center slider-heading2"></p>
                    <div className="text-center slider-heading">
                      {slide.text1}
                    </div>
                    <div className="slider-container">
                      {
                        slide.ImageType &&slide.ImageType.includes('video') ?
                          <video className="hut-img m-0" controls
                          style ={{width: '100%'}}
                          >
                            <source src={this.mediaSourceUrl(slide)} type="video/mp4" />
                          </video>
                          :
                          <img
                            className="img-responsive"
                            src={this.mediaSourceUrl(slide)}
                            alt=""
                          />
                      }
                    </div>
                    <div className="text-center slider-2-content">
                      {this.renderText(slides, companyDetail, index)}
                    </div>
                    {
                    <div className="numbering-box">
                      <div className="number-content" key={index}>
                        {index + 1} / {slides.resourcesChild.length}
                      </div>                     
                    </div>
              }
                  </React.Fragment>
                );
              })}
            </Slider>
          )}
        </div>
      </div>
    );
  }

  mediaSourceUrl(slide){
    if(slide.childImg && slide.childImg.includes('blob')){
      return slide.childImg;
    } else if(slide.s3Path){
      return `https://${envConfig.envConfig.s3.BUCKET}.s3.amazonaws.com/public/resourceMedia/${slide.s3Path}`
    } else {
      return require("../../../../Assets/Images/Resources/"+slide.childImg);
    }
  }

  renderText = (slides, companyDetail, index) => {
    const slideIndex = index + 1;
    if (
      slides.mainText === 'SOLAR JOURNEY' &&
      slideIndex !== 1 &&
      companyDetail
    ) {
      if (slides.resourcesChild[slideIndex - 1].text1 === 'Phase 1: Design') {
        let text = companyDetail['phaseGlance'].DesignPhaseCutOff
          ? slides.resourcesChild[1].text2.replace(
              '#X',
              companyDetail['phaseGlance'].DesignPhaseCutOff
            )
          : slides.resourcesChild[1].text2.replace('#X', 'some');
        return text;
      }
      if (
        slides.resourcesChild[slideIndex - 1].text1 === 'Phase 2: Permitting'
      ) {
        let text =
        companyDetail['phaseGlance'].PermittingPhaseCutOff !== null
            ? slides.resourcesChild[2].text2
                .replace('#X', companyDetail['phaseGlance'].PermittingPhaseCutOff)
                .replace('#SOLAR COMPANY', companyDetail['solarGlance'].companyName)
            : slides.resourcesChild[2].text2
                .replace('#X', 'some')
                .replace('#SOLAR COMPANY', companyDetail['solarGlance'].companyName);
        return text;
      }
      if (
        slides.resourcesChild[slideIndex - 1].text1 === 'Phase 3: The Build'
      ) {
        let text =
        companyDetail['phaseGlance'].BuildPhaseCutOff !== null
            ? slides.resourcesChild[3].text2.replace(
                '#X',
                companyDetail['phaseGlance'].BuildPhaseCutOff
              )
            : slides.resourcesChild[3].text2.replace('#X', 'some');
        return text;
      }
      if (
        slides.resourcesChild[slideIndex - 1].text1 === 'Phase 4: Inspection'
      ) {
        let text =
        companyDetail['phaseGlance'].InspectionPhaseCutOff !== null
            ? slides.resourcesChild[4].text2.replace(
                '#X',
                companyDetail['phaseGlance'].InspectionPhaseCutOff
              )
            : slides.resourcesChild[4].text2.replace('#X', 'some');
        return text;
      } else {
        return slides.resourcesChild[5].text2.replace(
          '#SOLAR COMPANY',
          companyDetail['solarGlance'].companyName
        );
      }
    } else {
      return slides.resourcesChild[slideIndex - 1].text2;
    }
  };

  renderEdResourceForm(slides){
    return(
      <>
        <label
          style={{
            'lineHeight' : '21px',
            'color'       : '#4a4a4a',
            'fontWeight' : '700',
            'fontSize'   : '18px'
          }}
        >Headline</label>
        <TextArea
          rows={5}
          value={slides.resourcesChild[this.state.currentSlideIndex].text1}
          onChange={e => {
            slides.resourcesChild[this.state.currentSlideIndex].text1 = e.target.value;
            this.handleChangeTextArea(slides,this.state.activePhaseIndex);
          }}
        />

        <div className="upload-file-wrap mt-4 mb-4">
          <label htmlFor="file-upload" className="custom-file-upload">
            <img src={addCloudIcon} />
            <span style={{ textAlign: 'center' }}>Click here to upload an image or video.</span>
          </label>
          <input id="file-upload"
            type="file"
            name="Media"
            accept="video/*,image/*"
            onChange={(event) => {

              if (event.target.files && event.target.files[0]) {
                let { edResourceImage } = this.state;
                let extension = event.target.files[0].name.split('.')[event.target.files[0].name.split('.').length - 1];
                let s3Path = `${this.slug}/${slides.mainImg}/${new Date().getTime()}.${extension}`;
                event.target.files[0]['s3Path'] = s3Path;
                edResourceImage[s3Path] = event.target.files[0];
                this.setState(edResourceImage);

                slides.resourcesChild[this.state.currentSlideIndex].childImg = URL.createObjectURL(event.target.files[0]);
                slides.resourcesChild[this.state.currentSlideIndex].s3Path = s3Path;
                slides.resourcesChild[this.state.currentSlideIndex].ImageType = event.target.files[0].type
                this.handleChangeTextArea(slides, this.state.activePhaseIndex);
              }
            }}
          />
        </div>
        <label
         style={{
          'lineHeight' : '21px',
          'color'       : '#4a4a4a',
          'fontWeight' : '700',
          'fontSize'   : '18px'
        }}
        >Detail</label>
        <TextArea
          rows={5}
          value={slides.resourcesChild[this.state.currentSlideIndex].text2}
          onChange={e => {
            slides.resourcesChild[this.state.currentSlideIndex].text2 = e.target.value;
            this.handleChangeTextArea(slides,this.state.activePhaseIndex);
          }}
        />
      </>
    )
  }

  render() {
    let {ResourcesObj,s3UploadProgress,currentSlideIndex,activePhaseIndex} = this.state;
    let {companyData:{companyDetail}} = this.props;

    let loader =  (s3UploadProgress!==null ? true : false);
    let antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
    let progressBar = <>
      <div className="campaign-file-upload">
        <div className="text"> Saving your changes…</div>
        <Progress type="circle"
          percent={(s3UploadProgress)} width={100}
        />
      </div>
    </>

    let slides =  ResourcesObj.resourcesMain[this.state.activePhaseIndex];

    this.state.currentSlideIndex === 0 && this.slider && this.slider.slickGoTo(0);

    return (
      <>
        <Spin
          spinning={loader}
          indicator={s3UploadProgress !== null ? progressBar : antIcon}
          style={{ color: "#00142D" }}
        >
          {
            this.state.deleteTrigger &&  this.renderDeletePopUp()
          }
          <div className="content-box box-Shadow">
            <div className='mob-box' style={{backgroundColor:"white"}} > Manage the content your customer will see in their Resources tab.</div>
            <Form onFinish={this.handleSubmit}>
              <div className="row v-h-center detail-info-box">
                <ul className="nav nav-tabs">
                  {
                    this.edResouceOption.map((ele, index) => {
                    return (<li className="nav-item border-right" key={index}>
                     <a className={"nav-link " + (this.state.activePhaseIndex === index ? 'active' : '')} onClick={
                       () => { this.setState({ currentSlideIndex: 0, activePhaseIndex: index }) }
                     }>{ele}</a>
                   </li>)
                  })
                }
                 
                  {
                    companyDetail && !companyDetail.isProjectTrack &&
                    <li className="nav-item">
                      <a className={"nav-link " + (this.state.activePhaseIndex === 4 ? 'active' : '')} onClick={
                        () => { this.setState({ currentSlideIndex: 0, activePhaseIndex: 4 }) }
                      }>Your monitoring system</a>
                    </li>
                  }

                </ul>

                <div className="edOption-container mt-3">
                  {slides.resourcesChild.map((ele, index) => {
                    return (
                      <div
                        key={index}
                        className={`slide-number ${index===currentSlideIndex ? 'active' : ''} `}
                        onClick={()=>{this.setState({currentSlideIndex:index})}}
                      >
                        Slide {index + 1}
                        {
                          currentSlideIndex === index && activePhaseIndex !== 0 &&
                          <img src={deleteIcon}
                            className="delete-icon"
                            style={{
                              position: 'relative',
                              height: '14px',
                              float: 'right',
                              cursor: 'pointer',
                              marginTop: '0px',
                              marginLeft :'5px',
                              display: 'inline-block'
                            }}
                            onClick={() => this.setState({ deleteTrigger: true })}
                          />
                        }
                      </div>
                    )
                  })}
                </div>

                <div className="col-md-5">
                  {
                    slides.resourcesChild[0] && this.renderEdResourceForm(slides)
                  }
                </div>
                <div className="col-md-3  mt-5">
                  {
                    slides.resourcesChild[0] && this.renderEdResource(slides)
                  }
                </div>

              </div>

              <div className="row">
                <div className="text-center margt40 margin-b-20 width-100 btn-mobiel comp">
                  <button
                    className="btn btn-secondary"
                    onClick={() => this.cancelHandler()}
                    type="button"
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary">
                    Save
                  </button>
                </div>
              </div>

            </Form>
          </div>
        </Spin>
      </>
    );
  }
}

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    updateJourney :state.companyBodhiJourney && state.companyBodhiJourney.updateBodhiJourneyReducer
        && state.companyBodhiJourney.updateBodhiJourneyReducer,
    resourceData : state.companyBodhiJourney && state.companyBodhiJourney.bodhiJourneyReducer
    && state.companyBodhiJourney.bodhiJourneyReducer.data&&
    state.companyBodhiJourney.bodhiJourneyReducer.data[0]
  };
};


const mapDispatchToProps = dispatch =>
  bindActionCreators({ 
    receiveCompanyJourney,
    requestCompanyJourney,
    receiveUpdateCompanyJourney,
    addCompanyJourney,
    setLoader 
  }, dispatch);

export default connect(
  mapStatetoProps,
  mapDispatchToProps
)(EdResourceForm);
