// import npm packages
import { combineReducers } from "redux";

// import local files
import {
	RECEIVE_LOGGED_IN_HOMEOWNER,
	RECEIVE_HOMEOWNER_DATA,
	RECEIVE_HOMEOWNER_ENERGY_REPORT,
	RECEIVE_HOMEOWNER_PAST_ENERGY_REPORT,
	RECEIVE_HOMEOWNER_SPECIAL_CARDDATA,
	RECEIVE_UPDATE_HOMEOWNER,
	RECEIVE_DEVICE_MAGIC
} from "../../types/homeowner";

const homeownerLoggedInUserReducer = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_LOGGED_IN_HOMEOWNER:
			return data;
		case RECEIVE_HOMEOWNER_DATA:
		state.data.homeownerData = data.data;
		 	return state;
		default:
			return state;
	}
};

const homeownerEnergyReportReducer = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_HOMEOWNER_ENERGY_REPORT:
			return data;
		default:
			return state;
	}
};

const homeownerPastEnergyReportReducer = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_HOMEOWNER_PAST_ENERGY_REPORT:
			return data;
		default:
			return state;
	}
};

const homeownerSpecialCardDataReducer = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_HOMEOWNER_SPECIAL_CARDDATA:
			return data;
		default:
			return state;
	}
};


const updateHomeownerReducer = (state = {}, { type, data }) => {
	switch (type) {
		case RECEIVE_UPDATE_HOMEOWNER:
			return data;
		default:
			return state;
	}
};

const getHomeownerDeviceMagicReducer = (state = [], { type, data }) => {
	switch (type) {
		case RECEIVE_DEVICE_MAGIC:
			return data;
		default:
			return state;
	}
};

export const reducer = combineReducers({
	homeownerLoggedInUserReducer,
	homeownerEnergyReportReducer,
	homeownerSpecialCardDataReducer,
	getHomeownerDeviceMagicReducer,
	homeownerPastEnergyReportReducer,
	updateHomeownerReducer
});