import React, { Component }         from 'react';
import { connect }                  from 'react-redux';
import { bindActionCreators }       from 'redux';
import withRouter                   from '../../../../shared/withRouter';
import DelayedProjects              from "../../components/dashboard/delayedProjects";
import TrackReferrals               from "../../components/dashboard/trackReferrals";
import SurveyResults                from "../../components/dashboard/surveyResults";
import SocialShares                 from "../../components/dashboard/socialShares";

class companyDashboardMetrics extends Component {
    constructor(props) {
        super(props);
        this.companyId = this.props.router.params.companyId;
        this.state = {
            collapsed: true,
        };
    }

    componentWillMount() {
        const data = {
            companyId: this.companyId
        }
        if (data.companyId) {
            this.props.requestSurveyData(data);
            this.props.requestSocialShareData(data);;
            this.props.requestDelayedProjectData(data);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps && nextProps.companyData && nextProps.companyData.companyDetail && nextProps.companyData.companyDetail.slug) {
            this.slug = nextProps.companyData.companyDetail.slug;
        }
    }
    
    render() {
        const data = [
            { a: 1, b: 3 },
            { a: 2, b: 6 },
            { a: 3, b: 2 },
            { a: 4, b: 12 },
            { a: 5, b: 8 }
        ];
        const width = 700, height = 350;
        return (<>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-5 margb20-desktop">
                            <DelayedProjects {...this.props} />
                        </div>
                        <div className="col-sm-12 col-md-7 margb20-desktop">
                            <TrackReferrals data={data} width={width} height={height} margin={20} {...this.props} />
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container paddb70">
                    <div className="row">
                        <div className="col-sm-12 col-md-5">
                            <SurveyResults
                                downloadSurveyData={this.props.dashboardData.downloadSurveyDataReducer || {}}
                                getSurveyData={this.props.dashboardData.getSurveyDataReducer || {}}
                                receiveDownloadSurveyData={this.props.receiveDownloadSurveyData}
                                requestDownloadSurveyData={this.props.requestDownloadSurveyData}
                                {...this.props}
                            />
                        </div>
                        <div className="col-sm-12 col-md-7 margb30-mobile installer-block-top-marg-mobile ">
                            <SocialShares
                                requestDownloadSocialShareData={this.props.requestDownloadSocialShareData}
                                receiveDownloadSocialShareData={this.props.receiveDownloadSocialShareData}
                                socialShareData={this.props.dashboardData.socialShareDataReducer || {}}
                                downloadSocialShareData={this.props.dashboardData.downloadSocialShareDataReducer || {}}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </>)
    }
}


const mapStatetoProps = state => {
    return {

    };
};


const mapDispatchToProps = dispatch =>
    bindActionCreators({
    }, dispatch);


export default withRouter(connect(
    mapStatetoProps,
    mapDispatchToProps
)(companyDashboardMetrics));