// referral
export const RECEIVE_REFERRALS_COUNT = 'RECEIVE_REFERRALS_COUNT'
export const REQUEST_REFERRALS_COUNT = 'REQUEST_REFERRALS_COUNT'

export const REQUEST_DOWNLOAD_REFERRAL = 'REQUEST_DOWNLOAD_REFERRAL'
export const RECEIVE_DOWNLOAD_REFERRAL = 'RECEIVE_DOWNLOAD_REFERRAL'

export const REQUEST_REFERRAL_DATA_CHART = 'REQUEST_REFERRAL_DATA_CHART'
export const RECEIVE_REFERRAL_DATA_CHART = 'RECEIVE_REFERRAL_DATA_CHART'


// survey
export const REQUEST_SURVEY_COUNT = 'REQUEST_SURVEY_COUNT'
export const RECEIVE_SURVEY_COUNT = 'RECEIVE_SURVEY_COUNT'

export const REQUEST_SURVEY_DATA = 'REQUEST_SURVEY_DATA'
export const RECEIVE_SURVEY_DATA = 'RECEIVE_SURVEY_DATA'

export const REQUEST_DOWNLOAD_SURVEY = 'REQUEST_DOWNLOAD_SURVEY'
export const RECEIVE_DOWNLOAD_SURVEY = 'RECEIVE_DOWNLOAD_SURVEY'


// social share
export const REQUEST_SOCIAL_SHARES_COUNT = 'REQUEST_SOCIAL_SHARES_COUNT'
export const RECEIVE_SOCIAL_SHARES_COUNT = 'RECEIVE_SOCIAL_SHARES_COUNT'

export const REQUEST_SOCIAL_SHARES_DATA = 'REQUEST_SOCIAL_SHARES_DATA'
export const RECEIVE_SOCIAL_SHARES_DATA = 'RECEIVE_SOCIAL_SHARES_DATA'

export const REQUEST_DOWNLOAD_SOCIAL_SHARE = 'REQUEST_DOWNLOAD_SOCIAL_SHARE'
export const RECEIVE_DOWNLOAD_SOCIAL_SHARE = 'RECEIVE_DOWNLOAD_SOCIAL_SHARE'


// active account
export const REQUEST_ACTIVE_ACCOUNT_COUNT = 'REQUEST_ACTIVE_ACCOUNT_COUNT'
export const RECEIVE_ACTIVE_ACCOUNT_COUNT = 'RECEIVE_ACTIVE_ACCOUNT_COUNT'


// delayed project
export const REQUEST_DELAYED_PROJECT_DATA = 'REQUEST_DELAYED_PROJECT_DATA'
export const RECEIVE_DELAYED_PROJECT_DATA = 'RECEIVE_DELAYED_PROJECT_DATA'

export const SET_METRICS_TYPE = 'SET_METRICS_TYPE'

// referral
export const receiveReferralCount = data => ({
  type: RECEIVE_REFERRALS_COUNT, data
})
export const requestReferralCount = data => (
  {
    type: REQUEST_REFERRALS_COUNT, data
  }
)

export const receiveReferralDataForChart = data => ({
  type: RECEIVE_REFERRAL_DATA_CHART, data
})
export const requestReferralDataForChart = data => (
  {
    type: REQUEST_REFERRAL_DATA_CHART, data
  }
)

export const receiveDownloadReferralData = data => ({
  type: RECEIVE_DOWNLOAD_REFERRAL, data
})
export const requestDownloadReferralData = data => (
  {
    type: REQUEST_DOWNLOAD_REFERRAL, data
  }
)



// survey
export const receiveSurveyData = data => ({
  type: RECEIVE_SURVEY_DATA, data
})

export const requestSurveyData = data => (
  {
    type: REQUEST_SURVEY_DATA, data
  }
)

export const receiveSurveyCount = data => ({
  type: RECEIVE_SURVEY_COUNT, data
})
export const requestSurveyCount = data => (
  {
    type: REQUEST_SURVEY_COUNT, data
  }
)

export const receiveDownloadSurveyData = data => ({
  type: RECEIVE_DOWNLOAD_SURVEY, data
})
export const requestDownloadSurveyData = data => (
  {
    type: REQUEST_DOWNLOAD_SURVEY, data
  }
)



// social share
export const receiveSocialShareCount = data => ({
  type: RECEIVE_SOCIAL_SHARES_COUNT, data
})
export const requestSocialShareCount = data => (
  {
    type: REQUEST_SOCIAL_SHARES_COUNT, data
})

export const receiveSocialShareData = data => ({
  type: RECEIVE_SOCIAL_SHARES_DATA, data
})
export const requestSocialShareData = data => (
  {
    type: REQUEST_SOCIAL_SHARES_DATA, data
})

export const receiveDownloadSocialShareData = data => ({
  type: RECEIVE_DOWNLOAD_SOCIAL_SHARE, data
})
export const requestDownloadSocialShareData = data => (
  {
    type: REQUEST_DOWNLOAD_SOCIAL_SHARE, data
  }
)



// active account
export const receiveActiveAccountCount = data => ({
  type: RECEIVE_ACTIVE_ACCOUNT_COUNT, data
})
export const requestActiveAccountCount = data => (
  {
    type: REQUEST_ACTIVE_ACCOUNT_COUNT, data
  }
)


// delayed project
export const receiveDelayedProjectData = data => ({
  type: RECEIVE_DELAYED_PROJECT_DATA, data
})

export const requestDelayedProjectData = data => (
  {
    type: REQUEST_DELAYED_PROJECT_DATA, data
  }
)

export const setMetricsType = data =>({
  type: SET_METRICS_TYPE, data
})