import { Services } from "../../../Api/services";
import * as config from "../../../shared/config";

export const createEversignDocument = async data => {
	const url = `${config.EVERSIGN_POST_URL}company/${
		data.companyId
	}/signature/${ data.homeownerId }`;

	const bodyData = {
		"name": data.name,
        "url": data.url,
        "homeownerId": data.homeownerId,
        "homeownerName": data.homeownerName,
        "homeownerEmail": data.homeownerEmail,
        "installerId": data.installerId,
        "createdAt": data.createdAt,
		"baseUrl": data.baseUrl,
		"signer" : data.signer,
		"recipients" : data.recipients,
		"signature" : data.signature,
		"message" : data.eversignEmailMessage,
		"homeownerIndex" : data.homeownerIndex,
		"token": data.token
		};

	let res = await Services.postCall(url, bodyData, false);
	return { data: res };
};

export const getSavedEversignData = async data => {
	let  url = `${config.EVERSIGN_POST_URL}company/${
		data.companyId
	}/signature/${ data.homeownerId }`;
	if(data.role==="homeowner"){
		url += "?role=homeowner"
	}
	let res = await Services.getCall(url, false);
	return { data: res.data };
};