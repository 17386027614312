import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css'
import 'font-awesome/css/font-awesome.css';
import App from './App';
import * as ServiceWorker from "./serviceWorker";

ReactDOM.render(
      <App />,
  document.getElementById('root')
)

ServiceWorker.register({
  onUpdate: registration => {
    const waitingServiceWorker = registration.waiting
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", event => {
        if (event.target.state === "activated") {
          window.location.reload()
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  }
});