export const getToken = () => localStorage.getItem('token');
export const setToken = (token) => localStorage.setItem('token', token);

export const setHeader = (token) => ({
  headers: {
    'Content-type': 'application/json',
    Authorization: token ? token : 'Bearer ' + getToken()
  }
});

export const ROLE = {
  ADMIN: 'ADMIN',
  INSTALLER: 'INSTALLER',
  HOMEOWNER: 'HOMEOWNER'
};
