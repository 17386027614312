//import npm packages
import axios from 'axios';

//import local files
import googleDrive from '../environments/index';

const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];
const SCOPES = 'https://www.googleapis.com/auth/drive';
const gapi = window.gapi;

class GoogleDriveSync {
  constructor() {
    this.result = [];
    this.flag = {}
  }

  handleClientLoad(cb) {
    return new Promise((resolve, reject) => {
      if (cb) {
        return;
      }
      gapi.load('client:auth2', () => {
        gapi.client.init({
          apiKey: googleDrive.envConfig.googleDrive.API_KEY,
          clientId: googleDrive.envConfig.googleDrive.CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES
        }).then((p) => {
          resolve(p)
        })
          .catch(err => {
            reject(err)
          })
      });
    }).then(() => {
      return this.updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get())
    })
  }

  updateSigninStatus(isSignedIn) {
    if (isSignedIn) {
      return this.folderList();
    } else {
      return this.handleAuthClick().then((r) => {
        if (r)
          return this.folderList();
        else {
          return false
        }
      })
    }
  }

  handleAuthClick(event) {
    return new Promise((res, rej) => {
      res(gapi.auth2.getAuthInstance().signIn());
    }).catch(error => {
      return false
    })

  }

  handleSignoutClick() {
    gapi.auth2 && gapi.auth2.getAuthInstance().signOut();
  }

  fetchSharedDrive() {
    return new Promise((res, rej) => {
      gapi.client.drive.drives.list({
        'useDomainAdminAccess': false
      }).then((response) => {
        if (response && response.result && response.result.drives && response.result.drives.length > 0) {
          res(response.result.drives)
        }
        else
          res([])
      }).catch(() => {
        res([]);
      })
    })
  }

  async folderList(folderId) {
    let query;
    let sharedDriveResult = [];
    if (!folderId) {
      query = `(mimeType="application/vnd.google-apps.folder" and "root" in parents) or (mimeType="application/vnd.google-apps.folder" and sharedWithMe= true)`;
      sharedDriveResult = await this.fetchSharedDrive();
    }
    else {
      query = `(mimeType="application/vnd.google-apps.folder" and "${folderId}" in parents)`;
    }
    return gapi.client.drive.files.list({
      'corpora':'user',
      'supportsAllDrives': true,
      'includeItemsFromAllDrives':true,
      'pageSize': 900,
      'fields': "nextPageToken, files(id, webContentLink,webViewLink,name,mimeType,parents,trashed)",
      'q': query
    }).then((response) => {
      let myDrive = response.result.files.filter(x => !x.trashed);
      let folder = sharedDriveResult && myDrive.concat(sharedDriveResult);
      let folderIdArray = []
      if (folder.length) {
        folder.forEach((file, index) => {
          if (!file.trashed)
            folderIdArray.push(file.id)
        });
        return { folder, folderId, folderIdArray }
      } else {
        return { folder: [], folderId: [], folderIdArray: [] };
      }
    });
  }

  getFiles = (type, folder, filter, parentFolder) => {
    let processFolder = [];
    if (folder && folder.length) {
      folder.forEach((file, index) => {
        if (file.webContentLink) {
          if (!this.result[file.parents[0]]) {
            this.result[file.parents[0]] = []
          }
          const index = this.result[file.parents[0]].findIndex(val => val.id === file.id);
          if (index === -1 || this.result[file.parents[0]].length === 0) {
            if (filter) {
              let filterArray = filter.split(',');
              filterArray.forEach(ele => {
                if (file.name.toLowerCase().includes(ele.toLowerCase())) {
                  const index = this.result[file.parents[0]].findIndex(elem => elem.id === file.id);
                  if (index === -1) {
                    Object.keys(this.flag).forEach(ele => {

                      if (file.name && file.name.toLowerCase().replace(/ /g, '').includes(ele)) {
                        this.flag[ele] = true;
                      }
                    })
                    this.result[file.parents[0]].push({
                      name      : file.name,
                      id        : file.id,
                      url       : file.webViewLink,
                      previewUrl: file.webContentLink,
                      mimeType  : file.mimeType,
                      driveId   : file.driveId
                    })
                  }
                }
              });
            } else {
              Object.keys(this.flag).forEach(ele => {
                if (file.name && file.name.toLowerCase().replace(/ /g, '').includes(ele)) {
                  this.flag[ele] = true;
                }
              })
              this.result[file.parents[0]].push({
                name      : file.name,
                id        : file.id,
                url       : file.webViewLink,
                previewUrl: file.webContentLink,
                mimeType  : file.mimeType,
                driveId   : file.driveId
              })
            }
          }
        }
        else if (file.mimeType === "application/vnd.google-apps.folder") {

          processFolder.push(file)
          if (!this.result[file.id])
            this.result[file.id] = [file.name];
        }
      });

      let processParentFolder = parentFolder.concat(processFolder)
      return this[type](null, filter, processParentFolder);
    } else {
      if (parentFolder && parentFolder.length > 0) {
        return this[type](null, filter, parentFolder);
      }
      else {
        return this.responseFile(this.result, this.flag);
      }
    }
  }

  listFiles(folderId, filter, parentFolder, callback) {
    if(folderId)
    {
      this.flag = {
        contract  : false,
        agreement : false,
        proposal  :false
      }
    }
    if (folderId || (parentFolder && parentFolder.length > 0)) {
      if (!parentFolder) {
        this.result = {};
        parentFolder = [];
      } else {
        folderId = parentFolder[0]['id'];
        parentFolder.splice(0, 1);
      }
      return gapi.client.drive.files.list({
        'corpora':'user',
        'supportsAllDrives': true,
        'includeItemsFromAllDrives':true,
        'pageSize': 100,
        'fields': "nextPageToken, files(driveId,id, webContentLink,webViewLink,name,mimeType,parents,trashed)",
        'q': `"${folderId}" in parents`
      }).then((response) => {
        let files = response.result.files.filter(x => !x.trashed)
        return this.getFiles('listFiles', files, filter, parentFolder)
      });
    } else {
      return this.responseFile(this.result, this.flag);
    }
  }

  makeFolderPublic =(folderId,isSharedDrive,files) => {
    let taskArray = [];
    if(isSharedDrive){
      for (var j in files) {
        files[j].forEach(ele => {
          if (ele.id){
            taskArray.push(this.readPermission(ele.id));
          }
        })
      }
    }
    else {
      taskArray.push(this.readPermission(folderId));
    }
    return Promise.all(taskArray).then(res=>{
      return (res);
    })
  }

  readPermission = (folderId) =>{
    return new Promise((res, rej) => {
      gapi.client.drive.permissions.create({
        fileId: folderId,
        "supportsAllDrives": true,
        "resource": {
          "type": "anyone",
          "role": "reader"
        }
      }).then((response) => {
        res([]);
        // Handle the results here (response.result has the parsed body).
      }).catch(err => {
        res([]);
      });
    })
  }

  editListFiles(folderId, filter, parentFolder) {
    if (folderId || (parentFolder && parentFolder.length > 0)) {
      if (!parentFolder) {
        this.result = {};
        parentFolder = [];
      } else {
        folderId = parentFolder[0]['id'];
        parentFolder.splice(0, 1);
      }
      const url = `https://www.googleapis.com/drive/v3/files?q='${folderId}'+in+parents&key=${googleDrive.envConfig.googleDrive.API_KEY}&fields=nextPageToken%2C%20files(id%2C%20webContentLink%2CwebViewLink%2Cname%2CmimeType%2Cparents%2Ctrashed)&includeItemsFromAllDrives=true&supportsAllDrives=true`;
      return axios.get(url).then((response) => {
        let files = response.data.files.filter(x => !x.trashed);
        return this.getFiles('editListFiles', files, filter, parentFolder)
      }).catch((error) => {
        console.log(error);
      });
    }
    else {
      return this.responseFile(this.result, this.flag);
    }
  }

  responseFile(result, flag) {
    let missingFile = JSON.parse(JSON.stringify(flag));
    let isSharedDrive = false;
    for (var i in missingFile) {

      if(flag[i]){
        delete missingFile[i];
      }
      if (i === 'layout' && flag[i]) {
        delete missingFile['siteplan'];
      }
      if (i === 'siteplan' && flag[i]) {
        delete missingFile['layout'];
      }
      if (i === 'contract' && flag[i]) {
        delete missingFile['agreement'];
        delete missingFile['proposal'];
      }
      if (i === 'agreement' && flag[i]) {
        delete missingFile['contract'];
        delete missingFile['proposal'];
      }
      if (i === 'proposal' && flag[i]) {
        delete missingFile['contract'];
        delete missingFile['agreement'];
      }
    }

    for(var j in result){
      if(result[j].length<2 && (typeof(result[j][0]) != "object")){
        delete result[j];
      }else if(!isSharedDrive){
        let temp = (result[j][1] && result[j][1].driveId) || (result[j][0]&&result[j][0].driveId);
        if(temp){
          isSharedDrive = true;
        }
      }
    }
    let r = {
      fileList: result,
      missingFile: Object.keys(missingFile),
      isSharedDrive 
    }
    return r;
  }
}

export default GoogleDriveSync;