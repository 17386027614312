import { connect } from 'react-redux'
import { bindActionCreators } from "redux";
import { reducer } from 'aws-cognito-redux-saga'
import SignInComponent from './SignIn'
import { requestSlugData, requestSlugVerification } from './../../data/action'
import { setLoader } from '../../../Common/Loader/action';
import {requestPostForgotPassword} from './../../../homeowner/actions/forgetPasswordAction';
import {setMetricsType} from "../../../Installer/actions/dashboard"

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    image: state.user.slugImage,
    loader: state.loader.loader.loader,
    slugName: state.user.slugVerification[state.user.slugVerification.length - 1]

  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    requestSlugData,
    requestSlugVerification,
    setLoader,
    setMetricsType,
    requestPostForgotPassword,
    init: () => dispatch(reducer.init()),
    signIn: (username, password, code) =>
      dispatch(reducer.signIn(username, password, code))
  }, dispatch)

)

export default connect(mapStatetoProps, mapDispatchToProps)(SignInComponent)
