import React, { Component } from 'react'
import personHeartImg from './../../../../Assets/Images/person_heart.svg'
import shareReferralsImg from './../../../../Assets/Images/share_referrals.svg'
import surveysIcon from './../../../../Assets/Images/surveys.png'
import fingerTouchIcon from './../../../../Assets/Images/finger_touch.png'

export default class DashboardStats extends Component {
  render() {
    const {companyData} = this.props;
    if(companyData)
      var {stats} = companyData
     return (
      <section>
        <div className='container margin-t-73'>
          <div className='row justify-content-md-center margb70 content-center'>
            <div className='col-10 '>
              <p className='mobile-heading-dashboard'>
                PROJECT MANAGER DASHBOARD
              </p>
            </div>
            <div className='col-10 text-center col-md-3 col-sm-6'>
              <div className='acitve-account-rating rating-box'>
                <div className='image-icon'>
                  {" "}
                  <img src={personHeartImg} alt='17terawatts'  />
                </div>
                <div className="stats">
                  {stats && stats.companyCount}
                </div>
              </div>
              <div className='heading'>
              Companies
              </div>
            </div>
            <div className='col-10 text-center col-md-3 col-sm-6'>
              <div className='referral-rating rating-box'>
                <div className='image-icon'>
                  {" "}
                  <img src={shareReferralsImg} alt='17terawatts' />
                </div>
                <div className="stats">
                  {stats && stats.activeCount}
                </div>
              </div>
              <div className='heading'>
              Homeowners
              </div>
            </div>
            <div className='col-10 text-center col-md-3 col-sm-6'>
              <div className='survey-rating rating-box'>
                <div className='image-icon'>
                  <img src={surveysIcon} alt='17terawatts' />
                </div>
                <div className="stats">
                  {(stats && stats.survey)|| 0}
                </div>
              </div>
              <div className='heading'>
              Surveys
              </div>
            </div>
            <div className='col-10 text-center col-md-3 col-sm-6'>
              <div className='social-share-rating rating-box'>
                <div className='image-icon'>
                  <img src={fingerTouchIcon} alt='17terawatts' />
                </div>
                <div className="stats">
                  {stats && stats.referrals}
                </div>
              </div>
              <div className='heading'>
              Referrals
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}