// import npm packages
import React, { Component } from 'react';
import { Form, Input,Slider } from 'antd';
import { connect }    from 'react-redux';

// import local files
import withRouter from '../../../../../shared/withRouter';
import { currencyFormater } from '../../../../../shared/currencyFormater';
import { MONTH_ARRAY }      from '../../../../../shared/config';
import {checkIsMobile}      from '../../../../../shared/checkIsMobile';
import { capitalize }       from '../../../../../shared/capitalize';
import GoogleDriveSync      from '../../../../../shared/GoogleDriveSync';
import ErrorPage            from '../../../../Common/ErrorPage';
import { getLoadingIcon }   from "../../../../User/components/setUserBackgroud";
import LandingPage          from '../../../../Common/LandingPage';
import defaultImage         from '../../../../../Assets/Images/thanks-solar.jpg';
import { openNotificationWithIcon }     from '../../../../../../src/shared/notification';
import {ReactComponent as ElectricIcon} from "../../../../../Assets/Images/electric-icon.svg";
import {ReactComponent as SolarIcon}    from "../../../../../Assets/Images/solar-icon.svg";
import {ReactComponent as SunIcon}      from "../../../../../Assets/Images/sun-icon.svg";

const FormItem = Form.Item;

class SystemGlanceLandingForm extends Component {
  constructor(props) {
    super(props);
    this.GoogleDriveSync = new GoogleDriveSync();
    this.state = {
      errorObj: {},
      address: '',
      sliderStatus: 3000,
      utilityRate: null,
      isDriveAPI: false,
      isInvalid:false,
    };
    this.marks = {
      500: '500 sqft',
      1800: '1800',
      3000: '3000 sqft',
      4000: '4000',
      5000: {
          label: <strong className="premiumLinks">5000+</strong>,
      },
  };
  }

  componentWillReceiveProps(nextProps) {
     let urlParam    = nextProps.router.params;
     let data = nextProps.referralDetails.postHomeownerReferrals.data;

    if (data) {
      this.props.receivePostReferralDetails('');
    }
    let temp = nextProps.referralDetails
      && nextProps.referralDetails.postHomeownerAddress
      && nextProps.referralDetails.postHomeownerAddress.data
      && nextProps.referralDetails.postHomeownerAddress.data.data;
      if (temp && (!temp.lat || !temp.lng) && !this.state.isInvalid) {
        nextProps.setLoader({ loader: false });
        openNotificationWithIcon('error', 'Please enter valid address', 'Failed');
        this.setState({isInvalid:true})
      } else if (temp && temp.utilityRate && temp.lat) {
      let urlParam = this.props.router.params
      nextProps.setLoader({ loader: false });
      this.props.router.navigate(`/${urlParam.slug}/referral/${urlParam.referralId}/${urlParam.share}/${urlParam.method}/calculate`);
    }

  }

  calculateGlacier(value) {
    value = value*1;
    let glacier = (value * 1.6* 32)/2000;
    let calc = glacier.toFixed(1);
    if (calc>0.9) {
      return ` has preserved ${calc} sq ft of glaciers,`
    } 
    else{
      return ''
    }
  }

  render2Form(postHomeownerAddressForm) {
    return(<>
      <div className="enter-your-ad glance rating">What is the size of your home?</div>
      <FormItem
        hasFeedback
        name="rating"
      >
        <div>
          <Slider marks={this.marks}
            value={postHomeownerAddressForm.slider ? postHomeownerAddressForm.slider : this.state.sliderStatus}
            onChange={val => { this.handleSlider(val) }}
            min={500} max={5000}
            className="slider-mark premiumLinks" />
        </div>
      </FormItem>
    </>)
  }

  render1Form(postHomeownerAddressForm) {
    return(
      <>
        <div className="enter-your-ad glance">Please enter your address</div>
        <FormItem
          hasFeedback
          name="address"
          rules = { [ {
              required: true
            }
          ] }
          initialValue = { postHomeownerAddressForm.add }
        >
          <Input
            type="text"
            className="form-control"
            onChange={(e)=>{
              this.setState({"address":e.target.value})
            }}
            placeholder="Street Address, City, State, Zip Code"
          />
        </FormItem> 
      </>
    )
  }

  renderLanding() {
    const {
      referralDetails :{postHomeownerAddressForm},
      systemGlance
    } = this.props;
    let details = this.props.referralDetails.homeownerReferrals.data;
    let activationDate = null;
    let yearlyProduction = details && details.yearlyProduction;

    let avgOffset = systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.production
    && systemGlance.solarGlance.consumption;
    
    if (
      systemGlance &&
      systemGlance.solarGlance &&
      systemGlance.solarGlance.phaseName === '6'
    ) {
      if (
        systemGlance &&
        systemGlance.solarGlance &&
        systemGlance.solarGlance.activationDate
      ) {
        if (typeof systemGlance.solarGlance.activationDate === 'string')
          activationDate = Number(systemGlance.solarGlance.activationDate);
        else activationDate = systemGlance.solarGlance.activationDate;
      }
    }

    return (
      <React.Fragment>

        <div className="systemGlance-content">
          {details && (
            <h1 className="systemGlance-heading premiumBackgroundText">
              {details.firstName.toUpperCase().trim()}'S SYSTEM AT A GLANCE
            </h1>
          )}
          <div className="row text-center system-glance-box w-100">
            <div className={`col-sm-${avgOffset ? '4' : '6'} col-${avgOffset ? '4' : '6'}`}>
              <div className="icon">
                <ElectricIcon/>
              </div>
              <div className="kw">
                {systemGlance &&
                  systemGlance.solarGlance &&
                  systemGlance.solarGlance.systemSize}{' '}
                kW
              </div>
              <div className="desc">Size</div>
            </div>
            <div className="col-sm-4 col-4">
              <div className="icon">
                <SolarIcon/>
              </div>
              <div className="kw">
                {systemGlance &&
                  systemGlance.solarGlance &&
                  systemGlance.solarGlance.moduleCount}
              </div>
              <div className="desc">Panels</div>
            </div>
            {
              avgOffset !=0 && avgOffset && <div className="col-sm-4 col-4">
                <div className="icon">
                  <SunIcon/>
                </div>
                <div className="kw">
                  {systemGlance &&
                    (systemGlance.solarGlance &&
                      systemGlance.solarGlance.production &&
                      systemGlance.solarGlance.consumption &&
                      currencyFormater(
                        (systemGlance.solarGlance.production /
                          systemGlance.solarGlance.consumption) *
                        100
                      ) + '%') || '-'}

                </div>
                <div className="desc">Avg offset</div>
              </div>
            }

          </div>

          <div className="white-box padd15 text-center comapny-detail-box border-none">
            <div className="detail-box  w-100">
              <span className="pull-left">Company:</span>
              <span className="pull-right">
                <a className="premiumLinks" target='_blank' style={{ color: "#04bfff" }} href={details && details.companyUrl} >
                  {systemGlance && systemGlance.solarGlance && capitalize(systemGlance.solarGlance.companyName)}{' '}
                </a>
              </span>
            </div>

            <div className="detail-box  w-100">
              <span className="pull-left">Panels: </span>
              <span className="pull-right">
                {systemGlance &&
                  systemGlance.solarGlance &&
                  `${systemGlance.solarGlance.moduleName}
                  ${systemGlance &&
                    systemGlance.solarGlance &&
                    (systemGlance.solarGlance.moduleModel)|| ''}
                  `
                }
              </span>
            </div>

            <div className="detail-box  w-100">
              <span className="pull-left">Inverter: </span>
              <span className="pull-right">
                {systemGlance &&
                  systemGlance.solarGlance &&
                  systemGlance.solarGlance.inverterName}
              </span>
            </div>

            <div className="detail-box  w-100">
              <span className="pull-left">Monitoring: </span>
              <span className="pull-right">
                {systemGlance &&
                  systemGlance.solarGlance &&
                  systemGlance.solarGlance.meterName}
              </span>
            </div>

            {activationDate ? <div className="detail-box  w-100">
              <span className="pull-left">Activation Date: </span>
              <span className="pull-right">
                {
                  `${MONTH_ARRAY[new Date(activationDate).getMonth()]}
                                        ${new Date(activationDate).getDate()},
                                        ${new Date(
                    activationDate
                  ).getFullYear()}`
                }
              </span>
            </div> : ''}
          </div>
          <div className="for-mobile glance">
            {details && (
              <h4 className="text-left glanceShare">
                Your solar community is waiting
              </h4>
            )}
            {details && (
              <p>
                Did you know {details.firstName.trim()} {yearlyProduction ? this.calculateGlacier(yearlyProduction): ''}{" "} 
                {details.totalSaving ? `saves $${details.totalSaving} on electricity every year, and ` : ''}
                is one of many solar families in {details.firstName.trim()}'s neighborhood? Most people are amazed that they can go solar with $0 down while also saving on their electricity bill.
              </p>
            )}
            
            <Form
              onFinish={values => {
                this.handleSubmitCalculate(values);
              }}
            >
              <h6 className="explore-savings">Explore Your Savings</h6>
              <div className="form-group">
              {
                !this.props.loggedInUser.homeownerData.calculatorUrl && this.render1Form(postHomeownerAddressForm)
              }
              </div>
              <div className="form-group">
                {
                  !this.props.loggedInUser.homeownerData.calculatorUrl && this.render2Form(postHomeownerAddressForm)
                }
                <div className="text-center" style={{marginBottom:'90px'}}>
                    {
                      this.props.loggedInUser.homeownerData.calculatorUrl ?
                        <a href={this.props.loggedInUser.homeownerData.calculatorUrl} target="_blank">
                            <input type="button" className="btn yellow-btn premiumButtonFill premiumButtonText rating-slider-btn" value={`Calculate with `+(systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.companyName)} />
                        </a>                                      
                        :
                        <button
                          type="submit"
                          className="btn yellow-btn premiumButtonFill premiumButtonText rating-slider-btn" 
                        >
                          Calculate
                        </button>
                    }
                </div>
              </div>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }

  handleSubmitCalculate = values => {
    this.props.receivePostAddressFormData({
      "add": values.address,
      "slider" :this.state.sliderStatus
    });

    this.props.setLoader({ loader: true });
    this.props.requestPostAddressDetails({
      "path": values.address
    });
    this.setState({isInvalid: false});
  };

  handleSlider = (val) => {
    this.setState({
        sliderStatus: val
    })
  }

  renderScanifly = (scaniflyUrl) => {
    let isMobileView = checkIsMobile(); 
    let height = isMobileView ? '175px' : '100vh';
    let css = isMobileView ? {
      position: 'absolute',
      top: '132px'
    } : {};
    return (
      <div className="col-sm-7 col-12" style={css}>
        <div className="scanifly" style={{height}}>
          <iframe height="100%" width="100%"  frameborder="0" allowfullscreen mozallowfullscreen="true" webkitallowfullscreen="true" src={scaniflyUrl}></iframe>
          {
            isMobileView && <div className="overlay glance" onClick={((e) => {
              e.stopPropagation();
              window.open(scaniflyUrl, '_blank').focus();
            })} />
          }
        </div>
      </div>
    )
  }


  render() {
    let homeOwnerReferralData = this.props.referralDetails.homeownerReferrals.data;
    let {systemGlance} = this.props;
    let scaniflyUrl = systemGlance.solarGlance && systemGlance.solarGlance.scaniflyUrl;
    let mobileImage = (systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.icon)||require('./../../../../../Assets/Images/bodhi_small.svg');
    let image = (systemGlance && systemGlance.solarGlance && systemGlance.solarGlance.logo)|| require('./../../../../../Assets/Images/bodhi-logo-png-001@3x.png');
    if (homeOwnerReferralData) {
      return (
        <>
        <title>{homeOwnerReferralData.firstName.trim()}'s solar system is saving money every month!</title>
        <div className="referral-wrap">
          <div className="row glance">
            <div
              className="col-md-5 system-glance"
              style={{ background: '#fff' }}
            >
              <p className="systMobleTitle">{homeOwnerReferralData.firstName.toUpperCase().trim()}’s system at a glance</p>
              <img
                src={checkIsMobile() ? mobileImage : image}
                style={ image && {width: 'auto'} }
                alt=""
              />
              {this.renderLanding()}
            </div>
            {scaniflyUrl ? this.renderScanifly(scaniflyUrl) : <div className="col-md-7 systemglanceLanding-image"
              style = {{'background-image':`url(${defaultImage})`}}
            /> }
          </div>
        </div>
      </>
        );
    } else {
      return <LandingPage loadingIcon={getLoadingIcon(this.props.router.params.slug)} role={'homeowner123'} />
    }
  }
}

const mapStatetoProps = state => {
  let loggedInUser = {};
  loggedInUser['homeownerData'] =
    state.homeownerReferralDetails &&
    state.homeownerReferralDetails.homeownerReferrals &&
    state.homeownerReferralDetails.homeownerReferrals.data;
  return {
    auth: state.auth,
    loggedInUser: loggedInUser
  };
};

export default withRouter(
  connect(
    mapStatetoProps,
    null
  )(
    SystemGlanceLandingForm
  )
);
