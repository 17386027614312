// import npm packages
import React, { Component } from 'react';
import { Input, Form, Select } from 'antd';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'antd';

import { getToken } from '../../../../shared/util';
import {
  requestHomeOwner,
  requestUpdateHomeOwner,
  receiveUpdateHomeOwner
} from './../../actions/homeOwner';
import { requestInstallerData } from './../../actions/addHomeOwner';
import { setLoader } from '../../../Common/Loader/action';
import withRouter from '../../../../shared/withRouter';
import { openNotificationWithIcon } from '../../../../shared/notification';
import { capitalize } from '../../../../shared/capitalize';
import { requestEgaugePowerMeter } from './../../actions/testMeterConnection';
import { ReactComponent as CaretIcon } from '../../../../Assets/Images/path-2.svg';

const FormItem = Form.Item;
const Option = Select.Option;

class EditHomeOwnerForm extends Component {
  constructor(props) {
    super(props);
    this.slug = '';
    this.isSubmit = false;
    this.state = {
      electricutilityList: [],
      powerMeter: [],
      moduleBrandList: [],
      inverterBrandList: [],
      installerList: [],
      missingFiles: null,
      ratePlanList: [],
      selectedRatePlan: [],
      timezone: [],
      homeOwnerData: {},
      meterType: '',
      meterUrl: '',
      meterId: '',
      meterVisible: false,
      meterID: '',
      identifier: null,
      isTestMeterClicked: false,
      installerConnection: null,
      files: []
    };
  }

  componentWillMount() {
    this.props.requestAddHomeOwner({});
    this.slug = localStorage.getItem('slug');
    this.companyId = this.props.router.params.companyId;
    this.props.requestInstallerData(this.companyId);
    this.props.setLoader({ loader: true });
    this.meterUrl = {
      1: this.props.loggedInUser.solarUnit.meterUrl,
      2: 'monitoring.solaredge.com',
      3: 'api.enphaseenergy.com',
      4: 'data.sense.com',
      5: 'apsystemsema.com',
      6: 'solarweb.com'
    };
  }

  componentDidMount() {
    this.setState({
      meterUrl: this.meterUrl[this.props.loggedInUser.solarUnit.meterId]
    });
  }

  handleSubmit = (values) => {
    if (this.state.isFileMissing && this.state.missingFiles) {
      openNotificationWithIcon(
        'error',
        `${this.state.missingFiles.join()} files is missing.Please add them.`,
        'Error'
      );
      return null;
    }

    this.isSubmit = true;
    const { homeOwnerData } = this.state;
    let moduleBrandList = [];
    let inverterBrandList = [];

    // filter module brand
    if (
      this.props.companyId.data.moduleBrands &&
      this.props.companyId.data.moduleBrands.length &&
      homeOwnerData.solarUnit &&
      (homeOwnerData.solarUnit.moduleBrandId || homeOwnerData.solarUnit.moduleBrand)
    ) {
      moduleBrandList = this.props.companyId.data.moduleBrands.filter((module) => {
        return module.id && module.id.toString() === values.moduleBrand.toString();
      });
    }

    // filter inverter brand
    if (
      this.props.companyId.data.inverterBrands &&
      this.props.companyId.data.inverterBrands.length &&
      homeOwnerData.solarUnit &&
      (homeOwnerData.solarUnit.inverterBrandId || homeOwnerData.solarUnit.inverterBrand)
    ) {
      const { inverterBrand } = homeOwnerData.solarUnit;
      inverterBrandList = this.props.companyId.data.inverterBrands.filter((inverter) => {
        return inverter.id && inverter.id.toString() === values.inverterBrand.toString();
      });
    }

    values['phone'] =
      typeof values.phone === 'string' ? values['phone'].replace(/[^0-9]/g, '') : values['phone'];

    let data = {
      slug:
        this.props.companyData &&
        this.props.companyData.companyDetail &&
        this.props.companyData.companyDetail.slug,
      companyId: this.companyId,
      homeOwnerId: this.props.homeOwnerId,
      bodyData: {
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        phone: Number(values.phone),
        address1: values.address1,
        address2: values.address2,
        ahj: values.ahj,
        city: values.city,
        state:
          values.state && values.state.label
            ? values.state.label
            : homeOwnerData &&
              homeOwnerData.homeownerData &&
              homeOwnerData.homeownerData.address &&
              homeOwnerData.homeownerData.address.state,
        zipcode: values.zipcode,
        timezone: values.timezone,
        monitorType: values.monitorType,
        meterID: values.meterID,
        meterUrl: values.meterUrl,
        moduleBrand: moduleBrandList.length !== 0 ? moduleBrandList[0].id : null,
        moduleModel:
          homeOwnerData && homeOwnerData.solarUnit && homeOwnerData.solarUnit.moduleModel,
        numberOfModules: values.numberOfModules,
        inverterBrand: inverterBrandList.length !== 0 ? inverterBrandList[0].id : null,
        systemSize: values.systemSize,
        systemPrice: values.systemPrice,
        annualConsumption: values.annualConsumption,
        annualProduction: values.annualProduction,
        projectAnnualSavings: values.projectAnnualSavings,
        projectManager: values.projectManager,
        salePersonId: values.salePersonId,
        electricityUtility: values.electricityUtility,
        ratePlan: values.ratePlan,
        activeJourney: values.activeJourney,
        connectionStatus: this.props.meterConnectionStatus
          ? this.props.meterConnectionStatus
          : homeOwnerData && homeOwnerData.solarUnit && homeOwnerData.solarUnit.connectionStatus,
        toolContactId:
          homeOwnerData && homeOwnerData.solarUnit && homeOwnerData.solarUnit.toolContactId,
        toolSolarUnitId:
          homeOwnerData && homeOwnerData.solarUnit && homeOwnerData.solarUnit.toolSolarUnitId,
        touchHistory:
          (homeOwnerData && homeOwnerData.solarUnit && homeOwnerData.solarUnit.touchHistory) || null
      }
    };
    this.props.setLoader({ loader: true });
    this.props.requestUpdateHomeOwner(data);
  };

  componentWillReceiveProps(nextProps) {
    // check update api status to reset old data and get new data
    if (
      nextProps.homeOwnerData.getUpdateHomeOwner &&
      nextProps.homeOwnerData.getUpdateHomeOwner.status &&
      nextProps.homeOwnerData.getUpdateHomeOwner.status === 200
    ) {
      nextProps.receiveUpdateHomeOwner({});
    }

    let homeOwnerData = this.state.homeOwnerData;
    if (JSON.stringify(homeOwnerData) === '{}') {
      homeOwnerData = nextProps.loggedInUser;
    }

    // filter installer
    if (
      nextProps.getInstaller &&
      nextProps.getInstaller.data &&
      nextProps.getInstaller.data.length &&
      nextProps.getInstaller.data.length !== 0
    ) {
      const installerList = nextProps.getInstaller.data.filter((installer) => !installer.isDisable);
      this.setState({ installerList });
    }

    let timezone = [],
      stateValue = null,
      moduleBrandList = [],
      inverterBrandList = [],
      electricutilityList = [],
      ratePlanList = [],
      selectedRatePlan = [],
      powerMeter = [];

    // filter time zone
    if (homeOwnerData.homeownerData && homeOwnerData.homeownerData.address.state) {
      let index = nextProps.companyId.data.states.findIndex(
        (state) =>
          state.code.toLowerCase() === homeOwnerData.homeownerData.address.state.toLowerCase()
      );

      if (index !== -1) stateValue = nextProps.companyId.data.states[index].code;

      timezone = nextProps.companyId.data.timezone.filter(
        (val) => val.id.toString() === homeOwnerData.homeownerData.timezone.toString()
      );
    }
    if (homeOwnerData.solarUnit) {
      // filter module brand
      if (
        nextProps.companyId.data.moduleBrands &&
        nextProps.companyId.data.moduleBrands.length &&
        (homeOwnerData.solarUnit.moduleBrandId || homeOwnerData.solarUnit.moduleBrand)
      ) {
        const { moduleBrandId } = homeOwnerData.solarUnit;
        moduleBrandList = nextProps.companyId.data.moduleBrands.filter((module) => {
          return module.id && module.id.toString() === moduleBrandId.toString();
        });
      }
      // filter inverter brand
      if (
        nextProps.companyId.data.inverterBrands &&
        nextProps.companyId.data.inverterBrands.length &&
        (homeOwnerData.solarUnit.inverterBrandId || homeOwnerData.solarUnit.inverterBrand)
      ) {
        const { inverterBrandId, inverterBrand } = homeOwnerData.solarUnit;
        inverterBrandList = nextProps.companyId.data.inverterBrands.filter((inverter) => {
          return inverter.id && inverter.id.toString() === inverterBrandId.toString();
        });
      }

      // filter Electricutility
      if (
        nextProps.companyId.data.electricutility &&
        nextProps.companyId.data.electricutility.length &&
        (homeOwnerData.solarUnit.electricUtilityName || homeOwnerData.solarUnit.electricUtilityId)
      ) {
        const { electricUtilityName, electricUtilityId } = homeOwnerData.solarUnit;
        electricutilityList = nextProps.companyId.data.electricutility.filter(
          (item) => item.electricUtilityId === electricUtilityId
        );

        if (electricutilityList && electricutilityList[0]) {
          nextProps.companyId.data.electricutility.forEach((item, index) => {
            if (item.electricUtilityId === electricutilityList[0].electricUtilityId) {
              ratePlanList = item.rateplan;
              if (homeOwnerData.solarUnit.utilityRateId) {
                const { utilityRateId } = homeOwnerData.solarUnit;
                selectedRatePlan = item.rateplan.filter(
                  (rate) => rate.rateId === utilityRateId.toString()
                );
              }
            }
          });
        }
      }

      // filter Meter
      if (
        nextProps.companyId.data.powerMeter &&
        nextProps.companyId.data.powerMeter.length &&
        (homeOwnerData.solarUnit.meterType || homeOwnerData.solarUnit.meterId)
      ) {
        const { meterId } = homeOwnerData.solarUnit;
        powerMeter = nextProps.companyId.data.powerMeter.filter((item) => item.meterId === meterId);
      }
    }

    // set values
    this.setState({
      stateValue,
      electricutilityList,
      powerMeter,
      moduleBrandList,
      inverterBrandList,
      ratePlanList,
      selectedRatePlan,
      homeOwnerData,
      timezone,
      connectionStatus: null
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      let data = {
        companyId: this.companyId,
        homeOwnerId: this.props.homeOwnerId
      };
      this.props.requestHomeOwner(data);
    }
  }

  onClickCancel = () => {
    this.props.requestAddHomeOwner({});
    this.props.switchDashboard();
  };

  renderOptions = (dataArray, display, id) => {
    if (!dataArray) {
      return null;
    }
    return dataArray.map((item) => (
      <Option value={item[id]} key={item[id]}>
        {capitalize(item[display])}
      </Option>
    ));
  };

  testConnection = () => {
    this.props.setLoader({ loader: true });
    let { homeOwnerData } = this.state;
    let egaugeBaseUrl = this.state.meterUrl;
    if (!egaugeBaseUrl) {
      egaugeBaseUrl = homeOwnerData && homeOwnerData.solarUnit && homeOwnerData.solarUnit.meterUrl;
    }
    let meterId = this.state.meterID;
    let meterKey = this.state.meterKey;

    if (!meterKey || meterKey == '') {
      meterKey = this.state.powerMeter[0] && this.state.powerMeter[0]['key'];
    }

    if (!this.state.meterID) {
      meterId = this.state.powerMeter[0] && this.state.powerMeter[0].meterId;
    }
    let identifier = this.state.identifier;
    if (!this.state.identifier) {
      identifier = this.state.powerMeter[0] && this.state.powerMeter[0].identifier;
    }
    let meterconId = this.state.meterconId
      ? this.state.meterconId
      : homeOwnerData && homeOwnerData.solarUnit && homeOwnerData.solarUnit.meterconId;
    this.props.requestEgaugePowerMeter({
      meterUrl: egaugeBaseUrl,
      meterID: meterId,
      meterconId: meterconId,
      key: meterKey,
      identifier: identifier
    });
    if (!this.props.loader) {
      this.setState({
        isTestMeterClicked: false
      });
    }
  };

  handleOk = (e) => {
    this.setState({ meterVisible: false });
  };

  handleCancel = (e) => {
    this.setState({ meterVisible: false });
  };

  /**
   * Retrieve and assign initial form values from props and state, and return data when values are assigned
   *
   * @returns {null|Object} either return null, to signal no render, or return complete form initial values
   */
  resolveInitialFormValues() {
    const { loggedInUser } = this.props;

    /**
     * antd Form 'initialValues' setting fails if values are blank, so return `null` until value assignment via 'componentWillReceiveProps' is complete
     */
    if (
      Object.keys(this.state.homeOwnerData).length < 1 ||
      this.state.homeOwnerData.homeownerData === undefined
    ) {
      return null;
    }

    const {
      homeOwnerData: { homeownerData },
      stateValue,
      timezone,
      powerMeter,
      meterUrl,
      meterID,
      moduleBrandList,
      inverterBrandList,
      electricutilityList,
      selectedRatePlan
    } = this.state;

    const {
      firstName,
      middleName,
      lastName,
      email,
      phone,
      address: { address1, city, zipCode },
      installerId,
      salePersonId
    } = homeownerData;

    const {
      solarUnit: {
        ahj,
        activeJourney,
        moduleCount,
        meterconId,
        moduleModel,
        systemSize,
        systemPrice,
        annualConsumption,
        annualProduction,
        saving
      }
    } = loggedInUser;

    return {
      firstName,
      middleName,
      lastName,
      address1,
      city,
      state: stateValue,
      zipcode: parseInt(zipCode) ? parseInt(zipCode) : '',
      timezone: timezone && timezone.length && timezone[0].id,
      email,
      phone,
      activeJourney: activeJourney && activeJourney.s3Key,
      ahj,
      monitorType: powerMeter && powerMeter.length ? powerMeter[0].meterId : null,
      meterUrl,
      meterID: meterID ? meterID : meterconId || '',
      moduleBrand: moduleBrandList && moduleBrandList.length && moduleBrandList[0].id,
      moduleModel,
      numberOfModules: moduleCount || '',
      inverterBrand: inverterBrandList && inverterBrandList.length && inverterBrandList[0].id,
      systemSize,
      systemPrice,
      annualConsumption,
      annualProduction,
      projectAnnualSavings: saving ? saving.toString() : '',
      electricityUtility:
        electricutilityList &&
        electricutilityList.length &&
        electricutilityList[0].electricUtilityId,
      ratePlan: selectedRatePlan && selectedRatePlan.length ? selectedRatePlan[0].rateId : '',
      projectManager: installerId || null,
      salePersonId: salePersonId || null
    };
  }

  renderNewInstall = () => {
    const { companyId, loggedInUser } = this.props;

    let { timezone, powerMeter } = this.state;

    /**
     * [!] antd Form 'initialValues' setting fails if values are blank, so render `null` until value assignment via 'componentWillReceiveProps' is complete
     */
    const initialValues = this.resolveInitialFormValues();
    if (!initialValues) {
      return null;
    }

    const renderModuleBrands =
      companyId && companyId.data
        ? companyId.data.moduleBrands.map((item) => (
            <Option value={item.id} key={item.id}>
              {item.name}
            </Option>
          ))
        : '';

    const renderProjectManager =
      this.state.installerList &&
      this.state.installerList.map((item) => (
        <Option value={item.id} key={item.id}>
          {`${item.name ? item.name + ' - ' : ''}  ${item.email}`}
        </Option>
      ));

    const renderinverterBrands =
      companyId && companyId.data
        ? companyId.data.inverterBrands.map((item) => (
            <Option value={item.id} key={item.id}>
              {item.name}
            </Option>
          ))
        : '';

    const renderelectricutility =
      companyId && companyId.data
        ? companyId.data.electricutility.map((item) => (
            <Option value={item.electricUtilityId} key={item.electricUtilityId}>
              {item.name}
            </Option>
          ))
        : '';

    const renderTimeZone =
      companyId && companyId.data
        ? companyId.data.timezone.map((timezone) => (
            <Option value={timezone.id} key={timezone.id}>
              {timezone.code}
            </Option>
          ))
        : '';

    const renderPowerMeter =
      companyId && companyId.data
        ? companyId.data.powerMeter.map((item) => (
            <Option value={item.meterId} key={item.meterId}>
              {item.name}
            </Option>
          ))
        : '';

    return (
      <section className="profile-detail-wrapper">
        <div className="container">
          {this.props.installerConnection &&
            this.props.installerConnection.installerMeterConnection && (
              <Modal
                title="Test Meter Data"
                visible={this.state.meterVisible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
              >
                {this.props.installerConnection.installerMeterConnection}
              </Modal>
            )}

          <Form onFinish={this.handleSubmit} initialValues={initialValues}>
            <div className="white-box pb-5 box-Shadow">
              <div className="form-section-wrapper">
                <div className="form-section-box">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12 form-container">
                        <h2>Homeowner Profile</h2>
                        <div className="form-wrap">
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <div className="form-group">
                                <label className="form-group">First Name</label>
                                <FormItem
                                  hasFeedback
                                  name="firstName"
                                  rules={[
                                    {
                                      required: true
                                    },
                                    {
                                      pattern: /^([^0-9]*)$/
                                    }
                                  ]}
                                >
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="firstName"
                                    disabled
                                  />
                                </FormItem>
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div className="form-group">
                                <label className="form-group">Middle Initial</label>
                                <FormItem
                                  hasFeedback
                                  name="middleName"
                                  rules={[
                                    {
                                      required: false
                                    },
                                    {
                                      pattern: /^([^0-9]*)$/
                                    }
                                  ]}
                                >
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="middleName"
                                    disabled
                                  />
                                </FormItem>
                              </div>
                            </div>
                            <div className="col-md-4 col-12">
                              <div className="form-group">
                                <label className="form-group">Last Name</label>
                                <FormItem
                                  hasFeedback
                                  name="lastName"
                                  rules={[
                                    {
                                      required: false
                                    },
                                    {
                                      pattern: /^([^0-9]*)$/
                                    }
                                  ]}
                                >
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="lastName"
                                    disabled
                                  />
                                </FormItem>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <label className="form-group">Address 1</label>
                                <FormItem
                                  hasFeedback
                                  name="address1"
                                  rules={[
                                    {
                                      required: false
                                    }
                                  ]}
                                >
                                  <Input
                                    type="text"
                                    className="form-control"
                                    id="address1"
                                    disabled
                                  />
                                </FormItem>
                              </div>
                            </div>
                            <div className="col-md-3 col-12">
                              <div className="form-group">
                                <label className="form-group">City</label>
                                <FormItem
                                  hasFeedback
                                  name="city"
                                  rules={[
                                    {
                                      required: false
                                    },
                                    {
                                      pattern: /^[A-Za-z ]+$/
                                    }
                                  ]}
                                >
                                  <Input type="text" className="form-control" id="city" disabled />
                                </FormItem>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            {companyId.data &&
                              companyId.data.states &&
                              companyId.data.states.length > 0 && (
                                <div className="col-md-3 col-12">
                                  <div className="form-group">
                                    <label className="form-group">State</label>
                                    <FormItem
                                      hasFeedback
                                      name="state"
                                      rules={[
                                        {
                                          required: false
                                        }
                                      ]}
                                    >
                                      <Select
                                        suffixIcon={<CaretIcon />}
                                        className="form-control"
                                        showSearch
                                        disabled
                                      >
                                        {this.renderOptions(
                                          companyId.data && companyId.data.states
                                            ? companyId.data.states
                                            : [],
                                          'code',
                                          'code'
                                        )}
                                      </Select>
                                    </FormItem>
                                  </div>
                                </div>
                              )}
                            <div className="col-md-3 col-12">
                              <div className="form-group">
                                <label className="form-group">Zip Code</label>
                                <FormItem
                                  hasFeedback
                                  name="zipcode"
                                  rules={[
                                    {
                                      required: false
                                    },
                                    {
                                      pattern: /^[0-9 ]+$/
                                    }
                                  ]}
                                >
                                  <NumberFormat
                                    format="#####"
                                    type="tel"
                                    mask=" "
                                    className="form-control ant-input"
                                    disabled
                                    placeholder=""
                                    style={{
                                      paddingRight: '0'
                                    }}
                                  />
                                </FormItem>
                              </div>
                            </div>

                            <div className="col-md-4 col-12">
                              <div className="form-group">
                                <label className="form-group">Time Zone</label>
                                <FormItem
                                  hasFeedback
                                  name="timezone"
                                  rules={[
                                    {
                                      required: true
                                    }
                                  ]}
                                >
                                  <Select
                                    suffixIcon={<CaretIcon />}
                                    className="form-control"
                                    id="timezone"
                                  >
                                    {renderTimeZone}
                                  </Select>
                                </FormItem>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6 col-12">
                              <div className="form-group">
                                <label className="form-group">Email</label>
                                <FormItem
                                  hasFeedback
                                  name="email"
                                  rules={[
                                    {
                                      required: false
                                    },
                                    {
                                      type: 'email'
                                    }
                                  ]}
                                >
                                  <Input
                                    type="email"
                                    className="form-control"
                                    id="exampleInputPassword"
                                    disabled
                                  />
                                </FormItem>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="form-group">
                                <label className="form-group">Phone Number</label>
                                <FormItem
                                  hasFeedback
                                  name="phone"
                                  rules={[
                                    {
                                      required: false
                                    }
                                  ]}
                                >
                                  <NumberFormat
                                    format={
                                      loggedInUser.homeownerData.formSetting &&
                                      loggedInUser.homeownerData.formSetting.phone
                                    }
                                    mask=" "
                                    type="tel"
                                    className="ant-input"
                                    disabled
                                    placeholder="(   )  -  "
                                  />
                                </FormItem>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 col-12">
                              <div className="form-group">
                                <label className="mb-0 ">Homeowner Journey</label>
                                <FormItem
                                  hasFeedback
                                  name="activeJourney"
                                  rules={[
                                    {
                                      required: true
                                    }
                                  ]}
                                >
                                  <Select
                                    suffixIcon={<CaretIcon />}
                                    className="form-control addHomeowner"
                                    optionFilterProp="children"
                                  >
                                    {this.renderOptions(
                                      companyId.data ? companyId.data.journey : [],
                                      'journeyName',
                                      's3Key'
                                    )}
                                  </Select>
                                </FormItem>
                              </div>
                            </div>

                            <div className="col-md-4">
                              <div className="form-group">
                                <label className="form-group">AHJ</label>
                                <FormItem
                                  hasFeedback
                                  name="ahj"
                                  rules={[
                                    {
                                      required: false
                                    }
                                  ]}
                                >
                                  <Input type="text" className="form-control" id="ahj" />
                                </FormItem>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {loggedInUser.homeownerData && !loggedInUser.homeownerData.isProjectTrack && (
                <div className="monitoring-profile-box">
                  <h2 className="conection">Meter Connection</h2>
                  <div className="monitioring-detail-box ">
                    <div className="form-section-wrapper meter-type">
                      <div className="form-group">
                        <label className="form-group">Meter</label>
                        <FormItem hasFeedback name="monitorType">
                          <Select
                            suffixIcon={<CaretIcon />}
                            className="form-control"
                            id="exampleFormControlSelect1"
                            onChange={(e) => {
                              this.setState(
                                {
                                  meterType: e,
                                  meterID: e,
                                  meterUrl: this.meterUrl[e],
                                  isTestMeterClicked: true
                                },
                                () => {
                                  this.props &&
                                    this.props.companyId &&
                                    this.props.companyId.data &&
                                    this.props.companyId.data.powerMeter.forEach((val) => {
                                      if (val.meterId == this.state.meterID) {
                                        this.setState({
                                          meterKey: val.key,
                                          identifier: val.identifier
                                        });
                                      }
                                    });
                                }
                              );
                            }}
                          >
                            {renderPowerMeter}
                          </Select>
                        </FormItem>
                      </div>
                    </div>
                    <div className="form-section-wrapper">
                      <div className="form-group">
                        <label className="form-group">Meter URL</label>
                        <FormItem hasFeedback name="meterUrl">
                          <Input
                            type="text"
                            className="form-control"
                            onChange={(e) =>
                              this.setState({
                                meterUrl: e.target.value,
                                isTestMeterClicked: true
                              })
                            }
                          />
                        </FormItem>
                      </div>
                    </div>
                    <div className="form-section-wrapper">
                      <div className="form-group">
                        <label className="form-group">Meter ID</label>
                        <FormItem hasFeedback name="meterID">
                          <Input
                            type="text"
                            className="form-control"
                            onChange={(e) =>
                              this.setState({
                                meterId: e.target.value.replace(/ /g, ''),
                                meterconId: e.target.value,
                                isTestMeterClicked: true
                              })
                            }
                          />
                        </FormItem>
                      </div>
                    </div>
                    <div className="form-section-wrapper">
                      <div className="form-group">
                        <label className="form-group">Status</label>
                        {this.props.meterConnectionStatus &&
                          this.props.meterConnectionStatus.hasOwnProperty('production') &&
                          (this.props.meterConnectionStatus.production ||
                            this.props.meterConnectionStatus.consumption) && <p>Online</p>}
                        {this.props.meterConnectionStatus &&
                          this.props.meterConnectionStatus.hasOwnProperty('production') &&
                          !this.props.meterConnectionStatus.consumption &&
                          !this.props.meterConnectionStatus.production && <p>Offline</p>}
                      </div>
                    </div>
                    <div className="text-center test-meter-box">
                      <a
                        onClick={() => this.testConnection()}
                        className="btn btn-secondary test-meter-link"
                      >
                        Test meter connection
                      </a>
                    </div>
                  </div>
                </div>
              )}
              <div></div>

              <div className="solar-system-wrapper">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-container">
                      <h2>Solar System Details</h2>
                      <div className="row">
                        <div className="col-sm-4 col-xs-12 form-section-wrapper">
                          <div className="form-group form-wrap">
                            <label className="form-group">Module Brand</label>
                            <FormItem
                              hasFeedback
                              name="moduleBrand"
                              rules={[
                                {
                                  required: true
                                }
                              ]}
                            >
                              <Select suffixIcon={<CaretIcon />} className="form-control" disabled>
                                {renderModuleBrands}
                              </Select>
                            </FormItem>
                          </div>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-section-wrapper">
                          <div className="form-group form-wrap">
                            <label className="form-group">Module Model</label>
                            <FormItem
                              hasFeedback
                              name="moduleModel"
                              rules={[
                                {
                                  required: false
                                }
                              ]}
                            >
                              <Input
                                type="text"
                                className="form-control"
                                id="moduleModel"
                                disabled
                              />
                            </FormItem>
                          </div>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-section-wrapper">
                          <div className="form-group form-wrap">
                            <label className="form-group">Number of Modules</label>
                            <FormItem
                              hasFeedback
                              name="numberOfModules"
                              rules={[
                                {
                                  required: false
                                },
                                {
                                  pattern: /^[0-9 ]+$/
                                }
                              ]}
                            >
                              <Input
                                type="number"
                                className="form-control"
                                id="moduleCount"
                                disabled
                              />
                            </FormItem>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4 col-xs-12 form-section-wrapper">
                          <div className="form-group form-wrap">
                            <label className="form-group">Inverter Brand</label>
                            <FormItem
                              hasFeedback
                              name="inverterBrand"
                              rules={[
                                {
                                  required: true
                                }
                              ]}
                            >
                              <Select suffixIcon={<CaretIcon />} className="form-control" disabled>
                                {renderinverterBrands}
                              </Select>
                            </FormItem>
                          </div>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-section-wrapper">
                          <div className="form-group form-wrap">
                            <label className="form-group">Total System Size</label>
                            <FormItem
                              hasFeedback
                              name="systemSize"
                              rules={[
                                {
                                  required: true
                                }
                              ]}
                            >
                              <NumberFormat mask=" " className="ant-input" disabled />
                            </FormItem>
                          </div>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-section-wrapper">
                          <div className="form-group form-wrap">
                            <label className="form-group">System Price</label>
                            <FormItem
                              hasFeedback
                              name="systemPrice"
                              rules={[
                                {
                                  required: false
                                }
                              ]}
                            >
                              <NumberFormat mask=" " className="ant-input" disabled />
                            </FormItem>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-4 col-xs-12 form-section-wrapper">
                          <div className="form-group form-wrap">
                            <label className="form-group">
                              Historical Annual Consumption in kWh
                            </label>
                            <FormItem
                              hasFeedback
                              name="annualConsumption"
                              rules={[
                                {
                                  required: false
                                }
                              ]}
                            >
                              <NumberFormat
                                mask=" "
                                className="form-control"
                                id="annualConsumption"
                                disabled
                              />
                            </FormItem>
                          </div>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-section-wrapper">
                          <div className="form-group form-wrap">
                            <label className="form-group">Projected Annual Production in kWh</label>
                            <FormItem
                              hasFeedback
                              name="annualProduction"
                              rules={[
                                {
                                  required: false
                                }
                              ]}
                            >
                              <NumberFormat
                                mask=" "
                                className="form-control"
                                id="annualProduction"
                                disabled
                              />
                            </FormItem>
                          </div>
                        </div>
                        <div className="col-sm-4 col-xs-12 form-section-wrapper">
                          <div className="form-group form-wrap">
                            <label className="form-group">Projected Annual Savings in $</label>
                            <FormItem
                              hasFeedback
                              name="projectAnnualSavings"
                              rules={[
                                {
                                  required: false
                                }
                              ]}
                            >
                              <NumberFormat mask=" " className="form-control" disabled />
                            </FormItem>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="electric-wrapper">
                <div className="container">
                  <div className="form-container">
                    <h2>Electric Utility</h2>
                    <div className="row">
                      <div className="col-sm-4 col-xs-12 form-section-wrapper">
                        <div className="form-group form-wrap">
                          <label className="form-group">Electric Utility</label>
                          <FormItem
                            hasFeedback
                            name="electricityUtility"
                            rules={[
                              {
                                required: true
                              }
                            ]}
                          >
                            <Select
                              suffixIcon={<CaretIcon />}
                              className="form-control captalize-text"
                              disabled
                            >
                              {renderelectricutility}
                            </Select>
                          </FormItem>
                        </div>
                      </div>

                      <div className="col-sm-4 col-xs-12 form-section-wrapper">
                        <div className="form-group form-wrap">
                          <label className="form-group">Rate Plan</label>
                          <FormItem
                            hasFeedback
                            name="ratePlan"
                            className="text-capitial"
                            rules={[
                              {
                                required: true
                              }
                            ]}
                          >
                            <Select
                              suffixIcon={<CaretIcon />}
                              className="form-control text-capitial"
                            >
                              {this.renderOptions(this.state.ratePlanList, 'display', 'rateId')}
                            </Select>
                          </FormItem>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="assign-project-wrapper">
                <div className="container">
                  <div className="form-container">
                    <h2>Assign personnel</h2>
                    <div className="row">
                      <div className="col-sm-5 col-xs-12 form-section-wrapper">
                        <div className="form-group form-wrap">
                          <label className="form-group">Project Manager</label>
                          <FormItem
                            hasFeedback
                            name="projectManager"
                            rules={[
                              {
                                required: true
                              }
                            ]}
                          >
                            <Select
                              suffixIcon={<CaretIcon />}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                return (
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                              className="form-control addHomeowner"
                            >
                              {renderProjectManager}
                            </Select>
                          </FormItem>
                        </div>
                      </div>

                      <div className="offset-md-1 col-sm-5 col-xs-12 form-section-wrapper">
                        <div className="form-group form-wrap">
                          <label className="form-group">Salesperson</label>
                          <FormItem
                            hasFeedback
                            name="salePersonId"
                            rules={[
                              {
                                required: false
                              }
                            ]}
                          >
                            <Select
                              suffixIcon={<CaretIcon />}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) => {
                                return (
                                  option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                );
                              }}
                              className="form-control addHomeowner"
                            >
                              {renderProjectManager}
                            </Select>
                          </FormItem>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center button-box">
                    <button className="btn btn-secondary" onClick={() => this.onClickCancel()}>
                      Cancel
                    </button>
                    <button className="btn btn-primary" type="submit">
                      Save details
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </section>
    );
  };

  render() {
    if (getToken()) {
      return this.renderNewInstall();
    } else {
      this.props.router.navigate('/');
      window.location.reload();
      return null;
    }
  }
}

const mapStatetoProps = (state) => {
  return {
    auth: state.auth,
    companyId: state.addhomeOwner.companyId,
    companyData: state.setting.companyDetail.data,
    getInstaller: state.addhomeOwner.getInstaller,
    updateHomeOwner: state.homeOwner.getUpdateHomeOwner,
    installerData: state.header.getInstaller,
    installerConnection: state.installerMeterConnection,
    meterConnectionStatus:
      state.installerMeterConnection && state.installerMeterConnection.installerMeterConnection,
    homeOwner: state.homeOwner
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestHomeOwner,
      requestInstallerData,
      requestUpdateHomeOwner,
      receiveUpdateHomeOwner,
      setLoader,
      requestEgaugePowerMeter
    },
    dispatch
  );

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(EditHomeOwnerForm));
