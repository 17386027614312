import React                  from 'react'
import PropTypes              from 'prop-types'
import {AutoComplete,Menu,Dropdown} from 'antd';
import withRouter             from '../../../../shared/withRouter';
import * as toast             from '../../../../shared/notification'
import * as config            from '../../../../shared/config';
import { jwtDecode }          from '../../../../shared/jwt';
// import { updateRefreshToken } from './../../../Utils/updateRefreshToken';
import imgSearch from "./../../../../Assets/Images/search.svg"
import logoutImg from "../../../../Assets/Images/logout.svg"

const Option = AutoComplete.Option;

class HeaderComponent extends React.Component {
  static propTypes = {
    isSignedIn: PropTypes.string,
    signUpError: PropTypes.bool,
    signOut: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    auth: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.options = []
    this.adminData = {};
  }

  componentWillReceiveProps(nextProps, nextstate) {
    if (nextProps.searchData && nextProps.searchData.data) {
      let dataSource = nextProps.searchData.data;
      this.options = dataSource.map((item, index) => {
        return (
          <Option key={item.companyId} value={item.Company}>
            {item.Company}
          </Option>
        );
      });
      this.forceUpdate();
    }

    if (nextProps.homeownerList) {
      this.homeownerOpions = nextProps.homeownerList.map((item, index) => {
        return (
          <Option key={item.id} value={JSON.stringify(item)}>
            {`${item.firstName} ${item.lastName} - ${item.email}`}
          </Option>
        );
      });
      this.forceUpdate();
    }
  }
  
  componentWillMount(){
    const token = localStorage.getItem('token');
    this.adminData = jwtDecode(token);
  }

  componentDidMount() {
    const token = localStorage.getItem('token');
    let expTime = this.adminData && this.adminData.exp ? Number(`${this.adminData.exp}000`) : 0
    const remainingTime = expTime - new Date().getTime() - 60000;
    if (!expTime || new Date().getTime() > expTime || remainingTime < 1) {
      this.signOut()
    } else {
      const timeInterval = localStorage.getItem('timeInterval');
      if (timeInterval) {
        clearInterval(timeInterval);
      }
      this.countdown = setInterval(() => {
        this.refreshToken(token);
      }, remainingTime);
      localStorage.setItem('timeInterval', this.countdown);
    }
  }

  onSelect = (option,type) => {
    if(type==="homeowner"){
      let prop = JSON.parse(option.props.value);
      this.props.router.navigate(`/${prop.slug}/${prop.companyId}/dashboard/${prop.id}/homeowner`);
    }
    else
      this.props.router.navigate(`/admin/${option.key}/companysetting`);
  }

  refreshToken = async (token) => {
    const { adminData, auth } = this.props;

    let expTime = this.adminData && this.adminData.exp ? Number(`${this.adminData.exp}000`) : 0
    if (Date.now() > expTime) {
      if (
        adminData
        && adminData.data
        && adminData.data.email
      ) {
        return;
        // await updateRefreshToken(adminData.data.email, auth.info.refreshToken)
      }
    }
  }

  handleSearch = (value, type) => {
    if (value.length > 2) {
      if (type === "homeowner") {
        this.props.requestAdminHomeownerSearch(value)
      } else {
        this.props.requestSearchData(value);
      }
    } else if (value.length < 3) {
      this.options = [];
      this.homeownerOpions = [];
      this.forceUpdate();
    }
  }

  updatePage = (path) => {
    this.props.router.navigate(path);
  }

  signOut = () => {
    this.props.signOut()
    localStorage.clear();
    this.props.router.navigate("/")
  }

  getToken() {
    return localStorage.getItem("token")
  }

  render() {
    let pathname = this.props.router.location.pathname;

    const menu = (
      <Menu>
        <Menu.Item key="0">
            <span style={{ cursor: 'pointer' }} className="dropdown-item" onClick={this.signOut}>
              <span className="drop-icon">
                <img
                  alt="logout_icon"
                  src={ logoutImg }
                />
              </span>
              <span>Logout</span>
            </span>
        </Menu.Item>
      </Menu>
    );

    if (this.getToken()) {
      return (
        <React.Fragment>
          <div className="nav-header-wrapper dashboard-nav-wrapper fixed-top">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <span className="navbar-brand default" onClick={() => this.props.router.navigate("/admin/dashboard")} />
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="mr-auto display-none"> </ul>
                <div className="my-2 my-lg-0">
                  <div>
                    <ul className="navbar-nav">

                    <li className="nav-item admin-option" onClick={()=>this.updatePage('/admin/utility')}>
                        <a className={`nav-link ${pathname.includes('utility') ? 'active' : ''}`} >
                        <i className="fa fa-wrench admin-icon" aria-hidden="true"></i>
                        utilities
                        </a>
                     </li>
                     <li className="nav-item admin-option" onClick={()=>this.updatePage('/admin/map')}>
                        <a className={`nav-link ${pathname.includes('map') ? 'active' : ''}`}  >
                        <i className="fa fa-map-marker admin-icon" aria-hidden="true"></i>
                        map
                        </a>
                     </li>
                     <li className="nav-item admin-option" onClick={()=>this.updatePage('/admin/report')}>
                        <a className={`nav-link ${pathname.includes('report') ? 'active' : ''}`} >
                        <i className="fa fa-file-text-o admin-icon" aria-hidden="true"></i>
                        reports
                        </a>
                     </li>

                      <li className="nav-item dashboar-search">
                        <form className="form-inline">
                          <div className="search-wrap">
                            <img
                              src={imgSearch}
                              alt='search'
                            />
                            <AutoComplete
                              className="certain-category-search header"
                              dropdownClassName="certain-category-search-dropdown"
                              dropdownMatchSelectWidth={false}
                              onSelect={(value, option) => { this.onSelect(option, "homeowner") }}
                              onSearch={(value) => this.handleSearch(value, "homeowner")}
                              placeholder="Search homeowners"
                            >
                              { this.homeownerOpions }
                            </AutoComplete>
                          </div>
                        </form>
                      </li>

                      <li className="nav-item dashboar-search" style={{width:'220px'}}>
                        <form className="form-inline">
                          <div className="search-wrap">
                            <img
                              src={imgSearch}
                              alt='search'
                            />
                            <AutoComplete
                              className="certain-category-search header"
                              dropdownClassName="certain-category-search-dropdown"
                              dropdownMatchSelectWidth={false}
                              onSelect={(value, option) => { this.onSelect(option, "company") }}
                              onSearch={value => this.handleSearch(value, "company")}
                              placeholder="Search companies"
                            >
                              { this.options }
                            </AutoComplete>
                          </div>
                        </form>
                      </li>

                      <li
                        className="nav-item nav-add-link"
                      onClick={() => {
                          this.props.router.navigate(
                            "/admin/addcompany")
                         }}
                      >
                        <a className={`nav-link addhomeowner`}>
                          <span>Add company</span>
                        </a>
                      </li>

                      <li className="nav-item admin-option">
                        <Dropdown overlay={menu} trigger={['click']}>
                          <span className="dashboard-profile-name">   
                            {this.adminData && this.adminData.email}
                          </span>
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </React.Fragment>

      )
    } else {
      return null;
    }
  }
}

export default withRouter(HeaderComponent);

