// import npm packages
import React, { Component }      from "react";
import { Input, Modal, Button,
  Spin, Switch, Progress }       from "antd";
import { LoadingOutlined }       from '@ant-design/icons';
import { connect }               from "react-redux";
import { bindActionCreators }    from "redux";
import moment                    from "moment"
import UserAvatar                from "react-user-avatar";
import NumberFormat              from "react-number-format";
import * as AWS                  from "aws-sdk";

import { setLoader }          from '../../../Common/Loader/action';
import { DeleteModal }        from "../../../Common/DeleteModal";
import { getToken }           from "../../../../shared/util";
import { jwtDecode }          from "../../../../shared/jwt";
import { checkIsVideo }       from "../../../../shared/checkIsMobile";
import envConfig              from "../../../../environments/index"
import { testCampaign,
  deleteCampaign }            from "../../api/campaign";
import CreateCampaignForm     from "./campaign-form";
import ReviewCampaignForm     from "./campaign-review";
import RecipentsCampaignForm  from "./campaign-recipents";
import {createCampaignFilterQuery} from "./campaignFilter"
import withRouter                  from '../../../../shared/withRouter'
import {openNotificationWithIcon}  from "../../../../shared/notification";
import {requestCreateCampaign,
  requestGetCampaignDetail,
  requestUpdateCampaign,
  receiveCreateCampaign,
  receiveUpdateCampaign,
  receiveGetCampaignDetail
}                             from "../../actions/campaign"
import addCloudIcon           from '../../../../Assets/Images/add_cloud.svg'
import campaignActiveIcon     from "./../../../../Assets/Images/campaign_icon_Active.svg"
import editDetailsIcon        from "./../../../../Assets/Images/edit-details-icon.png"
import highFiveIcon           from "./../../../../Assets/Images/highFive_icon.svg"

class CreateCampaign extends Component {
    constructor(props) {
      super(props);

      this.campaignFormDetail = {
        1: {
          heading : "Create Campaign",
          subHeading : "This information will be sent to the people you add to your campaign."
        },
        2: {
          heading : "Add Recipents",
          subHeading : "Segment homeowners to tailor messages that increase engagement."
        },
        3: {
          heading : "Review & Send",
          subHeading : "Review your campaign."
        }
      }
      this.state = {
        activeCampaignForm : 1,
        deleteTrigger      : false,
        sendTrigger        : false,
        testTrigger        : false,
        scheduledType      : true,
        isChannelEmail     : true,
        isChannelText      : true,
        isChannelApp       : true,
        s3UploadProgress   : null,
        launchedDate       : moment().add(1, 'days').valueOf(),
        campaignHeadline   :'',
        campaignEmailSubject:'',
        campaignEmailBody:'',
        ctaLabel:'',
        ctaLink:'',
        campaignImage : [],
        campaignSMS:''
      };

    }
  
    componentWillMount() {
      this.slug = localStorage.getItem("slug");
      let token = getToken();
      this.installerData = jwtDecode(token);
      this.companyId = this.props.router.params.companyId;
      this.props.receiveCreateCampaign({});
      this.props.receiveUpdateCampaign({})
      this.fetchExistCampaign();
    }

    fetchExistCampaign(){
      var url         = new URL(window.location.href);
      let campaignId  = url.searchParams.get('campaignId');
      let campaignStatus = url.searchParams.get('campaignStatus');
      let obj = {
        companyId   : this.installerData.companyId,
        campaignId  : campaignId
      }
      if (campaignId){
        this.setState({
          activeCampaignForm: campaignStatus,
          existingCampaignId: campaignId
        }, () => {
          this.props.requestGetCampaignDetail(obj);
          this.props.setLoader({loader: true});
        });
      }
    }
  
    componentWillReceiveProps(nextProps) {
      if (nextProps.router.location.search.includes('campaign') && nextProps && nextProps.campaignData && JSON.stringify(nextProps.campaignData)!=='{}') {
        let {campaignData} = nextProps;
        let channel = campaignData.channel && JSON.parse(campaignData.channel);
        let email = campaignData.email && JSON.parse(campaignData.email);
        let button = campaignData.button && JSON.parse(campaignData.button);
        let filter = (campaignData.filters && typeof(campaignData.filters)==="string")
        ? JSON.parse(campaignData.filters) : [];
        let json = {
          isChannelEmail    : channel.email,
          isChannelText     : channel.sms,
          isChannelApp      : channel.app,
          launchedDate      : campaignData.launchedDate || this.state.launchedDate,
          campaignHeadline  : campaignData.headline,
          campaignImage     : campaignData.photo,
          campaignImageUrl  : campaignData.photo[0],
          campaignImageType : campaignData.photo[0] && checkIsVideo(campaignData.photo[0]) ? 'video':'image',
          campaignSMS       : campaignData.sms,
          totalRecipients   : campaignData.totalRecipients,
          campaignEmailSubject: email.subject,
          campaignFilter    :  Array.isArray(filter) ? filter : [],
          campaignEmailBody   : email.body,
          ctaLabel: button.label,
          ctaLink : button.link
        };
        this.setState(json, () => {
          this.props.receiveGetCampaignDetail({});
        });
      }

      if (nextProps && nextProps.updateCampaignData && nextProps.updateCampaignData.status==200){
        if (nextProps.updateCampaignData.data.status==1){
          this.setState({sendTrigger: true})
        }
        else{
          this.props.router.navigate("/" + this.slug + "/"+ this.companyId +"/campaign/dashboard");
          openNotificationWithIcon('success','Campaign information has been successfully updated.','Success');
        }
      }
      if (nextProps.campaignCreated && nextProps.campaignCreated.data && nextProps.campaignCreated.data.status ===2){
        this.setState({sendTrigger: true,sendType:2})
      }
      else if (nextProps.campaignCreated && nextProps.campaignCreated.data && nextProps.campaignCreated.data.status!==3){
        this.setState({sendTrigger: true})
      }
      else if (nextProps.campaignCreated && nextProps.campaignCreated.data && nextProps.campaignCreated.data.status===3){
        this.props.router.navigate("/" + this.slug + "/"+ this.companyId +"/campaign/dashboard");
        openNotificationWithIcon('success','Campaign information has been successfully saved as a draft.','Success');
      }
    }  

    componentDidUpdate(prevProps){
      if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
        this.fetchExistCampaign();
      }
    }

    updateCampaignDataState=(key,value)=>{
      this.setState({[key]:value})
    }

    componentWillUnmount(){
      this.setState({sendType:null})
    }
    
    checkBoxWrapper = () => {
      return(
        <>
        <label htmlFor="exampleInputEmail1">Channel</label>
        <div className=" checkbox-wrapper">
          <div className="on-off-case">
            <Switch checked={this.state.isChannelEmail} checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(value) => {this.setState({isChannelEmail:value})}}
               />
            <div><span>Email</span></div>
          </div>

          <div className="on-off-case">
            <Switch checked={this.state.isChannelText} checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(value) => this.setState({isChannelText:value})}
               />
            <div><span>Text</span></div>
          </div>

          <div className="on-off-case">
            <Switch checked={this.state.isChannelApp} checkedChildren="ON"
              unCheckedChildren="OFF"
              onChange={(value) => this.setState({isChannelApp:value})}
               />
            <div><span>In App</span></div>
          </div>
        </div>
        </>
      )
    }

    uploadImageonS3 = () => {
      return new Promise((res,rej)=>{
        if (!this.state.campaignImage[0]) {
          res([]);
          return;
        }
        let temp = this.state.campaignImage[0];
        if (temp && typeof(temp)==="string" && temp.includes('http')){
          res([temp]);
          return;
        }        
        let fileName = temp.name.split(".");
        let extension = fileName[fileName.length - 1];
        let newFileName = `${this.installerData.slug}_${new Date().getTime()}.${extension}`;

        this.setState({ s3UploadProgress: 0 })
        let s3Obj = {
          params:{
            Bucket: envConfig.envConfig.s3.BUCKET,
            Key: `public/campaignImage/${newFileName}`,
            Body: this.state.campaignImage[0],
          }
        }

        AWS.config.region = envConfig.envConfig.cognito.Region;
        let x = { key: 'cognito-idp.us-east-1.amazonaws.com/' + envConfig.envConfig.cognito.UserPoolId }
        let y = {};
        y[x.key] = localStorage.getItem("token");
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: envConfig.envConfig.cognito.IdentityPoolId,
          Logins: y
        })
        let s3ManagedUpload2 = new AWS.S3.ManagedUpload(s3Obj).on('httpUploadProgress',(data)=>{
          this.setState({s3UploadProgress:parseInt((data.loaded*100)/data.total)})
        }).promise();

        s3ManagedUpload2.then(result=>{
          res([result.Location])
          this.setState({s3UploadProgress:null});
          return;
        },err=>{
          res(false);
          this.setState({s3UploadProgress:null});
          return;
          }).catch(() => {
            res(false);
            this.setState({s3UploadProgress:null});
            return;
          });
    })
  }

  ImageSelectBox(){
      return(
        <div className="upload-file-wrap">
          <label htmlFor="file-upload" className="custom-file-upload">
            <span><img src={addCloudIcon} /></span>
            <span>Click here to upload images.</span>
            <span>Supported file formats are GIF, JPEG, JPG, and PNG.</span>
          </label>
          <input id="file-upload"
            type="file"
            accept =".jpg,.jpeg,.png,.gif"
            onChange={(event) => {
              if (event.target.files && event.target.files[0]) {
                this.setState({
                  campaignImage: [event.target.files[0]],
                  campaignImageType: event.target.files[0].type,
                  campaignImageUrl: URL.createObjectURL(event.target.files[0])
                });
              }
            }}
          />
        </div>
      )
  }

  VideoPreviewBox(){
    return(
      <>
        <a className="hut-cross" onClick={() => this.setState({
          campaignImage: [],
          campaignImageUrl: ''
        })}>X</a>
        <video className="hut-img" controls>
          <source src={this.state.campaignImageUrl} type="video/mp4" />
        </video>
      </>
    )
  }

  ImagePreviewBox(){
    return (
      <div className="hut-img"
        style={{
          height: "217px",
          backgroundImage: `url(${this.state.campaignImageUrl})` }}
      >
        <a className="hut-cross" onClick={() => this.setState({
          campaignImage: [],
          campaignImageUrl: ''
        })}>X</a>
      </div>
    )
  }

  renderInstallerInital(installerData){
    let photo = installerData && installerData.photo;
    if (photo && photo.includes('installer-avatar')) {
      photo = null;
    }
    let installerName =  installerData && (installerData.name || installerData.companyName);
    return(
      photo ? <img
      className="profile-image"
      src={photo}
      alt='profile-pic'
    /> :
      <UserAvatar className="profile-image premiumIconBackGround" size="40" name={ installerName || 'Installer'} />
    )
  }

    previewPost = () => {
      let {installerData} = this.props;
      return(
        <div className="col-sm-5 post-preview">
        <h2 className="left-heading">Post Preview</h2>
        <div className="white-box-secondary white-box m-0">
          <div className="media">
            {this.renderInstallerInital(installerData)}
            <div className="media-body">
               <h5 className="mt-0">{installerData && installerData.name}</h5>
                <p className="text">{installerData && installerData.title}</p>
            </div>
          </div>

           {(this.state.campaignImage && this.state.campaignImage.length>0)
              ? (this.state.campaignImageType && 
                this.state.campaignImageType.includes('video') ? 
                this.VideoPreviewBox()
              : this.ImagePreviewBox()) : this.ImageSelectBox() }
            <div className="campaignPreview left-content-box">
              <h3>{this.state.campaignHeadline} </h3>
              {this.state.campaignEmailBody.split("\n").map((item, idx) => {
                return (
                  <p key={idx}>{item}</p>
                )
              })}
              {
                this.state.ctaLink && <a href={this.verifyLink(this.state.ctaLink)} target="_blank" 
                className="get-button btn btn-primary">{this.state.ctaLabel|| 'Click Here'}</a>
              }
              
            </div>
         
        </div>
      </div>
      )
    }

    verifyLink(value) {
      if (value && !value.includes('http')) {
        value = 'http://'+ value;
      }
      return value;
    }

    campaignFooter() {
      return(
        <div className="row footer-btn-box">
          <div className="col-sm-6 p-0">
            <a className="prev-btn" onClick={()=>{
              let temp = this.state.activeCampaignForm -1;
               temp <1 ? this.props.router.navigate(`/${this.slug}/${this.companyId}/campaign/dashboard`)
                 : 
              this.setState({activeCampaignForm : temp});
              
            }}>
              {'<< PREVIOUS PAGE'}</a>
          </div>
          <div className="col-sm-6 form-submitted-btn p-0">
            {
              this.state.activeCampaignForm===3 &&<>
              <a className="btn btn-secondary" onClick={() =>{this.validateData('draft')}}>Finish later</a>
              <a className="btn btn-primary" onClick={()=>this.triggerNewCampaign(false)}>Send campaign</a>
              </>
            }
            {
              this.state.activeCampaignForm!==3 &&<>
                <a className="btn btn-secondary" onClick={() => {
                  this.validateData('draft');
                }}>Finish later</a>
                <a className="btn btn-primary" onClick={() => {
                  this.validateData();
                }}>Continue</a>
              </>
            }
            
          </div>
        </div>
      )
    }

  validateData(status) {
    let temp = parseInt(this.state.activeCampaignForm) + 1;
    temp = temp > 3 ? 3 : temp;

    if (temp===3 && !this.state.totalRecipients) {
      this.setState({clickNext:true});
      setTimeout(()=>{
        this.setState({clickNext:false});
      },5000)
      return;
    }    

    let missingField = [];
   
    if (!this.state.campaignHeadline) {
      missingField.push('Headline')
    }

    if (status !=='draft' && this.state.isChannelEmail) {
      if (!this.state.campaignEmailSubject)
        missingField.push('Email Subject');

      if (!this.state.campaignEmailBody)
        missingField.push('Post Body');
    }
    if (status !=='draft' && this.state.isChannelApp && !this.state.campaignEmailBody)
      missingField.push('Post Body');

    if (status !=='draft' && this.state.isChannelText && !this.state.campaignSMS)
      missingField.push('SMS Body');
      
    if (missingField.length > 0)
      openNotificationWithIcon('error', `${missingField.join(', ')} are missing.
      Please fill these fields.`, 'Data Missing');
    else if (!this.state.isChannelText && !this.state.isChannelApp && !this.state.isChannelEmail) {
      openNotificationWithIcon('error', `To create campaign select one channel atleast.`,
        'Data Missing');
    }
    else {
      if (status ==='draft')
        this.triggerNewCampaign(false,3);
      else
        this.setState({ activeCampaignForm: temp });
    }
  }

  deletePopUpProp = (value) => {
    if (value=='cancel') {
      this.setState({
        deleteTrigger: false
      });
    }
    else {
      this.setState({ deleteTrigger: false });
      this.props.setLoader({loader:true});
      if (this.state.existingCampaignId){
        let obj = {
          companyId : this.installerData.companyId,
          campaignId: this.state.existingCampaignId
        }
        deleteCampaign(obj).then(r => {
          if (r && r.status == 200) {
            openNotificationWithIcon('success','Campaign is Deleted','Success');
            this.props.router.navigate("/" + this.slug + "/"+ this.companyId +"/campaign/dashboard");
          }
        })
      }
      else{
        this.props.router.navigate("/" + this.slug + "/"+ this.companyId +"/campaign/dashboard");
      }
    }
}

  renderDeletePopUp = () =>{
    let headerText = 'Do you really want to delete this campaign?';
    let subText = "Deleting this campaign cannot be undone";
    return(
         <DeleteModal deletePopUpProp={this.deletePopUpProp} headerText={headerText} subText={subText}/>
    )
  }

    renderTestPopUp = () => {
      let { testTrigger } = this.state;
      let {installerData} = this.props;
      return (
        <Modal
          visible={testTrigger}
          wrapClassName="popUp"
          closable={false}
          onOk={() => {
            this.setState({ testTrigger: false });
            this.props.setLoader({loader:true});
            this.triggerNewCampaign(null,null,true)
          }}

          footer={[
            <Button className="btn btn-secondary" onClick={() => { this.setState({ testTrigger: false }); }}>
              Cancel
            </Button>,
            <Button className="btn btn-primary" onClick={() => {
              this.setState({ testTrigger: false });
              this.props.setLoader({ loader: true });
              this.triggerNewCampaign(null, null, true)
            }}>
              Send test
            </Button>
          ]}
        >
          <img src={campaignActiveIcon} alt='' style={{height:'45px'}}/>
          <h3 className="header">Make sure your campaign hits all the right notes.
          Send a test version to yourself or a colleague.</h3>

          <div className="row">
            <div className="col-sm-6">
              <div className="form-group edit-icon-box">
                <label htmlFor="exampleInputEmail1">Email Address</label>
                <Input type="email" 
                  className="form-control" 
                  id="exampleInputEmail1" 
                  aria-describedby="emailHelp"
                  onChange={(e)=>this.setState({testEmail:e.target.value})}
                  placeholder=""
                />
                <span className="pen-icon">
                    <img src={editDetailsIcon} width="15" />
                </span>
              </div>
            </div>
            <div className="col-sm-6">
                <div className="form-group edit-icon-box">
                    <label htmlFor="exampleInputEmail1">Phone Number</label>
                        <NumberFormat
                          type="tel"
                          format={installerData && installerData.formSetting && installerData.formSetting.phone}
                          mask=" "
                          className="form-control"
                          onChange={(e) => this.setState({ testPhone: e.target.value })}
                        />
                        <span className="pen-icon">
                          <img src={editDetailsIcon} width="15" />
                        </span>
                </div>
            </div>
          </div>
        </Modal>
      )
    }

    renderSuccessPopUp = () => {
      let { sendTrigger } = this.state;
      return (
        <Modal
          visible={sendTrigger}
          wrapClassName="popUp campaign-success"
          closable={false}
          cancelText="Cancel"
          okText="RETURN TO CAMPAIGNS"
          onCancel={() => {
            this.setState({ sendTrigger: false },()=>{
              this.props.router.navigate("/" + this.slug + "/"+ this.companyId +"/campaign/dashboard")
            });
          }}
          onOk={() => {
            this.setState({ sendTrigger: false },() => {
              this.props.router.navigate("/" + this.slug + "/"+ this.companyId +"/campaign/dashboard")
            });
          }}
        >
          <img className="high-five" src={highFiveIcon} alt='' />
          <h3 className="header">Woo hoo!
          {
            this.state.sendType ? 
              'Your campaign has been scheduled!' : 'Your campaign is on its way!'
          }
          </h3>
        </Modal>
      )
    }
  
    renderCampaignDashboard = () => {

      let loader = this.props.loader || (this.state.s3UploadProgress!==null ? true : false);
      let antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

      let progressBar = <>
        <div className="campaign-file-upload">
          <div className="text"> Saving your changes…</div>
          <Progress type="circle"
            percent={(this.state.s3UploadProgress)} width={100}
          />
        </div>
      </>
        return <React.Fragment>
            <title>Add Campaign - Bodhi</title>
            <div>
                <Spin
                    spinning={loader}
                    indicator={this.state.s3UploadProgress!==null ? progressBar : antIcon  }
                    style={{ color: "#04bfff" }}
                >
              <section className="create-campaign-wrapper">
              {this.state.deleteTrigger &&  this.renderDeletePopUp()}
              {this.state.testTrigger && this.renderTestPopUp()}
              {this.state.sendTrigger && this.renderSuccessPopUp()}
                <div className="container">
                  <div className="row">
                    <div className="steps-wrapper">
                      <ul>
                        <li className="stepli-1">
                          <span className={`step-1 ${this.state.activeCampaignForm >=1? 'active' : ''}`}>Create Campaign</span>
                        </li>
                        <li className="stepli-2">
                          <span className={`step-2 ${this.state.activeCampaignForm >=2? 'active' : ''}`}>Add Recipents</span>
                        </li>
                        <li className="stepli-3">
                          <span className={`step-3 ${this.state.activeCampaignForm ===3 ? 'active' : ''}`}>Review & Send</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="white-box mt-4 box-Shadow">
                    <div className="del-btn" onClick={()=>this.setState({deleteTrigger:true})}>
                      <img src={require('../../../../Assets/Images/delete-icon.png')} /> Delete Campaign
                    </div>

                    <h2>{this.campaignFormDetail[this.state.activeCampaignForm].heading}</h2>
                    <p className="sub-heading">{this.campaignFormDetail[this.state.activeCampaignForm].subHeading}</p>

                    <div className={`row form-wrapper ${this.state.activeCampaignForm===2 && 'add-recipents-wrapper'}`}>
                      {this.campaignFormType()}
                    </div>
                    {this.campaignFooter()}
                  </div>
                </div>
              </section>
                </Spin>
            </div>
        </React.Fragment>;
    };

    campaignFormType() {
      let {activeCampaignForm} = this.state;
      switch(parseInt(activeCampaignForm)){
        case 3:
            return <ReviewCampaignForm previewPost={this.previewPost} updateCampaignDataState={this.updateCampaignDataState} checkBoxWrapper={this.checkBoxWrapper()} {...this.state} {...this.props}/>
        case 2:
            return <RecipentsCampaignForm updateCampaignDataState={this.updateCampaignDataState} {...this.state}   {...this.props}/>
        default:
            return <CreateCampaignForm  previewPost={this.previewPost} updateCampaignDataState={this.updateCampaignDataState} checkBoxWrapper={this.checkBoxWrapper()} {...this.state} {...this.props}/>
      }
    }

    render() {
      if (getToken()) {
        return this.renderCampaignDashboard();
      } else {
        this.props.router.navigate("/");
        window.location.reload();
        return null;
      }
    }

    testApi(data){
      data['emailAddress'] = this.state.testEmail;
      data['phone'] = this.state.testPhone && this.state.testPhone.replace(/[^0-9]/g, "");
      data['photo'] = data.photo[0];
      data['name']  = this.props.installerData && this.props.installerData.name;
      testCampaign(data).then(r=>{
        this.props.setLoader({loader:false});
        if (r && r.status===200){
          openNotificationWithIcon("success", 'Your test campaign was successfully sent.', " Success ")
        }
      })
    }

    triggerNewCampaign(isDeleted,status,test) {
      if (!this.installerData || !this.installerData.companyId){
        openNotificationWithIcon("error",'Not authorised to access it.','Error');
        return;
      }

      this.uploadImageonS3().then(r => {
        this.props.setLoader({loader:true})
        if (!r)
          return;
        let postData = {
          createdAt       : new Date().getTime(),
          installerId     : this.installerData.installerId,
          companyId       : this.installerData.companyId,
          isDeleted       : isDeleted || false,
          launchedDate    : status ===3 ? null : this.state.launchedDate,
          totalRecipients : this.state.totalRecipients,
          countryCode     : (this.slug ==="pmce" ? '+65' : '+1'),
          isWhatsapp      : this.props.installerData && this.props.installerData.isWhatsapp,
          companyName     : this.props.installerData && this.props.installerData.companyName,
          logoImageUrl    :  `${envConfig.envConfig.BUCKET_URL}/logo/${this.slug}_emailIcon.png`,
          status: status ? status : (this.state.scheduledType ? 2 : 1),
          channel: {
            email : this.state.isChannelEmail,
            sms   : this.state.isChannelText,
            app   : this.state.isChannelApp
          },
          headline: this.state.campaignHeadline,
          email: {
            subject : this.state.campaignEmailSubject,
            body    : this.state.campaignEmailBody
          },
          button: {
            label : this.state.ctaLabel,
            link  : this.verifyLink(this.state.ctaLink)
          },
          sms     : this.state.campaignSMS,
          photo   : r,
          filters : this.state.campaignFilter || []
        };

        if (postData.status==1){
          postData['launchedDate'] = postData.createdAt;
          postData['filterQuery'] = createCampaignFilterQuery(this.state.campaignFilter||[],this.installerData.companyId);
        }

        if (test){
          this.testApi(postData);
        }
        else if (this.state.existingCampaignId){
          this.props.requestUpdateCampaign({body:postData,campaignId:this.state.existingCampaignId});
        }
        else
          this.props.requestCreateCampaign(postData);
      })
    }
  }

const mapStatetoProps = state => {
    return {
      installerData   : state.header && state.header.getInstaller &&  state.header.getInstaller.data,
      loader          : state.loader.loader.loader,
      installerData   : state.header.getInstaller.data||{},
      campaignCreated : state.companyCampaign && state.companyCampaign.companyCreateCampaignReducer
                        && state.companyCampaign.companyCreateCampaignReducer,
      campaignData: state.companyCampaign && state.companyCampaign.GetCampaignDetailReducer
                    && state.companyCampaign.GetCampaignDetailReducer.data,
      updateCampaignData : state.companyCampaign && state.companyCampaign.updateCampaignDetailReducer,
     };
  };
  
  const mapDispatchToProps = dispatch =>
    bindActionCreators(
      {
        setLoader,
        requestCreateCampaign,
        requestGetCampaignDetail,
        receiveCreateCampaign,
        requestUpdateCampaign,
        receiveUpdateCampaign,
        receiveGetCampaignDetail
      },
      dispatch
    );
  
  export default connect(
    mapStatetoProps,
    mapDispatchToProps
  )(withRouter(CreateCampaign));
  