// import npm packages
import React, { Component } from "react";
import { FacebookShareButton, TwitterShareButton } from 'react-share';

// import local files
import TwoLayerDonutChart from '../../../Common/DonutChart/TwoLayerDonutChart';
import {currencyFormater} from '../../../../shared/currencyFormater';
import {checkIsMobile} 	  from "../../../../shared/checkIsMobile"
import {PostShareDetails} from '../../api/promiseApi';
import { MONTH_ARRAY }    from '../../../../shared/config';
import { parseDateTime }  from '../../../../shared/parseDateTime';
import MobileEnergyTips   from '../energyTips/mobileEnergyTips';
import {ReactComponent as Share_Icon  } from "../../../../Assets/Images/outline-share-24-px.svg";

class EnergyFlow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            peakData:{},
            hrefValue: '#'
        };
    }
    
    componentWillMount() {
        localStorage.removeItem('energyReportDate')
    }

    beforeClick(method) {
        const { loggedInUser } = this.props;

        const bodyData = {
            "homeownerId": loggedInUser && loggedInUser.solarUnit && loggedInUser.solarUnit.homeownerId,
            "companyId": loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.companyId,
            "method": method,
            "share": "glance"
        };
        return PostShareDetails(bodyData)
    }

    setMessageHref(url, method) {
        this.beforeClick(method).then(res => {
        }).catch(e => {
            console.log('error', e.toString())
        });
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null;
    }

    convertDate(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    renderCO2() {
        let lbs = 0;
        if (
            this.props.energyReport
            && this.props.energyReport.monthlyProduction
        ) {
            lbs = parseInt(this.props.energyReport.monthlyProduction * 1.6);
            return [
                `Your solar panels saved ${lbs} lbs of CO2 this month offsetting ${Math.ceil(Number(lbs / 10500 * 12))} cars on the road.`,
                `Your solar panels saved ${lbs} lbs of CO2 this month equivalent to ${Math.ceil(Number(lbs / 133))} trees planted.`,
                `Your solar panels saved ${lbs} lbs of CO2 this month offsetting ${Math.ceil(Number(lbs / 2.016))} pounds of coal burned.`,
                `Your solar panels saved ${lbs} lbs of CO2 this month offsetting ${Math.ceil(Number(lbs / 909))} barrels of oil burned.`
            ]
        }

        return [`Your solar panels saved 0 lbs of CO2 this month offsetting 0 cars on the road.`,
            `Your solar panels saved 0 lbs of CO2 this month equivalent to 0 trees planted.`,
            `Your solar panels saved 0 lbs of CO2 this month offsetting 0 pounds of coal burned.`,
            `Your solar panels saved 0 lbs of CO2 this month offsetting 0 barrels of oil burned.`
        ]
    }

    calcMaxPeak=(data)=>{
        if((this.state.peakData.peakTime !== data.peakTime)){
            this.setState({peakData:data})
            if(this.props.calcMaxPeakMobile)
                this.props.calcMaxPeakMobile(data);
        }
    }

    render() {
        const { loggedInUser } = this.props;
        let referralId = loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.referralId;
        this.slug = localStorage.getItem("slug");
        let offset = loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.offset;

        let lbs = 0;
        if (
            this.props.energyReport
            && this.props.energyReport.totalProduction
        ) {
            lbs = parseInt(this.props.energyReport.totalProduction * 1.6 * 60);
        }
        let {peakData} = this.state;
        const index = parseInt(Math.random() * 4);
        let date = new Date();
        if (
            localStorage.getItem('energyReportDate')
        )
            date = new Date(localStorage.getItem('energyReportDate'))

        let isConsumption = true;
        if (
            this.props.energyReport
            && !this.props.energyReport.totalConsumption
            && this.props.energyReport.totalProduction
        ) {
            isConsumption = false
        }

        return (
            <React.Fragment>
                <div className="heading ho-setting-heading desktop-view-only">{`${MONTH_ARRAY[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`}: ENERGY FLOW</div>
                <div className="row paddl25 paddr25 p-md-0">
                    <div className="col-sm-12">
                        <div className="text-center d3-chart-wrapper">
                            <TwoLayerDonutChart
                                calcMaxPeak = {this.calcMaxPeak}
                                energyReport={this.props.energyReport}
                                systemGlance={this.props.systemGlance}
                                offset={loggedInUser && loggedInUser.homeownerData && (loggedInUser.homeownerData.offset || null)}
                            />
                        </div>
                    </div>
                </div>
                <div className=" energy-flow-wrap">
                    <div className="row monitoring-desktop-view-only">
                        <div className="col-sm-6 paddl0 paddr0 col-6">
                            <h4 className="ho-setting-h4">SOLAR PRODUCTION</h4>
                            <p className="ho-setting-month-style margt0 margb0">Saved this month</p>
                            <div className="price ho-setting-yellow-value">
                                ${
                                    this.props.energyReport.totalSaving ?
                                        currencyFormater(this.props.energyReport.totalSaving)
                                        :
                                        0
                                }
                            </div>
                        </div>
                        {
                            isConsumption ?
                                <div className="col-sm-6 home-consumption paddl0 paddr0 col-6">
                                    <h4 className="ho-setting-h4">HOME CONSUMPTION</h4>
                                    <p className="ho-setting-month-style margt0 margb0">Today's peak</p>
                                    <div className="price ho-setting-blue-value premiumBackgroundText">
                                        {
                                            peakData
                                            && peakData.peakConsumption && peakData.peakConsumption.toFixed(1)
                                        }<span className="ho-setting-blue-value-pm">kW</span>–{
                                            peakData
                                            && peakData.peakTime
                                            && (parseDateTime(peakData.peakTime * 1000).replace('AM', '').replace('PM', '') || '00:00')
                                        }<span className="ho-setting-blue-value-pm" style={{ marginLeft: "-5px" }}>
                                            {
                                                peakData
                                                    && peakData.peakTime ?
                                                    parseDateTime(peakData.peakTime * 1000).toLowerCase().includes('am') ?
                                                        'AM'
                                                        :
                                                        'PM'
                                                    :
                                                    'AM'
                                            }
                                        </span>
                                    </div>
                                </div>
                                :
                                <div className="col-sm-6 home-consumption paddl0 paddr0 col-6">
                                    <h4 className="ho-setting-h4">{''}</h4>
                                    <p className="ho-setting-month-style margt0 margb0">Today's production</p>
                                    <div className="price ho-setting-blue-value premiumBackgroundText production-unit">
                                        {
                                            this.props.energyReport
                                            && this.props.energyReport.totalProduction.toFixed(1)
                                        }<span className="ho-setting-blue-value-pm"> kWh</span>
                                    </div>
                                </div>
                        }

                    </div>

                   {checkIsMobile() && <MobileEnergyTips
                        homeOwnerId={this.props.homeOwnerId}
                        receiveHomeownerEnergyData={this.props.receiveHomeownerEnergyData}
                        requestHomeownerEnergyData={this.props.requestHomeownerEnergyData}
                        setLoader={this.props.setLoader}
                        peakData ={peakData}
                        energyReport={this.props.energyReport}
                        setDate={date => {
                          this.setDate(date);
                        }}
                        loggedInUser={this.props.loggedInUser}
                        monthHistory={this.props.monthHistory}
                        renderMobileDates={true}
                        {...this.props}
                    />}
                    <div className="share-comments">
                        <p>{this.renderCO2()[index]}</p>
                        <div className="share-link">
                            <a href="#"
                                data-toggle="collapse"
                                data-target="#collapseShareIcons"
                                aria-expanded="true"
                                aria-controls="collapseShareIcons"
                            >
                                <Share_Icon/>
                            </a>
                        </div>
                    </div>

                    <div id="collapseShareIcons" className="white-box share-via-wrapper collapse">
                        <div className="heading"> Share via:</div>
                        {!this.props.isInstaller ?
                            <ul>
                                <li>
                                    {referralId && date && offset && 
                                    <FacebookShareButton beforeOnClick={() => this.beforeClick(1)} quote="Hey, I've gone solar and thought you might be interested. This is a link to how much I'm saving. Check it out!" url={window.location.origin + '/' + this.slug + '/referral/' + referralId + '/sunburst/1/' + this.convertDate(date) + '/' + offset}>
                                        <a href="#" className="facebook">
                                            <img
                                                src={require("../../../../Assets/Images/facebook.svg")}
                                                alt='facebook.svg'
                                                width="25"
                                            />
                                        </a>
                                    </FacebookShareButton>}
                                </li>
                                <li>
                                    {referralId && date && offset && 
                                    <TwitterShareButton beforeOnClick={() => this.beforeClick(2)} title="Hey, I've gone solar and thought you might be interested. This is a link to how much I'm saving. Check it out!" url={window.location.origin + '/' + this.slug + '/referral/' + referralId + '/sunburst/2/' + this.convertDate(date) + '/' + offset}>
                                        <a href="#" className="facebook">
                                            <img
                                                src={require("../../../../Assets/Images/twitter.svg")}
                                                alt='twitter.svg'
                                                width="25"
                                            />
                                        </a>
                                    </TwitterShareButton>}
                                </li>
                                <li className="disabled">
                                    <a onClick={() => this.setMessageHref("https://api.whatsapp.com/send?text=Hey, I've gone solar and thought you might be interested. This is a link to how much I'm saving. Check it out!" + window.location.origin + '/' + this.slug + '/referral/' + referralId + '/sunburst/4/' + this.convertDate(date) + '/' + offset,4)}
                                        href={'#'}
                                        className="message">
                                        <img
                                            src={require("../../../../Assets/Images/whatsapp.png")}
                                            alt='sms.svg'
                                            width="25"
                                        />
                                    </a>
                                </li>
                                <li className="disabled">
                                    {referralId && date && offset && 
                                    <a onClick={() => this.setMessageHref("sms:?&body=Hey, I've gone solar and thought you might be interested. This is a link to how much I'm saving. Check it out!" + window.location.origin + '/' + this.slug + '/referral/' + referralId + '/sunburst/3/' + this.convertDate(date) + '/' + offset,3)}
                                        href={'#'}
                                        className="message">
                                        <img
                                            src={require("../../../../Assets/Images/sms.svg")}
                                            alt='sms.svg'
                                            width="25"
                                        />
                                    </a>}
                                </li>
                            </ul> :
                            <ul>
                                <li>
                                    <img
                                        src={require("../../../../Assets/Images/facebook.svg")}
                                        alt='facebook.svg'
                                        width="25"
                                        style={{ cursor: 'not-allowed' }}
                                    />
                                </li>
                                <li>
                                    <img
                                        src={require("../../../../Assets/Images/twitter.svg")}
                                        alt='twitter.svg'
                                        width="25"
                                        style={{ cursor: 'not-allowed' }}
                                    />
                                </li>
                                <li>
                                    <img
                                        src={require("../../../../Assets/Images/whatsapp.png")}
                                        alt='twitter.svg'
                                        width="25"
                                        style={{ cursor: 'not-allowed' }}
                                    />
                                </li>
                                <li>
                                    <img
                                        src={require("../../../../Assets/Images/sms.svg")}
                                        alt='sms.svg'
                                        width="25"
                                        style={{ cursor: 'not-allowed' }}
                                    />
                                </li>
                            </ul>}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default EnergyFlow;
