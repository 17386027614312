import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import withRouter           from '../../../../shared/withRouter';
import { requestDeleteHomeOwner } from './../../actions/homeOwner';

class DeleteHomeOwner extends Component {
  constructor(props) {
    super(props);
  }
  handleDelete = () => {
    if (this.props.homeownerLoggedInUser &&
      this.props.homeownerLoggedInUser.homeownerLoggedInUserReducer &&
      this.props.homeownerLoggedInUser.homeownerLoggedInUserReducer.data &&
      this.props.homeownerLoggedInUser.homeownerLoggedInUserReducer.data.homeownerData) {

      let slug = this.props.router.params.slug;

      let homeOwnerId = this.props.homeownerLoggedInUser.homeownerLoggedInUserReducer.data
        .homeownerData.id;
      let homeOwnerEmail = this.props.homeownerLoggedInUser.homeownerLoggedInUserReducer.data
        .homeownerData.email;
      let homeOwnerCompanyId = this.props.homeownerLoggedInUser.homeownerLoggedInUserReducer.data
        .homeownerData.companyId;

      this.props.requestDeleteHomeOwner({
        homeOwnerId,
        homeOwnerEmail,
        homeOwnerCompanyId
      });
      if (
        this.props.homeOwner &&
        this.props.homeOwner.getUpdateHomeOwner !== {}
      ) {
        this.props.router.navigate('/' + slug + '/'+ homeOwnerCompanyId + '/dashboard');
      }
    }
  };

  render() {
    return (
      <section className="container delete_homeowner">
        <div className="row">
          <div className="col delete_homeowner-base text-center">
            <span
              className="delete_homeowner-close"
              onClick={() => {
                this.props.handleDeleteHomeownerPopupVisibility(false);
              }}
            >
              X
            </span>
            <h2>
              DO YOU REALLY WANT TO DELETE <br />
              THIS HOMEOWNER’S ACCOUNT?
            </h2>
            <h4>
              Deleting this homeowner’s account
              <br /> cannot be undone.
            </h4>
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-primary margr15"
                onClick={this.handleDelete}
              >
                Yes, delete
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  this.props.handleDeleteHomeownerPopupVisibility(false);
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStatetoProps = state => {
  return {
    homeownerLoggedInUser: state.homeownerLoggedInUser,
    homeOwner: state.homeOwner
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      requestDeleteHomeOwner
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStatetoProps,
    mapDispatchToProps
  )(DeleteHomeOwner)
);
