// import npm packages
import React, { Component } from "react";
import PropTypes            from "prop-types";
import * as moment          from "moment";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap/dist/css/bootstrap.min.css";
import withRouter           from './../../../../shared/withRouter';
import { setToken }         from "./../../../../shared/util";
import { jwtDecode }        from "./../../../../shared/jwt";

class Homepage extends Component {
    static propTypes = {
        history: PropTypes.object
    };

    constructor(props) {
        super(props);
    }

    componentWillMount() {
        localStorage.setItem("isLogout", true);
    }

    componentDidUpdate(){
        let token = localStorage.getItem("token");
        if (token) {
            let decoded = jwtDecode(token);
            if (!this.slug || (this.slug && decoded.slug === this.slug.toLowerCase())) {
                localStorage.setItem("isLogout", false);
                setToken(token);
                localStorage.setItem('slug', decoded.slug);
                const isStatusFeed = localStorage.getItem('isStatusFeed');
                this.props.router.navigate(`/${decoded.slug}/dashboard`)
                isStatusFeed ? this.props.router.navigate(isStatusFeed)
                    : this.props.router.navigate(`/${decoded.slug}/dashboard`);
            }
        }
    }

    login = () => {
        if (this.props.router.params.slug) {
            this.props.router.navigate(`/${this.props.router.params.slug}/login`);
        } else {
            this.props.router.navigate('/login')
        } 
    };

    videoState = (state) => {
        var iframe = document.getElementById('player').contentWindow
        iframe.postMessage('{"event":"command","func":"' + state + '","args":""}', '*');
    }

    render() {
        return (
            <React.Fragment>
                <title>Bodhi</title>
           
            <div className="dashobard-main-wrapper">
                <section className="banner-graphic">
                    <div className="overlay"></div>
                    <video playsInline="playsinline" autoPlay="autoplay" muted="muted" loop="loop" className="video-graphic">
                        <source src={require(`../../../../Assets/Videos/Bodhi_30s_2.mp4`)} type="video/mp4" />
                    </video>
                    <div className="continer">
                        <div className="row">
                            <div className="col-sm-12 text-right">
                                <a className="login"
                                    onClick={() => this.login()}
                                > Log in</a>
                            </div>
                        </div>
                        <div className="banner-content-box">
                            <div className="row">
                                <div className="col-sm-12">
                                    <h1>Say hello to</h1>
                                    <div className="bodhi-logo"><img src={require(`../../../../Assets/Images/bodhi_logo_white.png`)} />
                                    </div>
                                    <p>Bodhi keeps you updated on every step of the
                                        solar installation process and helps you understand the performance of your investment.
                        </p>
                                    <div className="video-link">
                                        <p><a className="watch-video"
                                            href="#" id="playvideo"
                                            data-toggle="modal" data-target="#watchvideo"
                                            onClick={() => { this.videoState('playVideo') }}
                                        >WATCH VIDEO</a></p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bodhi-build-for-you-wrapper">
                    <h2>Bodhi is built for you</h2>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="box-first boxes">
                                    <div className="icon text-center">
                                        <img className="web" src={require(`../../../../Assets/Images/access.png`)} />
                                        <img className="mobile" src={require(`../../../../Assets/Images/access_mobile.png`)} />
                                    </div>
                                    <h3>Access your system details</h3>
                                    <p>
                                        Easily access important information about your solar system, from technical
                                        specifications to project documents and installation photos.
                        </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="box-second boxes">
                                    <div className="icon text-center">
                                        <img className="web" src={require(`../../../../Assets/Images/track.png`)} />
                                        <img className="mobile" src={require(`../../../../Assets/Images/track_mobile.png`)} />
                                    </div>
                                    <h3>Track your project status</h3>
                                    <p>
                                        Stay up to date on every step of the project via texts and emails, from design through
                                        powering up; always knowing what to expect next.
                        </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="box-third boxes">
                                    <div className="icon text-center">
                                        <img className="web" src={require(`../../../../Assets/Images/monitor.png`)} />
                                        <img className="mobile" src={require(`../../../../Assets/Images/monitor_mobile.png`)} />
                                    </div>
                                    <h3>Monitor your energy flow</h3>
                                    <p>
                                        Understand your solar production and home energy usage with meaningful visualizations,
                                        savings totals, and system performance alerts.
                        </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="progress-graphic-box">
                    <img src={require(`../../../../Assets/Images/progress&flow.jpg`)} />
                </section>
                <footer className="footer-wrapper">
                    <div>Bodhi is a product of © 17TeraWatts 2018-{moment().format('YYYY')} </div>
                    <div><a href={`${window.location.href}terms`}> Terms of Service</a> | <a href={`${window.location.href}privacy-policy`}>Privacy Policy</a></div>
                    <div><a href="https://www.17terawatts.com/" target="_blank">www.17terawatts.com</a> | <a href="mailto:info@17terawatts.com">info@17terawatts.com</a></div>
                </footer>

                <div className="modal fade" id="watchvideo" tabIndex="-1" role="dialog" aria-labelledby="watchvideoTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-body">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.videoState('pauseVideo')}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div id="watch-video" className="mfp-hide embed-responsive embed-responsive-21by9" style={{
                                    position: "relative",
                                    paddingBottom: "56.25%" /* 16:9 */,
                                    paddingTop: 25,
                                    height: 0
                                }}>
                                    <iframe id="player" style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%"
                                    }} src="https://www.youtube.com/embed/l5YHpeS19cQ?start=0&version=3&enablejsapi=1&playerapiid=ytplayer" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
            </React.Fragment>
        );
    }
}

export default withRouter(Homepage);
