import React from 'react';
import PropTypes from 'prop-types';
import withRouter   from '../../../../shared/withRouter';
import { getToken } from '../../../../shared/util';
import { ReactComponent as DasboardActive }         from "../../../../Assets/Images/home-blue.svg";
import { ReactComponent as DasboardInActive }       from "../../../../Assets/Images/home-icon.svg";
import { ReactComponent as SolarCommunityActive }   from "../../../../Assets/Images/shareSun_icon.svg";
import { ReactComponent as SolarCommunityInActive } from "../../../../Assets/Images/shareSun_inActive.svg";
import { ReactComponent as SearchInActive }         from "../../../../Assets/Images/sun_rays.svg";
import { ReactComponent as SearchActive }           from "../../../../Assets/Images/sun-icon.svg";
let timeOut;

class FooterComponent extends React.Component {
  static propTypes = {
    isSignedIn: PropTypes.string,
    signUpError: PropTypes.bool,
    signOut: PropTypes.func,
    signIn: PropTypes.func,
    signUp: PropTypes.func,
    auth: PropTypes.object
  };
  constructor(props) {
    super(props);
    this.dataSource = [];
    this.data = {};
    this.dasboardActive = '';
    this.searchActive = '';
    this.addhomeownerActive = '';
    this.state = {
      dasboardActive: 'home-blue.svg',
      searchActive: 'sun-icon.svg',
      addhomeownerActive: 'group7.png',
      solarCommunityActive: 'shareSun_icon.svg'
    };
  }
  get token() {
    return getToken();
  }
  componentWillMount() {
    this.setIcons();
  }
  componentDidUpdate(prevProps) {
    if (this.props.router.location.pathname !== prevProps.router.location.pathname) {
      this.setIcons();
    }
  }
  setIcons() {
    let { params } = this.props.router;
    this.slug = params.slug;
    let {
      dasboardActive,
      searchActive,
      addhomeownerActive,
      systemGlance,
      solarCommunityActive
    } = this.state;

    if (this.props.router.location.pathname.includes('/dashboard')) {
      dasboardActive = <DasboardActive />
    } else {
      dasboardActive = <DasboardInActive
        onClick={() => {
          if (
            document.body.scrollTop !== 0 ||
            document.documentElement.scrollTop !== 0
          ) {
            window.scrollBy(0, -1500000000);
          } else clearTimeout(timeOut);
          if (this.props.isHomeownerProtal)
            this.props.router.navigate('/' + this.slug + '/dashboard');
          else
            this.props.router.navigate(`/${params.slug}/${params.companyId}/dashboard`);

        }}
      />
    }
    if (this.props.router.location.pathname.includes('/search')) {
      searchActive = <SearchActive onClick={() => {
        this.props.router.navigate(`/${params.slug}/${params.companyId}/search`);
      }} />
    } else {
      searchActive = <SearchInActive onClick={() => {
        this.props.router.navigate(`/${params.slug}/${params.companyId}/search`);
      }} />
    }

    if (this.props.router.location.pathname === '/' + this.slug + '/systemGlance') {
      systemGlance = <SearchActive />
    } else {
      systemGlance = <SearchInActive onClick={() => {
        if (
          document.body.scrollTop !== 0 ||
          document.documentElement.scrollTop !== 0
        ) {
          window.scrollBy(0, -1500000000);
        } else clearTimeout(timeOut);
        this.props.router.navigate('/' + this.slug + '/systemGlance');
      }} />
    }

    if (
      this.props.router.location.pathname.includes('addhomeowner')
    ) {
      addhomeownerActive = 'new-install.svg';
    } else {
      addhomeownerActive = 'group7.png';
    }

    if (this.props.router.location.pathname === '/' + this.slug + '/solarCommunity') {
      solarCommunityActive = <SolarCommunityActive />
    } else {
      solarCommunityActive = <SolarCommunityInActive onClick={() => {
        if (
          document.body.scrollTop !== 0 ||
          document.documentElement.scrollTop !== 0
        ) {
          window.scrollBy(0, -1500000000);
        } else clearTimeout(timeOut);
        this.props.router.navigate('/' + this.slug + '/solarCommunity');
      }} />
    }
    this.setState({
      dasboardActive,
      searchActive,
      systemGlance,
      addhomeownerActive,
      solarCommunityActive
    });
  }

  renderInstallerFooter() {
    let { params } = this.props.router;
    return (
      <div className="footer-17">
        <div
          className={`mob-menu ${window.location.pathname.includes('dashboard') ? 'premiumIcon' : ''}`}
        >
          {this.state.dasboardActive}
          <span>Home</span>
        </div>
        <div
          className={`mob-menu ${window.location.pathname.includes('search') ? 'premiumIcon' : ''}`}
        >
          {this.state.searchActive}
          <span>Search</span>
        </div>
        <div
          className={`mob-menu ${window.location.pathname.includes('addhomeowner') ? 'active' : ''}`}
        >
          <img
            src={require(`./../../../../Assets/Images/${this.state.addhomeownerActive
              }`)}
            style={{ width: '32px' }}
            onClick={() => {
              this.props.router.navigate(`/${params.slug}/${params.companyId}/addhomeowner`);
            }}
            alt="addhomeowner"
          />
          <span style={{ paddingLeft: '10px' }}>Add</span>
        </div>
      </div>
    );
  }

  renderHomerOwnerFooter() {
    return (
      <div className="footer-17">
        <div
          className={`mob-menu ${window.location.pathname === '/' + this.slug + '/dashboard' ? 'premiumIcon' : ''}`}
        >
          {this.state.dasboardActive}
          <span>Home</span>
        </div>
        <div
          className={`mob-menu ${window.location.pathname === '/' + this.slug + '/systemGlance' ? 'premiumIcon' : ''
            }`}
        >
          {this.state.systemGlance}
          <span>System</span>
        </div>
        <div
          className={`mob-menu ${window.location.pathname === '/' + this.slug + '/solarCommunity' ? 'premiumIcon' : ''}`}
        >
          {this.state.solarCommunityActive}
          <span>Share The Sun</span>
        </div>
      </div>
    );
  }
  render() {
    if (!this.token) {
      return null;
    }
    if (this.props.isHomeownerProtal) {
      return this.renderHomerOwnerFooter();
    }
    return this.renderInstallerFooter();
  }
}

export default withRouter(FooterComponent);
