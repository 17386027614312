// import node modules
import React, { Component }     from "react";
import moment                   from "moment";
import withRouter               from '../../../../shared/withRouter'
import { getToken }             from "../../../../shared/util";
import EnergyTips               from '../../components/energyTips';
import BodhiJourney             from './../../components/shared/bodhiJourney';
import { setHeight }            from '../../../../shared/setHeight';
import StatusFeed               from '../statusFeed';
import phaseInfoJSON            from "../../../Common/phaseInfo.json"
import {replaceStatusFeedText}  from "../statusFeed/replaceText";
import {filterHomeownerTouches} from "../../../Common/FilterTouchHistory";
import {ReactComponent as ChevronDown  } from "../../../../Assets/Images/chevronDown.svg";
import {ReactComponent as ChevronUP  }   from "../../../../Assets/Images/chevronUP.svg";

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            onNextInfo: false,
        }
    }

    componentDidMount(){
        setHeight('homeOwnerWrapper', 'menuMobile');
    }

    renderMoreDetails(phase) {
        const { loggedInUser, systemGlance } = this.props;
        if(loggedInUser && loggedInUser.solarUnit && phase=='2' && loggedInUser.solarUnit.permitMessage)
            return loggedInUser.solarUnit.permitMessage.replace(/{AHJ}/g, loggedInUser.solarUnit.ahj).replace(/{UTILITY}/g, this.displayUtilityName(systemGlance.solarGlance.utilityName,systemGlance.solarGlance.utilityLabel));

        if (!phase || !systemGlance || !systemGlance.solarGlance)
            return '';

        let text = '';    
        let days = 'some';

        let utilityName = this.displayUtilityName(systemGlance.solarGlance.utilityName,systemGlance.solarGlance.utilityLabel);
        switch (parseInt(phase)) {
            case 1:
                days = systemGlance.phaseGlance.DesignPhaseCutOff;
                text = systemGlance.phaseGlance.DesignDetail     || phaseInfoJSON['DesignDetail']
            break
            case 2:
                days = systemGlance.phaseGlance.PermittingPhaseCutOff;
                text = systemGlance.phaseGlance.PermittingDetail || phaseInfoJSON['PermittingDetail']
            break
            case 3:
                days = systemGlance.phaseGlance.BuildPhaseCutOff;
                text = systemGlance.phaseGlance.BuildDetail      || phaseInfoJSON['BuildDetail']
            break
            case 4:
                days = systemGlance.phaseGlance.InspectionPhaseCutOff;
                text = systemGlance.phaseGlance.InspectionDetail || phaseInfoJSON['InspectionDetail']
            break
            case 5:
                days = systemGlance.phaseGlance.PoweringUpPhaseCutOff;
                text = systemGlance.phaseGlance.PoweringUpDetail || phaseInfoJSON['PoweringUpDetail']
            break
            case 6:
                text = systemGlance.phaseGlance.PoweringUpDetail || ''
            default:
                text = '';
        }

        return text.replace(/{UTILITY}/g,utilityName).replace(/{PHASE_CUTTOFF_DAYS}/g,days);
    }

    displayUtilityName = (utilityName,utilityLabel) => {
        if(utilityLabel)
            return utilityLabel;
        else
            return utilityName;
    }

    whatsNextText(content, systemGlance, loggedInUser, customTouchData) {
        return content && replaceStatusFeedText(content,systemGlance,loggedInUser,customTouchData,this.props.router.params,customTouchData);
    }

    renderDashboard = () => {
        const { systemGlance, loggedInUser, eversignDetails,companyJourney,activeCampaign,magicData } = this.props;                        
        let currentDate = new Date().getTime();
        let activationDate = systemGlance && parseInt(systemGlance.solarGlance && systemGlance.solarGlance.phaseName && (systemGlance.solarGlance.phaseName === "6") && systemGlance.solarGlance.activationDate);
        let apiData = (loggedInUser && loggedInUser.solarUnit) ||  {};   
        let filterData = apiData.touchHistory && filterHomeownerTouches(apiData.touchHistory,[],true,companyJourney)[0];

        const touchName = (filterData && filterData.touchName) || '';
        
        let customTouchData = companyJourney&& filterData &&companyJourney[touchName.toLowerCase().replace(/ /g, '')]
        let whatsNextContent =  customTouchData && customTouchData['whatsNext'];
        let whatsNextTitle =  customTouchData && customTouchData['whatsNextTitle']
        if (loggedInUser && loggedInUser.solarUnit && 
            loggedInUser.solarUnit.dailySyncTime
        ) {

            return (<EnergyTips
                isInstaller={this.props.isInstaller}
                location={this.props.router.location}
                installerDetails={this.props.installerDetails}
                companyDetail={this.props.companyDetail}
                homeOwnerId={this.props.homeOwnerId}
                requestHomeownerEnergyData={this.props.requestHomeownerEnergyData}
                receiveHomeownerEnergyData={this.props.receiveHomeownerEnergyData}
                energyReport={this.props.energyReport}
                setLoader={this.props.setLoader}
                loggedInUser={loggedInUser}
                systemGlance={systemGlance}
                eversignDetails={this.props.eversignDetails}
                {...this.props}
            />)
                
        }

        if(magicData && filterData && magicData.length>0 && (magicData[magicData.length-1].createdAt > filterData.createdAt)){
            whatsNextContent     = 'Once we complete the installation of your solar system, we will coordinate the inspection and utility approval before it can be turned on.  Typically the inspection takes place within a few days of our work being completed, and we will update you soon on the utility process timeline.';
            whatsNextTitle       = 'Complete Installation';
            filterData.createdAt =  magicData[magicData.length-1].createdAt;
        }

        let titleValue = loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.companyName;
        return (
            <>
            <title>My Dashboard - {titleValue}</title>
            <div className='row'>
                <div className="mobile-view-only">
                    <BodhiJourney {...this.props} isInstaller={false} />
                </div>
                <div className="next-level-wrapp">
                    {
                        (
                            <div className="white-box  margb35 border-r0 border0">
                                <div className="premiumBackground premiumSecondaryText margb20 border-r0 padding-22">
                                    <div className="heading-small">What’s next?</div>
                                    <h2 className="dashboard-shedule-heading">{whatsNextTitle}</h2>
                                    <p className="dashboard-shedule-text">{this.whatsNextText(whatsNextContent,systemGlance,loggedInUser,customTouchData)}</p>
                                    <p></p>

                                    <div className="dashboar-info-box">
                                        <span className="text">
                                            {
                                               loggedInUser.homeownerData && moment(filterData && filterData.createdAt).utcOffset(loggedInUser.homeownerData.offset * 60).format('MMM DD, h:mm a')
                                            }
                                        </span>
                                        <span className="float-right">
                                            <a href="#"
                                                data-toggle="collapse"
                                                data-target="#collapseDetails"
                                                aria-expanded="true"
                                                aria-controls="collapseDetails"
                                                onClick={() => this.setState({ onNextInfo: !this.state.onNextInfo })}
                                            >
                                                {this.state.onNextInfo ? this.collapse() : this.expand()}

                                            </a>
                                        </span>
                                    </div>
                                </div>
                                <div id="collapseDetails" className="more-detail-wrapper border-r0 collapse">
                                    <h3> More details</h3>
                                    {this.renderMoreDetails(filterData && filterData.phase).split("\n").map((item, idx) => {
                                        return (
                                            <span key={idx}>
                                                {item}
                                                <br />
                                            </span>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }

                    {eversignDetails && eversignDetails.map((detail) =>
                     !detail.signerSigned && 
                        <StatusFeed
                            {...this.props}
                            docCreatedAt={detail.createdAt}
                            eversignDocument = {detail}
                            isDocument={true}
                            isInstaller={this.props.isInstaller}
                        />
                    )}

                    {/* {first one for weekly} */}
                    {
                        this.props.loggedInUser
                        && this.props.loggedInUser.homeownerData
                        && !this.props.loggedInUser.homeownerData.isExisting
                        && (((currentDate - activationDate) / 86400000) >= 7)
                        && (((currentDate - activationDate) / 86400000) < 30)
                        &&
                        <StatusFeed dateParam={{
                            startDate: ((activationDate) / 1000).toFixed(),
                            endDate: ((activationDate / 1000 + (7 * 86400))).toFixed()
                        }} touchNameData={{ touchName: '1 week impact and survey', createdAt: (activationDate + (7 * 86400000)) }} {...this.props} showCard={true} />
                    }
                    {/* {second one for month} */}
                    {
                        this.props.loggedInUser
                        && this.props.loggedInUser.homeownerData
                        && !this.props.loggedInUser.homeownerData.isExisting
                        && (((currentDate - activationDate) / 86400000) >= 30)
                        && (((currentDate - activationDate) / 86400000) < 60)
                        &&
                        <StatusFeed dateParam={{
                            startDate: ((activationDate) / 1000).toFixed(),
                            endDate: ((activationDate / 1000 + (30 * 86400))).toFixed()
                        }} touchNameData={{ touchName: '1st Month Impact', createdAt: (activationDate + (30 * 86400000)) }} {...this.props} showCard={true} />
                    }
                    {/* {third one for year} */}
                    {
                        this.props.loggedInUser
                        && this.props.loggedInUser.homeownerData
                        && !this.props.loggedInUser.homeownerData.isExisting
                        && (((currentDate - activationDate) / 86400000) >= 365)
                        && (((currentDate - activationDate) / 86400000) < 395)
                        &&
                        <StatusFeed dateParam={{
                            startDate: ((activationDate) / 1000).toFixed(),
                            endDate: ((activationDate / 1000 + (365 * 86400))).toFixed()
                        }} touchNameData={{ touchName: '1 Year Impact', createdAt: (activationDate + (365 * 86400000)) }} {...this.props} showCard={true} />
                    }

                    {/* Render Status feed */}
                    {
                        loggedInUser
                        && loggedInUser.homeownerData
                        && loggedInUser.solarUnit
                        && loggedInUser.solarUnit.touchHistory
                        && loggedInUser.solarUnit.touchHistory.length !== 0
                        && loggedInUser.solarUnit.phase
                        && filterHomeownerTouches(loggedInUser.solarUnit.touchHistory, activeCampaign,'activityFeed',companyJourney,magicData)
                            .map((touchNameData, index) => {
                                let whatsNextData = (companyJourney&& touchName &&companyJourney[touchName.toLowerCase().replace(/ /g, '')] && 
                                companyJourney[touchName.toLowerCase().replace(/ /g, '')])||  null;

                                if ((whatsNextData
                                    && !loggedInUser.homeownerData.isExisting)
                                || (touchNameData && touchNameData.isCampaign) || (touchNameData && touchNameData.Customer_Email)) {
                                    return (
                                        <StatusFeed
                                        {...this.props}
                                        isCampaign = {touchNameData.isCampaign}
                                        touchNameData={touchNameData}
                                        key={index}
                                        campaignData={touchNameData.isCampaign && touchNameData}
                                        isMagicFileData = {(touchNameData && touchNameData.magicData) ? true : false}
                                      />
                                    )
                                }
                                return ''

                            })
                    }


                </div>
            </div>
            </>
        );
    };

    collapse = () => {
        return <React.Fragment><span className="text info premiumSecondaryText">Collapse</span><ChevronUP/></React.Fragment>
    }

    expand = () => {

        return <React.Fragment><span className="text info premiumSecondaryText">More info</span><ChevronDown/></React.Fragment>
    }

    render() {
        if (getToken()) {
            return this.renderDashboard();
        } else {
            this.props.router.navigate("/");
            return null;
        }
    }
}

export default withRouter(Dashboard);