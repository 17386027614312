import { connect }            from 'react-redux'
import { reducer }            from 'aws-cognito-redux-saga'
import { bindActionCreators } from "redux";
import HeaderComponent        from './Header'
import { requestSearchData,requestAdminHomeownerSearch }  from "../../Actions/header";

const mapStatetoProps = state => {
  return {
    auth: state.auth,
    homeownerList: state.adminHeader.adminListHomeowner.data,
    searchData: state.adminHeader.searchData
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({ requestSearchData,
    requestAdminHomeownerSearch,
     signOut: () => dispatch(reducer.signOut()) }, dispatch)

)

export default connect(mapStatetoProps, mapDispatchToProps)(HeaderComponent)
