import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Input, Form, Checkbox, Tooltip } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import UserAvatar from 'react-user-avatar';
import { jwtDecode } from '../../../../shared/jwt';
import { getToken, ROLE } from '../../../../shared/util';
import LogoUpload from '../../../Common/LogoUpload';
import ChangePassword from './changePassword';
import { requestUpdateLoggedInInstallerById } from '../../actions/profile';
import alertIcon from '../../../../Assets/Images/alert.svg';
import personHeartIcon from '../../../../Assets/Images/person_heart.svg';

const FormItem = Form.Item;

function SettingsForm(props) {
  const [form] = Form.useForm();

  const {
    handleSubmit,
    mobileImage,
    installerName,
    image,
    uploadUrl,
    getSource,
    data,
    errorObj,
    formSetting,
    installerData,
    numberRangeHandler,
    weeklyReportInitialValue,
    weeklyReportChangeHandler,
    referralReportInitialValue,
    referralReportChangeHandler,
    setLoader,
    cancelhandlerPS
  } = props;

  return (
    <Form form={form} onFinish={handleSubmit}>
      <div className="section-from-box personal-setting-wrap">
        <div>
          <div className="row">
            <div className="col-md-4 col-12">
              <div className="profile-setting">
                {image ? (
                  <div
                    className="profile-pic mobile-profile-pic"
                    style={{
                      backgroundImage: `url(${mobileImage})`
                    }}
                  />
                ) : (
                  <UserAvatar
                    className="profile-pic mobile-profile-pic premiumIconBackGround"
                    size="140"
                    name={installerName || ''}
                  />
                )}
                {image ? (
                  <div
                    className="profile-pic desktop-profile-pic"
                    style={{
                      backgroundImage: `url(${image})`
                    }}
                  />
                ) : (
                  <UserAvatar
                    className="profile-pic desktop-profile-pic premiumIconBackGround"
                    size="140"
                    name={installerName || ''}
                  />
                )}
                <div className="edit-proile-image">
                  <div className="setting-profile-update">
                    <LogoUpload
                      setLoader={setLoader}
                      photoUrl={uploadUrl}
                      type="desktop"
                      getSource={getSource}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="row">
                <div className="col-sm-7">
                  <div className="form-group">
                    <label> Name</label>
                    <Form.Item
                      hasFeedback
                      name="name"
                      rules={[{ required: true }, { pattern: /[a-zA-Z0-9][a-zA-Z0-9 ]*/ }]}
                      initialValue={data ? data.name : ''}>
                      <Input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-sm-5">
                  <div className="form-group">
                    <Tooltip
                      overlayClassName="black-tooltip"
                      placement="topLeft"
                      title="Leaving this blank will default your Title to Project Manager.">
                      <label>Title</label>
                    </Tooltip>
                    <Form.Item
                      name="title"
                      rules={[{ required: false }]}
                      initialValue={data ? data.title : 'Project Manager'}>
                      <Input type="text" className="form-control" />
                    </Form.Item>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Email</label>
                <Form.Item
                  hasFeedback
                  name="email"
                  rules={[{ required: false }]}
                  initialValue={data ? data.email : ''}>
                  <Input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail2"
                    aria-describedby="emailHelp"
                    disabled
                  />
                </Form.Item>
              </div>
              <div className="row">
                <div className="col-sm-8">
                  <div className="form-group">
                    <label>Phone Number </label>
                    <Form.Item
                      hasFeedback
                      name="phone"
                      validateStatus={errorObj.phoneNumber || ''}
                      rules={[{ required: true }]}
                      initialValue={data ? data.phone : ''}>
                      <NumberFormat
                        format={formSetting && formSetting.phone}
                        type="tel"
                        mask=" "
                        className="ant-input"
                        placeholder="(   )  -  "
                        onChange={numberRangeHandler}
                      />
                    </Form.Item>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="form-group">
                    <label>Extension</label>
                    <Form.Item
                      name="extension"
                      rules={[{ required: false }]}
                      initialValue={data ? data.extension : null}>
                      <NumberFormat
                        format="###"
                        type="tel"
                        mask=" "
                        className="ant-input"
                        placeholder="###"
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div />
          <div className="row">
            <div className="margt40 mx-auto">
              <button
                className="btn btn-secondary margr15"
                type="button"
                onClick={() => cancelhandlerPS(form)}>
                {' '}
                Cancel
              </button>
              <button className="btn btn-primary" type="submit">
                Save settings
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="change-pwd-section">
        <h2>
          <img
            src={alertIcon}
            alt="setting-icon-blue.svg"
            className="notify-bell"
            style={{ height: '36px' }}
          />
          &nbsp;Notification settings
        </h2>
        <div className="row" />
      </div>
      <div className="col-md-8 col-sm-12 offset-md-2">
        <div className="form-group notification-btn margb10">
          <FormItem
            name="weeklyReport"
            initialValue={weeklyReportInitialValue}
            valuePropName="checked">
            <Checkbox onChange={weeklyReportChangeHandler} size="small">
              <span className="paddl10 text-left">Weekly summary</span>
            </Checkbox>
          </FormItem>
        </div>
        <div className="form-group notification-btn referralReport">
          <FormItem
            name="referralReport"
            initialValue={referralReportInitialValue}
            valuePropName="checked">
            <Checkbox
              checkedChildren="ON"
              unCheckedChildren="OFF"
              size="medium"
              onChange={referralReportChangeHandler}>
              <span className="paddl10 text-left">Referral summary</span>
            </Checkbox>
          </FormItem>
        </div>
      </div>
      <div className="change-pwd-section pl-1">
        <h2>
          <img src={personHeartIcon} alt="setting-icon-blue.svg" className="notify-bell" />
          &nbsp;Change password
        </h2>
        <div className="row" />
      </div>
    </Form>
  );
}

class ProfileSettingForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorObj: {},
      uploadUrl: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.updateInstallerById) {
      if (
        nextProps.updateInstallerById.status === 200 ||
        nextProps.updateInstallerById.status === 201
      ) {
        const token = getToken();
        this.installerData = jwtDecode(token);
      }
    }
    if (nextProps.installerData && nextProps.installerData.photo) {
      if (!this.state.uploadUrl) {
        this.setState({
          uploadUrl: nextProps.installerData.photo
        });
      }
    }
  }

  onReportChange = (value, type, reportType) => {
    const data = this.props.installerData;
    const empData = {
      bodyData: { [reportType]: value },
      companyId: data.companyId,
      installerId: data.id,
      message: `User ${value == true ? 'selected' : 'de-selected'} for ${type} Report`
    };
    this.props.setLoader({ loader: true });
    this.props.requestUpdateLoggedInInstallerById(empData);
  };

  switchValue(value) {
    if (value || value === false) {
      return value;
    }
    return true;
  }

  getSource = (url) => {
    this.setState({
      uploadUrl: url
    });
  };

  cancelhandlerPS = (form) => {
    if (this.props.installerData) {
      this.setState({
        uploadUrl: this.props.installerData.photo || ''
      });
    }
    form.resetFields();
  };

  handleSubmit = (values) => {
    if (JSON.stringify(this.state.errorObj) !== '{}' && this.state.errorObj.phoneNumber) {
      return null;
    }
    const token = getToken();
    this.installerData = jwtDecode(token);
    values.photo = this.state.uploadUrl;
    values.isProfileCreated = true;
    values.title = values.title || 'Project Manager';
    values.extension = values.extension
      ? typeof values.extension === 'string'
        ? parseInt(values.extension.replace(/[^0-9]/g, ''))
        : values.extension
      : null;
    values.phone = values.phone ? parseInt(values.phone.replace(/[^0-9]/g, '')) : undefined;
    const data = {
      bodyData: values,
      companyId: this.installerData.companyId,
      installerId: this.installerData.installerId
    };
    this.props.setLoader({ loader: true });
    this.props.requestUpdateLoggedInInstallerById(data);
  };

  // check the range of number
  checkNumberRange(value, range, type) {
    const { errorObj } = this.state;
    switch (type) {
      case 'phoneNumber':
        if (value.length !== (range || 10)) {
          errorObj.phoneNumber = 'error';
        } else {
          errorObj.phoneNumber = '';
        }
        this.setState({
          errorObj
        });
        break;
      default:
        if (value > range) {
          errorObj[type] = 'error';
        } else {
          errorObj[type] = '';
        }
        this.setState({
          errorObj
        });
        break;
    }
  }

  render() {
    const { data, installerData } = this.props;

    let image = this.state.uploadUrl || (installerData && installerData.photo);
    const installerName = installerData && (installerData.name || installerData.companyName);
    const formSetting = installerData && installerData.formSetting;

    if (image && image.includes('installer-avatar')) {
      image = null;
    }

    const mobileImage = this.state.uploadUrl;

    if (data) {
      return (
        <>
          <SettingsForm
            handleSubmit={this.handleSubmit}
            mobileImage={mobileImage}
            installerName={installerName}
            image={image}
            uploadUrl={this.state.uploadUrl}
            getSource={this.getSource} // to pass down to LogoUpload
            errorObj={this.state.errorObj}
            installerData={installerData}
            data={data}
            formSetting={formSetting}
            numberRangeHandler={(e) => {
              this.checkNumberRange(
                e.target.value.replace(/[^0-9]/g, ''),
                formSetting && formSetting.phoneLength,
                'phoneNumber'
              );
            }}
            cancelhandlerPS={(form) => this.cancelhandlerPS(form)}
            weeklyReportInitialValue={
              this.props &&
              this.props.installerData &&
              this.switchValue(this.props.installerData.weeklyReport)
            }
            weeklyReportChangeHandler={(e) => {
              this.onReportChange(e.target.checked, 'Weekly', 'weeklyReport');
            }}
            referralReportInitialValue={
              this.props &&
              this.props.installerData &&
              this.switchValue(this.props.installerData.referralReport)
            }
            referralReportChangeHandler={(e) =>
              this.onReportChange(e.target.checked, 'Referral', 'referralReport')
            }
            setLoader={this.props.setLoader} // to pass down to LogoUpload
          />
          <div className="col-md-8 col-sm-12 offset-md-2">
            <ChangePassword
              role={ROLE['INSTALLER']}
              setLoader={(loader) => {
                this.props.setLoader(loader);
              }}
              email={data.email}
            />
          </div>
        </>
      );
    }
    return null;
  }
}

const mapStatetoProps = (state) => {
  return {
    auth: state.auth,
    companyName: state.user.slugImage,
    updateInstallerById: state.setting.updateInstallerById,
    installerData: state.header.getInstaller.data,
    inviteResponse: state.setting.inviteEmployees
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      requestUpdateLoggedInInstallerById
    },
    dispatch
  );
export default connect(mapStatetoProps, mapDispatchToProps)(ProfileSettingForm);
