// import npm packages
import React, { Component } from 'react'

// import local files
import { MONTH_ARRAY } from './../../../../shared/config'
import { jwtDecode } from "../../../../shared/jwt";
import { getToken } from "../../../../shared/util";
import { JSONToCSVConvertor } from './../../../../shared/downloadCSV';
import { openNotificationWithIcon } from '../../../../shared/notification';
import { capitalize } from '../../../../shared/capitalize';

export default class SocialShares extends Component {
  
  static getDerivedStateFromProps(props, state) {
    const { downloadSocialShareData } = props
    if (
      downloadSocialShareData
      && downloadSocialShareData.status
      && downloadSocialShareData.status === 200
    ) {
      const data = downloadSocialShareData.data;
      let token = getToken();
      let decoded = jwtDecode(token);
      if(data && data.socialSharesCount && data.socialSharesCount.length && data.socialSharesCount.length !== 0)
        JSONToCSVConvertor(data.socialSharesCount, `${decoded.companyId}_socialShare`, true)
      else
        openNotificationWithIcon('error', 'No data found', 'Error')
      props.receiveDownloadSocialShareData({})
    }
  }

  handleChangeMonth = (e) => {
    let monthId = e.target.value;
    let token = getToken();
    let decoded = jwtDecode(token);
    const data = {
      companyId: decoded.companyId,
      installerId: decoded.installerId,
      month: monthId
    };
    if(monthId)
      this.props.requestDownloadSocialShareData(data)
  }
  
  socialShare() {
    const tempSocialShare = [{
      name: 'facebook',
      imageName : 'facebook.svg',
      count: 0
    }, {
      name: 'twitter',
      imageName : 'twitter.svg',
      count: 0
    }, {
      name: 'SMS',
      imageName : 'sms.svg',
      count: 0
    },{
      name:'whatsApp',
      imageName : 'whatsapp.png',
      count:0
    }
  ]
    const { socialShareData } = this.props;
    if(
      socialShareData
      && socialShareData.data
      && socialShareData.data.socialSharesCount
      && socialShareData.data.socialSharesCount.length
      && socialShareData.data.socialSharesCount.length !== 0 
    ) {
      socialShareData.data.socialSharesCount.forEach(val => {
        const index = tempSocialShare.findIndex(data => data.name.toLowerCase() === val.name.toLowerCase())
        if(index !== -1) {
          tempSocialShare[index].count = val.count;
        }
      })
    }
    return tempSocialShare;
  }
  render() {
    
    return (
      <div className='utility-tab-main-pnl box-Shadow white-box margb70'>
        <div className='heading d-flex socialShare-outer' style={{marginTop: '25px'}}>
          <h2 className='w-100 font-weight-400 margin-bottom-20'>Social shares</h2>
          <div className='justify-content-end'>
            <section className='white-box-select'>
              <select onChange={(e) => this.handleChangeMonth(e)}>
                <option value=''>
                Download Month
                </option>
                {
                  MONTH_ARRAY && MONTH_ARRAY.map((item, index) => (
                    <option value={index} >
                      {item}
                    </option>
                  ))
                }
              </select>
            </section>
          </div>
        </div>
        <div className='pddl30 pddr30 pddb30 padding-left-10px'>
          {
              this.socialShare().map( socialShare => (
                <div className='d-flex social-media-box'>
                  <div className='col-sm-8 col-8'>
                    <div className='icon-text'>
                      <img src={require(`./../../../../Assets/Images/${socialShare.imageName}`)} alt={socialShare.name} />
                      {" "} {socialShare && socialShare.name && capitalize(socialShare.name)}
                    </div>
                  </div>
                  <div className='col-sm-4 text-right col-4'>
                    <div className='number-box'>
                      {socialShare.count}
                    </div>
                    <small>Shares</small>
                  </div>
                </div>
              ))
          }
        </div>
      </div>
    )
  }
}
