// import npm packages
import React, { Component }     from "react";
import { Form, Slider, Input }  from "antd";
import { bindActionCreators }   from "redux";
import { connect }              from "react-redux";
import UserAvatar               from "react-user-avatar";
import NumberFormat             from "react-number-format";

// import local files
import withRouter from '../../../../shared/withRouter';
import { getToken } from "../../../../shared/util";
import { jwtDecode } from "../../../../shared/jwt";
import { requestPostContactData, receivePostContactData } from "../../actions/contact";
import { openNotificationWithIcon } from '../../../../shared/notification';
import { capitalize } from '../../../../shared/capitalize';
import { setHeight } from '../../../../shared/setHeight';

const FormItem = Form.Item;
const { TextArea } = Input;

const ContactFormPart = props => {
    const [form] = Form.useForm()
    const {
        handleSubmit,
        isInstaller,
        handleSlider,
        sliderStatus,
        reset
    } = props

    if (reset) {
        form.resetFields()
    }

    return (
        <Form form={form} onFinish={handleSubmit}>
            <div className="form-group" style={{paddingTop:'20px'}}>
                <FormItem
                    name="message"
                >
                    <TextArea
                        disabled={isInstaller ? 'disabled' : ''}
                        className="textarea"
                        placeholder="Type message here..."
                        rows={6}
                    />
                </FormItem>
            </div>
            <p className="paddt15">
                How has your experience been so far?
            </p>
            <div className="text-left">
                <FormItem
                    name="rating"
                    initialValue = { 3 }
                >
                    <Slider
                        className="premiumLinks"
                        disabled={isInstaller}
                        min={1}
                        max={5}
                        onChange={val => {
                            handleSlider(val)
                        }}
                    />
                </FormItem>
            </div>
            <ul className="ratingSlider" style={{ fontSize: 'small' }}>
                <li>{sliderStatus === 'Poor' ? <span className="premiumLinks">Poor</span> : <span>Poor</span>}</li>
                <li className="text-center">{sliderStatus === 'dot1' ? <span className="dots premiumLinks"></span> : <span className="dots"></span>}</li>
                <li className="text-center">{sliderStatus === 'Satisfied' ? <span className="premiumLinks">Neutral</span> : <span>Neutral</span>}</li>
                <li className="text-center">{sliderStatus === 'dot2' ? <span className="dots premiumLinks"></span> : <span className="dots"></span>}</li>
                <li className="text-right">{sliderStatus === 'LoveIt' ? <span className="premiumLinks">Love It</span> : <span>Love It</span>}</li>
            </ul>
            <div className="text-center">
                <button
                    style={{marginTop: '40px'}}
                    className="btn yellow-btn premiumButtonFill premiumButtonText rating-slider-btn"
                    disabled={isInstaller ? 'disabled' : ''}
                >
                    Send
                </button>
            </div>
        </Form>
    )
}

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderStatus: 'Satisfied'
        }
    }

    componentWillReceiveProps(nextProps) {
        setHeight('homeOwnerWrapper', 'menuMobile');
        let message = nextProps.contactDetails.homeownerContactDetails.data;
        if (message && message.message === "message sent") {
            openNotificationWithIcon('success', 'Message sent successfully', 'Success');
            this.props.receivePostContactData('');
        }
    }
    
    handleSlider = (rating) => {
        if (rating === 1)
            this.setState({ sliderStatus: 'Poor' });
        else if (rating === 2)
            this.setState({ sliderStatus: 'dot1' });
        else if (rating === 3)
            this.setState({ sliderStatus: 'Satisfied' });
        else if (rating === 4)
            this.setState({ sliderStatus: 'dot2' });
        else if (rating === 5)
            this.setState({ sliderStatus: 'LoveIt' });
    }

    handleSubmit = values => {
        let {loggedInUser:{homeownerData}} = this.props;

        this.props.setLoader({ loader: true })
        this.props.requestPostContactData({
            companyId       : homeownerData.companyId,
            homeownerId     : homeownerData.id,
            message         : values.message,
            statusFeed      : false,
            rating          : values.rating,
            homeOwnerName   : `${homeownerData.firstName} ${homeownerData.lastName}`,
            homeOwnerEmail  : homeownerData.email,
            installerId     : homeownerData.installerId,
            installerName   : homeownerData.installerName,
            installerEmail  : homeownerData.installerEmail
        });
    }

    render() {
        const { loggedInUser } = this.props;
        let installerDetails = loggedInUser && loggedInUser.homeownerData;
        let formSetting = loggedInUser.homeownerData && loggedInUser.homeownerData.formSetting;
        let titleValue = installerDetails && installerDetails.companyName;
        let photo = installerDetails && installerDetails.photo;
        if (photo && photo.includes('installer-avatar')) {
            photo = null;
        }
        let installerName = (installerDetails && installerDetails.installerName && capitalize(installerDetails.installerName)) || (titleValue && (capitalize(titleValue)))
        
        const imageSyle = {
            'backgroundImage': photo ? `url(` + photo + `)` : ''
        };
    
        return (
            <>
            <title>Contact - {titleValue}</title>
            <div className="white-box">
            <p className="page-title border0" style={{marginBottom:"-7px"}}>CONTACT {loggedInUser && loggedInUser.homeownerData && loggedInUser.homeownerData.companyName} </p>
                <div className="contact-wrapper padd25">
                    <div className="media">
                            {photo ?
                                <div className="dashboard-profile-image" style={imageSyle}></div>
                                : <UserAvatar className="dashboard-profile-image premiumIconBackGround" size="40" name={installerName || 'Installer'} />
                            }
                        <div className="media-body">
                            <h5 className="mt-0">{installerName}</h5>
                             <div className="email-id font">{installerDetails && installerDetails.installerTitle}</div>
                        </div>
                    </div>
                    <p className="paddt25 marg0  font-weight-300">
                        Send a message directly to your project manager {installerDetails && installerDetails.installerName && capitalize(installerDetails.installerName)}.
                        </p>
                    <p className="paddt25 marg0 font-weight-300">
                        Phone:
                            <NumberFormat
                                type="tel"
                                format={(formSetting && formSetting.phone)}
                                value={installerDetails && installerDetails.installerPhone}
                                className="ml-1 premiumLinks border-0"
                                
                            />
                    </p>
                    <p className="padd0 font-weight-300">
                        Email:<span className="ml-1 premiumLinks"> {installerDetails && installerDetails.installerEmail}
                        </span>
                    </p>
                    <ContactFormPart
                        handleSubmit={this.handleSubmit}
                        isInstaller={this.props.isInstaller}
                        handleSlider={val => {
                            this.handleSlider(val)
                        }}
                        sliderStatus={this.state.sliderStatus}
                        reset={ this.props.contactDetails && this.props.contactDetails.homeownerContactDetails && this.props.contactDetails.homeownerContactDetails.data && this.props.contactDetails.homeownerContactDetails.data.message === 'message sent' ? true : false }
                    />
                </div>
            </div>
            </>
        );
    }
}

const mapStatetoProps = state => {
    return {
        contactDetails: state.homeownerContact
    };
};

const mapDispatchToProps = dispatch => {
    return bindActionCreators(
        {
            requestPostContactData,
            receivePostContactData
        },
        dispatch
    )};

export default withRouter(
    connect(
        mapStatetoProps,
        mapDispatchToProps
    )(ContactForm)
);