import React, { Component }     from "react";
import { connect }              from 'react-redux'
import { bindActionCreators }   from "redux";
import {Form,Select,Input,Spin,
Modal,Checkbox,Radio}           from "antd";
import { LoadingOutlined }      from '@ant-design/icons';
import personlizeOption         from "./personalize.json";
import { ReactSortable }        from "react-sortablejs";
import {
    requestCompanyJourney,
    addCompanyJourney,
    receiveCompanyJourney,
    receiveUpdateCompanyJourney
}                                   from "../../actions/companyJourney";
import {companyCRMData}             from "../../api/companyJourney"
import {openNotificationWithIcon}   from "../../../../shared/notification";
import {DeleteModal}                from "../../../Common/DeleteModal"
import {statusButtonOption}         from "../../../homeowner/container/shared/statusFeedButton"
import threeDotImg     from "./../../../../Assets/Images/three-dot.svg"
import editDetailsIcon from "./../../../../Assets/Images/edit-details-icon.png"
import removeIcon      from "./../../../../Assets/Images/remove.svg"
import addIcon         from "../../../../Assets/Images/addIcon.svg"
import {ReactComponent as CaretIcon}from "../../../../Assets/Images/path-2.svg";

// Sortable.mount(new MultiDrag(), new Swap());
const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;
const { Option }   = Select;
const { TextArea } = Input;
let WhatsNext = {};
class BodhiJourneyForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
         crmVariable    : {},
         journeyData    : {
            journeyName  : 'Default Bodhi Journey',
            s3Key        : 'default',
            isDeleted    : false,
            journeyKey   : 0,
            journeyPhase : ['design','permit','thebuild','inspection','powering','services']
        },
         journey        :'Design',
         triggerData    : {},
         deleteTrigger  : {},
         touchActiveLabel:{},
         list           : null,
         crmValueMapping: [],
         defaultTriggerType     : 'crm',
         cursorPositionSMS      : 0,
         cursorPositionEmail    : 0,
         cursorPositionWhatNext : 0, 
         cursorPositionFeed     : 0
        };
        this.scaniflyText = `This is a to-scale 3D model of your solar project. To rotate, hold down the right mouse button / trackpad. To move the model, hold Shift while holding down the right button.`;
        this.serviceOption = [
            {field:'crm', label:'CRM Field'},
            {field:'timeSince',label:'Time Since'},
            {field:'calendar',label:'Calendar'}
        ];
        this.monthS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];
        this.DayS = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,2,28,29,30,31];
    }

    componentWillMount(){
        let {journeyData,companyData:{companyDetail},router:{location:{state}}} = this.props;
        let  journeyProps = (state && state.journeyData) || journeyData;
        if(!journeyProps){
           return this.props.router.navigate(`/${companyDetail.slug}/${companyDetail.id}/companysetting?${'view'}=${'isCompany'}`)
        }
        
        this.setState({journeyData: journeyProps});
        this.triggerCondition = [
            {field:'equal', label:'Equals'},
            {field:'includes',label:'Contains'},
            {field:'consist',label:'Has a value'}
        ]
        this.surveyOption  = [
            { key :1, label : 'Survey 1'},
            { key :2, label : 'Survey 2'},
            { key :3, label : 'Survey 3'},
            { key :4, label : 'Survey 4'}
        ]
    }

    fetchCompanyCRMData = (data) => {
        companyCRMData(data).then(r => {
            if (r.status==200 && r.data && Object.keys(r.data).length>0) {
                this.companyJourney = r.data['dealInfo'];
                this.bodhiKeyFormat = r.data['bodhiKeyFormat'];
                this.companyJourneyProps = r.data;
                if (r.data['serviceOption'] && this.serviceOption.length<4) {
                    this.companyJourneyOption = JSON.parse(JSON.stringify(this.companyJourney));
                    this.serviceOption = this.serviceOption.concat(r.data['serviceOption']).reverse();
                    this.serviceOption.splice(this.serviceOption.length-1,1).reverse();
                    this.setState({defaultTriggerType:this.serviceOption[0].field},()=>{
                        this.companyJourney = this.companyJourneyOption[this.state.defaultTriggerType];
                    })
                }
                if (this.companyJourneyOption && this.companyJourneyOption[this.state.defaultTriggerType]) {
                    this.companyJourney = this.companyJourneyOption[this.state.defaultTriggerType]
                }
            }
        })
    }

    componentWillReceiveProps(nextProps, prevProps) {

        let {journeyData,router:{location:{state}}} = nextProps;
        let updateJourneyProps = (state && state.journeyData) || journeyData;

        if ((nextProps.updateJourney && nextProps.updateJourney.status) || (this.state.journeyData && this.state.journeyData.s3Key && updateJourneyProps && (updateJourneyProps.s3Key !== this.state.journeyData.s3Key))) {
            this.setState({
                journeyData: updateJourneyProps || this.state.journeyData,
            }, () => {
                this.props.setLoader({loader:false});
                this.fetchJourneyData();
                this.props.receiveUpdateCompanyJourney({});
            })
        }
        
        if (nextProps.companyJourney && nextProps.companyJourney[0] && Object.keys(nextProps.companyJourney[0]).length>0) {
            WhatsNext = nextProps.companyJourney[0];
            if (!this.bodhiKeyFormat) {
                setTimeout(() => {this.setState({reRender:!this.state.reRender})},5000);
            }
            this.setState({
                journeyData : updateJourneyProps || this.state.journeyData,
                journey: nextProps.journeyState,
                triggerData : nextProps.companyJourney[0],
                list: null,
                crmValueMapping : nextProps.companyJourney[0].crmValueMapping || []
            });   
            nextProps.receiveCompanyJourney({}); 
        }

        this.setState({journey: nextProps.journeyState}); 
    }

    array2JSON(data){
        let newArr = [];
        data.forEach((ele,index)=>{
            newArr.push({id:index,name:ele})
        })
        this.setState({list:newArr});
        return;
    }

    sortData(triggerData){
        this.phaseArray = [];
        return this.orderKeys(triggerData, (a, b)=> {
            if(!a.isDeleted && this.phaseArray.indexOf(a.phaseName)===-1){
                this.phaseArray.push(a.phaseName);
            }
            return a.newTouchNo - b.newTouchNo;
        }); 
    }

    orderKeys(o, f) {
        var os = [], ks = [], i;
        for (i in o) {
            if(!o[i].isDeleted)
            os.push([i, o[i]]);
        }
        os.sort(function (a, b) { return f(a[1], b[1]); });
        for (i = 0; i < os.length; i++) {
            ks.push(os[i][0]);
        }
        return ks;
    };

    componentDidMount() {
        this.fetchJourneyData();
    }
    
    fetchJourneyData() {
        let { tokenData, companyData:{ companyDetail } } = this.props;
        tokenData.toolSearch = true;
        tokenData.slug = companyDetail ? companyDetail.slug : tokenData.slug;
        tokenData.type = 'companyJourney';
        tokenData.fileName = this.state.journeyData ? this.state.journeyData.s3Key : 'default';

        !this.bodhiKeyFormat && this.fetchCompanyCRMData(tokenData);
        this.props.setLoader({loader:true});
        this.props.requestCompanyJourney(tokenData);
    }

    handleSubmit = () => {
        let {tokenData,companyData:{companyDetail}} = this.props;
        let cleanData = this.cleanData(this.state.triggerData);
        if (cleanData.missing) {
            openNotificationWithIcon('error',`Please fill complete data 
            for ${cleanData.missing} touch`,'Error');
            return;
        }
        let data ={
            companyId   : companyDetail.id || tokenData.companyId,
            triggerData : cleanData,
            toolSearch  : true,
            slug        : `${companyDetail.slug}/${(this.state.journeyData.s3Key || 'default')}`,
            type        : 'companyJourney'
        }
        data.triggerData['crmValueMapping'] = this.state.crmValueMapping;
        this.props.setLoader({loader: true});
        this.props.addCompanyJourney(data);
    };

    cleanData(data){
        for(var i in data){
            let element = data[i];
            if (!element.isDeleted) {
                let temp = element && element['triggers'];
                // if ((i !== 'signintobodhiandwelcome' && element.phaseName !== 'Service') && (!temp || temp.length < 1 || !element.sms || !element.emailSubject || !element.emailBody ||
                //     !element.phase || !element.phase[0] || !element.phase[0].content || !element.whatsNext))
                //     return { missing: element.display }

                temp && temp.forEach((ele, index) => {
                    if (ele['isActive'] === false || ele.length === 0 || ele.value === "Select Pipeline Stage")
                        temp.splice(index, 1);
                    else for (var i in ele) {
                        if (Array.isArray(ele[i]))
                            delete ele[i];
                    }
                });
            }
            else{
                delete element.triggers;
            }
        }
        return data;
    }

    deletePopUpProp = (value) =>{
        let {deleteTrigger} = this.state;
        if(value=='cancel'){
            this.setState({
                deleteTrigger: {popUp: false}
               });
        }
        else{
            let oldTouchObj = this.state.triggerData;
        oldTouchObj = this.changeOrder(oldTouchObj,oldTouchObj[deleteTrigger.touchName].newTouchNo,'sub',JSON.parse(JSON.stringify(this.phasekeys)));
        oldTouchObj[deleteTrigger.touchName].isDeleted = true;
        this.setState({triggerData:oldTouchObj,deleteTrigger: {popUp: false},list:null});
        }
    }

    renderDeletePopUp = () =>{
        let headerText = 'Do you really want to delete this touch?';
        let subText = "Deleting this touch can't be undone";
        return(
             <DeleteModal deletePopUpProp={this.deletePopUpProp} headerText={headerText} subText={subText}/>
        )
    }

    findNewTouchNumber(phaseName){
        let previousTouchName;
        switch(phaseName){
            case 'Service':
                previousTouchName = 'Powering Up';
            break;

            case 'Powering Up':
                previousTouchName = 'Inspection';
            break;

            case 'Inspection':
                previousTouchName = 'The Build';
            break;

            case 'The Build':
                previousTouchName = 'Permit';
            break;

            case 'Permit':
                previousTouchName = 'Design';
            break;

            default:
                previousTouchName = 'Design';
            break;

        }
        let d2 = this.sortData(this.state.triggerData).filter(ele=>{
            return this.state.triggerData[ele].phaseName === previousTouchName;
        });
        if(d2 && d2.length>0)
            return {newTouchNo:this.state.triggerData[d2[d2.length-1]].newTouchNo}
    }

    renderPhaseTrigger = (phaseData,index) =>{
        let {triggerData} = this.state;
        let isDisplayClassName = (phaseData && phaseData['phaseName'] === this.state.journey && 
        phaseData['bodhiJourney'] && !phaseData['isDeleted']) ? 'd-block' : 'd-none';
        if(this.phaseArray.indexOf(this.state.journey)===-1 && index===this.state.list.length-1){
            return this.addNewTouch(this.findNewTouchNumber(this.state.journey),'d-block',this.state.journey);
        }
        else
            return(
            <>
                <div className={isDisplayClassName}>
                    <div className="content-box box-Shadow">
                        <div className="trigger-box mb0"><span className="my-handle">
                            <img src={threeDotImg} alt='' />
                        </span> Touch {phaseData.newTouchNo}:
                            <Input className="phase-name"
                                value={phaseData.display}
                                onChange={(e) => {
                                    let ele = this.state.triggerData[phaseData.touchName];
                                    let obj = { display: e.target.value };
                                    Object.assign(ele, obj);
                                    this.state.triggerData[phaseData.touchName] = ele;
                                    this.setState({
                                        triggerData
                                    })
                                }}
                            />
                            <img className="edit-icon" src={editDetailsIcon} alt='' />
                            {(phaseData.touchName !== "signintobodhiandwelcome" && phaseData
                            .touchName !=="statusdashboardtransitionstomonitoringdashboard")&&
                                <span className="delete-heading" onClick={() => {
                                    this.setState({ deleteTrigger: { popUp: true, touchName: phaseData.touchName } })
                                }}>
                                    <span>
                                        <img src={removeIcon} alt='' />
                                    </span>
                                    <span>Delete</span>
                                </span>}
                        </div>
                        <div className="tabs-wrapper">
                            <ul className="nav nav-tabs">
                                {this.renderNavTab(phaseData.touchName)}
                            </ul>
                            {
                                this.renderJourneyPhase(this.state.touchActiveLabel, phaseData, index)
                            }
                        </div>
                    </div>
                    {this.addNewTouch(phaseData,isDisplayClassName)}
                </div>
            </>
        )
    }

    addNewTouch(phaseData,isDisplayClassName,phaseName){
        return(
            <div className={"border-box-add "+isDisplayClassName}>
            <div onClick={() => {
                let oldTouchObj = this.state.triggerData;
                let touchesLength = Object.keys(oldTouchObj).length;
                oldTouchObj = this.changeOrder(oldTouchObj, phaseData && phaseData.newTouchNo, 'add',JSON.parse(JSON.stringify(this.phasekeys)));
                let newTouchObj = {
                    ['touch' + touchesLength]: {
                    "display"   : `Touch ${phaseData.newTouchNo+1}`,
                    "touchName" : `touch${touchesLength}`,
                    "phaseName" : phaseName ? phaseName : phaseData.phaseName,
                    "isDeleted" : false,
                    "newTouchNo": phaseData.newTouchNo+1,
                    "bodhiJourney": true,
                    "fixedIndex" : touchesLength
                    }
                }
                Object.assign(oldTouchObj, newTouchObj);
                this.setState({ triggerData: oldTouchObj,list:null })
            }}>
                <img className="add-step" src={addIcon}/>
                <span className="link-Color"> Add step</span>
            </div>
        </div>
        )
    }

    changeOrder(oldTouchObj,newTouchNo,operation,phasekeys){
        if(newTouchNo!=null)
            phasekeys.splice(0,newTouchNo);
        phasekeys.forEach((ele,index)=>{
            switch(operation){
                case 'add':
                    oldTouchObj[ele].newTouchNo +=1;
                break;
                case 'sub':
                    oldTouchObj[ele].newTouchNo -=1;
                break;
                case 'dragDrop':
                    oldTouchObj[ele.name].newTouchNo = index+1
                break;
            }
        });
        return oldTouchObj;
    }

    renderNavTab(touchName){
        let labelState = this.state.touchActiveLabel;
        var activeLabel = labelState[touchName] ? labelState[touchName] : 'triggers';
        return(
            <>
                <li className="nav-item border-right">
                <a className={"nav-link "+ (activeLabel==="triggers" ? 'active' : '' )} onClick={
                    () =>{
                        Object.assign(labelState,{[touchName]:"triggers"});
                        this.setState({touchActiveLabel : labelState});
                    }
                  }>Triggers</a>
                </li>
                <li className="nav-item border-right">
                <a className={"nav-link "+ (activeLabel==="sms" ? 'active' : '' )} onClick={
                    () =>{
                        Object.assign(labelState,{[touchName]:"sms"});
                        this.setState({touchActiveLabel : labelState});
                    }
                  }>SMS</a>
                </li>
                <li className="nav-item border-right">
                <a className={"nav-link "+ (activeLabel==="email" ? 'active' : '' )} onClick={
                    () =>{
                        Object.assign(labelState,{[touchName]:"email"});
                        this.setState({touchActiveLabel : labelState});
                    }
                  }>Email</a>
                </li>
                 <li className="nav-item border-right">
                <a className={"nav-link "+ (activeLabel==="statusFeed" ? 'active' : '' )} onClick={
                    () =>{
                        Object.assign(labelState,{[touchName]:"statusFeed"});
                        this.setState({touchActiveLabel : labelState});
                    }
                  }>Status feed</a>
                </li>
                <li className="nav-item border-right">
                <a className={"nav-link "+ (activeLabel==="whatsNext" ? 'active' : '' )} onClick={
                    () =>{
                        Object.assign(labelState,{[touchName]:"whatsNext"});
                        this.setState({touchActiveLabel : labelState});
                    }
                  }>What's next</a>
                </li> 
            </>
        )
    }

    renderJourneyPhase(labelData,phaseData,index){
        let label = labelData[phaseData.touchName];
        switch (label) {      
            case 'sms':
              return this.renderSMS(phaseData);
      
            case 'email':
              return this.renderEmail(phaseData);
      
            case 'statusFeed':
              return this.renderStatusFeed(phaseData);
      
            case 'whatsNext':
              return this.renderWhatsNext(phaseData);

            default:
                return this.renderTrigger(phaseData,index);
          }
    }

    renderTrigger(phaseData,index){
        return(
            <div className="tab-content">
                            <div id="triggers" className="container tab-pane active">
                                {
                                    this.state.triggerData[phaseData.touchName] && this.state.triggerData[phaseData.touchName]['triggers'] && this.state.triggerData[phaseData.touchName]['triggers'].map((e, i) => {
                                            return this.renderTriggerTypeOption(e, phaseData, index, i);
                                        })
                                }
                                <div className="add-new-box">
                                    <a 
                                        onClick={()=>{
                                        let data = this.state.triggerData;
                                        if(!data[phaseData.touchName]||!data[phaseData.touchName]['triggers'])
                                            data[phaseData.touchName] = Object.assign(data[phaseData.touchName],{"triggers" : [{triggerType: this.state.defaultTriggerType}] });
                                        else if(data[phaseData.touchName]&& (data[phaseData.touchName]['triggers'].length>0) && (data[phaseData.touchName]['triggers'][data[phaseData.touchName]['triggers'].length-1].length===0)){
                                            openNotificationWithIcon('error','Please fill last value','Error');
                                        }
                                        else 
                                            data[phaseData.touchName]['triggers'].push({triggerType: this.state.defaultTriggerType});
                                        this.setState({triggerData:data});
                                        }}><img className="add-trigger" src={addIcon}/><span className="link-Color">Add trigger</span>
                                    </a>
                                </div>
                            </div>
                        </div>  
        )
    }

    renderSMS(phaseData){
        let {triggerData,crmValueMapping} = this.state;
        let smsData = triggerData[phaseData.touchName];
        let smsTemplate = (smsData && smsData['sms']) || '';

        if(phaseData.touchName==='signintobodhiandwelcome'){
            smsTemplate = `Message 1: Hi #FIRSTNAME, I'm Bodhi! I'll be sending you updates on your #SOLARCOMPANY project. Add me to your contacts to stay in the loop!\n\nMessage 2: #SOLARCOMPANY has created a personalized portal for you to see more details about your solar system. Check your email for how to log in.`;
        }

        return(
            <div className="tab-content">
            <div className="container tab-pane fade form-box-wrapper active">
            <div className="row tab-content-wrapper">
                <form>
                    <div className="form-group">
                        <label htmlFor="exampleFormControlTextarea1">SMS Message</label>
                            <TextArea 
                                className="form-control filter-select" 
                                id="exampleFormControlTextarea1"
                                disabled = {phaseData.touchName==='signintobodhiandwelcome' &&true}
                                rows="5" 
                                onClick={(e)=>this.setState({cursorPositionSMS:e.target.selectionStart})}
                                value = {smsTemplate}
                                placeholder="Type message here…"
                                onChange={e=>{
                                    let obj = {sms : e.target.value};
                                    if(!smsData){
                                        triggerData[phaseData.touchName] =obj
                                    }else{
                                        Object.assign(triggerData[phaseData.touchName],obj);
                                    }
                                    this.setState({triggerData,cursorPositionSMS:e.target.selectionStart});
                                }}
                            >

                            </TextArea>
                                <div className="col-md-6 p-0">
                                    <Select  suffixIcon = {<CaretIcon/>}
                                        className="form-control filter-select personlize"
                                        id="exampleFormControlSelect1"
                                        disabled={phaseData.touchName === 'signintobodhiandwelcome' && true}
                                        value=''
                                        onChange={(e) => {
                                            if (!e.toString().includes('#')) {
                                                if (crmValueMapping.indexOf(e) === -1)
                                                    crmValueMapping.push(e);
                                                e = '#' + e;
                                            }
                                            let cursorPosition = this.state.cursorPositionSMS;
                                            let textBeforeCursorPosition = smsTemplate.substring(0, cursorPosition)
                                            let textAfterCursorPosition = smsTemplate.substring(cursorPosition, smsTemplate.length)
                                            let tempate = textBeforeCursorPosition + ` ${e} ` + textAfterCursorPosition;
                                            let obj = { sms: tempate };
                                            if (!smsData) {
                                                triggerData = {
                                                    [phaseData.touchName]: obj
                                                }
                                            } else {
                                                Object.assign(triggerData[phaseData.touchName], obj);
                                            }
                                            this.setState({ crmValueMapping, triggerData, cursorPositionSMS: cursorPosition + e.length + 1 });
                                        }}
                                    >
                                        <Option value={''}>Add personalization</Option>
                                        {this.renderDropDown(personlizeOption, true)}
                                    </Select>
                                </div>
                    </div>
                </form>
            </div>
        </div>
        </div>
        )
    }

    renderEmail(phaseData){
        let {triggerData,crmValueMapping} = this.state;
        let emailData = triggerData[phaseData.touchName];
        let emailSubjectTemplate = (emailData && emailData['emailSubject']) || '';
        let emailBodyTemplate = (emailData && emailData['emailBody']) || '';

        if(phaseData.touchName==='signintobodhiandwelcome'){
            let {companyData:{companyDetail}} = this.props;
            let companyName = (companyDetail && companyDetail.name) || '';
            emailSubjectTemplate = `Log into your ${companyName} Bodhi account`
            emailBodyTemplate = `Hi #FIRSTNAME,\n\nI'm Bodhi, and I work with your ${companyName} project manager to keep you up to date on every step of your installation. Inside your personalized ${companyName} portal, I'll also help you understand the performance of your investment, and make it easy to brag to your neighbors about your home being powered by the sun! \n\Watch this 1-minute demo to learn more.\n\nPlease use the login info below to access your ${companyName} portal, and let's start this journey together.\n\n\Email: #EMAIL\nPassword: #TEMP_PASSWORD\n\n#LINK\n\nIf the button doesn’t work, please copy and paste the following URL into your browser: https://mybodhiapp.com/${this.props.tokenData.slug}`;
        }

        return(
            <div className="tab-content">
                <div className="container tab-pane fade form-box-wrapper active">
                    <div className="row tab-content-wrapper">
                        <form>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlInput1">Email subject</label>
                                <Input type="text"
                                    className="form-control filter-select"
                                    value={emailSubjectTemplate}
                                    disabled = {phaseData.touchName==='signintobodhiandwelcome' &&true}
                                    id="exampleFormControlInput1"
                                    placeholder="Type email subject here...."
                                    onChange={e => {
                                        let obj = { emailSubject: e.target.value };
                                        if (!emailData) {
                                            triggerData[phaseData.touchName] = obj
                                        } else {
                                            Object.assign(triggerData[phaseData.touchName], obj);
                                        }
                                        this.setState({ triggerData });
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">Email message</label>
                                <TextArea
                                    className="form-control filter-select"
                                    id="exampleFormControlTextarea1"
                                    disabled = {phaseData.touchName==='signintobodhiandwelcome' &&true}
                                    rows="5"
                                    onClick={(e)=>this.setState({cursorPositionEmail:e.target.selectionStart})}
                                    value={emailBodyTemplate}
                                    placeholder="Type message here…"
                                    onChange={e => {
                                        let obj = { emailBody: e.target.value };
                                        if (!emailData) {
                                            triggerData[phaseData.touchName] = obj
                                        } else {
                                            Object.assign(triggerData[phaseData.touchName], obj);
                                        }
                                        this.setState({ triggerData,cursorPositionEmail:e.target.selectionStart });
                                    }}
                                ></TextArea>
                                <div className="col-md-6 p-0">
                                <Select  suffixIcon = {<CaretIcon/>}
                                    className="form-control filter-select personlize"
                                    id="exampleFormControlSelect1"
                                    disabled = {phaseData.touchName==='signintobodhiandwelcome' &&true}
                                    value=''
                                    onChange={(e) => {
                                        if(!e.toString().includes('#')){
                                            if(crmValueMapping.indexOf(e)===-1)
                                                crmValueMapping.push(e);
                                            e = '#'+e;
                                        }
                                        let cursorPosition = this.state.cursorPositionEmail;
                                        let textBeforeCursorPosition = emailBodyTemplate.substring(0, cursorPosition)
                                        let textAfterCursorPosition = emailBodyTemplate.substring(cursorPosition, emailBodyTemplate.length)
                                        let tempate = textBeforeCursorPosition + ` ${e} ` + textAfterCursorPosition;
                                        let obj = { emailBody: tempate };
                                        if (!emailData) {
                                            triggerData = {
                                                [phaseData.touchName]: obj
                                            }
                                        } else {
                                            Object.assign(triggerData[phaseData.touchName], obj);
                                        }
                                        this.setState({crmValueMapping,triggerData,cursorPositionEmail: cursorPosition + e.length +1 });
                                    }}
                                >
                                    <Option value=''>Add personalization</Option>
                                    {this.renderDropDown(personlizeOption,true)}
                                </Select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>                  
        )
    }

    renderWhatsNext(phaseData){
        let {triggerData,crmValueMapping} = this.state;
        let whatsNextData = triggerData[phaseData.touchName];
        let whatsNextTemplate = (whatsNextData && whatsNextData['whatsNext']) || '';
        return(
            <div className="tab-content">
                <div className="container tab-pane fade form-box-wrapper active">
                    <div className="row tab-content-wrapper">
                        <form>
                        <div className="form-group">
                                <label htmlFor="exampleFormControlInput1">What’s next headline</label>
                                <Input type="text"
                                    className="form-control filter-select"
                                    value={whatsNextData && whatsNextData.whatsNextTitle}
                                    id="exampleFormControlInput1"
                                    placeholder="Type headline here...."
                                    onChange={e => {
                                        let obj = {whatsNextTitle : e.target.value};
                                        if(!whatsNextData){
                                            triggerData[phaseData.touchName] =obj
                                        }else{
                                            Object.assign(triggerData[phaseData.touchName],obj);
                                        }
                                        this.setState({triggerData});
                                    }}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlTextarea1">What’s next message</label>
                                <TextArea 
                                className="form-control filter-select" 
                                id="exampleFormControlTextarea1"
                                rows="5" 
                                onClick={(e)=>this.setState({cursorPositionWhatNext:e.target.selectionStart})}
                                value = {whatsNextTemplate}
                                placeholder="Type message here…"
                                onChange = {(e)=>{
                                    let obj = {whatsNext : e.target.value};
                                    if(!whatsNextData){
                                        triggerData[phaseData.touchName] =obj
                                    }else{
                                        Object.assign(triggerData[phaseData.touchName],obj);
                                    }
                                    this.setState({triggerData,cursorPositionWhatNext:e.target.selectionStart});
                                }}
                                ></TextArea>
                                <div className="col-md-6 p-0">
                                <Select  suffixIcon = {<CaretIcon/>} 
                                className="form-control filter-select personlize" 
                                id="exampleFormControlSelect1"
                                value = ''
                                onChange = {(e)=>{
                                if(!e.toString().includes('#')){
                                    if(crmValueMapping.indexOf(e)===-1)
                                        crmValueMapping = crmValueMapping.push(e);
                                    e = '#'+e;
                                }
                                let cursorPosition = this.state.cursorPositionWhatNext;
                                let textBeforeCursorPosition = whatsNextTemplate.substring(0, cursorPosition)
                                let textAfterCursorPosition = whatsNextTemplate.substring(cursorPosition, whatsNextTemplate.length)
                                let tempate = textBeforeCursorPosition + ` ${e} ` + textAfterCursorPosition;
                                let obj = {whatsNext : tempate};
                                    if(!whatsNextData){
                                        triggerData ={
                                            [phaseData.touchName] : obj
                                        }
                                    }else{
                                        Object.assign(triggerData[phaseData.touchName],obj);
                                    }
                                    this.setState({crmValueMapping,triggerData, cursorPositionWhatNext : cursorPosition + e.length+1});
                            }}
                            >
                                <Option value = {''}>Add personalization</Option>
                               {this.renderDropDown(personlizeOption,true)}
                            </Select>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    renderTriggerTypeOption(element, phaseData, index, i) {
        let triggerData = JSON.parse(JSON.stringify(this.state.triggerData));
        let touchData = triggerData[phaseData.touchName]['triggers'][i];
        return(
            <div className="row tab-content-wrapper" key={i}>
                <div className="col-sm-3">
                    <Select  suffixIcon = {<CaretIcon/>}
                        className="form-control"
                        value = {element && element.triggerType}
                        optionFilterProp="children"
                        placeholder="Select a value type"
                        onChange={
                            (e) => {
                                let data= {'triggerType': e}
                                triggerData[phaseData.touchName]['triggers'][i] = data;
                                if(this.companyJourneyOption && this.companyJourneyOption[e]){
                                    this.companyJourney = JSON.parse(JSON.stringify(this.companyJourneyOption[e]));       
                                }                      
                                this.setState({triggerData});    
                        }
                    }>
                        {this.renderPipelineDropDown(this.serviceOption,'field','label')}
                    </Select>
                </div>
                {element && element.triggerType && this.renderCRMTriggerType(element,phaseData,index,i,touchData,triggerData)}
            </div>
        )
    }

    renderCRMTriggerType(element,phaseData,index,i,touchData,triggerData){
        switch(element.triggerType){
            case 'projectTaskList':
                return this.renderSalesForce(element,phaseData,index,i,touchData,triggerData);
            case 'calendar':
                return this.renderCalendarBlock(element,phaseData,index,i,triggerData);
            case 'timeSince':
                return this.renderTimeSinceBlock(element,phaseData,index,i,triggerData);
            default:
                return this.renderTriggerBlock(element,phaseData,index,i,touchData,triggerData);
        }
    }

    renderTimeSinceBlock(element,phaseData,index,i,triggerData){

        let phasekeys = JSON.parse(JSON.stringify(this.phasekeys)).splice(0,index);

        return(
            <>
                <div className="col-sm-6">
                    <Select  suffixIcon = {<CaretIcon/>}
                        className="form-control filter-select"
                        optionFilterProp="children"
                        value={element && element['touch']}
                        placeholder="Select Touch Type"
                        onChange={
                            (e) => {
                                this.saveCalendar_Time_Trigger(e,index,element,phaseData,triggerData,'timeSince','touch',i);
                    }}>
                        {this.renderTouchOption(phasekeys)}
                    </Select>
                </div>

                <div className="col-sm-3">
                    <Input 
                        className="form-control filter-select" 
                        value = {element && element['time']} 
                        placeholder='Enter days'
                        onChange={(e) => {
                            this.saveCalendar_Time_Trigger(e.target.value.trim(), index, element, phaseData, triggerData, 'timeSince', 'time',i);
                        }}
                    />
                </div>
            </>
        )
    }

    renderCalendarBlock(element,phaseData,index,i,triggerData){
        return(
            <>
                <div className="col-sm-3">
                    <Select  suffixIcon = {<CaretIcon/>}
                        className="form-control filter-select"
                        optionFilterProp="children"
                        placeholder="Select Month"
                        value = {element && element['month']}
                        onChange={(e) => {
                                this.saveCalendar_Time_Trigger(e,index,element,phaseData,triggerData,'calendar','month',i);                          
                            }
                        }>
                        {this.renderPipelineDropDown(this.monthS,null,null,true)}
                    </Select>
                </div>

                <div className="col-sm-3">
                    <Select  suffixIcon = {<CaretIcon/>}
                        className="form-control filter-select"
                        optionFilterProp="children"
                        value = {element && element['date']}
                        placeholder="Select Date"
                        onChange={
                            (e) => {
                                this.saveCalendar_Time_Trigger(e,index,element,phaseData,triggerData,'calendar','date',i);
                            }
                        }>
                        {this.renderPipelineDropDown(this.DayS,null,null,true)}
                    </Select>
                </div>
            </>
        )
    }

    saveCalendar_Time_Trigger(e,index,element,phaseData,triggerData,triggerType,key,i){
        let data = {
            [key]: e,
            triggerCondition :'equal',
            triggerType,
            index
        };

        if(JSON.stringify(element)==='{}')
            triggerData[phaseData.touchName]['triggers'][i] = data;
        else{
            Object.assign(element,data);
            triggerData[phaseData.touchName]['triggers'][i] = element;
        }
        this.setState({triggerData}); 
    }

    renderSalesForce(element,phaseData,index,i,touchData,triggerData){
        return(
            <>
                <div className="col-sm-4">
                    <Select  suffixIcon = {<CaretIcon/>}
                        showSearch
                        value={element && element['salesforceSubject']}
                        className="form-control filter-select"
                        id="exampleFormControlSelect1"
                        optionFilterProp="children"
                        placeholder="Select a trigger field"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => {
                            let  merged = {'salesforceSubject':e};
                            this.createArray(triggerData,phaseData,merged,e,i);
                        }
                        }>
                        {this.renderDropDown(this.companyJourney && this.companyJourney[0])}
                    </Select>
                </div>
                <div className="col-sm-5">
                    <Select  suffixIcon = {<CaretIcon/>}
                        showSearch
                        value={element && element['key']}
                        className="form-control filter-select"
                        id="exampleFormControlSelect1"
                        optionFilterProp="children"
                        placeholder="Select a trigger value"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={
                            (e) => {
                                let data = {
                                    key: e,
                                    value: this.renderValue(this.companyJourney[e]),
                                    [e]: this.renderValue(this.companyJourney[e]),
                                    hubspotPipelineActive: false,
                                    triggerCondition: 'equal',
                                    index
                                };
                                Object.assign(element,data);
                                this.keyPairExist(element,phaseData.touchName, i);
                            }
                        }
                        >
                        {this.renderPipelineDropDown(this.state.crmVariable[element.salesforceSubject])}
                    </Select>
                </div>
                {this.conditionTrigger(element,phaseData,i,triggerData)}
               
                <div className="col-sm-4">
                    <Select  suffixIcon = {<CaretIcon/>}
                        showSearch
                        value={element && element['salesforceStatus']}
                        className="form-control filter-select"
                        id="exampleFormControlSelect1"
                        optionFilterProp="children"
                        placeholder="Select a trigger status"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={(e) => {
                            let merged = {'salesforceStatus': e};
                            this.createArray(triggerData,phaseData,merged,e,i);
                        }
                        }>
                        {this.renderDropDown(this.companyJourney && this.companyJourney[0])}
                    </Select>
                </div>
                <div className="col-sm-4">
                    <Select  suffixIcon = {<CaretIcon/>}
                        showSearch
                        value={element && element['value']}
                        className="form-control filter-select"
                        id="exampleFormControlSelect1"
                        optionFilterProp="children"
                        placeholder="Select a status value"
                        filterOption={(input, option) =>
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    onChange={
                    (e) => {
                        let data = {
                            value: e,
                            [element.key]: e,
                            index
                        };
                        let mergedData = {...element,...data};
                        this.keyPairExist(mergedData,phaseData.touchName, i);
                    }   
                }>
                    {this.renderPipelineDropDown(this.state.crmVariable[element.salesforceStatus])}
                    </Select>
                </div>

                {this.deleteTouchTrigger(phaseData,i)}
            </>
        )
    }

    createArray(triggerData,phaseData,json,field,i){
        let arr= [];
        if(!this.state.crmVariable.field && field){
            this.companyJourney.forEach(ele=>{
                if(arr.indexOf(ele[field])===-1)
                    arr.push(ele[field]);
            });
            const merged = {...this.state.crmVariable,...{[field] : arr}};
            Object.assign(triggerData[phaseData.touchName]['triggers'][i],json);
            this.setState({crmVariable: merged,triggerData});
        }
        return arr;
    }

    renderCRMKeys(journeyOption,pipelineOption){
        let condition = pipelineOption || this.bodhiKeyFormat;
        switch(condition){
            case 'sharepoint':
                return this.renderPipelineDropDown(journeyOption ? journeyOption : this.companyJourney,'EntityPropertyName','Title');

            case 'teamwork':
                return this.renderPipelineDropDown(this.companyJourney,'content','content');

            case 'scoop':
                return this.renderPipelineDropDown(this.companyJourney,'id','name');

            case 'quickbase':
                return this.renderPipelineDropDown(this.companyJourney,'id','label');

            case 'trello':
                let arr = [];
                let checkListName = [];
                this.companyJourney.map(ele => {
                    if(checkListName.indexOf(ele['name'])===-1){
                        checkListName.push(ele['name'])
                        ele['checkItems'] && ele['checkItems'].map((element) => {
                            if (!element['name'].includes(ele['name'] + ' - ')){
                                element['uid'] = element['name'];
                                element['name'] = `${ele['name']} - ${element['name']}`;
                            }
                        });
                        arr = [...arr, ...ele['checkItems']]
                    }
                });
                return this.renderPipelineDropDown(arr,'uid','name');

            case 'monday':
                return this.renderPipelineDropDown(this.companyJourney,'id','title');
            case 'solarnexus':
                return this.renderPipelineDropDown(this.companyJourney,'id','name_before_completion');
            case 'dynamics365':
                return this.renderPipelineDropDown(this.companyJourney,'Task No','Task Name');
            case 'hubspotPipeline':
                return this.renderPipelineDropDown(journeyOption,'label','label')
            case 'insightlyCustom':
                return this.renderPipelineDropDown(journeyOption,'CUSTOM_FIELD_ID','CUSTOM_FIELD_ID');
            default:
                return this.renderDropDown(journeyOption ? journeyOption : this.companyJourney)
        }
    }

    renderTriggerBlock(element,phaseData,index,i,touchData,triggerData){
        let journeyOption ;
        if(this.companyJourneyOption && element.triggerType){
            journeyOption = this.companyJourneyOption[element.triggerType];
        }
        else if(this.companyJourneyOption){
            journeyOption = this.companyJourneyOption[this.state.defaultTriggerType]
        }
        return(<>
                <div className="col-sm-5">
                    {this.companyJourney ? 
                    <Select  suffixIcon = {<CaretIcon/>} 
                        showSearch
                        value={element && element['key']}
                        className="form-control filter-select"
                        id="exampleFormControlSelect1"
                        optionFilterProp="children"
                        placeholder="Select a trigger type"
                        filterOption={(input, option) =>
                          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={
                            (e) => {
                                var hubspotPipeline;
                                if((this.bodhiKeyFormat==="hubspot" && e==="dealstage")|| (this.bodhiKeyFormat==="insightly" && e==="CUSTOMFIELDS"))
                                    hubspotPipeline = true;
                                else
                                    hubspotPipeline = false;

                                if(this.bodhiKeyFormat==="hubspot" && hubspotPipeline)
                                    var v = 'Select Pipeline Stage';
                                else if(this.bodhiKeyFormat=="scoop" || this.bodhiKeyFormat=="trello" || this.bodhiKeyFormat=="teamwork"|| this.bodhiKeyFormat=="solarnexus" || this.bodhiKeyFormat=="dynamics365"){
                                    v = e;
                                }

                                let data = {
                                    key: e,
                                    value: v ? v : this.renderValue(journeyOption ? journeyOption[e] : this.companyJourney[e]),
                                    [e]: v ? v : this.renderValue(journeyOption ? journeyOption[e] : this.companyJourney[e]),
                                    hubspotPipelineActive : hubspotPipeline,
                                    triggerCondition :'equal',
                                    triggerType : element.triggerType,
                                    index
                                }
                                this.keyPairExist(data,phaseData.touchName,i);
                            }
                        }>
                            {this.renderCRMKeys(journeyOption)}
                    </Select>
                    : 
                    <Input
                        value={element && element['key']}
                        placeholder="Enter a trigger type"
                        onChange={(e) => {
                            let data = {
                                key: e.target.value,
                                hubspotPipelineActive: false,
                                triggerCondition: 'equal',
                                triggerType: element.triggerType,
                                index
                            }
                            triggerData[phaseData.touchName]['triggers'][i] = data;
                            this.setState({triggerData});
                        }

                        }
                    />
    }
                </div>

                {(this.bodhiKeyFormat!="scoop" && this.bodhiKeyFormat!="trello" && this.bodhiKeyFormat!="teamwork" && this.bodhiKeyFormat !="solarnexus" && this.bodhiKeyFormat!="dynamics365") && this.conditionTrigger(element,phaseData,i,triggerData)}
                        

                {touchData.hubspotPipelineActive &&
                 <div className="col-sm-4">
                 <Select  suffixIcon = {<CaretIcon/>} 
                     value={(element && element['pipelineType'])||''}
                     className="form-control filter-select"
                     id="exampleFormControlSelect1"
                     onChange={
                         (e) => {
                             let hubspotState = triggerData[phaseData.touchName]['triggers'][i];
                             if(!hubspotState)
                              hubspotState = {};
                             hubspotState['pipelineType'] = e;
                             Object.assign(triggerData[phaseData.touchName]['triggers'][i],hubspotState)
                             this.setState({triggerData})
                         }
                     }>
                         <Option value=''>Select Pipeline</Option>
                         {this.bodhiKeyFormat==="insightly" && this.renderCRMKeys(this.companyJourney['CUSTOMFIELDS'],'insightlyCustom')}
                         {this.bodhiKeyFormat==="hubspot" && this.renderCRMKeys(this.companyJourneyProps['pipeline'],'hubspotPipeline')}
                 </Select>
                </div>
                }

                {this.bodhiKeyFormat==="hubspot"&& element['pipelineType'] &&
                 <div className="col-sm-5">
                 <Select  suffixIcon = {<CaretIcon/>} 
                     value={element['pipelineType'] && element && element['value']}
                     className="form-control filter-select"
                     id="exampleFormControlSelect1"
                     onChange={
                         (e) => {
                             let hubspotState = triggerData[phaseData.touchName]['triggers'][i];
                             if(!hubspotState){
                                openNotificationWithIcon('error','Please select pipeline field firstly','Error');
                             }
                             else{
                                Object.assign(element,{value: e,[element.key]: e});
                                this.keyPairExist(element,phaseData.touchName,i);
                             }
                         }
                     }>
                     {this.companyJourneyProps['pipeline'] && 
                     this.companyJourneyProps['pipeline'].map(ele=>{
                         if(ele.label ===element['pipelineType'])
                       return this.renderPipelineDropDown(ele.stages,'stageId','label')
                     })
                     }
                 </Select>
                </div>
                }

                {(this.bodhiKeyFormat!="scoop" && this.bodhiKeyFormat!="trello" && this.bodhiKeyFormat!="teamwork" && this.bodhiKeyFormat !="solarnexus" && this.bodhiKeyFormat!="dynamics365") && this.renderValueType(touchData,element,phaseData,i)}
                {(this.bodhiKeyFormat==='solarSuccess') && this.renderSolarSuccessJourney(element,index,triggerData,phaseData,i,touchData)}
                {this.deleteTouchTrigger(phaseData,i)}
        </>)
    }

    renderValueType(touchData,element,phaseData,i,number){
        let valueNumber = number ? 'value'+number : 'value';
        let triggerCondition = number ? 'triggerCondition'+number : 'triggerCondition';

        return (
            !touchData.hubspotPipelineActive && element[triggerCondition] !== 'consist' && <div className="col-sm-3">
                <Input type="text"
                    className="form-control filter-select"
                    value={element && element[valueNumber]}
                    placeholder="Trigger Field"
                    onChange={(e) => {
                        if (!element || !element.key) {
                            openNotificationWithIcon('error', 'Please select trigger field firstly', 'Error');
                        }
                        else {
                            Object.assign(element, { [valueNumber]: e.target.value, [element.key]: e.target.value });
                            this.keyPairExist(element, phaseData.touchName, i);
                        }
                    }}
                />
            </div>
        )
    }

    renderSolarSuccessJourney(element,index,triggerData,phaseData,i,touchData){
        let dropdownOption = [
            {field:'TaskCompletedDate', label:'Task CompletedDate'},
            {field:'TaskStatus',label:'Action Status'}
        ];

        return(
            <>
            <div className="col-sm-5">
                    <Select  suffixIcon = {<CaretIcon/>}
                        value={element && element['key2']}
                        className="form-control filter-select"
                        id="exampleFormControlSelect1"
                        placeholder="Select a trigger type"
                        onChange={
                            (e) => {
                                Object.assign(element, { key2: e,triggerCondition2: 'equal'});
                                triggerData[phaseData.touchName]['triggers'][i] = element;
                                this.setState({ triggerData });
                            }
                        }>
                        {this.renderPipelineDropDown(dropdownOption,'field','label')}
                    </Select>
            </div>
             {this.conditionTrigger(element,phaseData,i,triggerData,2)}
             {this.renderValueType(touchData,element,phaseData,i,2)}
            </>
        )
    }

    conditionTrigger(element,phaseData,i,triggerData,number){
        let conditionNumber = number ? 'triggerCondition'+number : 'triggerCondition';
        return(
            <div className="col-sm-3">
                    <Select  suffixIcon = {<CaretIcon/>} value={
                         element[conditionNumber]
                    }
                        className="form-control filter-select"
                        id="exampleFormControlSelect1"
                        onChange={
                            (e) => {
                                if(Array.isArray(element)){
                                    openNotificationWithIcon('error','Please select trigger type and field firstly','Error');
                                }else{
                                    Object.assign(element,{[conditionNumber]: e});
                                    this.keyPairExist(element,phaseData.touchName, i);
                                }
                            }
                        }>
                        {this.renderPipelineDropDown(this.triggerCondition,'field','label')}
                    </Select>
                </div>
        )
    }

    deleteTouchTrigger(phaseData,i){
        return(
            <span className="mt-3 delete-heading" onClick={() => {
                this.state.triggerData[phaseData.touchName]['triggers'].splice(i, 1);
                this.setState({ triggerData: this.state.triggerData });
            }}>
                <span>
                    <img src={removeIcon} alt='' />
                </span>
            </span>
        )
    }

    renderStatusFeed(phaseData) {
        let {triggerData,crmValueMapping} = this.state;
        let touchData = triggerData[phaseData.touchName];
        let statusFeedData = (touchData && touchData['phase'] && touchData['phase'][0]) || {};

        let addStatusValue = '';
        if (statusFeedData && statusFeedData.isImages)
            addStatusValue = 'isImages';
        else if (statusFeedData && statusFeedData.isLayout)
            addStatusValue = 'isLayout';
        else if (statusFeedData && statusFeedData.isScanifly)
            addStatusValue = 'isScanifly';

        return (
            <div className="tab-content">
                <div className="container tab-pane fade form-box-wrapper active">
                    <div className="row tab-content-wrapper">
                        <form>
                            <div className="row">
                                <div className="col-md-6 col-sm-12">
                                    <div className="form-group">
                                        <label htmlFor="exampleFormControlTextarea1">Status feed message</label>
                                            <TextArea className="form-control filter-select"
                                                id="exampleFormControlTextarea1" 
                                                rows="5"
                                                onClick={(e)=>this.setState({cursorPositionFeed:e.target.selectionStart})}
                                                value ={statusFeedData.content}
                                                placeholder="Type message here…"
                                                onChange = {(e)=>{
                                                    this.setState({cursorPositionFeed:e.target.selectionStart})
                                                    this.statusFeedData(e.target.value,'content',phaseData)
                                                }}
                                            ></TextArea>
                                    </div>
                                </div>



                                <div className="col-md-3 col-sm-6 check-box-wrapper">
                                <div className="row-md-3 row-sm-6">
                                        <div className="form-check form-check-inline">
                                            <Checkbox className="form-check-input"
                                                checked={statusFeedData.isShareSun ? true : false}
                                                onChange={(e => {
                                                    this.statusFeedData(e.target.checked, 'isShareSun', phaseData);
                                                })} />
                                            <label className="form-check-label"
                                                htmlFor="inlineCheckbox1">Share the sun </label>
                                        </div>
                                    </div>

                                    <div className="row-md-4 mt-2 mb-2">
                                        <div className="form-check form-check-inline">
                                            <Checkbox className="form-check-input"
                                                id="inlineCheckbox1"
                                                checked={statusFeedData.survey ? true : false}
                                                onChange={(e) => {
                                                    if (!e.target.checked) {
                                                        this.statusFeedData('', 'survey', phaseData);
                                                    }
                                                }}
                                            />
                                            <Select suffixIcon = {<CaretIcon/>}
                                                className="form-control col-md-12 filter-select"
                                                id="exampleFormControlSelect1"
                                                value={statusFeedData.survey ? statusFeedData.survey : ''}
                                                onChange={(e) => {
                                                    this.statusFeedData(e, 'survey', phaseData);
                                                }}
                                            >
                                                <Option value={''}>Add survey</Option>
                                                {this.renderPipelineDropDown(this.surveyOption, 'key', 'label')}
                                            </Select>
                                        </div>
                                    </div>
                                    
                                    <div className="row-md-3 row-sm-6">
                                        <div className="form-check form-check-inline">
                                            <Checkbox className="form-check-input"
                                                checked={statusFeedData.isButton ? true : false}
                                                onChange={(e => {
                                                    this.statusFeedData(e.target.checked, 'isButton', phaseData);
                                                })} />
                                            <label className="form-check-label"
                                                htmlFor="inlineCheckbox1">Add button</label>
                                        </div>
                                    </div>
                                    {
                                        statusFeedData.isButton &&<> 
                                        <div className="row-md-3 row-sm-6 button-tab-detail">
                                        <Input className="button-name"
                                            value={statusFeedData.buttonName}
                                            placeholder="Button Name"
                                            onChange={(e) => {
                                                this.statusFeedData(e.target.value,'buttonName',phaseData)
                                            }}
                                        />
                                    </div>
                                    <div className="row-md-3 row-sm-6 button-tab-detail">
                                        <Select suffixIcon = {<CaretIcon/>}
                                            className="form-control survey filter-select"
                                            id="exampleFormControlSelect1"
                                            value={statusFeedData.buttonUrl ? statusFeedData.buttonUrl : ''}
                                            onChange={(e) => {
                                                this.statusFeedData(e, 'buttonUrl', phaseData);
                                            }}
                                        >
                                            <Option value={''}>Linked To</Option>
                                            {this.renderPipelineDropDown(statusButtonOption, 'key', 'label')}
                                        </Select>
                                    </div>
                                    </>
                                    }
                                </div>

                                <div className="col-md-3 col-sm-6 check-box-wrapper pr-0">
                                    <div className="row-md-3 row-sm-6">
                                        <div className="form-check form-check-inline">
                                            <Radio.Group className="status-feed-option" 
                                                value={addStatusValue}
                                                onChange={(e) => {
                                                    let value = e.target.value;
                                                    if (value == 'isScanifly') {
                                                        let updateText = `${statusFeedData.content}
${this.scaniflyText}`;
                                                        this.statusFeedData(updateText, 'content', phaseData);
                                                    } else {
                                                        let updateText = statusFeedData.content.replace(this.scaniflyText, '').trim();
                                                        this.statusFeedData(updateText, 'content', phaseData);
                                                    }
                                                    this.statusFeedData(value == 'isImages' ? true : false, 'isImages', phaseData);
                                                    this.statusFeedData(value == 'isLayout' ? true : false, 'isLayout', phaseData);
                                                    this.statusFeedData(value == 'isScanifly' ? true : false, 'isScanifly', phaseData);
                                                }}
                                            >
                                                <Radio value={'isLayout'}>Add layout</Radio>
                                                <Radio value={'isScanifly'}>Add Scanifly 3D model</Radio>
                                                <Radio value={'isImages'}>Add images</Radio>
                                                <Radio value={''}>None</Radio>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <Select suffixIcon = {<CaretIcon/>}
                                        className="form-control filter-select personlize"
                                        id="exampleFormControlSelect1"
                                        value=''
                                        onChange={(e) => {
                                            if (!e.toString().includes('#')) {
                                                if (crmValueMapping.indexOf(e) === -1)
                                                    crmValueMapping.push(e);
                                                e = '#' + e;
                                            }
                                            let statusFeedTemplate = statusFeedData.content;
                                            let cursorPosition = this.state.cursorPositionFeed;
                                            let textBeforeCursorPosition = statusFeedTemplate.substring(0, cursorPosition)
                                            let textAfterCursorPosition = statusFeedTemplate.substring(cursorPosition, statusFeedTemplate.length)
                                            let tempate = textBeforeCursorPosition + ` ${e} ` + textAfterCursorPosition;
                                            this.setState({ crmValueMapping, cursorPositionFeed: cursorPosition + e.length + 1 })
                                            this.statusFeedData(tempate, 'content', phaseData)
                                        }}
                                    >
                                        <Option value={''}>Add personalization</Option>
                                        {this.renderDropDown(personlizeOption, true)}
                                    </Select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    renderValue(data){
        if(this.bodhiKeyFormat ==="hubspot"){
            return data.value;
        }
        else{
            return data;
        }
    }


    statusFeedData(value,type,phaseData){
        let {triggerData} = this.state;
        let touchData = triggerData[phaseData.touchName];
        let statusFeedData = (touchData && touchData['phase'] && touchData['phase'][0]);
        if(statusFeedData){
            if(type ==="isShareSun"&&statusFeedData['survey']){
                openNotificationWithIcon('error', `For given touch, you can choose either Share The Sun
                OR Survey`, 'Error');
                return
            }
            if(type ==="survey"&&statusFeedData['isShareSun']){
                openNotificationWithIcon('error', `For given touch, you can choose either Share The Sun
                OR Survey`, 'Error');
                return
            } 
        }

        let obj = { [type] : value };
        if(!touchData){
            let temp ={
                [phaseData.touchName] : {
                    phase : [obj]
                }
            }
            Object.assign(triggerData,temp);
        }else{
            if(!statusFeedData){
                triggerData[phaseData.touchName]['phase'] = [];
                triggerData[phaseData.touchName]['phase'].push(obj);
            }
            else{
                Object.assign(statusFeedData,obj);
                triggerData[phaseData.touchName]['phase'][0] = statusFeedData;
            }
        }
        this.setState({triggerData});
    }

    keyPairExist(data,touchName,i){
        let triggerData = this.state.triggerData;
        let flag = false;
        delete data.isActive;
        if(data && data.value!=="Select Pipeline Stage"){
            for(var j in triggerData){
                let testArray =  triggerData[j]['triggers'];
                if (testArray && !triggerData[j]['isDeleted']) {
                    if (testArray && !triggerData[j]['isDeleted'] && data.triggerCondition === 'consist') {
                        let result = (testArray.some((o,index)=> {
                            if (data.value && (triggerData[j]['touchName'] !== touchName || index!==i ))
                                return o['key'] === data.key;
                        }))
                        if (result)
                            flag = true;
                    }
                }
            }
        }
        if(flag){
            openNotificationWithIcon('error', 'Selected Key & Value already exist ', 'Error');
            data['isActive']= false;
        }
        triggerData[touchName]['triggers'][i] = data;
        this.setState({triggerData});
    }

    renderTouchOption(data){
        let {triggerData} = this.state; 
        if(data){
            return data.sort().map((ele, index) => {
                return <Option key={index} value={triggerData[ele].fixedIndex}>{triggerData[ele].display}</Option>
            })
        }
    }

    //  render different type of dropdown options
    renderDropDown(data,isPersonlize) {
        if (!data || JSON.stringify(data) === '{}')
            return;
        
        //  render dropdown for podio
        if(data && Array.isArray(data)){
            return this.renderPodioArrayDropDown(data,isPersonlize);
        }   
        let arr = Object.keys(data);

        //  add salesforce custom filter option
        // if(this.companyJourneyOption && this.companyJourneyOption.projObj){
        //     let arr2 = Object.keys(this.companyJourneyOption.projObj);
        //     arr = arr.concat(arr2);
        // }        

        return arr.sort().map((ele, index) => {
            if (!ele.includes('hs_'))
        return <Option key={index} value={isPersonlize ? (data[ele]|| ele) : ele}>{`${this.appendValue(isPersonlize,data[ele],ele)}`}</Option>
        })
    }

    renderPodioArrayDropDown(data,isPersonlize) {    
        let sortedData = data.sort((a, b) => {
            if(a['label'])
                return a['label'].localeCompare(b['label'], undefined, { caseFirst: "upper" })
        });
        return sortedData.map(ele => {
            return <Option value={ele['field_id']}>{`${this.appendValue(false, null, ele['label'])}`}</Option>
        })   
        // if(isPersonlize){
        //     var sortedData = JSON.parse(JSON.stringify(this.companyJourney));
        //     sortedData.push(personlizeOption);
        // }
        // else{
        //     var sortedData = Array.isArray(data) && data.sort((a, b) => {
        //         if(a['label'])
        //             return a['label'].localeCompare(b['label'], undefined, { caseFirst: "upper" })
        //     });
        // }

        // return sortedData.map((ele,index) => {
        //     if(index == sortedData.length-1 && isPersonlize){
        //         let arr = Object.keys(ele);
        //         return arr.map(ele => {
        //             return <Option value={(data[ele]|| ele)}>{`${this.appendValue(isPersonlize,data[ele],ele)}`}</Option>
        //         })
        //     }
        //     else
        //         return <Option value={ele['field_id']}>{`${this.appendValue(isPersonlize, null, ele['label'])}`}</Option>
        // })
    }
    
    appendValue=(isPersonlize,value,ele)=>{
        if(isPersonlize && value)
            return `${ele} - ${value}`
        else if(isPersonlize)
            return `#${ele}`
        else
            return `${ele}`
    }

    renderPipelineDropDown(data,key,label,isDisableSort){
        var sortedData = [];
        if(data && label && data[0] && data[0]['label'] && !isDisableSort)
         sortedData = data.sort((a, b) => a[label].localeCompare(b[label], undefined, { caseFirst: "upper" }));
        else if(data && !isDisableSort)
            sortedData = data.sort();

        if(data && data.length>0){
            return data.map((ele, index) => {
                if(this.bodhiKeyFormat=="solarnexus" && ele['milestone'])
                    ele = ele['milestone'];
                return <Option key={index} value={key ? ele[key] : ele}>{label ? ele[label] : ele}</Option>
            })
        }
    }
 
    render() {
        return (
            <Spin
                spinning={JSON.stringify(WhatsNext) !== '{}' ? false : true}
                indicator={antIcon}
                style={{ color: "#00142D" }}
            >
            {
                WhatsNext && JSON.stringify(WhatsNext) !== '{}' &&
                JSON.stringify(this.state.triggerData) !== '{}' && this.renderCustomJourney()
            }
            </Spin>
        );
    }

    renderPhaseName(journey,journeyData){
        let temp = journey.toLowerCase().replace(/ /g,'');
        if(journeyData && journeyData['journeyPhase'][temp].name)
            return journeyData['journeyPhase'][temp].name;
        else
            return journey;
    }

    renderCustomJourney(){
        WhatsNext = JSON.parse(JSON.stringify(this.state.triggerData));
        this.phasekeys = WhatsNext && this.sortData(WhatsNext);
        if(!this.state.list)
         this.array2JSON(this.phasekeys);
        const {list} =  this.state;
        return(
            <div className="content-section-box">   
                <div className="right-content-box">
                    <h2>{this.renderPhaseName(this.state.journey,this.props.journeyData)}</h2>
                        {this.state.deleteTrigger.popUp && this.renderDeletePopUp()}
                    {list && <ReactSortable
                        list={list}
                        handle= ".my-handle"
                        onEnd = {()=>{
                            let newOrder = this.changeOrder(this.state.triggerData,null,'dragDrop',list);
                            this.setState({triggerData:newOrder})
                        }}
                        setList={(list) => {
                            this.setState({ list })
                        }}
                        animation={150}
                    >
                    {list && list.map((ele) => {
                        return this.renderPhaseTrigger(WhatsNext[ele.name], ele.id);
                    })}
                    </ReactSortable>}
                 
                    <div className="button-wrapper btn-box text-center" style={{marginTop:'45px',marginBottom:'20px'}}>
                        <button
                            className="btn btn-secondary"
                            onClick={() => this.fetchJourneyData()}
                            type="button"
                        >Cancel
                        </button>
                        <button className="btn btn-primary" onClick={this.handleSubmit}>
                            Save
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStatetoProps = state => {
    return {
        updateJourney :state.companyBodhiJourney && state.companyBodhiJourney.updateBodhiJourneyReducer
        && state.companyBodhiJourney.updateBodhiJourneyReducer,
        companyJourney: (state.companyBodhiJourney && state.companyBodhiJourney.bodhiJourneyReducer
        && state.companyBodhiJourney.bodhiJourneyReducer.data)||{}
    }
}

const mapDispatchToProps = dispatch => (
    bindActionCreators({
        requestCompanyJourney,
        receiveCompanyJourney,
        receiveUpdateCompanyJourney,
        addCompanyJourney
    }, dispatch)
)

export default connect(mapStatetoProps, mapDispatchToProps)(BodhiJourneyForm)
