import { call, put} from "redux-saga/effects";

import { getPowerMeters,getStateList,getSlugStatus,addCompany,
  homeownerReport,referralReportApi  } from "../../screens/Admin/Api/dashboard";
import {  recievePowerMeterApiData,recieveStateListApiData,
  recieveHomeownerReportApiData, recieveReferralReportApiData,
  recieveSlugStatusApiData,recieveAddCompanyApiData } from "../../screens/Admin/Actions/dashboard";
import  handleError  from "../../shared/errorHandler";

function* getPowerMetersAPI(action) {
    try {
      // do api call
      const data = yield call(getPowerMeters);
      yield put(recievePowerMeterApiData(data));
    } catch (e) {
      handleError(e)

    }
  }
  
  
  function* getStateListAPI(action) {
    try {
      // do api call
      const data = yield call(getStateList,action.data);
      yield put(recieveStateListApiData(data));
    } catch (e) {
      handleError(e)
    }
  }

  function* getSlugStatusAPI(action) {
    try {
      // do api call
      const data = yield call(getSlugStatus, action.data);
      yield put(recieveSlugStatusApiData(data));
    } catch (e) {
      // handleError(e)
    }
  }
  
  function* addCompanyAPI(action) {
    try {
      // do api call
      let data = {};
      if (JSON.stringify(action.data) !== "{}") {
        data = yield call(addCompany, action.data);
      }
      yield put(recieveAddCompanyApiData(data));
    } catch (e) {
      handleError(e)
    }
  }

function* howmownerReport(action) {
  try {
    // do api call
    let data = {}
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(homeownerReport, action.data);
    }

    yield put(recieveHomeownerReportApiData(data));
  } catch (e) {
    handleError(e)

  }
}

function* referralReport(action) {
  try {
    // do api call
    let data = {}
    if (JSON.stringify(action.data) !== '{}') {
      data = yield call(referralReportApi, action.data);
    }

    yield put(recieveReferralReportApiData(data));
  } catch (e) {
    handleError(e)

  }
}
 
  export const adminData =  {
    howmownerReport,
    referralReport,
    getPowerMetersAPI,
    getStateListAPI,
    getSlugStatusAPI,
    addCompanyAPI
  }
  