// import npm packages
import React, { Component }       from "react";
import { connect }                from "react-redux";
import withRouter                 from './../../../shared/withRouter';
import { bindActionCreators }     from "redux";
import { Select,Menu, Dropdown}    from 'antd';
import { Map, Marker,InfoWindow,
        GoogleApiWrapper }        from 'google-maps-react';
import DashboardStats             from "../components/dashboard/dashboardStats";
import CompanyMetrics             from "../components/metrics/companyMetrics";
import {requestCompanyFleetData}  from "../actions/fleet";
import googleMap                  from "../../../environments/index";
import {removeClass}              from "../../../shared/removeClass";
import { jwtDecode }              from "../../../shared/jwt";
import { getToken }               from '../../../shared/util';
import { monitoringSystemData }   from '../../../shared/fleet';
import { setLoader }              from '../../Common/Loader/action';
import Search_Icon                from "../../../Assets/Images/search_black.svg"


class FleetDashboard extends Component {
    constructor(props) {
      super(props);
      let token = getToken()
      this.companyId = this.props.router.params.companyId;
      this.slug = this.props.router.params.slug;
      this.installerData = jwtDecode(token);
      this.isBoundCenter = true;
      this.state= {
        filterBox     : true,
        mapView       : false,
        tempState     : false,
        markerBounce  : null,
        counter       : 1,
        metricsType   : 'fleet',
        metricsQuery  : {"fleet":{"skip":0,"limit":"25","count":null}},
        metricColumnParam   : [],
        companyFleetCount   : null,
        metricFilterParam   : {"fleet":{}},
        metricRef     : null,
        metricsHeader : ["Homeowner","Street address","Alert severity","Status","Last data sync","Today's production","Yesterday's production","Monitoring system"]
      }
    }

    componentWillMount() {
        this.props.setLoader({ loader: true });
        this.props.requestCompanyFleetData({companyId:this.companyId});
    }

    componentWillUnmount(){
        this.siderMenuDisplay(false);
    }

    componentDidUpdate(prevProps,prevState){
        if(prevState.counter != this.state.counter){
            this.isBoundCenter = true;
            this.setState({tempState : !this.state.tempState})
        } else {
            this.isBoundCenter = false;
        }
    }

    componentWillReceiveProps(nextProps){
        let {companyFleet} = nextProps;
        if(!this.state.companyFleetCount && companyFleet && companyFleet[1]){
           
            let noDataRed       = 0;
            let noDataYellow    = 0;
            let noProdRed       = 0;
            let noProdYellow    = 0;
            let lowProdRed      = 0;
            let lowProdYellow   = 0;
            let totalUser       = companyFleet[companyFleet.length -1][0]['totalUser'];
            companyFleet[0].map((ele)=>{
               
                switch(ele.type){
                    case 1:
                        noDataYellow++;
                        ele['Alert severity'] = 'Yellow';
                        ele['Status'] = 'No data';
                        break;
                    case 2:
                        noDataRed++;
                        ele['Alert severity'] = 'Red';
                        ele['Status'] = 'No data';
                        break;
                    case 3:
                        noProdYellow++;
                        ele['Alert severity'] = 'Yellow';
                        ele['Status'] = 'No production';
                        break;
                    case 4:
                        noProdRed++;
                        ele['Alert severity'] = 'Red';
                        ele['Status'] = 'No production';
                        break;
                    case 5:
                        lowProdYellow++;
                        ele['Alert severity'] = 'Yellow';
                        ele['Status'] = 'Low production';
                        break;
                    case 6:
                        lowProdRed++;
                        ele['Alert severity'] = 'Red';
                        ele['Status'] = 'Low production';
                        break;
                    
                }
            });
            this.setState({
                companyFleet,
                companyFleetCount : {
                    noProdRed,
                    noProdYellow,
                    noDataYellow,
                    noDataRed,
                    totalUser,
                    lowProdRed,
                    lowProdYellow,
                    totalAlert : noDataRed + noDataYellow + noProdRed + noProdYellow + lowProdRed + lowProdYellow
                }
            });
            this.props.setLoader({ loader: false });
        }
    }


    getmetricRef = (metricRef) => {
        this.setState({
          metricRef
        })
    }

    changeMetricQueryParam = (operation, value) => {
        let { metricsQuery, metricFilterParam } = this.state;
        let temp = metricsQuery['fleet'];
        switch (operation) {
            case 'sort':
                temp['sort'] = value;
                break;

            case 'filter':
                metricFilterParam['fleet'] = value;
                temp['skip'] = 0
                break;
        }

            metricsQuery['fleet'] = temp;
            this.receiveMetricData(metricsQuery,metricFilterParam,operation);
    }

    receiveMetricData = (metricsQuery,metricFilterParam,operation) => {
        let companyFleetData   = this.state.companyFleet[0];

        if(operation == "sort"){
            let key   = Object.keys(metricsQuery['fleet']['sort'])[0];
            let value = Object.values(metricsQuery['fleet']['sort'])[0];
            if(value == "asc")
                companyFleetData.sort((a, b) => a[key] < b[key] ? -1 : (a[key] > b[key] ? 1 : 0));
            else
                companyFleetData.sort((a, b) => a[key] > b[key] ? -1 : (a[key] < b[key] ? 1 : 0));

        }

        for (var index in metricFilterParam['fleet']) {
            let key = index;
            let value = metricFilterParam['fleet'][index];

            if (Array.isArray(value) && value.length !== 0) {
                companyFleetData = companyFleetData.filter((ele) => {
                    return value.indexOf(ele[key]) > -1
                });
            } else {
                if(value){
                    companyFleetData = companyFleetData.filter((ele) => {
                        return value && ele[key] && ele[key].toLowerCase().includes(typeof (value) == "string" ? value.toLowerCase() : value);
                    });
                }
                
            }
        };

        this.setState({counter:this.state.counter+1,metricsData:companyFleetData,metricsQuery,metricFilterParam});
    }

    renderCompanyMetrics = () => {
        if (this.state.metricRef) {
            this.state.metricRef.setState({
                isCoulmnDisplay: false,
                currentShow: null,
                filterValue: null,
                stateMetricFilterParam: {},
                metricColumnParam: (this.state.metricColumnParam && this.state.metricColumnParam.length > 0) ? this.state.metricColumnParam : []
            });
        }
        let { metricsType, metricFilterParam, metricsHeader,metricsQuery } = this.state;
        let companyFleet = this.state.companyFleet;
        let temp = companyFleet && Array.isArray(companyFleet) && companyFleet[0];

        if(temp && metricsQuery['fleet']['count'] === null){
            temp.sort((a, b) => a['dailySyncTime'] > b['dailySyncTime'] ? -1 : (a['dailySyncTime'] < b['dailySyncTime'] ? 1 : 0));

            metricsQuery['fleet']['count'] = temp.length;
            this.setState({metricsQuery, metricsData : temp});
        }

        let metricsData = this.state.metricsData || temp;

        return <CompanyMetrics changeState ={this.changeState}  getmetricRef={this.getmetricRef} {...this.props} metricsType={metricsType} metricsData={metricsData} metricsHeader={metricsHeader}
            changeMetricQueryParam={this.changeMetricQueryParam} metricsParam={metricsQuery[metricsType]} metricFilterParam={metricFilterParam[metricsType] ? metricFilterParam[metricsType] : {}} />
    }

    onMarkerClick = (props, marker) =>{
        this.setState({
            activeMarker: marker,
            selectedPlace: props,
            showingInfoWindow: true
          });
      
    }
    
    siderMenuDisplay(mapView){
        var d = document.getElementsByClassName('siderMenu');
        var body = document.getElementsByTagName('body');
        if(d[0] && mapView){
            d[0].style.display = "none";
            body[0].className = "harmonia";
            var headerTag = document.getElementsByClassName('navbar-brand default');
            if(!headerTag[0])
            document.querySelector('#installerHeader').insertAdjacentHTML( 'afterbegin',`  <a href=${window.location.origin}/${this.slug}/${this.companyId}/dashboard  class="navbar-brand default fleetLogo"/>`);
        } else if(d[0] && !mapView) {
            d[0].style.display = "block";

            var headerTag = document.getElementsByClassName('fleetLogo');
            if(headerTag[0]){
                headerTag[0].remove();
            }
            
            if (localStorage.getItem('siderBarState') == "true") {
                removeClass("installer-view");
                if (body && body[0]) {
                    body[0].className = "sider-collapse harmonia";
                }
            } else {
                removeClass("sider-collapse");
                if (body && body[0]) {
                    body[0].className = "installer-view harmonia";
                }
            }                     
        } if (this.installerData && this.installerData.role === "installer") {
            body[0].className = "harmonia";
        }
    }

    getBoundsZoomLevel = (bounds) => {
        var body = document.getElementsByTagName('body')[0];
        let mapDim = {
            width   :   body.clientWidth,
            height  :   body.clientHeight
        };
        var WORLD_DIM = { height: 256, width: 256 };
        var ZOOM_MAX = 17;

        function latRad(lat) {
            var sin = Math.sin(lat * Math.PI / 180);
            var radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
            return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
        }

        function zoom(mapPx, worldPx, fraction) {
            return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
        }

        var ne = bounds.getNorthEast();
        var sw = bounds.getSouthWest();
        var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;
        var lngDiff = ne.lng() - sw.lng();
        var lngFraction = ((lngDiff < 0) ? (lngDiff + 360) : lngDiff) / 360;
        var latZoom     = zoom(mapDim.height, WORLD_DIM.height, latFraction);
        var lngZoom     = zoom(mapDim.width, WORLD_DIM.width, lngFraction);
        var zoomLevel   = Math.min(latZoom, lngZoom, ZOOM_MAX);
        return zoomLevel;
    }

    onInfoWindowClose = () =>
    this.setState({
      activeMarker: null,
      showingInfoWindow: false
    });
    
    renderFleetMap() {        
        let {selectedPlace} = this.state;
        let addressBlock = selectedPlace && selectedPlace.homeowner['Street address'] && selectedPlace.homeowner['Street address'].split(',');
        let companyFleet = this.state.companyFleet;
        let temp = (companyFleet && Array.isArray(companyFleet) && companyFleet[0]) || [];
        let fleetData = this.state.metricsData || temp;
        if(temp.length === 0){
            return  <div className="map-wrapper">
                <Map
                    google={this.props.google}
                    onReady={this.onMapReady}
                    zoom={9}
                    mapTypeControl={false}
                    fullscreenControl={false}
                    zoomControl={false}
                    streetViewControl={false}
                    initialCenter={{
                        lat : 29.777320,
                        lng : -95.543200
                    }}
                ></Map>
            </div>
        }

       
        var bounds = new this.props.google.maps.LatLngBounds();
        fleetData.map(ele=>{
            let obj = {
                lat : ele['lat'] * 1,
                lng : ele['lng'] * 1
            };
            bounds.extend(obj);
        });
   
        return (
            <div className="map-wrapper">
                {this.renderFleetFilter(fleetData)}
                <Map
                    google={this.props.google}
                    onReady={this.onMapReady}
                    ref={(ref) => { this.map = ref; }} // bind the ref
                    onCenterChanged={(e) => {
                        this.onCenterChanged = this.map.map.center;
                    }}
                    center= {this.isBoundCenter ? (bounds && bounds.getCenter()) : this.onCenterChanged}
                    zoom= { (bounds) ? this.getBoundsZoomLevel(bounds) : 9}
                    mapTypeControl={false}
                    fullscreenControl={false}
                    zoomControl={false}
                    streetViewControl={false}
                    initialCenter={{
                        lat: fleetData && fleetData[0] && fleetData[0].lat,
                        lng: fleetData && fleetData[0] && fleetData[0].lng
                    }}
                >
                    {
                        fleetData.map((homeowner, index) => {
                            if (homeowner.lat && homeowner.lng) {
                                return (
                                    <Marker
                                        id={index}
                                        key={index}
                                        homeowner={homeowner}
                                        onClick={this.onMarkerClick}
                                        animation = {(this.state.markerBounce==homeowner.homeownerId) ? 1 :0}
                                        position={{
                                            lat: Number(homeowner.lat),
                                            lng: Number(homeowner.lng)
                                        }}
                                        icon={{
                                            url: require(`../../../Assets/Images/${this.imageName(homeowner['Alert severity'] + homeowner.Status)}.svg`)
                                        }}
                                    />
                                );
                            } else {
                                return '';
                            }
                        })
                    }
                    {
                        <InfoWindow
                            onClose={this.onInfoWindowClose}
                            options={{disableAutoPan: true}}
                            marker={this.state.activeMarker}
                            visible={this.state.showingInfoWindow}
                        >
                            {
                                selectedPlace && 
                                <div className="row fleet-homeownerInfo">
                                <div className="col-5 seprator-right">
                                    <a className="font-weight-bold" href={`${window.location.origin}/${this.slug}/${this.companyId}/dashboard/${selectedPlace.homeowner['homeownerId']}/homeowner`} target='_blank'>{selectedPlace.homeowner['Name']}</a>
                                    {
                                        addressBlock && addressBlock[0] &&
                                            <div className="mt-2 mb-3">
                                                {addressBlock[0]}<br />{addressBlock.slice(1, addressBlock.length).join(", ")}
                                            </div>
                                    }
                                </div>

                                <div className="col-7">
                                    <div className="mb-2"><span className="font-weight-bold">Last sync:</span> {selectedPlace.homeowner['Last data sync']}</div>
                                    <div className="mb-2"><span className="font-weight-bold">Today’s prod:</span> {this.productionValue(selectedPlace.homeowner,'todayProd')} kWh</div>
                                    <div className="mb-2"><span className="font-weight-bold">Yesterday’s prod:</span> {this.productionValue(selectedPlace.homeowner,'yesterdayProd')} kWh</div>
                                    <div className="mb-3"><span className="font-weight-bold">Monitoring:</span> {monitoringSystemData(selectedPlace.homeowner['Monitoring system'],selectedPlace.homeowner)}</div>
                                </div>

                            </div>
                            }
                        </InfoWindow>
                    }

                </Map>
            </div>
        )
    };

    productionValue(data,key){
        if(!data || !data[key]){
            return 0;
        } else {
            return (data[key]/data.dataInterval).toFixed(1);
        }
    }

    changeState = (obj) => {
        this.setState(obj)
    }

    filterMapView(filterType,ele){
        let {metricFilterParam} = this.state;
        if(!ele.key){
            delete metricFilterParam['fleet'][filterType];
        }
        this.setState({[filterType + "mapFilter"]:(ele.key)});
        let stateMetricFilterParam = {[filterType]:[(ele.filterKey || ele.key)]};
        Object.assign(metricFilterParam['fleet'], ele.key ? stateMetricFilterParam : {});
        this.changeMetricQueryParam('filter',metricFilterParam['fleet']);
    }

    renderFleetFilter(fleetData){
        let {companyFleetCount,metricFilterParam,metricFilterParam : {fleet}} = this.state;
        let option = [
            {
                display: 'No data',
                imageName: 'nodata_icon.svg',
                className: 'row col-md-12 seprator-bottom pb-2',
                key : 'noData'
            },
            {
                display: 'No production',
                imageName: 'noprod_icon.svg',
                className: 'row col-md-12 pt-3 pb-2 seprator-bottom',
                key : 'noprod'
            },
            {
                display: 'Low production',
                imageName: 'lowprod_icon.svg',
                className: 'row col-md-12 pt-3',
                key : 'lowprod'
            }
        ];

        let filterOption = [
            {
                display: 'Alert type',
                filterType : 'Status',
                option: [
                    {
                        key: 'No data',
                    },
                    {
                        key: 'No production'
                    },
                    {
                        key: 'Low production'
                    }
                ]
            },
            {
                display: 'Alert status',
                filterType : 'Alert severity',
                option: [
                    {
                        key: 'Yellow',
                    },
                    {
                        key: 'Red'
                    }
                ]

            },
            {
                display: 'Monitoring',
                filterType : 'Monitoring system',
                option: [
                    {   
                        filterKey : 5,
                        key: 'APsystems'
                    },
                    {
                        filterKey : 1,
                        key: 'eGauge',
                    },
                    
                    {
                        filterKey : 3,
                        key: 'Enphase',
                    },
                    {   
                        filterKey : 2,
                        key: 'SolarEdge'
                    },
                    
                ]
            }
        ]
        
        return (
            <>
                <div className="map-filter-box">
                    <div className={"fleet-sider " + (this.state.filterBox ? '' : 'hide')} onClick={() => this.changeState({filterBox: !this.state.filterBox})}></div>
                    {
                        this.state.filterBox &&
                        <>
                            <div className="filter-box">
                                <div className="search-wrap fleet">
                                    <img
                                        src={Search_Icon}
                                        alt='search'
                                    />
                                    <input
                                        className="border-0"
                                        placeholder="Search address"
                                        value={metricFilterParam['fleet']['Street address']}
                                        onChange={(e)=>{
                                            let stateMetricFilterParam = {'Street address':e.target.value};
                                            Object.assign(metricFilterParam['fleet'], stateMetricFilterParam);
                                            this.changeMetricQueryParam('filter',metricFilterParam['fleet']);
                                        }}

                                    />
                                </div>
                                <div className="menuOption">
                                    {
                                        filterOption.map((ele) => {
                                            return (<Dropdown overlay={this.menuFilter(ele.option,ele.filterType)} trigger={['click']}>
                                                <div>{this.dropDownTitle(ele.display,ele.filterType)}
                                                {
                                                    this.state[ele.filterType + "mapFilter"] ?
                                                    <i class="fa fa-times dropdown-icon" aria-hidden="true"  onClick={(e)=>{
                                                        e.stopPropagation();
                                                        this.filterMapView(ele.filterType,{});
                                                    }}/>:
                                                    <i class="fa fa-angle-down dropdown-icon" aria-hidden="true" />
                                                }
                                                </div>
                                            </Dropdown>)
                                        })
                                    }
                                </div>
                            </div>

                           {fleet && JSON.stringify(fleet) =='{}' ?  <div className="fleetBox white-box mapView hideMargin pt-0">
                                {option.map((ele) => {
                                    return (
                                        <div className={ele.className}>
                                            <div className="mt-3 col-md-1 p-0"><img src={require(`../../../Assets/Images/${ele.imageName}`)} /></div>
                                            <div className="col-md-11 p-0">
                                                <div className="text" onClick={()=>{
                                                    this.filterMapView('Status',{key:ele.display});
                                                }}>{ele.display}</div>
                                                <div className="row mt-1 text-left">
                                                    <div className="col-md-6 seprator-right pr-0" onClick={() => {
                                                        this.filterMapView('Status', { key: ele.display });
                                                        this.filterMapView('Alert severity', { key: 'Red' });
                                                    }}>
                                                        <div className="total red">{(companyFleetCount && companyFleetCount[ele.key + 'Red']) || 0}</div>
                                                        <div className="subTotal">{this.totalPercent(companyFleetCount, ele.key + 'Red')}% of fleet</div>
                                                    </div>
                                                    <div className="col-md-5 ml-2" onClick={() => {
                                                        this.filterMapView('Status', { key: ele.display });
                                                        this.filterMapView('Alert severity', { key: 'Yellow' });
                                                    }}>
                                                        <div className="total yellow">{(companyFleetCount && companyFleetCount[ele.key + 'Yellow']) || 0}</div>
                                                        <div className="subTotal">{this.totalPercent(companyFleetCount, ele.key + 'Yellow')}% of fleet</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div> : 
                            
                            <div className="white-box detail-box">
                                {fleetData.length > 0 ? fleetData.map((ele) => {
                                    let addressBlock = ele['Street address'].split(',')
                                    return (
                                        <div className="seprator-bottom"
                                            onMouseOver ={() => {
                                                if(this.state.markerBounce !== ele.homeownerId)
                                                this.setState({ markerBounce: ele.homeownerId });
                                            }}
                                            onMouseLeave={() => this.setState({ markerBounce: null })}
                                        >
                                            <div className="row m-0 pb-2 pt-3">
                                                <div className="col-6 seprator-right">
                                                    <a className="font-weight-bold" href={`${window.location.origin}/${this.slug}/${this.companyId}/dashboard/${ele['homeownerId']}/homeowner`} target='_blank'>{ele['Name']}</a>
                                                    {
                                                        addressBlock && addressBlock[0] &&
                                                        <div className="mt-2 mb-2">
                                                            {addressBlock[0]}<br />{addressBlock.slice(1, addressBlock.length).join(", ")}
                                                        </div>
                                                    }
                                                    <div className="fleetBox hideMargin">
                                                        <img className="mr-2" src={require(`../../../Assets/Images/${`${(ele['Alert severity'] + ele['Status']).toLowerCase().replace(/ /g, '')}_icon`}.svg`)}></img>
                                                        <span className={`font-weight-300 total ${ele['Alert severity'].toLowerCase()}`} style={{ fontSize: '14px' }}>{ele.Status}</span>
                                                    </div>
                                                </div>

                                                <div className="col-6">
                                                    <div className="mb-2"><span className="font-weight-bold">Last sync:</span> {ele['Last data sync']}</div>
                                                    <div className="mb-2"><span className="font-weight-bold">Today’s prod:</span> {this.productionValue(ele,'todayProd')} kWh</div>
                                                    <div className="mb-2"><span className="font-weight-bold">Yesterday’s prod:</span> {this.productionValue(ele,'yesterdayProd')} kWh</div>
                                                    <div className="mb-3"><span className="font-weight-bold">Monitoring:</span> {monitoringSystemData(ele['Monitoring system'],ele)}</div>
                                                </div>

                                            </div>
                                        </div>
                                    )
                                }) :
                                        <div className="no-result-block text-center">
                                            <h4 className="">No results found.</h4>
                                            <p>
                                            Try changing or removing some of your filters or adjusting your search area.
                                            </p>
                                        </div>
                                }
                            </div>
                            }
                        </>
                    }
                </div>
            </>
        )        
    }

    dropDownTitle(display,filterType){
        return this.state[filterType+"mapFilter"] ? this.state[filterType+"mapFilter"] : display;
    }

    totalPercent(companyFleetCount,key){
        if(!companyFleetCount)
        return 0
        let typeValue = 0;
        switch(key){
            case 'noDataRed':
                typeValue = companyFleetCount['noDataRed'];
            break;
            case 'noDataYellow':
                typeValue = companyFleetCount['noDataYellow'];
            break;
            case 'noprodRed':
                typeValue = companyFleetCount['noProdRed'];
            break;
            case 'noprodYellow':
                typeValue = companyFleetCount['noProdYellow'];
            break;
            case 'lowprodYellow':
                typeValue = companyFleetCount['lowProdYellow'];
            break;
            case 'lowprodRed':
                typeValue = companyFleetCount['lowProdRed'];
            break;
        }

        return ((typeValue*100)/(companyFleetCount['totalUser'] || 1)).toFixed(1);
    }

    menuFilter(option,filterType) {
        const menu = (
            <Menu>{option.map(ele=>{return <Menu.Item key={ele.key} onClick={(e)=>{
                this.filterMapView(filterType,ele);
            }}>{ele.key}</Menu.Item>})}</Menu>
        );
        return menu;
    }


    handleMouseExit = e => {
        this.setState({
            showInfoWindow: false
        });
    };


    imageName(status){
        status = status.toLowerCase().replace(/ /g,'');
        switch(status){
            case 'rednodata':
                return 'nodata_pin_red';
            case 'yellownodata':
                return 'nodata_pin_yellow';
            case 'rednoproduction':
                return 'noprod_pin_red';
            case 'yellownoproduction':
                return 'noprod_pin_yellow';
        }
    }


    renderSwitch(){
        return (
            <div className="fleet-viewType mapView" id="radios">

                <input id="rad1" type="radio" name="radioBtn" onChange={() => this.setState({ mapView: false })} />
                <label className={`labels ${!this.state.mapView ? 'checked' : ''}`} for="rad1">List view</label>

                <input id="rad2" type="radio" name="radioBtn" onChange={() => this.setState({ mapView: true })} />
                <label className={`labels ${this.state.mapView ? 'checked' : ''}`} for="rad2">Map view</label>

                <div id="bckgrnd"></div>
            </div>
        )
    }

    render() {
        this.siderMenuDisplay(this.state.mapView);
        return (
            <React.Fragment>
                <title>Fleet - {this.props.companyName + '-'} Bodhi</title>
                <div>

                {this.state.mapView && this.renderSwitch()}
                    {
                        this.state.mapView ? this.renderFleetMap() :
                        <>
                            <DashboardStats changeMetricQueryParam={this.changeMetricQueryParam} {...this.props} companyFleetCount={this.state.companyFleetCount} companyId={this.companyId} statsType="fleet" />
                            {this.renderCompanyMetrics()}
                        </> 
                    }
                </div>
            </React.Fragment>
        )
    }
}
const mapStatetoProps = (state) => {
    return {
        companyName: state.header.getInstaller && state.header.getInstaller.data && state.header.getInstaller.data.companyName,
        searchData: state.header.getInstaller,
        companyFleet : state.fleet.companyFleet
    };
  };
  
  const mapDispatchToProps = dispatch => (
    bindActionCreators({
        setLoader,
        requestCompanyFleetData
    }, dispatch)
  )

export default withRouter(connect(mapStatetoProps, mapDispatchToProps)(GoogleApiWrapper({
    apiKey: googleMap.envConfig.googleMap.API_KEY
})(FleetDashboard)));