// import npm packages
import React, { Component }   from "react";
import { state }              from "aws-cognito-redux-saga";
import PropTypes              from "prop-types";
import { reduxForm }          from "redux-form";
import { Input, Spin }  from "antd";
import { Buffer } from 'buffer';
import { LoadingOutlined }    from '@ant-design/icons';
import {Helmet}               from "react-helmet";
import { EyeOutlined, EyeInvisibleOutlined }         from '@ant-design/icons';
// import local files
import withRouter           from './../../../../shared/withRouter';
import {handleCognitoError} from './../../../../shared/handleCognitoError'
import * as validate    from "./../../../../shared/validators";
import { jwtDecode }    from "./../../../../shared/jwt";
import { setToken }     from "./../../../../shared/util";
import { deviceType }   from "./../../../../shared/checkIsMobile"
import { removeClass }  from "./../../../../shared/removeClass"
import { envImageUrl,envAppIcon,checkImageValid,
  convertJSON2CSS}      from "../setUserBackgroud"
import crossIcon        from "../../../../Assets/Images/cross.png"

const style = {
  button: {
    margin: "8px 0"
  },
  error: {
    width: "100%",
    display: 'contents',
    margin: "8px",
    color: "rgb(200,0,0)",
    height: "32px"
  }
};

const antIcon = <LoadingOutlined style={{ fontSize: 60 }} spin />;

class SignIn extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    signIn: PropTypes.func.isRequired,
    auth: PropTypes.object,
    init: PropTypes.func,
    history: PropTypes.object
  };

  constructor(props) {
    super(props);
    props.init();
    this.props.setLoader({loader: false})
    this.image = "";
    this.slug = "";
    this.state = {
      email: "",
      password: "",
      error: ''
    };
  }

  checkForMagicLinkLogin() {
    const locationObject = this.props.router.location
    if (locationObject.search.includes("token")) {
      const queryString = locationObject.search.substring(1);
      const [tokenString] = queryString.split("&");
      const [, tokenValue] = tokenString.split("=");
      const tokenPayload = JSON.parse(Buffer.from(tokenValue, 'base64').toString());
      const email = tokenPayload.email;
      const code = tokenPayload.pwd;
      if (email && code) {
        this.setState({
          password: code,
          email: email
        }, () => {
          this.props.setLoader({ loader: true });
          this.props.handleSubmit(this.props.signIn(this.state.email.toLowerCase(), this.state.password));
        });
      }
    }
  }

  addScript() {
    let url = this.state.headTag;
    !this.state.headTag && this.setState({ headTag: true }, () => {
        envAppIcon(this.slug).then(headTag => {
          this.setState({ headTag });
        });
    });
    
    if (this.state.headTag && this.state.headTag !== true) {
      return (
        <Helmet>
          <link rel="shortcut icon" href={url} />
          <meta name="apple-mobile-web-app-title" content={this.companyName || 'Bodhi'} />
          <link rel="apple-touch-icon" href={url} sizes="72x72" />
        </Helmet>
      )
    }

  }

  componentWillMount() {
    if(this.state.error) {
      this.setState({
        error: ''
      })
    }
    this.props.setLoader({loader: false});
    this.props.setMetricsType(null);
    this.slug = localStorage.getItem("slug") || this.props.router?.params.slug;
    convertJSON2CSS(this.slug).then(r=>{
      this.companyName= r;
    });
    localStorage.setItem("isLogout", true);
    removeClass("installer-view");
    removeClass("sider-collapse");
    this.props.init();
    if (this.slug) this.props.requestSlugData(this.slug);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.image &&
      nextProps.image.status === 200 &&
      nextProps !== this.props
    ) {
      this.image = nextProps.image.data.logo;
    }
    if(!this.state.notifyHomeowner && nextProps.auth.error && nextProps.auth.error.message === "Temporary password has expired and must be reset by an administrator." && this.props.auth.error !== nextProps.auth.error){
      this.setState({notifyHomeowner:true},()=>{
        nextProps.requestPostForgotPassword(this.state.email);
      });  
    }
  }

  componentDidUpdate(prevProps) {
    const { auth } = this.props;
    if (
      auth.isSignedIn === state.AUTH_SUCCESS &&
      this.props.auth !== prevProps.auth
    ) {
      this.props.setLoader({loader: false})
      let token = auth.info.idToken && auth.info.idToken.jwtToken;
      let decoded = token && jwtDecode(token);

      if(decoded && decoded.slug==='admin'){
        let token = auth.info.idToken.jwtToken;
        localStorage.setItem("isLogout", false);
        setToken(token);
        this.props.router.navigate(`/admin/dashboard`);
      }
       else if (decoded && decoded.slug) {
        localStorage.setItem("isLogout", false);
        localStorage.setItem('slug',decoded.slug);
        setToken(token);

        const isStatusFeed = localStorage.getItem('isStatusFeed');
        isStatusFeed ? this.props.router.navigate(isStatusFeed) 
        : this.props.router.navigate(`/${decoded.slug}/${decoded.companyId}/dashboard`);

      }
    } else if (auth.error && JSON.stringify(auth.error) !== '{}') {
      this.props.setLoader({loader: false})
    }
    
  }

  signIn = async values => {
    let {error} = this.state;
    if(validate.checkEmail(this.state.email)) {
      error = validate.checkEmail(this.state.email)
    } else if (validate.passwordValidation(this.state.password)) {
      error = validate.passwordValidation(this.state.password)
    }
    if (!error) {
      this.props.setLoader({loader: true})
      localStorage.setItem("isLogout", false);
      this.props.signIn(this.state.email.toLowerCase(), this.state.password);
    } else {
      this.setState({
        error
      })
      this.props.setLoader({loader: false})
    }
  };

  componentDidMount(){
    let deferredPrompt;
    var div = document.querySelector('.add-to');
    var buttonInstall = document.querySelector('.add.button');
    if(this.slug){
      envImageUrl(this.slug).then((response)=>{
        if (response && !this.state.defaultLoginImage) {
          this.setState({
            defaultLoginImage: response
          })
        }
      });
    } else {
      this.setState({
        defaultLoginImage: "https://prod-17terawatts.s3.amazonaws.com/public/logo/desktopLogin_BG.jpg"
      })
    }
    
 
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      deferredPrompt = e;
      div.style.display= 'block';
      buttonInstall.addEventListener('click', (e) => {
        deferredPrompt.prompt();
        deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            this.setState({hideAddToHome:true});
          } 
          deferredPrompt =  null;
        });
      });
    });
    this.checkForMagicLinkLogin();
  }

  onChangeEmail = e => {
    if(this.state.error) {
      this.setState({
        error: ''
      })
    }
    this.setState({ email: e.target.value.trim() });
  };

  onChangePassword = e => {
    if(this.state.error) {
      this.setState({
        error: ''
      })
    }
    this.setState({ password: e.target.value });
  };

  forgotPassword = () => {
    if (this.slug)
      this.props.router.navigate("/" + this.slug + "/forgotpassword");
    else this.props.router.navigate("/forgotpassword");
  };

  renderSignIn() {
    const { handleSubmit, auth } = this.props;
    const image = this.props.image;

    if(document.getElementById('beacon-container')){
      document.getElementById('beacon-container').style.display='none';
    }       
    
    let css = {
      "backgroundImage": `url(${this.state.defaultLoginImage})`
    }

    let deviceInfo = deviceType();
    return (
      <React.Fragment>
        {
          this.addScript()
        }
        <title>{this.companyName || 'Bodhi'} - Login</title>
      <div>
          <Spin
            spinning={this.props.loader}
            indicator={antIcon}
            style={{ color: "white" }}
          >
            <div id="test-bg" className="login-bg body-height isLogin" style={css}>
              <div id="login-container" className="h-100">
                <div className="login-box ">
                  <div className="d-inline-block justify-content-end w-100 text-right">
                    <div className="white-logo d-inline-block" />
                  </div>
                  <div className="box-Shadow center-white-box">
                    <div className="logo-blue" style={{ 'backgroundImage': `url(${(image && image.data && image.data.mobileLogo) || ''})` }}>
                    {
                      image && image.data && image.data.mobileLogo &&
                      <img
                        style={{
                          visibility: "hidden",
                          height: "auto",
                          width: "100%"
                        }}
                        src={image.data.mobileLogo}
                        alt={`Logo for ${this.companyName}`}
                      />

                    }
                    </div>
                    <p className="mobile-h2">Hi. I'm Bodhi.<br />
                    Your partner on the solar journey
                    </p>
                    <form>
                      <div className="form-group">
                        <Input
                          type="email"
                          className="form-control"
                          onChange={this.onChangeEmail}
                          id="exampleInputEmail1"
                          aria-describedby="emailHelp"
                          placeholder="Email"
                        />
                      </div>

                      <div className="form-group">
                        <Input.Password
                          className="form-control login-password"
                          id="exampleInputPassword1"
                          type="password"
                          iconRender={ (visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />) }
                          onChange={this.onChangePassword}
                          placeholder="Password"
                        />
                      </div>
                      {
                        auth.error
                          && !this.state.error ? (
                            <p style={style.error}>
                              {
                                JSON.stringify(auth.error) !== '{}' && auth.isSignedIn === "AUTH_UNKNOWN" && (auth.error && auth.error.message !== "Temporary password has expired and must be reset by an administrator.") ?
                                  handleCognitoError(auth.error.code)
                                  :
                                  null
                              }
                            </p>
                          ) : (
                            ""
                          )
                      }

                      {
                        (auth.error && auth.error.message === "Temporary password has expired and must be reset by an administrator.") ? (<p style={style.error}>
                          Sorry, your previous temporary password has expired.  I just emailed you new login credentials.
                        </p>) : ""
                      }
                      {
                        (!auth.error || JSON.stringify(auth.error) === '{}')
                          && this.state.error && (auth.error && auth.error.message !== "Temporary password has expired and must be reset by an administrator.") ? (
                            <p style={style.error}>
                              { this.state.error}
                            </p>
                          ) : (
                            ""
                          )
                      }

                      <div className="form-group login-remeber">
                        <div className="row">
                          <div className="col-6 paddr0">
                            <div className="check-wrapper">
                              <label className="container">
                                Remember Me
                              </label>
                              <input type="checkbox" defaultChecked={true}/>
                              <span className="checkmark premiumLinks bgColor" />
                            </div>
                          </div>
                          <div
                            className="col-6 paddl0"
                            style={{ textAlign: "right" }}
                          >
                            <div
                              className="forgot premiumLinks"
                              onClick={() => this.forgotPassword()}
                            >
                              Forgot Password
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          onClick={handleSubmit(this.signIn)}
                          className="btn yellow-btn premiumButtonFill premiumButtonText login-text"
                        >
                          Log in
                      </button>
                      </div>                      
                    </form>
                  </div>
                  
                  </div>
                {
                !this.state.hideAddToHome && 
                deviceInfo.os==="android"&&deviceInfo.browser ==="Chrome" &&
                  <div className="xZ2Xk">
                    <div className="overlay ant-slider-dot-active">
                      <div className="text">Add Bodhi to your home screen to easily access it anytime.</div>

                      <div className="button-block add-to">

                        <img alt="close icon" src={crossIcon} className="close" onClick={() => {
                          this.setState({ "hideAddToHome": true })
                        }}
                        />

                        <div className="add button premiumButtonFill premiumButtonText notHover">
                          <div className="sub-text">Add now</div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                </div>
              </div>
          </Spin>
      </div>
     </React.Fragment>
    );
  }

  render() {
    const { auth } = this.props;
    if (auth.passwordResetRequired === state.AUTH_SUCCESS) {
      localStorage.setItem('emailForceChangePassword',this.state.email)
      if (this.slug){
        localStorage.setItem('slug',this.slug);
        this.props.router.navigate("/" + this.slug + "/forcechangepassword", {
          isRefresh : true,
          email: this.state.email
        });
      }
      else
        this.props.router.navigate("/forcechangepassword", {
          isRefresh : true,
          email: this.state.email
        });

      return null;
    } else {
      return this.renderSignIn();
    }
  }
}

// Decorate the form component
export default reduxForm({
  form: "signIn"
})(withRouter(SignIn));
