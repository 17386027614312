import React, { Component }        from 'react';
import { Form, Select, Input, Modal } from 'antd';
import NumberFormat                from 'react-number-format';
import addHardwareIcon from "../../../../Assets/Images/add-hardware.svg"
import {ReactComponent as CaretIcon} from "../../../../Assets/Images/path-2.svg";

const { Option }   = Select;
const FormItem = Form.Item;
const { TextArea } = Input;

export class AddHardware extends Component {
  constructor(props) {
    super(props);
    this.state = {
        addPopUp : true
    };
  }

  adduserForm = () => {
    return(
      <>
        <div className="row" style={{'textAlign': 'initial'}}>
          <div className="col-md-5 offset-md-1">
            <label>First name</label>
            <FormItem
              hasFeedback
              name="firstName"
              rules = { [{ required: true }] }
            >
              <Input
                type="text"
                className="ant-input"
              />
            </FormItem>
          </div>
          <div className="col-md-5">
            <label>Last name</label>
            <FormItem
              hasFeedback
              name="lastName"
              rules={ [{ required: true }] }
            >
              <Input
                type="text"
                className="ant-input"
              />
            </FormItem>
          </div>
        </div>
        <div className="row" style={{'text-align': 'initial'}}>
          <div className="col-md-5 offset-md-1">
            <label>Email</label>
            <FormItem
              hasFeedback
              name="email"
              rules = { [{ type: 'email' }, { required: true }] }
              style={{ marginTop: '4px' }}
            >
                <Input
                  type="text"
                  className="ant-input"
                />
            </FormItem>
          </div>
          <div className="col-md-5">
            <label>Phone number</label>
            <FormItem
              hasFeedback
              name="phone"
              rules = { [{ required: true }] }
            >
                <NumberFormat
                  type='tel'
                  format={(this.props.formSetting && this.props.formSetting.phone)}
                  mask=" " className="ant-input mt-4" placeholder="(   )  -  "
                />
            </FormItem>
          </div>
        </div>
      </>
    )
  }

  componentWillMount() {
    this.id = 1;
    const electricUtilityList = this.props.utilityAndRatePlan && this.props.utilityAndRatePlan.data && this.props.utilityAndRatePlan.data.electricutilityList;
    if (this.props.viewName === 'utility' && !electricUtilityList) {
      this.props.requestGetUtilityRatePlanData();
    }
    if(this.props.note){
      this.setState({note:this.props.note})
    }
  }

  handleSubmit = values => {
    if (JSON.stringify(values) !== '{}' && values.keys && values.keys.length !== 0) {
      const newVals = {
        keys: [],
        names: [],
        labels: []
      }
      values.keys.map((key, index) => {
        newVals.keys.push(index)
        newVals.names.push(key.names)
        newVals.labels.push(key.labels)
      })

      this.props.updateState('addPopUp', false);
      this.props.updateState('formData', newVals);
    } else if (this.props.addUserForm) {
      this.props.updateState(null,false);
      this.props.updateState(values, true);
    } else if(this.props.addNote){
      this.props.updateState('note', this.state.note);
    }
  }

   render() {
     return (
       <Modal
         width={this.props.popWidth ? this.props.popWidth : 520}
         visible={this.props.addPopUp}
         className="add-utility popUp"
         footer={null}
         onCancel={() => {
           this.props.updateState('addPopUp', false);
         }}
       >
         <div class="modal-header cmn-modal-header">
           <h4 class="modal-title cmn-modal-title">
             <img src={require(`../../../../Assets/Images/${this.props.headerImg ? this.props.headerImg : 'add-hardware.svg'
               }`)} alt="" className={"modal-title-logo " + (this.props.className ? this.props.className : '')} />
                 Add {this.props.viewName}
           </h4>
         </div>
         <div>
           <Form  onFinish={this.handleSubmit}>
             {this.props.addUserForm ? this.adduserForm() : (this.props.addNote ? this.renderAddNote() :this.renderHardWareForm())}
             <div className="form-submitted-btn">
               <button
                 className="btn btn-secondary"
                 onClick={() => this.props.updateState('addPopUp', false)}
                 type="button"
               >Cancel</button>

               <button className="btn btn-primary"
               >Save</button>
             </div>
           </Form>
         </div>
       </Modal>
     )
   }

  renderAddNote(){
    return (
      <div className='detail-info-box d-block ml-4 mr-4 mb-0'>
        <FormItem hasFeedback
          rules={[{ required: true }]}
        >
          <TextArea
            rows={4}
            value={this.state.note || ''}
            onChange={(e) => {
              this.setState({ note: e.target.value });
            }}
          />
        </FormItem>
      </div>
    )
  }

  renderHardWareForm(){
    const ElectricUtilityList = this.props.utilityAndRatePlan && this.props.utilityAndRatePlan.data && this.props.utilityAndRatePlan.data.electricutilityList ? this.props.utilityAndRatePlan.data.electricutilityList : [];
    const { viewName } = this.props
    return (
      <Modal
        visible={this.props.addPopUp}
        className="add-utility popUp"
        footer={null}
        onCancel={() => {
          this.props.updateState('addPopUp', false);
        }}
      >
        <div className="modal-header cmn-modal-header">
          <h4 className="modal-title cmn-modal-title">
            <img src={addHardwareIcon} alt="" className="modal-title-logo" />
                 Add {viewName}
          </h4>
        </div>
        <div>
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
          >
            <Form.List name="keys" initialValue={ [{
                names: "",
                labels: ""
              }] }
            >
            {
              (fields, { add, remove }) => (
                <>
                {fields.map((field, index) => {
                  return (
                    <div className="form-el-dyn-add" key={index}>
                      <Form.Item
                        style={{ display: 'inline-block', width: '45%' }}
                        hasFeedback
                        name={[field.name, "names"]}
                        validateTrigger = { ['onChange', 'onBlur'] }
                        rules = { [{
                          required: true,
                          message: `Please provide a name for the ${viewName}`
                        }] }
                      > 
                      {
                          this.props.viewName !== 'utility' ? 
                            <Input
                              className="inline-85-percent"
                              placeholder={`Name`} 
                              style={{
                                marginRight: "20px",
                                lineHeight: "2",
                                paddingTop: "6px"
                              }}
                            />
                          :
                          <Select suffixIcon = {<CaretIcon/>}
                            showSearch
                            style={{ 'width': '100%' }}
                            placeholder="Name"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                          >{
                              ElectricUtilityList && ElectricUtilityList.map((utility, i) => {
                                return (
                                    <Option value={utility.id} key={utility.id}>
                                        {utility.name}
                                    </Option>
                                )
                              })
                            }
                          </Select>
                      }
                      </Form.Item>
                      <Form.Item
                        style={{
                          display: 'inline-block',
                          width: '45%',
                          paddingTop: '2px',
                          paddingBottom: '2px'
                        }}
                        name={[field.name, "labels"]}
                        validateTrigger = { ['onChange', 'onBlur'] }
                      >
                        <Input
                          className="inline-85-percent"
                          placeholder={`Customer-facing name (optional)`}
                        />
                      </Form.Item>
                      {
                        fields.length !== 1 ? (
                        <a
                          className="plus-add-unity"
                          href="#"
                          onClick={e => {
                            e.preventDefault()
                            remove(field.name)
                          }}
                        >
                          -
                        </a>
                        ) : null
                      }
                      {
                        index === fields.length - 1 ? (
                          <a
                            className="plus-add-unity"
                            href="#"
                            onClick={e => {
                              e.preventDefault()
                              add()
                            }}
                          >
                            +
                          </a>
                        ) : null
                      }
                    </div>
                  )
                })}
                </>
              )
            }
            </Form.List>
            <div className="form-submitted-btn">
              <button
                className="btn btn-secondary"
                onClick={() =>  this.props.updateState('addPopUp', false)}
                type="button"
              >Cancel</button>
              <button className="btn btn-primary" 
              >Save</button>
            </div>
          </Form>
        </div>
      </Modal>
    )
  }
}
