import React, { Component } from 'react';
import * as moment from "moment";
import engageScoreIcon  from '../../../../Assets/Images/engagement-score-icon.png'
import shareReferIcon   from '../../../../Assets/Images/share_referrals.svg'
import surveysIcon      from '../../../../Assets/Images/surveys.png'
import fingerTouchIcon  from '../../../../Assets/Images/finger_touch.png'
import totalFleetIcon   from '../../../../Assets/Images/total_fleet.svg'
export default class DashboardStats extends Component {

  renderInstallerStats(){
    const referralCount = (this.props.dashboardData && this.props.dashboardData.getReferralCountReducer &&
      this.props.dashboardData.getReferralCountReducer.data) ? this.props.dashboardData.getReferralCountReducer.data.referralsCount : 0;
    const surveyCount = (this.props.dashboardData && this.props.dashboardData.getSurveyCountReducer &&
      this.props.dashboardData.getSurveyCountReducer.data) ? this.props.dashboardData.getSurveyCountReducer.data.surveysCount : 0;
    const totalSurvey = (this.props.dashboardData && this.props.dashboardData.getSurveyCountReducer &&
      this.props.dashboardData.getSurveyCountReducer.data) ? this.props.dashboardData.getSurveyCountReducer.data.totalSurvey : 0;
    const socialShareCount = (this.props.dashboardData && this.props.dashboardData.getSocialShareCountReducer &&
      this.props.dashboardData.getSocialShareCountReducer.data) ? this.props.dashboardData.getSocialShareCountReducer.data.socialSharesCount : 0;
    const activeAccountCount = (this.props.dashboardData && this.props.dashboardData.activeAccountCountReducer &&
      this.props.dashboardData.activeAccountCountReducer.data) ? this.props.dashboardData.activeAccountCountReducer.data.activeCount : 0;
    return(
      <div className="col-md-7 no-padding-box offset-md-1">
        <div className="white-box box-Shadow">

          <div className="col-md-3 seprator-right"  style={{cursor:'pointer'}} onClick={()=>this.props.changeMetricState("Active Accounts",activeAccountCount)}>
            <div className="headline pl-1">Active accounts</div>
            <div className="icon">
              <img src={engageScoreIcon} />
              <span className="stats">{activeAccountCount}</span>
            </div>
            
          </div>

          <div className="col-md-3 seprator-right"  style={{cursor:'pointer'}} onClick={()=>this.props.changeMetricState("Referrals",referralCount)}>
            <div className="headline pl-1">Referrals</div>
            <div className="icon">
              <img className="referral" src={shareReferIcon} />
              <span className="stats">{referralCount}</span>
            </div>
           
          </div>
          <div className="col-md-3 seprator-right"  style={{cursor:'pointer'}} onClick={()=>this.props.changeMetricState("Surveys",totalSurvey)}>
            <div className="headline pl-1">Surveys</div>
            <div className="icon">
              <img className="survey" src={surveysIcon} />
              <span className="stats">{surveyCount}</span>
            </div>
            
          </div>
          <div className="col-md-3"  style={{cursor:'pointer'}} onClick={()=>this.props.changeMetricState("Social Shares",socialShareCount)}>
            <div className="headline pl-1">Social shares</div>
            <div className="icon">
              <img className="share" src={fingerTouchIcon}/>
              <span className="stats">{socialShareCount}</span>
            </div>
            
          </div>
        </div>
      </div>
    )
  }

  renderFleetStats(){
    let { companyFleetCount } = this.props;
    let isCursorClass = this.props.changeMetricQueryParam ? ' cursor' : '';
    let option = [
      {
        display   : 'No data',
        imageName : 'nodata_icon.svg',
        className : 'row col-md-3 seprator-right pr-0' + isCursorClass,
        key       : 'noData'
      },
      {
        display   : 'No production',
        imageName : 'noprod_icon.svg',
        className : 'row col-md-3 seprator-right pr-0' + isCursorClass,
        key       : 'noprod'
      },
      {
        display   : 'Low production',
        imageName : 'lowprod_icon.svg',
        className : 'row col-md-3 seprator-right pr-0' + isCursorClass,
        key       : 'lowprod'
      }
    ];

    return (
    <>
        <div className="col-md-7 offset-md-1">
          <div className="white-box fleetBox box-Shadow">

            {option.map((ele) => {
              return (
                <div className={ele.className}>
                  <h6 className="title row col-md-12" onClick={() => {
                    if (this.props.changeMetricQueryParam) {
                      this.props.changeMetricQueryParam('filter', { "Status": [ele.display] });
                    }
                  }}>{ele.display}</h6>
                  <div className="col-md-3"><img src={require(`../../../../Assets/Images/${ele.imageName}`)} /></div>
                  <div className="col-md-9 p-0">
                    <div className="row mt-1">
                      <div className="col-md-6 seprator-right" onClick={() => {
                        if (this.props.changeMetricQueryParam) {
                          this.props.changeMetricQueryParam('filter', { "Status": [ele.display], "Alert severity": ["Red"] });
                        }
                      }}>
                        <div className="total red">{(companyFleetCount && companyFleetCount[ele.key + 'Red']) || 0}</div>
                      </div>
                      <div className="col-md-3 offset-md-1" onClick={() => {
                        if (this.props.changeMetricQueryParam) {
                          this.props.changeMetricQueryParam('filter', { "Status": [ele.display], "Alert severity": ["Yellow"] });
                        }
                      }}>
                        <div className="total yellow">{(companyFleetCount && companyFleetCount[ele.key + 'Yellow']) || 0}</div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}

            <div class={"row col-md-3"  + isCursorClass} onClick={() => {
              if (this.props.changeMetricQueryParam) {
                this.props.changeMetricQueryParam('filter', {});
              }
            }}>
              <h6 className="title row col-md-12">Total alerts</h6>
              <div class="col-md-4"><img src={totalFleetIcon} /></div>
              <div className="col-md-7 p-0">
                <div className="row mt-1">
                  <div className="col-md-5">
                    <div className="total" style={{marginLeft:'6px'}}>{(companyFleetCount && companyFleetCount['totalAlert']) || 0}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> 
    </>
    )
     


  }

  renderInstallerGreet(){
    let {searchData:{data}} = this.props;
    return(
      <div className="col-md-4">
        <h2 className="border-0">{this.generateGreetings()}{(data && data.name && ', '+data.name.split(' ')[0]) || ''}!</h2>
        <div className="font-weight-light">Here's your snapshot for {(data && data.companyName) || ''} on {moment().format('MMM DD, YYYY')}.</div>
      </div>
    )
  }

  generateGreetings(){
    var currentHour = moment().format("HH");
    if (currentHour >= 2 && currentHour < 12){
        return "Good morning";
    } else if (currentHour >= 12 && currentHour < 17){
        return "Good afternoon";
    } else {
        return "Good evening";
    }
  }

  render() {
    let {statsType} = this.props;

    if (statsType === "systemNotification")
      return this.renderFleetStats();
    else {
      return (
        <section>
          <div className="profile-detail-box">
            <div className='container'>
              <div className='row margb70'>
                <div className='col-10 '>
                  <p className='mobile-heading-dashboard'>
                    PROJECT MANAGER DASHBOARD
                  </p>
                </div>
                {this.renderInstallerGreet()}
                {statsType === "fleet" ? this.renderFleetStats() : this.renderInstallerStats()}
              </div>
            </div>
          </div>
        </section>
      )
    }
  }
}