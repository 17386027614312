import { Services } from "../../../Api/services";
import envConfig from '../../../environments/index';

const COMPANY_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.COMPANY;
const HOMEOWNER_BASE_URL = envConfig.envConfig.apiGateway.BASE_URL.HOME_OWNER


export const searchApi = async data => {
 
  let url = `${COMPANY_BASE_URL}company/?search=${data}`;
  let res = await Services.getCall(url,true)
  return { data: res.data, status: res.status}
};

export const adminCompanyList = async data =>{
  let url = `${COMPANY_BASE_URL}company/metrics`;
  let res = await Services.getCall(url,true)
  return { data: res.data, status: res.status}
}

export const adminSearchHomeowner = async data=>{
  const url = `${HOMEOWNER_BASE_URL}company/1/homeowner?search=${encodeURIComponent(data)}&admin=${true}`;
  let res = await Services.getCall(url, true)
  return { data: res.data, status: res.status }
}
  