

export const REQUEST_POWERMETERAPI_DATA = "REQUEST_POWERMETERAPI_DATA";
export const RECEIVE_POWERMETERAPI_DATA = "RECEIVE_POWERMETERAPI_DATA";



export const REQUEST_STATELISTAPI_DATA = "REQUEST_STATELISTAPI_DATA";
export const RECEIVE_STATELISTAPI_DATA = "RECEIVE_STATELISTAPI_DATA";
export const REQUEST_SLUGSTATUSAPI_DATA = "REQUEST_SLUGSTATUSAPI_DATA";
export const RECEIVE_SLUGSTATUSAPI_DATA = "RECEIVE_SLUGSTATUSAPI_DATA";
export const REQUEST_ADDCOMPANY_DATA = "REQUEST_ADDCOMPANY_DATA";
export const RECEIVE_ADDCOMPANY_DATA = "RECEIVE_ADDCOMPANY_DATA";

export const REQUEST_HOMEOWNERREPORT_DATA = "REQUEST_HOMEOWNERREPORT_DATA";
export const RECEIVE_HOMEOWNERREPORT_DATA = "RECEIVE_HOMEOWNERREPORT_DATA";

export const REQUEST_REFERRALREPORT_DATA = "REQUEST_REFERRALREPORT_DATA";
export const RECEIVE_REFERRALREPORT_DATA = "RECEIVE_REFERRALREPORT_DATA";

export const requestPowerMeterApiData = () => ({ type: REQUEST_POWERMETERAPI_DATA });
export const recievePowerMeterApiData = data => ({ type: RECEIVE_POWERMETERAPI_DATA, data });


export const requestStateListApiData = (data) => ({ type: REQUEST_STATELISTAPI_DATA,data });
export const recieveStateListApiData = data => ({ type: RECEIVE_STATELISTAPI_DATA, data });
export const requestSlugStatusApiData = (data) => ({ type: REQUEST_SLUGSTATUSAPI_DATA,data });
export const recieveSlugStatusApiData = data => ({ type: RECEIVE_SLUGSTATUSAPI_DATA, data });
export const requestAddCompanyApiData = (data) => ({ type: REQUEST_ADDCOMPANY_DATA,data });
export const recieveAddCompanyApiData = data => ({ type: RECEIVE_ADDCOMPANY_DATA, data });

export const requestHomeownerReportApiData = (data) => ({ type: REQUEST_HOMEOWNERREPORT_DATA, data});
export const recieveHomeownerReportApiData = data => ({ type: RECEIVE_HOMEOWNERREPORT_DATA, data });

export const requestReferralReportApiData = (data) => ({ type: REQUEST_REFERRALREPORT_DATA, data});
export const recieveReferralReportApiData = data => ({ type: RECEIVE_REFERRALREPORT_DATA, data });
