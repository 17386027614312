// import npm packages
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import withRouter from '../../../../shared/withRouter';

// import local files
import { requestFaqQuestionAnswer } from "../../actions/faq";
import {setHeight} from '../../../../shared/setHeight';

class FAQ extends Component {
    
    componentWillMount() {
        let companyId = (this.props.loggedInUser && this.props.loggedInUser.homeownerData && this.props.loggedInUser.homeownerData.companyId)||
        (this.props.router.params.companyId);
        if(this.props.questionAnswer && this.props.questionAnswer.homeownerFaqQuestionAnswer&&
        !this.props.questionAnswer.homeownerFaqQuestionAnswer.data){
            this.props.requestFaqQuestionAnswer({
                companyId
            });
            this.props.setLoader({ loader: true });
        }
    }
    componentWillReceiveProps() {
        setHeight('homeOwnerWrapper', 'menuMobile');
    }
    render() {
     let questionAnswers = this.props.questionAnswer.homeownerFaqQuestionAnswer.data;
     let titleValue = this.props.loggedInUser && this.props.loggedInUser.homeownerData &&  this.props.loggedInUser.homeownerData.companyName;
        return (
            <>
                <title>Frequent Questions - {titleValue}</title>
                <div className="next-level-wrapp">
                    <p className="page-title">Faqs</p>
                    <div className="white-box  margb35">
                        <div className="more-detail-wrapper">
                            {questionAnswers && questionAnswers.map((questionAnswer, index) => {
                                if(questionAnswer.answer.includes('<a')){
                                    questionAnswer.answer = questionAnswer.answer.replace('<a','<a className="premiumLinks" target="_blank"');
                                }
                                return (
                                <div key={index} className="faq-box">
                                    <p className="question">
                                        {questionAnswer.question}
                                    </p>
                                    <div className="answer" dangerouslySetInnerHTML={{ __html: questionAnswer.answer }} />

                                </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStatetoProps = state => {
    return {
        questionAnswer: state.homeownerFaq
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            requestFaqQuestionAnswer
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStatetoProps,
        mapDispatchToProps
    )(FAQ)
);