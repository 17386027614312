import React, { Component }         from "react";
import { bindActionCreators }       from "redux";
import NumberFormat                 from "react-number-format";
import { Form,Input}                from 'antd';
import { Map, Marker, 
        GoogleApiWrapper }                from 'google-maps-react';
import { connect }                        from 'react-redux';
import withRouter                         from '../../../../../shared/withRouter';
import { capitalize }                     from '../../../../../shared/capitalize';
import googleMap                          from '../../../../../environments/index';
import { setLoader }                      from '../../../../Common/Loader/action';
import * as config                        from '../../../../../shared/config';
import ErrorPage                          from '../../../../Common/ErrorPage';
import LandingPage                        from '../../../../Common/LandingPage';
import { getLoadingIcon }                 from "../../../../User/components/setUserBackgroud";
import {checkIsMobile}                    from '../../../../../shared/checkIsMobile';
import { openNotificationWithIcon }       from '../../../../../../src/shared/notification';
import { ReactComponent as Solar_Icon }   from "../../../../../Assets/Images/solar-icon.svg";
import { ReactComponent as Glacier_Icon } from "../../../../../Assets/Images/glacier_icon.svg";
import { requestPostReferralDetails, receivePostReferralDetails,receiveReferralNeighbour,
        requestPostAddressDetails,receivePostAddressDetails,requestReferralNeighbour,
        receivePostAddressFormData } from "../../../actions/referralDetails";

class CalculatorComponent extends Component {
   
    constructor(props) {
        super(props);
        this.state = {
            errorObj: {},
            isThankyouMessage: false,
            utilityRate: null,
            lat:null,
            lng:null,  
        };
    }
    componentWillMount(){
        this.homeownerData = this.props.loggedInUser.homeownerData;
        window.scroll({top: 0});
        let temp = this.props.referralDetails
            && this.props.referralDetails.postHomeownerAddress
            && this.props.referralDetails.postHomeownerAddress.data
            && this.props.referralDetails.postHomeownerAddress.data.data;
        if (temp) {
            this.setState({ 
                utilityRate      :  typeof(temp.utilityRate)== 'string' ? 0.1: temp.utilityRate, 
                lat              :  temp.lat, 
                lng              :  temp.lng
            });
            this.props.receivePostAddressDetails({});
            this.props.receiveReferralNeighbour({});
            this.props.requestReferralNeighbour({
                lat        : temp.lat,
                lng        : temp.lng,
                shareType  : 'referral',
                referralId : this.props.router.params.referralId
            });
        }
        else{
            let urlParam    = this.props.router.params
            this.props.setLoader({ loader: false });
            this.props.router.navigate(`/${urlParam.slug}/referral/${urlParam.referralId}/${urlParam.share}/${urlParam.method}`);
           }
    }
    componentWillReceiveProps(nextProps) {
        let {referralDetails:{postHomeownerReferrals}} = nextProps;
        if (postHomeownerReferrals && postHomeownerReferrals.data) {
            this.setState({ 
                isThankyouMessage: true,
                utilityRate      :  0.1
            })
        }
    }

    shouldComponentUpdate() {
        if (!this.state.utilityRate || !this.state.neighbourAddress) {
            return true;
        } else {
            return false;
        }
    }

    checkNumberRange(value, range, type) {
        const { errorObj } = this.state;
        this.setState({phone:value})
        switch (type) {
            case 'phoneNumber':
                if (value.length !== range) {
                    errorObj.phoneNumber = 'error';
                } else {
                    errorObj.phoneNumber = '';
                }
                this.setState({
                    errorObj
                });
                break;
            default:
                if (value > range) {
                    errorObj[type] = 'error';
                } else {
                    errorObj[type] = '';
                }
                this.setState({
                    errorObj
                });
                break;
        }
    } 
    calculateAnnualProduction(sq){
        sq = sq * 1;
        let anualProduction=(sq * 5.75) 
        return anualProduction
    }

    calculateSystemSize(sq) {
        sq = sq * 1;
        let systemSize1 = (sq * 0.003).toFixed();
        let systemSize2 = (sq * 0.004).toFixed();
        return `${systemSize1} - ${systemSize2}`
    }

    calculateLifetimeSaving(sq) {
        let electricityRate = this.state.utilityRate;

        if (electricityRate) {
            let anualProduction = this.calculateAnnualProduction(sq);
            let lifetimesSaving = (anualProduction * electricityRate * 30) - (7 * 2500 * 0.76);
            lifetimesSaving = parseInt(lifetimesSaving);
            let calc = lifetimesSaving / 1000;
            calc = lifetimesSaving / 1000 > 1 ? parseInt(calc) + 'k' : lifetimesSaving
            return calc;
        }
        else
            return 0

    }
    calculateGlacierPreserved(sq) {
        let anualProduction = this.calculateAnnualProduction(sq);
        let glacierPreserved = anualProduction * 1.6 * (32 / 2000) * 30;
        return glacierPreserved.toFixed()
    }
    handleSubmit = e => {
        let {neighbourAddress,referralDetails} = this.props;
        e.preventDefault();
            if (this.state.name && (this.state.phone || this.state.email)) {
                let address ={
                    address     :   this.props.referralDetails.postHomeownerAddressForm.add,
                    lat         :   this.state.lat,
                    lng         :   this.state.lng,
                    utilityRate :   this.state.utilityRate
                };
                let homeSize       = referralDetails.postHomeownerAddressForm.slider;
                let homeownerId    = referralDetails.homeownerReferrals.data.id;
                let companyId      = referralDetails.homeownerReferrals.data.companyId;
                let installerId    = referralDetails.homeownerReferrals.data.installerId;
                let totalNeighbour = neighbourAddress && neighbourAddress.homesByAdd && neighbourAddress.homesByAdd.length;
                let method  = this.props.router.params.method;
                let name    = this.state.name;
                let email   = this.state.email ? this.state.email : '';
                let phone   = this.state.phone ? this.state.phone.replace(/[^0-9]/g, '') : null;
                let isLead  = true;
                let share   = this.props.router.params.share;
                let baseUrl = window.location.origin + '/' + window.location.href.split('/')[3];
                let solarCompany = this.homeownerData && this.homeownerData.companyName;
                let power   = Number((this.props.energyReport &&this.props.energyReport.totalProduction /this.props.energyReport.totalConsumption) * 100).toFixed(1);
                let powered = power === 'NaN' ? '' : power;
                let markers = '';
                neighbourAddress && 
                neighbourAddress.homesByAdd &&
                neighbourAddress.homesByAdd.length>0 &&
                neighbourAddress.homesByAdd.forEach(
                        (element, index) => {
                            if (
                                element.lat &&
                                element.lng &&
                                element.nearaddress &&
                                index < 90
                            ) {
                                markers +=
                                    `&markers=icon:${
                                    config.S3_URL
                                    }/public/location.png%7Clabel:S%7C` +
                                    Number(element.lat) +
                                    ',' +
                                    Number(element.lng);
                            }
                        }
                    );
                let mapUrl =
                    this.state.lat &&
                    this.state.lng &&
                    this.props.referralDetails.postHomeownerAddressForm.add &&
                    // this.state.address &&
                    'https://maps.googleapis.com/maps/api/staticmap?center=' +
                    this.state.lat +
                    ',' +
                    this.state.lng +
                    `&zoom=15&scale=1&size=600x300&maptype=roadmap&markers=icon:${
                    config.S3_URL
                    }/public/current-location.png%7Clabel:S%7C` +
                    this.state.lat +
                    ',' +
                    this.state.lng +
                    markers +
                    '&key=AIzaSyCB6-bafvWwJRIi48ycKHKbWLfiJbhPt4w&format=png&visual_refresh=true';
                this.props.setLoader({ loader: true });
                this.setState({
                    utilityRate: null
                },()=>{
                    this.props.requestPostReferralDetails({
                        homeownerId,
                        companyId,
                        installerId,
                        totalNeighbour,
                        method,
                        name,
                        email,
                        phone,
                        isLead,
                        share,
                        solarCompany,
                        powered,
                        baseUrl,
                        mapUrl,
                        address,
                        homeSize
                    });
                })
                
            }
            else if(this.state.name){
                openNotificationWithIcon('error', 'Email or phone is needed to share the sun', 'Please try again');
            }
            else if(this.state.phone || this.state.email){
                openNotificationWithIcon('error', 'Name is needed to share the sun', 'Please try again');
            }
            else {
                openNotificationWithIcon('error', 'Name and email or phone are needed to share the sun', 'Please try again');
            }
    };
    renderThankYou() {
        return (
            <React.Fragment>
                <div className="referral-title d-flex justify-content-center">
                <h1 className={`referral-heading ${!checkIsMobile() ? 'premiumButtonFill premiumButtonText notHover' : ''}`}>
                    Let’s go solar!
                </h1>
                </div>
                <p className="referralThankyou-content">
                    Thank you for your interest in being part of the solar community.
                    <span className="referral-contact premiumLinks">
                    {this.homeownerData && capitalize(this.homeownerData.companyName)}{' '}
                        will be contacting you shortly.
                    </span>
                </p>
                <button
                    className="btn premiumButtonFill yellow-btn premiumButtonText referral-submit-bookMeeting"
                    onClick={() => {
                        let urlParam    = this.props.router.params;
                        this.props.receivePostReferralDetails({});
                        this.setState({isThankyouMessage:false},()=>{
                            this.props.router.navigate(`/${urlParam.slug}/referral/${urlParam.referralId}/${urlParam.share}/${urlParam.method}`);
                        });
                        
                    }
                }
                >
                    Close
                </button>
            </React.Fragment>
        );
    }
    
    renderMap(){
        let {neighbourAddress} = this.props
        const element       = document.getElementsByClassName('premiumLinks');
        const style         = element[0] && window.getComputedStyle(element[0]);
        const premumLink    = style && style.getPropertyValue('color');

        if(neighbourAddress && !this.state.neighbourAddress && neighbourAddress.homesByAdd.length===0){
            this.setState({neighbourAddress:true});
        }

        return(
            <div className="map-wrapper">
                <Map
                    google={this.props.google}
                    onReady={this.onMapReady}
                    zoom={16}
                    mapTypeControl={false}
                    fullscreenControl={false}
                    zoomControl={false}
                    streetViewControl={false}
                    initialCenter={{
                        lat: this.state.lat,
                        lng: this.state.lng
                    }}
                >
                    <Marker
                        title={this.props.referralDetails.postHomeownerAddressForm.add}
                        position={{
                            lat: this.state.lat,
                            lng: this.state.lng
                        }}
                        icon={{
                            path:
                                `M 0,-1
                                  C 0.5523, -1   1, -0.5523    1,0
                                  C 1, 0.5523    0.5523, 1     0,1
                                  C -0.5523, 1   -1, 0.5523    -1,0
                                  C -1, -0.5523  -0.5523, -1   0,-1`,
                            fillColor: premumLink || "#FF6F47",
                            fillOpacity: 1,
                            strokeWeight: 0,
                            rotation: 0,
                            scale: 5,
                        }}
                    />

                    {neighbourAddress &&
                        neighbourAddress.homesByAdd &&
                        neighbourAddress.homesByAdd.map((homeowner, index) => {
                            
                            if(!this.state.neighbourAddress && index ===neighbourAddress.homesByAdd.length-1){
                                this.setState({neighbourAddress:true});
                            }
                            if (
                                homeowner.lat &&
                                homeowner.lng &&
                                homeowner.nearaddress
                            ) {
                                return (
                                    <Marker
                                        key={index}
                                        title={homeowner.nearaddress}
                                        position={{
                                            lat: Number(homeowner.lat),
                                            lng: Number(homeowner.lng)
                                        }}
                                        icon={{
                                            path:
                                                `M 0,-1
                                            C 0.5523, -1   1, -0.5523    1,0
                                            C 1, 0.5523    0.5523, 1     0,1
                                            C -0.5523, 1   -1, 0.5523    -1,0
                                            C -1, -0.5523  -0.5523, -1   0,-1`,
                                            fillColor: "#FFD110",
                                            fillOpacity: 1,
                                            strokeWeight: 0,
                                            rotation: 0,
                                            scale: 5,
                                        }}
                                    />
                                );
                            }
                            return '';
                        })}
                </Map>
            </div>
        )
    }
    renderCalculatorComponent(){
        let formData = this.props.referralDetails.postHomeownerAddressForm;
        let details = this.props.referralDetails.homeownerReferrals.data;
        let sqft = formData ? formData.slider : 0;
        return (
            <React.Fragment>

                <h1 className={`referral-heading ${!checkIsMobile() ? 'premiumButtonFill premiumButtonText notHover' : ''}`}>
                    Your benefits of going solar
                </h1>
                <div className="row">
                    <div className="col-md-5 offset-md-1 padding-offset">

                        <div className="row text-center system-glance-box w-100">
                            <div className="col-sm-4 col-4 center-icon">
                                <div className="icon calculatedIcon">
                                    <div className="solar-img">
                                        <Solar_Icon/>
                                    </div>

                                </div>
                                <div className="kw">
                                    {this.calculateSystemSize(sqft)}
                                    {' '}
                                    kW
                        </div>
                                <div className="desc">Recommended System Size</div>
                            </div>
                            <div className="col-sm-4 col-4 center-icon-value">

                                <div className="icon calculatedIcon lifetimeSaving premiumIcon">
                                    ${this.calculateLifetimeSaving(sqft)} {' '}
                                </div>
                                <div className="kw">Lifetime</div>
                                <div className="desc">Savings</div>
                            </div>
                            <div className="col-sm-4 col-4 center-icon">
                                <div className="icon calculatedIcon">
                                    <Glacier_Icon />
                                </div>
                                <div className="kw">
                                    {this.calculateGlacierPreserved(sqft)}{' '}
                                    sqft
                            </div>
                                <div className="desc">Glacier Preserved</div>
                            </div>
                        </div>

                        <p className="referral-homeowner">
                            Based on your home size and electricity prices in your area, this is an estimate of your solar system size and your benefits of going solar. Find out from {' '}
                            <a target='_blank' className="premiumLinks" href={details &&
                                details.companyUrl} >  {this.homeownerData &&
                                    capitalize(this.homeownerData.companyName)}{' '}
                            </a>
                            how you can go solar with $0 down{
                            (this.homeownerData && this.referralType ==1) ?
                            ` and receive a $${this.homeownerData && this.homeownerData.referralAmount} discount when you sign up with them.`:'.'}
                        </p>
                    </div>
                    <div className="col-md-5 calc-gap"> 
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <label>Name</label>
                                <Input
                                    type="text"
                                    className="form-control"
                                    onChange={e => {this.setState({ name: e.target.value })}}
                                />
                            </div>

                            <div className="form-group">
                                <label>Email</label>
                                <Input
                                    type="email"
                                    className="form-control"
                                    onChange={e => {this.setState({ email: e.target.value })}}
                                />
                            </div>

                            <div className="form-group">
                                <label>Phone Number</label>
                                <NumberFormat
                                  type="tel"
                                  format="(###) ###-####"
                                  mask=" "
                                  className="form-control"
                                  placeholder="(   )  -  "
                                  onChange={(e) => {
                                    this.checkNumberRange(e.target.value.replace(/[^0-9]/g, ""), 10, 'phone')
                                  }}
                                />
                            </div>

                            {this.props.loggedInUser.homeownerData && this.props.loggedInUser.homeownerData.meetingUrl ?
                                <div className="text-center footer-btn">
                                    <a href={this.props.loggedInUser.homeownerData.meetingUrl} target="_blank">
                                        <Input type="button" className="btn btn-secondary yellow-btn reversePremiumButtonFill referral-submit-bookMeeting" value="Book a meeting" />
                                    </a>
                                </div> : ""}
                            <div className={`text-center ${
                                this.props.loggedInUser.homeownerData.meetingUrl ? 'footer-btn-contactMe' : 'footer-btn'
                                }`}>
                                <button
                                    type="submit"
                                    className="btn premiumButtonFill yellow-btn premiumButtonText referral-submit-bookMeeting"
                                > Contact me
                             </button>
                            </div>
                        </form>
                    </div>
                </div>
            </React.Fragment>
        );
    }
    render() {
        let {utilityRate} = this.state;
        let mobileImage = this.homeownerData && this.homeownerData.icon;
        let image = this.homeownerData && this.homeownerData.logo;
        let sectionStyle = checkIsMobile() ? {
            backgroundImage: `url(${mobileImage})`,
            height: '33px',
            width: '33px',
            'background-size': '33px'
        } : 
        {
                backgroundImage: `url(${image})`,
                height: '44px',
                width: '350px',
                'background-size': 'auto 44px',
                'background-repeat': 'no-repeat'
        };
        if (utilityRate) {
            return (
                <>
                    <header>
                        <div className="nav-header-wrapper dashboard-nav-wrapper">
                            <nav className="navbar navbar-expand-lg navbar-light bg-light bg-light-homeowner pt-0">
                                <a style={ image && sectionStyle } className="navbar-brand publicReferral" />
                            </nav>
                        </div>
                    </header>
                    <div className="referral-wrap mapMargin">
                        <title>Bodhi Referral</title>
                        {this.state.lat && this.state.lng && 
                        this.props.referralDetails.postHomeownerAddressForm.add &&
                        this.renderMap()}
                            <div
                                className="referral-content"
                            >
                            
                                { !this.state.isThankyouMessage
                                    ? this.renderCalculatorComponent()
                                    : this.renderThankYou()}
                        </div>
                    </div>
                </>
            );
        } else {
            return <LandingPage loadingIcon={getLoadingIcon(this.props.router.params.slug)} role={'homeowner123'} />
        }
    }
}

const mapStatetoProps = state => {
    let loggedInUser = {};
    loggedInUser['homeownerData'] =
        state.homeownerReferralDetails &&
        state.homeownerReferralDetails.homeownerReferrals &&
        state.homeownerReferralDetails.homeownerReferrals.data;
	return {
		referralDetails: state.homeownerReferralDetails,
		loader: state.loader.loader.loader,
        energyReport: state.homeownerLoggedInUser.homeownerEnergyReportReducer.data || {},
        neighbourAddress: state.homeownerReferralDetails.homeownerReferralNeighbour.data,
        loggedInUser: loggedInUser
        
	};
};
const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			setLoader,
            requestReferralNeighbour,
            receiveReferralNeighbour,
			requestPostReferralDetails,
			receivePostReferralDetails,
			requestPostAddressDetails,
            receivePostAddressDetails,
            receivePostAddressFormData
		},
		dispatch
	);
export default withRouter(
	connect(
		mapStatetoProps,
		mapDispatchToProps
	)(    GoogleApiWrapper({
        apiKey: googleMap.envConfig.googleMap.API_KEY
    })(CalculatorComponent))
);
